import React, {useEffect, Component} from 'react';
// import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import 'react-phone-input-2/lib/style.css';

// import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import $ from 'jquery';
// import transparent from '../assets/img/transparent.png';
// import user7 from '../assets/img/user7-128x128.jpg';
// import imgalert_contact from '../assets/img/alert_contact.png';
// import redtie_appstore from '../assets/img/redtie_appstore.png';
// import redtie_playstore from '../assets/img/redtie_playstore.png';
import MicRecorder from 'mic-recorder-to-mp3';
// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/receiver_innerpages_customstyle.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import '../assets/plugins/fontawesome-free/css/all.min.css';
import '../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import '../assets/css/bootstrap-select.css';
import '../assets/css/font.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min';
import queryString from 'query-string';
// import redtie_logo from '../assets/img/redtie_logo.png';
import send_icon from '../assets/img/send_icon.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import alert_search from '../assets/img/alert_search.png';
//import sample_painting from '../assets/img/sample_painting1.jpg';
//import nft_icon from '../assets/img/nft_icon.png';
//import ethereum_logo from '../assets/img/ethereum_logo.png';

import VideoRecorder from 'react-video-recorder';
import ReactTooltip from 'react-tooltip';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
//Header
import Receiver_Header_component from '../includes/Receiver_Header_component';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import Linkify from 'react-linkify';
import {ToastContainer, toast, Flip} from 'react-toastify';
const Mp3Recorder = new MicRecorder({bitRate: 128});
let nn = 0;
let ll = 0;
let mm = 0;
let docAttachmentsCount_cmb = 0;

var placeholder_cmb = document.createElement('div');
placeholder_cmb.className = 'placeholder_cmb';
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
var QRCode = require('qrcode.react');

const qs = require('qs');
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;

const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
var moment = require('moment');
var walletaadress = '';
var wllaetaadr = 'http://blezar.ephrontech.com/address/';

function validate(password, password1) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (password.length === 0 && password1.length === 0) {
    errors.push("Password and Confirm password can't be empty");
  }
  // if (password1.length === 0) {
  //     errors.push("Confirm password can't be empty");
  //   }
  if (password != password1) {
    errors.push("Passwords aren't matching");
  }

  return errors;
}
const editorConfiguration = {
  // plugins: [ Essentials, Bold, Italic, Paragraph ],
  // toolbar: [ 'bold', 'italic' ],
  link: {
    decorators: {
      openInNewTab: {
        mode: 'manual',
        label: 'Open in a new tab',
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
};
class Attach_receiver extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('receiver_loggedIn');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    // Remove a few plugins from the default setup.
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    this.audioPly = this.audioPly.bind(this);
    this.audioStop = this.audioStop.bind(this);
    this.audioPaue = this.audioPaue.bind(this);
    this.audioPly_cmb = this.audioPly_cmb.bind(this);
    this.audioPause_cmb = this.audioPause_cmb.bind(this);
    this.dcReadClk = this.dcReadClk.bind(this);
    this.view_sentmessage_click = this.view_sentmessage_click.bind(this);
    this.individual_view_sentmessage_click = this.individual_view_sentmessage_click.bind(this);

    this.view_receivedmessage_click = this.view_receivedmessage_click.bind(this);
    this.viewreceivedmessage_close1 = this.viewreceivedmessage_close1.bind(this);

    this.contacmessage_clck = this.contacmessage_clck.bind(this);
    this.respondmessage_clk = this.respondmessage_clk.bind(this);
    this.individual_respondmessage_clk = this.individual_respondmessage_clk.bind(this);
    this.respondmessage_close = this.respondmessage_close.bind(this);

    this.msg_send_cmb = this.msg_send_cmb.bind(this);
    this.msg_update_send_cmb = this.msg_update_send_cmb.bind(this);
    this.nft_transfer = this.nft_transfer.bind(this);
    this.nft_changestatus = this.nft_changestatus.bind(this);
    this.msg_save_cmb = this.msg_save_cmb.bind(this);
    this.msg_update_save_cmb = this.msg_update_save_cmb.bind(this);

    this.onMessageEdit = this.onMessageEdit.bind(this);

    this.sendmsgattach_clkNotes2_cmb = this.sendmsgattach_clkNotes2_cmb.bind(this);
    this.sendmsgattach_clkDocmnts2_cmb = this.sendmsgattach_clkDocmnts2_cmb.bind(this);
    this.sendmsgattach_clkAudio2_cmb = this.sendmsgattach_clkAudio2_cmb.bind(this);
    this.sendmsgattach_clkVideo2_cmb = this.sendmsgattach_clkVideo2_cmb.bind(this);
    this.DocLclDlete_cmb = this.DocLclDlete_cmb.bind(this);
    this.notesAtchDelte_cmb = this.notesAtchDelte_cmb.bind(this);
    this.documntAtchDelte_cmb = this.documntAtchDelte_cmb.bind(this);
    this.audioAtchDelte_cmb = this.audioAtchDelte_cmb.bind(this);
    this.videAtchDelte_cmb = this.videAtchDelte_cmb.bind(this);
    this.videoLclDelte_cmb = this.videoLclDelte_cmb.bind(this);
    this.notesLclDelte_cmb = this.notesLclDelte_cmb.bind(this);
    this.audioLclDelte_cmb = this.audioLclDelte_cmb.bind(this);
    this.dragStart_cmb = this.dragStart_cmb.bind(this);
    this.dragOver_cmb = this.dragOver_cmb.bind(this);
    this.dragEnd_cmb = this.dragEnd_cmb.bind(this);

    this.conversation_list_get = this.conversation_list_get.bind(this);

    this.onClick_followup = this.onClick_followup.bind(this);
    this.onClick_followup_filter = this.onClick_followup_filter.bind(this);

    this.message_subscribe = this.message_subscribe.bind(this);
    this.message_unsubscribe = this.message_unsubscribe.bind(this);
    // this.modal_unsubscribe = this.modal_unsubscribe.bind(this);
    this.ListTimeLineClk = this.ListTimeLineClk.bind(this);
    this.ListTimeLineClk_View = this.ListTimeLineClk_View.bind(this);
    this.onMessageDelete = this.onMessageDelete.bind(this);

    this.onClickButtonAdder = this.onClickButtonAdder.bind(this);
    this.onClickButtonSubmit = this.onClickButtonSubmit.bind(this);

    this.onChangepwd = this.onChangepwd.bind(this);
    this.onChangepwd1 = this.onChangepwd1.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showHide1 = this.showHide1.bind(this);
    this.reset_password = this.reset_password.bind(this);
    this.onClick_scrollbtm = this.onClick_scrollbtm.bind(this);
    this.Video_play_url = this.Video_play_url.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.document_read = this.document_read.bind(this);
    this.timer = null;
    this.savetimer = null;
    //Header
    // this.logout = this.logout.bind(this);
    // this.close_imageCrop = this.close_imageCrop.bind(this);
    // this.togglePopup = this.togglePopup.bind(this);
    // this.save_imageCrop = this.save_imageCrop.bind(this);

    this.state = {
      loggedIn,
      attachment_list: [],
      attachment: [],
      attachment_response: [],
      contact_number: '',
      first_name: '',
      last_name: '',
      text_message: '',
      text_id: '',
      reply_id: '',
      parent_unique_id: '',
      user_id: '',
      campaign_id: '',
      channel_id: '',
      nft_id: '',
      nft_token_id: '',
      nft_status: '',
      business_list: [],
      phone_number: '',
      plivo_phone_number: '',
      conversation_list: [],
      errors: [],
      success: [],
      notesValue2_cmb: '',
      isBlocked_cmb: false,
      messageAttachd_cmb: false,
      attchmnt_audioId_cmb: [],
      attchmnt_videoId_cmb: [],
      attchmnt_FileDocId_cmb: [],
      attchmnt_UrlIdDets_cmb: [],
      DcfilNm_cmb: '',
      attchmnt_audioIdUrl_cmb: [],
      Rnunique_id_cmb: '',
      message_edit: false,
      lodingicon_cmb: '',
      atcharryDet_cmb: [],
      msg_update_details: [],
      attachOrderIncrs_cmb: 1,

      onClick_followup_filter_status: false,
      business_subscribe_name: '',
      unsubscribe_contact_id: '',
      unsubscribe_business_id: '',
      receiver_reply_made: false,
      receiver_reply_made_sideoptns: false,
      individual_conversation_reply: [],
      timeLineListDetls: [],
      team_emails: [''],
      team_members: '3',
      current_business_name: '',
      parent_text_message: '',
      parent_shorten_url: '',
      parent_contact_total: '',
      receiver_id: '',
      email_attachment_url: '',

      passwordtype: 'password',
      confirmpasswordtype: 'password',
      password: '',
      reset_password: false,
      password1: '',
      isLoading: false,
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      audio_play_id: '',
      messageSendDisabled: false,
      nftTransferDisabled: false,
      nftChangeStatusDisabled: false,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      //     src: null,
      // 	crop: {
      // 	  unit: '%',
      // 	  width: 30,
      // 	  aspect: 14 / 12,
      // 	},
      //   display:false,
      //   croppedImage:'',
      //   receiver_profile_img:''
      attachmentdoc_count_cmb: 0,
      active_edit_id: '',
      cmb_notesedit: false,
      savingprogress: '',
      business_info_det: [],
      business_info_det_sub: [],
      window_height_conv: window.innerHeight - 130,
      window_height_cont: window.innerHeight - 130,
      window_height_attach: window.innerHeight - 250,
      active_business_id: '',
      search_status_conv: '',
      nft_timelineid: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // removeCSS("../assets/css/receiver_innerpages_customstyle.css")
  }
  componentDidMount() {
    $('body').css({overflow: 'hidden'});
    window.addEventListener('resize', this.handleResize);
    if (window.innerWidth >= 992) {
      $('.contacts_listdiv').show();
      $('.conversation_contentback').hide();
      $('.conversation_contentdsply_div').show();
    } else if (window.innerWidth <= 991) {
      $('.individual_contactmessage_div').hide();
      $('.contacts_listdiv').hide();
      $('.conversation_contentdsply_div').show();

      $('.viewreceivedmessage_div1').show();
      $('.conversation_contentback').show();
    }
    this.setState({
      window_height_conv: window.innerHeight - 130,
      window_height_cont: window.innerHeight - 130,
      window_height_attach: window.innerHeight - 250,
    });
    // loadCSS("../assets/css/receiver_innerpages_customstyle.css")
    // $(window).scroll(function () {
    //   if ($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
    //     var jumptobottom = document.getElementById('jumptobottom');
    //     if (jumptobottom) {
    //       jumptobottom.style.display = 'none';
    //     }
    //     // document.getElementById("jumptobottom").style.display = 'none'
    //   } else {
    //     var jumptobottom = document.getElementById('jumptobottom');
    //     if (jumptobottom) {
    //       jumptobottom.style.display = 'block';
    //     }
    //     // document.getElementById("jumptobottom").style.display = 'block'
    //   }
    // });

    var url = this.props.location.search;
    var business_id_url = this.props.match.params.business_id;
    var unique_id_url = this.props.match.params.unique_id;
    var contact_id_url = this.props.match.params.contact_id;
    var user_id_url = this.props.match.params.user_id;

    console.log('url',url )
    console.log('business_id_url',business_id_url )
    console.log('unique_id_url',unique_id_url )
    console.log('contact_id_url',contact_id_url )
    console.log('user_id_url',user_id_url )

    if (unique_id_url === 'unsupport') {
      $('.individual_contactmessage_div').show();
      $('.viewreceivedmessage_div1').hide();
      this.setState({receiver_reply_made_sideoptns: false});
    }
    AsyncStorage.getItem('receiver_unique_id').then((unique_id) => {
      if (unique_id) {
        console.log('receiver_unique_id', unique_id)
        if (unique_id_url == unique_id) {
          this.setState({unique_id: unique_id});
          // console.log('unique_id');
          // console.log(unique_id);
        } else {
          localStorage.removeItem('receiver_loggedIn');
          // console.log('loggedout');
          window.location.reload(true);
        }
      }
    });
    // console.log('Current_url : ' + unique_id_url);
    var params = queryString.parse(url);
    //console.log("URL Params : "+params);
    // console.log('URL : ' + url);
    AsyncStorage.setItem('receiver_business_id', business_id_url);
    AsyncStorage.setItem('receiver_unique_id', unique_id_url);
    AsyncStorage.setItem('receiver_contact_id', contact_id_url);
    AsyncStorage.setItem('receiver_user_id', user_id_url);

    console.log('nft_accept click', params.nft_accept);
  

    if (params.nft_accept) {
      console.log('nft_accept inner click');
      $('#nft_show_popup').trigger('click');
    }
    this.setState({
      attach_uniqId: unique_id_url,
      business_id: business_id_url,
      contact_id: contact_id_url,
      user_id: user_id_url,
      active_business_id: business_id_url,
    });

    setTimeout(() => {
      if (this.state.loggedIn) {
        const receiverinfo = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            contact_id: this.state.contact_id,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/text/receiver/info', receiverinfo)
          .then((res) => {
            // console.log(res.data);
            if (res.data.status === 'success') {
              // console.log('receiver_phonepin', res.data.result);
              if (res.data.result.length == 0) {
                const receiver_pin_send = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  contact_id: this.state.contact_id,
                };

                axios.post(API_CALL_ENDPOINT + '/text/receiver/pin/send', receiver_pin_send).then((res) => {
                  // console.log('receiver_pin_send', res);

                  if (res.data.status == 'success') {
                    this.props.history.push('/receiver_phonepin');
                  }
                  if (res.data.error) {
                  }
                });
              } else {
                // console.log('receiverPhone', res.data.result[0].phone_number);
                const response = res.data.result[0].phone_number.toString().substring(1, 0);
                if (response == '1' || response == '0') {
                  var phone_number = res.data.result[0].phone_number.toString().substring(1);
                  var formated_phone_number = phone_number
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                    .trim();

                  this.setState({
                    phone_number: formated_phone_number,
                    plivo_phone_number: res.data.result[0].phone_number,
                    receiver_id: res.data.result[0]._id,
                  });
                }
                const text_conversation = {
                  params: {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    business_id: this.state.business_id,
                    user_id: this.state.user_id,
                    phone_number: res.data.result[0].phone_number,
                    source: 'receiver',
                  },
                };

                axios
                  .get(API_CALL_ENDPOINT + '/text/conversation', text_conversation)
                  .then((res) => {
                    // console.log('/text/conversation', res.data);
                    if (res.data.status == 'success') {
                      this.setState({
                        conversation_list: res?.data?.result?.conversation,
                        onClick_followup_filter_status: false,
                      });

                      document.getElementById('conversation_nil').style.display = 'none';
                      document.getElementById('jumptobottom').style.display = 'block';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                    if (res.data.status === 'no_record') {
                      this.setState({
                        conversation_list: [],
                        disableconversationsearch: true,
                      });
                      document.getElementById('conversation_nil').style.display = 'block';
                      document.getElementById('jumptobottom').style.display = 'none';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                    if (res.data.error) {
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.status === 'no_record') {
                        this.setState({
                          conversation_list: [],
                          disableconversationsearch: true,
                        });
                        document.getElementById('conversation_nil').style.display = 'block';
                        document.getElementById('jumptobottom').style.display = 'none';
                        document.getElementById('viewsentmessage_div').style.display = 'none';
                      }
                      if (error.response.data.error) {
                      }
                    }
                  });

                const businessList = {
                  params: {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    contact_id: this.state.contact_id,
                    phone_number: res.data.result[0].phone_number,
                  },
                };

                axios
                  .get(API_CALL_ENDPOINT + '/text/receiver/business/list', businessList)
                  .then((res) => {
                    // console.log('receiver/business/list', res.data);
                    // console.log(res.data.result[0].phone_number.length)
                    if (res.data.status === 'success') {
                      this.setState({business_list: res.data.result});
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                if (res.data.result[0].pin_status == 'no') {
                  this.props.history.push('/receiver_phonepin');
                } else {
                  if (res.data.result[0].security_preferences === '') {
                    this.props.history.push('/receiver_securitypreferences');
                  }
                  if (this.state.loggedIn) {
                    // console.log('LoggedIN');
                  } else {
                    if (res.data.result[0].security_preferences === 'standard_pin') {
                      this.props.history.push('/receiver_standardpin');
                    }
                    if (res.data.result[0].security_preferences === 'every_time') {
                      this.props.history.push('/receiver_dynamicpin');
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const receiver_send = {
          apikey: API_KEY,
          secret: API_SECRET,
          contact_id: this.state.contact_id,
        };
        axios
          .post(API_CALL_ENDPOINT + '/text/receiver/pin/send', receiver_send)
          .then((res) => {
            // console.log(res.data);
            if (res.data.status === 'success') {
              // console.log('receiver_phonepin', res.data);
              const response = res.data.result.phone_number.toString().substring(1, 0);
              if (response == '1' || response == '0') {
                var phone_number = res.data.result.phone_number.toString().substring(1);
                var formated_phone_number = phone_number
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({
                  phone_number: formated_phone_number,
                  plivo_phone_number: res.data.result.phone_number,
                  receiver_id: res.data.result._id,
                });
              }
              const text_conversation = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  business_id: this.state.business_id,
                  user_id: this.state.user_id,
                  phone_number: res.data.result.phone_number.toString(),
                  source: 'receiver',
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/text/conversation', text_conversation)
                .then((res) => {
                  // console.log('/text/conversation', res.data);
                  if (res.data.status == 'success') {
                    this.setState({
                      conversation_list: res?.data?.result?.conversation,
                      onClick_followup_filter_status: false,
                    });

                    document.getElementById('conversation_nil').style.display = 'none';
                    document.getElementById('jumptobottom').style.display = 'block';
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                  if (res.data.status === 'no_record') {
                    this.setState({
                      conversation_list: [],
                      disableconversationsearch: true,
                    });
                    document.getElementById('conversation_nil').style.display = 'block';
                    document.getElementById('jumptobottom').style.display = 'none';
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                  if (res.data.error) {
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.status === 'no_record') {
                      this.setState({
                        conversation_list: [],
                        disableconversationsearch: true,
                      });
                      document.getElementById('conversation_nil').style.display = 'block';
                      document.getElementById('jumptobottom').style.display = 'none';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                    if (error.response.data.error) {
                    }
                  }
                });

              const businessList = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  contact_id: this.state.contact_id,
                  phone_number: res.data.result.phone_number,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/text/receiver/business/list', businessList)
                .then((res) => {
                  // console.log('receiver/business/list', res.data);
                  // console.log(res.data.result[0].phone_number.length)
                  if (res.data.status === 'success') {
                    this.setState({business_list: res.data.result});
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              if (res.data.result.length == 0) {
                this.props.history.push('/receiver_phonepin');
              } else {
                if (res.data.result.pin_status == 'no') {
                  this.props.history.push('/receiver_phonepin');
                } else {
                  if (res.data.result.security_preferences === '') {
                    this.props.history.push('/receiver_securitypreferences');
                  }
                  if (this.state.loggedIn) {
                    // console.log('LoggedIN');
                  } else {
                    if (res.data.result.security_preferences === 'standard_pin') {
                      this.props.history.push('/receiver_standardpin');
                    }
                    if (res.data.result.security_preferences === 'every_time') {
                      this.props.history.push('/receiver_dynamicpin');
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (unique_id_url != 'unsupport') {
        const attachlist_details = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            unique_id: this.state.attach_uniqId ? this.state.attach_uniqId : undefined,
            attachment: this.state.attach_uniqId ? true : false,
            contact_id: this.state.contact_id,
          },
        };

        axios.get(API_CALL_ENDPOINT + '/text/attachment/list', attachlist_details).then((res) => {
           console.log('/text/attachment/list', res.data);
           console.log('/text/attachment/list', res.data.result.contact.phone_number.toString());
          const response = res.data.result.contact.phone_number.toString().substring(1, 0);
          if (response == '1' || response == '0' || response == '9') {
            var plivo_number = res.data.result.contact.phone_number.toString().substring(1);
            var plivo_number = plivo_number
              .replace(/[^\dA-Z]/g, '')
              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
              .trim();

            this.setState({
              contact_number: plivo_number,
              plivo_phone_number: res.data.result.contact.phone_number,
            });
          }
          const timeline_details = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              text_id: res.data.result.campaign_id,
              contact_id: this.state.contact_id ? this.state.contact_id : undefined,
            },
          };

          // console.log(timeline_details);
          axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
            console.log(res);
            if (res.data.status == 'success') {
              for (var j in res.data.result) {
                if (res.data.result[j].file_type == 'nft') {
                  this.setState({nft_timelineid: res.data.result[j]._id});
                }
                if (res.data.result[j].status == 'unread') {
                  if (
                    res.data.result[j].file_type == 'notes' ||
                    res.data.result[j].file_type == 'google_calendar' ||
                    res.data.result[j].file_type == 'quick_meeting'
                  ) {
                    const unread_details = {
                      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                      secret: 'R1eqD2twI3E4',
                      user_id: res.data.result[j].parent_id,
                      business_id: res.data.result[j].business_id,
                      timeline_id: res.data.result[j]._id,
                    };

                    // console.log(unread_details);

                    axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                      // console.log(res);
                      // console.log(res.data.message);
                    });
                  }
                  if (res.data.result[j].display_name == 'opened') {
                    const unread_details = {
                      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                      secret: 'R1eqD2twI3E4',
                      user_id: res.data.result[j].parent_id,
                      business_id: res.data.result[j].business_id,
                      timeline_id: res.data.result[j]._id,
                    };

                    // console.log(unread_details);

                    axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                      console.log(res);
                      console.log(res.data.result[j]._id);
                    });
                  }
                }
              }
            }
            if (res.data.error) {
            }
          });

          const conversation_info = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              business_id: this.state.business_id,
              user_id: this.state.user_id,
              text_id: res.data.result.campaign_id,
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/text/campaign/info', conversation_info)
            .then((res) => {
              // console.log('/text/campaign/info', res.data);
              if (res.data.status == 'success') {
                this.setState({
                  individual_conversation_reply: res.data.result,
                  text_id: res.data.result._id,
                  email_attachment_url: res.data.result.attachment_url,
                  password: res.data.result.password.length == 0 ? '' : res.data.result.password,
                  reset_password: res.data.result.password.length == 0 ? false : true,
                });
                if (res.data.result.receiver_reply == 'yes') {
                  const reveiver_info = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      business_id: this.state.business_id,
                      user_id: this.state.user_id,
                      text_id: res.data.result._id,
                    },
                  };
                  axios
                    .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
                    .then((res) => {
                      // console.log('/text/reply/info ', res.data);
                      if (res.data.status == 'success') {
                        this.setState({
                          individual_conversation_reply1: res.data.result,
                        });
                      }
                      if (res.data.error) {
                      }
                    })
                    .catch((error) => {
                      // console.log(error)
                    });
                  this.setState({
                    receiver_reply_made: true,
                  });
                } else {
                  this.setState({
                    receiver_reply_made: false,
                  });
                }
              }
              if (res.data.error) {
              }
            })
            .catch((error) => {
              // console.log(error)
            });
          this.setState({
            attachment_list: res.data.result.attachment_list,
            attachment_response: res.data.result,
            first_name: res.data.result.contact.first_name,
            last_name: res.data.result.contact.last_name,
            text_message: res.data.result.text_message,
            received_date: res.data.result.created_date,
            user_id: res.data.result.parent._id,
            campaign_id: res.data.result.campaign_id,
            channel_id: res.data.result.channel_id,
          });
          this.setState({attachment: res.data.result.attachment});

          if (res.data.status == 'success') {
            this.setState({shortenUrl: res.data.result.shorten_url});
          }
          if (res.data.error) {
          }

          for (var j in res.data.result.attachment_list) {
            if (res.data.result.attachment_list[j].attachment_type == 'notes') {
              // console.log('ll', ll);
              ll++;

              const unread_details = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: res.data.result.parent._id,
                business_id: res.data.result.attachment_list[j].business_id,
                attachment_id: res.data.result.attachment_list[j]._id,
                campaign: res.data.result.campaign_id,
                file_name: 'notes' + ll,
                file_type: 'notes',
                display_name: 'Notes_' + res.data.result.attachment_list[j].updated_date,
                status: 'read',
                channel: res.data.result.channel_id,
              };

              // // console.log(unread_details);

              // axios.post(API_CALL_ENDPOINT+'/text/timeline/create', unread_details)
              //     .then(res => {
              //         console.log(res);
              //         // console.log(res.data.message);

              //     })
            } else if (res.data.result.attachment_list[j].attachment_type == 'video') {
              // console.log('mm', mm);
              mm++;

              const unread_details = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: res.data.result.parent._id,
                business_id: res.data.result.attachment_list[j].business_id,
                attachment_id: res.data.result.attachment_list[j]._id,
                campaign: res.data.result.campaign_id,
                file_name: 'video' + mm,
                file_type: 'video',
                display_name: 'Redtie_' + res.data.result.attachment_list[j].updated_date + '.mp4',
                status: 'unread',
                channel: res.data.result.channel_id,
              };

              // // console.log(unread_details);

              // axios.post(API_CALL_ENDPOINT+'/text/timeline/create', unread_details)
              //     .then(res => {
              //         console.log(res);
              //         // console.log(res.data.message);

              //     })
            } else if (res.data.result.attachment_list[j].attachment_type == 'audio') {
              // console.log('nn', nn);
              nn++;

              const unread_details = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: res.data.result.parent._id,
                business_id: res.data.result.attachment_list[j].business_id,
                attachment_id: res.data.result.attachment_list[j]._id,
                campaign: res.data.result.campaign_id,
                file_name: 'audio' + nn,
                file_type: 'audio',
                display_name: 'Redtie_' + res.data.result.attachment_list[j].updated_date + '.mp3',
                status: 'unread',
                channel: res.data.result.channel_id,
              };

              // // console.log(unread_details);

              // axios.post(API_CALL_ENDPOINT+'/text/timeline/create', unread_details)
              //     .then(res => {
              //         console.log(res);
              //         // console.log(res.data.message);

              //     })
            } else if (res.data.result.attachment_list[j].attachment_type == 'nft') {
              this.setState({
                nft_id: res.data.result.attachment_list[j]?.nft_id?._id,
                nft_token_id: res.data.result.attachment_list[j]?.nft_id?.token_id,
                nft_status: res.data.result.attachment_list[j]?.nft_id?.status,
              });
              console.log('NFT _id', res.data.result.attachment_list[j]?.nft_id?._id);
              console.log('NFT token_id', res.data.result.attachment_list[j]?.nft_id?.token_id);
            } else {
              for (var k in res.data.result.attachment_list[j].attachment) {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.parent._id,
                  business_id: res.data.result.attachment_list[j].business_id,
                  attachment_id: res.data.result.attachment_list[j].attachment[k]._id,
                  campaign: res.data.result.campaign_id,
                  file_name: res.data.result.attachment_list[j].attachment[k].file_name,
                  file_type: res.data.result.attachment_list[j].attachment_type,
                  display_name: res.data.result.attachment_list[j].attachment[k].display_name,
                  status: 'unread',
                  channel: res.data.result.channel_id,
                };

                // // console.log(unread_details);

                // axios.post(API_CALL_ENDPOINT+'/text/timeline/create', unread_details)
                //     .then(res => {
                //         // console.log(res);
                //         // console.log(res.data.message);

                //     })
              }
            }
          }
        });
      }

      const business_info = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/text/receiver/business/info', business_info)
        .then((res) => {
          // console.log('receiver/business/info', res.data);
          if (res.data.status == 'success') {
            this.setState({current_business_name: res.data.result.business_name, business_info_det: res.data.result});
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    }, 100);
    /**********Contact Message**********/
    $('.contacmessage_clck').on('click', function () {
      $('.overallcontactlist_div').hide();
      $('.individual_contactmessage_div').show();
    });

    $('.alert_closing').on('click', function () {
      window.location.reload();
    });

    $('.ctmessage_close').on('click', function () {
      $('.individual_contactmessage_div').hide();
      $('.overallcontactlist_div').show();
    });
    $(document).on('click', '.url_redirection', function () {
      var $self = $(this);
      var url = $self.text();
      var result = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
      // const myUrl = withHttp(url);
      const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
      var links = url.indexOf('://') === -1 ? 'https://' + url : url;
      console.log('result', result);
      // console.log("myUrl",myUrl);
      console.log('withHttp', withHttp);
      window.open(links, '_blank');
      console.log('links', links);
    });
    /**********View Received Message**********/
    $('.viewreceivedmessagebtn_clk').on('click', function () {
      $('.individual_contactmessage_div').hide();
      $('.viewreceivedmessage_div').show();
    });

    $('.viewreceivedmessage_close').on('click', function () {
      $('.individual_contactmessage_div').show();
      $('.viewreceivedmessage_div').hide();
    });

    $('.viewreceivedmessagebtn_clk1').on('click', function () {
      $('.individual_contactmessage_div').hide();
      $('.viewreceivedmessage_div1').show();
    });

    $('.viewreceivedmessage_close1').on('click', function () {
      $('.individual_contactmessage_div').show();
      $('.viewreceivedmessage_div1').hide();
    });

    /*$('.viewreceivedmessagebtn_clk2').on('click', function () {
        $('.individual_contactmessage_div').hide();
        $('.viewreceivedmessage_div2').show();
   });
   
   $('.viewreceivedmessage_close2').on('click', function () {
        $('.individual_contactmessage_div').show();
        $('.viewreceivedmessage_div2').hide();
   });*/

    /**********View Sent Message**********/
    $('.viewsentmessagebtn_clk').on('click', function () {
      $('.individual_contactmessage_div').hide();
      $('.viewsentmessage_div').show();
    });

    $('.viewsentmessage_close').on('click', function () {
      $('.individual_contactmessage_div').show();
      $('.viewsentmessage_div').hide();
    });

    /*******View Sent Message from view attachment******/
    $('.viewsentmessage_clk1').click(function () {
      $('.viewreceivedmessage_div').hide();
      $('.viewsentmessage_div1').show();
    });

    $('.viewsentmessage_close1').click(function () {
      $('.viewsentmessage_div1').hide();
      $('.viewreceivedmessage_div').show();
    });

    /**********From CMB - One to One**********/

    $('.respondmessage_clk').click(function () {
      $('.individual_contactmessage_div').hide();
      $('.respondmessage_div').show();
    });

    $('.respondmessage_close').click(function () {
      $('.respondmessage_div').hide();
      $('.individual_contactmessage_div').show();
    });

    /**********From View Attachment- One to One**********/

    $('.respondmessage_clk1').click(function () {
      $('.viewreceivedmessage_div1').hide();
      $('.respondmessage_div1').show();
    });

    $('.respondmessage_close1').click(function () {
      $('.respondmessage_div1').hide();
      $('.viewreceivedmessage_div1').show();
    });

    /**********Saved Message View**********/

    $('.savedmessagebtn_clk').click(function () {
      $('.individual_contactmessage_div').hide();
      $('.saved_respondmessage_div').show();
    });

    $('.saved_respondmessage_close1').click(function () {
      $('.saved_respondmessage_div').hide();
      $('.individual_contactmessage_div').show();
    });

    $('.savedmessagebtn_clk1').click(function () {
      $('.viewreceivedmessage_div2').hide();
      $('.saved_respondmessage_div1').show();
    });

    $('.saved_respondmessage_close2').click(function () {
      $('.saved_respondmessage_div1').hide();
      $('.viewreceivedmessage_div2').show();
    });

    $('.sendmsgattach_slim_clk').click(function () {
      $('.sendmsg_attachmentdiv_sm').show();
    });

    /**********Business Details Toggle**********/

    $('.businessdtls_clck_unsub').on('click', function () {
      $('body').toggleClass('control-sidebar-slide-open3');
      $('body').removeClass('control-sidebar-slide-open1');
      $('.contactdtls_sidebar').addClass('ctdtls_hide');

      if ($('body').hasClass('control-sidebar-slide-open3')) {
        $('.accent-custom .control-sidebar3.contactdtls_sidebar3::before').addClass('slider_animation');
        $('.contactdtls_sidebar3').removeClass('ctdtls_hide');
        $('.contactdtls_sidebar3').addClass('ctdtls_show slider_animation');
      } else {
        $('.accent-custom .control-sidebar3.contactdtls_sidebar3::before').addClass('slider_animation');
        $('.contactdtls_sidebar3').removeClass('ctdtls_show');
        $('.contactdtls_sidebar3').addClass('ctdtls_hide');
      }
    });

    /**********Contact Details**********/
    $('.ctdtls_close').on('click', function () {
      $('.contactdtls_sidebar').removeClass('ctdtls_show');
      $('.contactdtls_sidebar').addClass('ctdtls_hide');
    });

    $('.ctdtls_close3').on('click', function () {
      $('.contactdtls_sidebar3').removeClass('ctdtls_show');
      $('.contactdtls_sidebar3').addClass('ctdtls_hide');
    });
    setTimeout(() => {
      $('.newfeatures_announcement').show(1000);
    }, 6000);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.notesValue2_cmb !== nextState.notesValue2_cmb) {
      // this.autoupdateNotes2_cmb();
      return false;
    } else {
      return true;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      //
    }

    // if (prevState.notesValue2_cmb !== this.state.notesValue2_cmb) {
    //   if (this.state.cmb_notesedit === false) {
    //     if (this.state.notesValue2_cmb.length >= 13) {
    //       this.saveNotes2_cmb();
    //     }
    //   }

    //   this.autoupdateNotes2_cmb();
    // }
  }
  onClick_scrollbtm() {
    // const {conversationList} = this.refs;
    // conversationList.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
    $('#convlist_loadmore').scrollTop($('#convlist_loadmore')[0].scrollHeight);
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input',
    });
  }
  showHide1(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      confirmpasswordtype: this.state.confirmpasswordtype === 'input' ? 'password' : 'input',
    });
  }
  onChangepwd(e) {
    this.setState({password: e.target.value});
  }
  onChangepwd1(e) {
    this.setState({password1: e.target.value});
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  onSubmit(e) {
    e.preventDefault();
    this.setState({isLoading: true});
    const {password, password1} = this.state;

    const errors = validate(password, password1);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      const userObject = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_url: this.state.email_attachment_url ? this.state.email_attachment_url : undefined,
        receiver_id: this.state.receiver_id,
        text_id: this.state.text_id,
        password: password,
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/receiver/password', userObject)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == 'success' || 'conflict') {
            const success = [];
            success.push(res.data.message);
            console.log(this.state.success);
            this.setState({
              success,
            });
            setTimeout(() => {
              this.setState({isLoading: false});
              this.setState({success: []});
            }, 3000);
            const conversation_info = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                business_id: this.state.business_id,
                user_id: this.state.user_id,
                text_id: this.state.text_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/text/campaign/info', conversation_info)
              .then((res) => {
                console.log('/text/campaign/info', res.data);
                if (res.data.status == 'success') {
                  this.setState({
                    individual_conversation_reply: res.data.result,
                    text_id: res.data.result._id,
                    email_attachment_url: res.data.result.attachment_url,
                    password: res.data.result.password.length == 0 ? '' : res.data.result.password,
                    reset_password: res.data.result.password.length == 0 ? false : true,
                    password1: '',
                    passwordtype: 'password',
                    confirmpasswordtype: 'password',
                  });
                  if (res.data.result.receiver_reply == 'yes') {
                    const reveiver_info = {
                      params: {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        business_id: this.state.business_id,
                        user_id: this.state.user_id,
                        text_id: res.data.result._id,
                      },
                    };
                    axios
                      .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
                      .then((res) => {
                        console.log('/text/reply/info ', res.data);
                        if (res.data.status == 'success') {
                          this.setState({
                            individual_conversation_reply1: res.data.result,
                          });
                        }
                        if (res.data.error) {
                        }
                      })
                      .catch((error) => {
                        // console.log(error)
                      });
                    this.setState({
                      receiver_reply_made: true,
                    });
                  } else {
                    this.setState({
                      receiver_reply_made: false,
                    });
                  }
                }
                if (res.data.error) {
                }
              })
              .catch((error) => {
                // console.log(error)
              });
          }
          // if (res.data.status == "conflict"){
          //     const success = [];
          //     success.push(res.data.message);
          //     console.log(this.state.success);
          //     this.setState({ success });
          //     setTimeout(() => {
          //         this.setState({ isLoading: false });
          //         this.setState({ success: [] });
          //       }, 3000)

          // }
          if (res.data.error) {
            if (res.data.error.error_type == 'invalid_password') {
              this.setState({isLoading: false});
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'conflict') {
              const success = [];
              success.push(error.response.data.message);
              console.log(this.state.success);
              this.setState({
                success,
              });
              setTimeout(() => {
                this.setState({isLoading: false});
                this.setState({success: []});
              }, 3000);
              const conversation_info = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  business_id: this.state.business_id,
                  user_id: this.state.user_id,
                  text_id: this.state.text_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/text/campaign/info', conversation_info)
                .then((res) => {
                  console.log('/text/campaign/info', res.data);
                  if (res.data.status == 'success') {
                    this.setState({
                      individual_conversation_reply: res.data.result,
                      text_id: res.data.result._id,
                      email_attachment_url: res.data.result.attachment_url,
                      password: res.data.result.password.length == 0 ? '' : res.data.result.password,
                      reset_password: res.data.result.password.length == 0 ? false : true,
                      password1: '',
                      passwordtype: 'password',
                      confirmpasswordtype: 'password',
                    });
                    if (res.data.result.receiver_reply == 'yes') {
                      const reveiver_info = {
                        params: {
                          apikey: API_KEY,
                          secret: API_SECRET,
                          business_id: this.state.business_id,
                          user_id: this.state.user_id,
                          text_id: res.data.result._id,
                        },
                      };
                      axios
                        .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
                        .then((res) => {
                          console.log('/text/reply/info ', res.data);
                          if (res.data.status == 'success') {
                            this.setState({
                              individual_conversation_reply1: res.data.result,
                            });
                          }
                          if (res.data.error) {
                          }
                        })
                        .catch((error) => {
                          // console.log(error)
                        });
                      this.setState({
                        receiver_reply_made: true,
                      });
                    } else {
                      this.setState({
                        receiver_reply_made: false,
                      });
                    }
                  }
                  if (res.data.error) {
                  }
                })
                .catch((error) => {
                  // console.log(error)
                });
            }
            // if (res.data.status == "conflict"){
            //     const success = [];
            //     success.push(res.data.message);
            //     console.log(this.state.success);
            //     this.setState({ success });
            //     setTimeout(() => {
            //         this.setState({ isLoading: false });
            //         this.setState({ success: [] });
            //       }, 3000)

            // }
            if (error.response.data.error) {
              if (error.response.data.error.error_type == 'invalid_password') {
                this.setState({isLoading: false});
                const errors = [];
                errors.push(error.response.data.error.message);
                console.log(this.state.errors);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById('error').style.display='none'
                }, 3000);
              }
            }
          }
        });
    }
  }
  reset_password() {
    const reset_password = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      receiver_id: this.state.receiver_id,
      text_id: this.state.text_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/receiver/password/remove', reset_password)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          const success = [];
          success.push(res.data.message);
          console.log(this.state.success);
          this.setState({success});
          setTimeout(() => {
            this.setState({isLoading: false});
            this.setState({success: []});
          }, 3000);
          const conversation_info = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              business_id: this.state.business_id,
              user_id: this.state.user_id,
              text_id: this.state.text_id,
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/text/campaign/info', conversation_info)
            .then((res) => {
              console.log('/text/campaign/info', res.data);
              if (res.data.status == 'success') {
                this.setState({
                  individual_conversation_reply: res.data.result,
                  text_id: res.data.result._id,
                  email_attachment_url: res.data.result.attachment_url,
                  password: res.data.result.password.length == 0 ? '' : res.data.result.password,
                  reset_password: res.data.result.password.length == 0 ? false : true,
                  password1: '',
                  passwordtype: 'password',
                  confirmpasswordtype: 'password',
                });
                if (res.data.result.receiver_reply == 'yes') {
                  const reveiver_info = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      business_id: this.state.business_id,
                      user_id: this.state.user_id,
                      text_id: res.data.result._id,
                    },
                  };
                  axios
                    .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
                    .then((res) => {
                      console.log('/text/reply/info ', res.data);
                      if (res.data.status == 'success') {
                        this.setState({
                          individual_conversation_reply1: res.data.result,
                        });
                      }
                      if (res.data.error) {
                      }
                    })
                    .catch((error) => {
                      // console.log(error)
                    });
                  this.setState({
                    receiver_reply_made: true,
                  });
                } else {
                  this.setState({
                    receiver_reply_made: false,
                  });
                }
              }
              if (res.data.error) {
              }
            })
            .catch((error) => {
              // console.log(error)
            });
        }
        if (res.data.status == 'conflict') {
          const success = [];
          success.push(res.data.message);
          console.log(this.state.success);
          this.setState({success});
          setTimeout(() => {
            this.setState({isLoading: false});
            this.setState({success: []});
          }, 3000);
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'conflict') {
            const success = [];
            success.push(error.response.data.message);
            console.log(this.state.success);
            this.setState({success});
            setTimeout(() => {
              this.setState({isLoading: false});
              this.setState({success: []});
            }, 3000);
          }
          if (error.response.data.error) {
          }
        }
      });
  }
  status_hide(id) {
    // console.log("id",id)
    // $('.TimeLinDivLs_Contacts_view_'+id).removeClass('show');
    $('.TimeLinDivLs_Contacts_view_' + id).hide();
    // this.setState({timeLineListDetls:[]})
  }
  onClickButtonAdder(e) {
    e.preventDefault();

    if (this.state.team_emails.length < this.state.team_members) {
      console.log('added');
      this.setState({
        team_emails: ['', ...this.state.team_emails],
      });
    } else {
      var errors = [];
      errors.push('To add more contacts, please upgrade your plan');
      this.setState({errors});
      // console.log(this.state.errors);
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
    }
    if (this.state.team_emails.length == this.state.team_members) console.log('limit');
  }

  onClickFormGroupButton(index, e) {
    e.preventDefault();
    let team_emails = [...this.state.team_emails];
    team_emails.splice(index, 1);
    this.setState({team_emails});
  }

  onChangeFormGroupInput(index, e) {
    let team_emails = [...this.state.team_emails];
    team_emails[index] = e.target.value;
    // var regx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    // if(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(team_emails[index])){
    //   this.setState({ team_emails });
    // }
    console.log(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(team_emails[index]));

    this.setState({team_emails});
  }
  onChangeFormGroupInput1(e) {
    let team_emails = [...this.state.team_emails];
    team_emails[0] = e.target.value;
    this.setState({team_emails});
  }

  onClickButtonSubmit(e) {
    e.preventDefault();
    const filteredValues = this.state.team_emails.filter((value) => value);
    const filteredstring = filteredValues.toString();
    const mail_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      email_address: filteredstring,
      receiver_id: this.state.receiver_id,
      text_id: this.state.text_id,
      attachment_url: this.state.email_attachment_url ? this.state.email_attachment_url : undefined,
    };
    console.log(mail_details);

    axios
      .post(API_CALL_ENDPOINT + '/text/receiver/mail/send', mail_details)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          $('#modal_securityprefer_email .close').click();
          const success = [];
          success.push(res.data.message);
          this.setState({success});

          setTimeout(() => {
            this.setState({
              success: [],
            });
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(filteredValues);
  }

  audioPly = (attachment, attach_det) => {
    console.log('attachment_detail', attachment);
    if (
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp3' ||
      attachment?.second_file_path?.split('.')[attachment?.second_file_path?.split('.').length - 1] == 'mp3'
    ) {
      if (this.state.audio_play_id) {
        this.audioStop();
      }

      var PlyIdn = attachment._id;
      var playx = document.getElementById('audio_' + attachment._id);
      const audio_url_detail = {
        params: {
          object_key:
            // attachment.file_path,
            attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
              ? attachment.file_path
              : attachment.second_file_path,
        },
      };

      console.log(audio_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);

          this.setState({
            play_audio_url: res.data,
            audio_play_id: attachment._id,
          });
          playx.play();
          $('#audio_' + attachment._id).show();
          $('.adiPlyICn_' + attachment._id).hide();
        }
        if (res.data.error) {
        }
      });
      // $('.adiPlyICn_' + attachment._id).hide();
      $('.adiPusIcn_' + attachment._id).hide();
      playx.onended = function () {
        $('.adiPusIcn_' + PlyIdn).hide();
        $('.adiPlyICn_' + PlyIdn).show();
        $('#audio_' + PlyIdn).hide();
      };
    } else {
      const audio_url_detail = {
        params: {
          object_key: attachment.file_path,
          // attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
          //   ? attachment.file_path
          //   : attachment.second_file_path,
        },
      };

      console.log(audio_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);
          window.open(res.data, '_blank');
        }
        if (res.data.error) {
        }
      });
    }
    const text_conversation_read = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: this.state.business_id,
        user_id: this.state.user_id,
        attachment_id: attach_det._id,
      },
    };
    console.log('text_conversation_read', text_conversation_read);
    axios
      .get(API_CALL_ENDPOINT + '/text/timeline/info', text_conversation_read)
      .then((res) => {
        if (res.data.status == 'success') {
          console.log('/text/timeline/info', res.data);
          const read_details = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: attach_det.business_id,
            timeline_id: res.data.result[0]._id,
          };

          // console.log(read_details);

          axios.post(API_CALL_ENDPOINT + '/text/timeline/update', read_details).then((res) => {
            console.log(res);
            console.log(res.data.message);
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  audioPaue = (e) => {
    var pausex = document.getElementById('audio_' + e.target.id);
    pausex.pause();
    $('.adiPusIcn_' + e.target.id).hide();
    $('.adiPlyICn_' + e.target.id).show();
    $('#audio_' + e.target.id).hide();
  };
  audioStop() {
    if (this.state.audio_play_id) {
      //   var pausex = document.getElementById("audio_" + this.state.audio_play_id);
      //   pausex.pause();
      $('.adiPusIcn_tostop').hide();
      $('.adiPlyICn_toshow').show();
      $('#audio_' + this.state.audio_play_id).hide();
    }
  }
  audioPly_cmb = (attachment) => {
    if (
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp3' ||
      attachment?.second_file_path?.split('.')[attachment?.second_file_path?.split('.').length - 1] == 'mp3'
    ) {
      console.log('attachment_detail', attachment);
      if (this.state.audio_play_id) {
        this.audioStop();
      }

      var PlyIdn = attachment._id;
      var playx = document.getElementById('audio_' + attachment._id);
      const audio_url_detail = {
        params: {
          object_key:
            // attachment.file_path,
            attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
              ? attachment.file_path
              : attachment.second_file_path,
        },
      };

      console.log(audio_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);

          this.setState({
            play_audio_url: res.data,
            audio_play_id: attachment._id,
          });
          playx.play();
          $('#audio_' + attachment._id).show();
          $('.adiPlyICn_' + attachment._id).hide();
        }
        if (res.data.error) {
        }
      });
      // var PlyIdn = e.target.id;
      // var playx = document.getElementById("audio_"+e.target.id);
      // playx.play();
      // $('.adiPlyICn_' + attachment._id).hide();
      $('.adiPusIcn_' + attachment._id).hide();
      playx.onended = function () {
        $('.adiPusIcn_' + PlyIdn).hide();
        $('.adiPlyICn_' + PlyIdn).show();
        $('#audio_' + PlyIdn).hide();
      };
    } else {
      const audio_url_detail = {
        params: {
          object_key: attachment.file_path,
          // attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
          //   ? attachment.file_path
          //   : attachment.second_file_path,
        },
      };

      console.log(audio_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);
          window.open(res.data, '_blank');
        }
        if (res.data.error) {
        }
      });
    }
  };

  audioPause_cmb = (e) => {
    var pausex = document.getElementById('audio_' + e.target.id);
    pausex.pause();
    $('.adiPusIcn_' + e.target.id).hide();
    $('.adiPlyICn_' + e.target.id).show();
    $('#audio_' + e.target.id).hide();
  };

  dcReadClk = (attachment, id, business_id, attachment_id, file_name, file_type, display_name, index) => {
    if (file_type == 'video') {
      const text_conversation_read = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
          user_id: this.state.user_id,
          attachment_id: id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/text/timeline/info', text_conversation_read)
        .then((res) => {
          if (res.data.status == 'success') {
            // console.log("/text/timeline/info",res.data);
            const read_details = {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: business_id,
              timeline_id: res.data.result[0]._id,
            };

            // console.log(read_details);

            axios.post(API_CALL_ENDPOINT + '/text/timeline/update', read_details).then((res) => {
              console.log(res);
              console.log(res.data.message);
            });
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    } else if (file_type == 'documents') {
      const text_conversation_read = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
          user_id: this.state.user_id,
          attachment_id: id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/text/timeline/info', text_conversation_read)
        .then((res) => {
          if (res.data.status == 'success') {
            console.log('/text/timeline/info', res.data.result);
            for (var j in res.data.result) {
              if (res.data.result[j].file_name == file_name) {
                if (res.data.result[j].contact_id == this.state.contact_id) {
                  const read_details = {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                    business_id: business_id,
                    timeline_id: res.data.result[j]._id,
                  };
                  axios.post(API_CALL_ENDPOINT + '/text/timeline/update', read_details).then((res) => {
                    console.log(res);
                    console.log(res.data.message);
                  });
                  if (res.data.error) {
                  }
                }
              }
            }
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
      console.log('attachment_detail', attachment);
      var fileType = attachment.file_path.split('.');
      const document_url_detail = {
        params: {
          object_key:
            // attachment.file_path ? attachment.file_path : undefined,
            attachment.file_path
              ? attachment?.file_path?.split('.')[attachment.file_path.split('.').length - 1] === 'mp4'
                ? attachment.file_path
                : attachment?.second_file_path?.split('.')[attachment.second_file_path.split('.').length - 1] === 'mp4'
                ? attachment.second_file_path
                : attachment.file_path
              : undefined,
        },
      };

      console.log(document_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);
          if (
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
          ) {
            this.setState({
              video_play_link: res.data,
              Lightbox_open_video:
                attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
                attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
                  ? true
                  : false,
            });
          } else {
            this.setState({
              document_url: res.data,
              Lightbox_open:
                fileType[fileType.length - 1] === 'jpg' ||
                fileType[fileType.length - 1] === 'jpeg' ||
                fileType[fileType.length - 1] === 'png' ||
                fileType[fileType.length - 1] === 'jfif' ||
                fileType[fileType.length - 1] === 'gif'
                  ? true
                  : false,
            });
          }

          // this.setState({
          //   document_url: res.data,
          //   Lightbox_open:
          //     fileType[fileType.length - 1] === 'jpg' ||
          //     fileType[fileType.length - 1] === 'jpeg' ||
          //     fileType[fileType.length - 1] === 'png' ||
          //     fileType[fileType.length - 1] === 'jfif' ||
          //     fileType[fileType.length - 1] === 'gif'
          //       ? true
          //       : false,
          // });

          //   if(fileType[fileType.length - 1] === "jpg" || fileType[fileType.length - 1] === "jpeg" || fileType[fileType.length - 1] === "png")
          //     {null}else if(fileType[fileType.length - 1] === "pdf" || fileType[fileType.length - 1] === "PDF"){
          //         AsyncStorage.setItem("PDF_View_url", res.data)
          //           window.open(
          //             "http://" + window.location.href.split("/")[2] + "/pdf_viewer/",
          //             "_blank"
          //           )

          //     }else{
          //         window.open(res.data, "_blank");
          //     }
          if (
            fileType[fileType.length - 1] === 'jpg' ||
            fileType[fileType.length - 1] === 'jpeg' ||
            fileType[fileType.length - 1] === 'png' ||
            fileType[fileType.length - 1] === 'jfif' ||
            fileType[fileType.length - 1] === 'gif' ||
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
          ) {
            // null
          } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
            AsyncStorage.setItem('PDF_View_url', res.data);
            window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
          } else {
            window.open(res.data, '_blank');
          }

          //   :
          //     ? AsyncStorage.setItem("PDF_View_url", res.data) &&
          //       window.open(
          //         "http://" + window.location.href.split("/")[2] + "/pdf_viewer/",
          //         "_blank"
          //       )
          //       :
        }
        if (res.data.error) {
        }
      });
    } else if (file_type == 'images') {
      const text_conversation_read = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
          user_id: this.state.user_id,
          attachment_id: id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/text/timeline/info', text_conversation_read)
        .then((res) => {
          if (res.data.status == 'success') {
            console.log('/text/timeline/info', res.data.result);
            for (var j in res.data.result) {
              if (res.data.result[j].file_name == file_name) {
                if (res.data.result[j].contact_id == this.state.contact_id) {
                  const read_details = {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                    business_id: business_id,
                    timeline_id: res.data.result[j]._id,
                  };

                  // console.log(read_details);

                  axios.post(API_CALL_ENDPOINT + '/text/timeline/update', read_details).then((res) => {
                    console.log(res);
                    console.log(res.data.message);
                  });

                  if (res.data.error) {
                  }
                }
              }
            }
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
      console.log('attachment_detail', attachment);
      var fileType = attachment.file_path.split('.');
      const document_url_detail = {
        params: {
          object_key:
            // attachment.file_path ? attachment.file_path : undefined,
            attachment.file_path
              ? attachment?.file_path?.split('.')[attachment.file_path.split('.').length - 1] === 'mp4'
                ? attachment.file_path
                : attachment?.second_file_path?.split('.')[attachment.second_file_path.split('.').length - 1] === 'mp4'
                ? attachment.second_file_path
                : attachment.file_path
              : undefined,
        },
      };

      console.log(document_url_detail);

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          console.log('/file/presigned/url', res.data);

          if (
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
          ) {
            this.setState({
              video_play_link: res.data,
              Lightbox_open_video:
                attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
                attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
                  ? true
                  : false,
            });
          } else {
            this.setState({
              document_url: res.data,
              Lightbox_open:
                fileType[fileType.length - 1] === 'jpg' ||
                fileType[fileType.length - 1] === 'jpeg' ||
                fileType[fileType.length - 1] === 'png' ||
                fileType[fileType.length - 1] === 'jfif' ||
                fileType[fileType.length - 1] === 'gif'
                  ? true
                  : false,
            });
          }

          if (
            fileType[fileType.length - 1] === 'jpg' ||
            fileType[fileType.length - 1] === 'jpeg' ||
            fileType[fileType.length - 1] === 'png' ||
            fileType[fileType.length - 1] === 'jfif' ||
            fileType[fileType.length - 1] === 'gif' ||
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
          ) {
            // null
          } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
            AsyncStorage.setItem('PDF_View_url', res.data);
            window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
          } else {
            window.open(res.data, '_blank');
          }

          //   :
          //     ? AsyncStorage.setItem("PDF_View_url", res.data) &&
          //       window.open(
          //         "http://" + window.location.href.split("/")[2] + "/pdf_viewer/",
          //         "_blank"
          //       )
          //       :
        }
        if (res.data.error) {
        }
      });
    }

    // const read_details = {
    //     apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
    //     secret: "R1eqD2twI3E4",
    //     user_id: this.state.user_id,
    //     business_id: business_id,
    //     timeline_id: id

    // };

    // console.log(read_details);

    // axios.post(API_CALL_ENDPOINT+'/text/timeline/update', read_details)
    //     .then(res => {
    //         console.log(res);
    //         console.log(res.data.message);
    //     })
  };
  view_receivedmessage_click(conversation) {
    console.log('individual_contactmessage_div', conversation);
    this.setState({
      text_id: conversation._id,
      individual_conversation_reply: conversation,
      password: conversation.password == '' ? '' : conversation.password,
      reset_password: conversation.password ? (conversation.password.length == 0 ? false : true) : false,
    });

    if (conversation.status == 'received') {
      const reveiver_info = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
          user_id: this.state.user_id,
          text_id: conversation.parent_reply_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
        .then((res) => {
          console.log('/text/reply/info ', res.data);
          if (res.data.status == 'success') {
            this.setState({
              individual_conversation_reply1: res.data.result,
            });
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
      this.setState({
        receiver_reply_made: true,
      });
    } else {
      this.setState({
        receiver_reply_made: false,
      });
    }

    const attachlist_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        unique_id: conversation.parent_unique_id ? conversation.parent_unique_id : undefined,
        attachment: conversation.parent_unique_id ? true : false,
        contact_id: this.state.contact_id,
        text_id: conversation.parent_reply_id,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/attachment/list', attachlist_details).then((res) => {
      console.log(res.data.result.contact.first_name);
      console.log(res.data.result.contact.phone_number.toString());
      const response = res.data.result.contact.phone_number.toString().substring(1, 0);
      if (response == '1' || response == '0' || response == '9') {
        var plivo_number = res.data.result.contact.phone_number.toString().substring(1);
        var plivo_number = plivo_number
          .replace(/[^\dA-Z]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          .trim();

        this.setState({contact_number: plivo_number, plivo_phone_number: res.data.result.contact.phone_number});
      }

      this.setState({
        attachment_list: res.data.result.attachment_list,
        attachment_response: res.data.result,
        first_name: res.data.result.contact.first_name,
        last_name: res.data.result.contact.last_name,
        text_message: res.data.result.text_message,
        received_date: res.data.result.created_date,
        user_id: res.data.result.parent._id,
        campaign_id: res.data.result.campaign_id,
        channel_id: res.data.result.channel_id,
      });
      this.setState({attachment: res.data.result.attachment});

      if (res.data.status == 'success') {
        this.setState({shortenUrl: res.data.result.shorten_url});
      }
      if (res.data.error) {
      }
      const timeline_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          text_id: res.data.result.campaign_id,
          contact_id: this.state.contact_id ? this.state.contact_id : undefined,
        },
      };

      console.log(timeline_details);
      axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
        if (res.data.status == 'success') {
          for (var j in res.data.result) {
            if (res.data.result[j].status == 'unread') {
              if (
                res.data.result[j].file_type == 'notes' ||
                res.data.result[j].file_type == 'google_calendar' ||
                res.data.result[j].file_type == 'quick_meeting'
              ) {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
              if (res.data.result[j].display_name == 'opened') {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
            }
          }
        }
        if (res.data.error) {
        }
      });
    });
    $('.individual_contactmessage_div').hide();
    $('.viewreceivedmessage_div1').show();
  }

  viewreceivedmessage_close1() {
    this.setState({receiver_reply_made_sideoptns: false});
    $('.individual_contactmessage_div').show();
    $('.viewreceivedmessage_div1').hide();
  }
  individual_view_sentmessage_click(conversation) {
    console.log('individual_contactmessage_div', conversation);
    this.setState({unique_id: conversation.unique_id});

    this.setState({
      text_id: conversation._id,
      individual_conversation_reply: conversation,
      password: conversation.password == '' ? '' : conversation.password,
      reset_password: conversation.password ? (conversation.password.length == 0 ? false : true) : false,
    });

    if (conversation.status == 'received') {
      this.setState({
        receiver_reply_made_sideoptns: true,
      });
    } else {
      this.setState({
        receiver_reply_made_sideoptns: false,
      });
    }
    const attachlist_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        unique_id: conversation.unique_id,
        attachment: conversation.unique_id ? true : false,
        contact_id: this.state.contact_id,
        text_id: conversation._id,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/attachment/list', attachlist_details).then((res) => {
      console.log(res.data.result.contact.first_name);
      console.log(res.data.result.contact.phone_number.toString());
      const response = res.data.result.contact.phone_number.toString().substring(1, 0);
      if (response == '1' || response == '0' || response == '9') {
        var plivo_number = res.data.result.contact.phone_number.toString().substring(1);
        var plivo_number = plivo_number
          .replace(/[^\dA-Z]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          .trim();

        this.setState({contact_number: plivo_number, plivo_phone_number: res.data.result.contact.phone_number});
      }
      this.setState({
        attachment_list: res.data.result.attachment_list,
        attachment_response: res.data.result,
        first_name: res.data.result.contact.first_name,
        last_name: res.data.result.contact.last_name,
        text_message: res.data.result.text_message,
        received_date: res.data.result.created_date,
        user_id: res.data.result.parent._id,
        campaign_id: res.data.result.campaign_id,
        channel_id: res.data.result.channel_id,
      });
      this.setState({attachment: res.data.result.attachment});

      if (res.data.status == 'success') {
        this.setState({shortenUrl: res.data.result.shorten_url});
      }
      if (res.data.error) {
      }
      const timeline_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          text_id: res.data.result.campaign_id,
          contact_id: this.state.contact_id ? this.state.contact_id : undefined,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
        if (res.data.status == 'success') {
          for (var j in res.data.result) {
            if (res.data.result[j].status == 'unread') {
              if (
                res.data.result[j].file_type == 'notes' ||
                res.data.result[j].file_type == 'google_calendar' ||
                res.data.result[j].file_type == 'quick_meeting'
              ) {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
              if (res.data.result[j].display_name == 'opened') {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
            }
          }
        }
        if (res.data.error) {
        }
      });
    });

    $('.individual_contactmessage_div').hide();
    $('.viewreceivedmessage_div1').show();
  }
  view_sentmessage_click(conversation) {
    console.log('individual_contactmessage_div', conversation);
    this.setState({unique_id: conversation.unique_id});
    this.setState({
      text_id: conversation._id,
      individual_conversation_reply: conversation,
    });
    const conversation_info = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: this.state.business_id,
        user_id: this.state.user_id,
        text_id: conversation._id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/campaign/info', conversation_info)
      .then((res) => {
        console.log('/text/campaign/info', res.data);
        if (res.data.status == 'success') {
          this.setState({
            email_attachment_url: res.data.result.attachment_url,
            password: res.data.result.password.length == 0 ? '' : res.data.result.password,
            reset_password: res.data.result.password.length == 0 ? false : true,
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });

    if (conversation.status == 'received') {
      const reveiver_info = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          business_id: this.state.business_id,
          user_id: this.state.user_id,
          text_id: conversation._id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/text/reply/info', reveiver_info)
        .then((res) => {
          console.log('/text/reply/info ', res.data);
          if (res.data.status == 'success') {
            this.setState({
              individual_conversation_reply1: res.data.result,
            });
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          // console.log(error)
        });
      this.setState({
        receiver_reply_made: true,
        receiver_reply_made_sideoptns: true,
      });
    } else {
      this.setState({
        receiver_reply_made: false,
        receiver_reply_made_sideoptns: false,
      });
    }
    const attachlist_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        unique_id: conversation.unique_id,
        attachment: conversation.unique_id ? true : false,
        contact_id: this.state.contact_id,
        text_id: conversation._id,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/attachment/list', attachlist_details).then((res) => {
      console.log('/text/attachment/list',res.data.result);
      // console.log(res.data.result.contact.phone_number.toString());
      const response = res.data.result.contact.phone_number.toString().substring(1, 0);
      if (response == '1' || response == '0' || response == '9') {
        var plivo_number = res.data.result.contact.phone_number.toString().substring(1);
        var plivo_number = plivo_number
          .replace(/[^\dA-Z]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          .trim();

        this.setState({contact_number: plivo_number, plivo_phone_number: res.data.result.contact.phone_number});
      }
      this.setState({
        attachment_list: res.data.result.attachment_list,
        attachment_response: res.data.result,
        first_name: res.data.result.contact.first_name,
        last_name: res.data.result.contact.last_name,
        text_message: res.data.result.text_message,
        received_date: res.data.result.created_date,
        user_id: res.data.result.parent._id,
        campaign_id: res.data.result.campaign_id,
        channel_id: res.data.result.channel_id,
      });
      this.setState({attachment: res.data.result.attachment});

      if (res.data.status == 'success') {
        this.setState({shortenUrl: res.data.result.shorten_url});
      }
      if (res.data.error) {
      }
      const timeline_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          text_id: res.data.result.campaign_id,
          contact_id: this.state.contact_id ? this.state.contact_id : undefined,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
        if (res.data.status == 'success') {
          for (var j in res.data.result) {
            if (res.data.result[j].status == 'unread') {
              if (
                res.data.result[j].file_type == 'notes' ||
                res.data.result[j].file_type == 'google_calendar' ||
                res.data.result[j].file_type == 'quick_meeting'
              ) {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
              if (res.data.result[j].display_name == 'opened') {
                const unread_details = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result[j].parent_id,
                  business_id: res.data.result[j].business_id,
                  timeline_id: res.data.result[j]._id,
                };

                // console.log(unread_details);

                axios.post(API_CALL_ENDPOINT + '/text/timeline/update', unread_details).then((res) => {
                  console.log(res);
                  // console.log(res.data.message);
                });
              }
            }
          }
        }
        if (res.data.error) {
        }
      });
    });

    $('.individual_contactmessage_div').hide();
    $('.viewreceivedmessage_div1').show();
  }
  contacmessage_clck(business) {
    this.setState({
      current_business_name: business.business_id.business_name,
      active_business_id: business.business_id._id,
      business_info_det_sub: business,
    });
    this.setState({
      conversation_list: [],
      onClick_followup_filter_status: false,
    });
    this.setState({
      business_id: business.business_id._id,
      contact_id: business._id,
      user_id: business.user_id._id,
    });
    const text_conversation = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: business.business_id._id,
        user_id: business.user_id._id,
        phone_number: business.phone_number.toString(),
        source: 'receiver',
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', text_conversation)
      .then((res) => {
        console.log('/text/conversation', res.data);
        if (res.data.status == 'success') {
          this.setState({
            conversation_list: res?.data?.result?.conversation,
            onClick_followup_filter_status: false,
          });

          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
          if (error.response.data.error) {
          }
        }
      });

    // $('.overallcontactlist_div').hide();
    // $('.individual_contactmessage_div').show();
    this.setState({receiver_reply_made_sideoptns: false});
    if (window.innerWidth <= 991) {
      $('.individual_contactmessage_div').show();
      $('.contacts_listdiv').hide();
      $('.conversation_contentdsply_div').show();
      $('.viewreceivedmessage_div1').hide();
      $('.conversation_contentback').show();
    } else {
      $('.individual_contactmessage_div').show();
      $('.viewreceivedmessage_div1').hide();
      $('.conversation_contentback').hide();
    }
  }
  /**********From CMB - One to One**********/

  respondmessage_clk(conversation) {
    console.log('this.state.parent_unique_id', conversation.unique_id);
    this.setState({
      text_id: conversation._id,
      parent_unique_id: conversation.unique_id ? conversation.unique_id : undefined,
      parent_text_message: conversation.text_message,
      parent_shorten_url: conversation.shorten_attachment_url ? conversation.shorten_attachment_url : undefined,
      parent_contact_total: conversation?.contact_total,
    });
    $('.individual_contactmessage_div').hide();
    $('.respondmessage_div').show();
  }
  individual_respondmessage_clk(conversation) {
    console.log('this.state.parent_unique_id', conversation.unique_id);
    this.setState({
      text_id: conversation._id,
      parent_unique_id: conversation.unique_id ? conversation.unique_id : undefined,
      parent_text_message: conversation.text_message,
      parent_shorten_url: conversation.shorten_attachment_url ? conversation.shorten_attachment_url : undefined,
      parent_contact_total: conversation?.contact_total,
    });

    $('.individual_contactmessage_div').hide();
    $('.respondmessage_div').show();
    $('.viewreceivedmessage_div1').hide();
  }

  respondmessage_close() {
    this.setState({
      atcharryDet_cmb: [],
      message_edit: false,
      Rnunique_id_cmb: '',
    });
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    $('.respondmessage_div').hide();
    $('.attchLstShow_cmb').hide();
    $('.individual_contactmessage_div').show();
  }
  onMessageEdit(conversation) {
    console.log(conversation);
    this.setState({
      message_edit: true,
      msg_update_details: conversation,
      Rnunique_id_cmb: conversation.unique_id,
      text_id: conversation.parent_reply_id,
      reply_id: conversation._id,
      parent_unique_id: conversation.parent_unique_id ? conversation.parent_unique_id : undefined,
      parent_text_message: conversation.parent_text_message,
      parent_shorten_url: conversation.parent_shorten_url ? conversation.parent_shorten_url : undefined,
    });
    console.log('check the msg_update details: ', this.state.msg_update_details);
    const attachmentinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        unique_id: conversation.unique_id,
        contact_id: this.state.contact_id,
      },
    };
    console.log(attachmentinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/attachment/list', attachmentinfo)
      .then((res) => {
        console.log('check the response EDIT', res.data);
        if (res.data.status === 'success') {
          this.setState({messageAttachd: true});
          var arrayLength = res.data.result.attachment_list.length;

          var OrderIncr1 = this.state.attachOrderIncrs_cmb + parseInt(arrayLength);
          this.setState({attachOrderIncrs_cmb: OrderIncr1});

          for (var i = 0; i < arrayLength; i++) {
            if (res.data.result.attachment_list[i].attachment_type == 'notes') {
              $('.attchLstShow_cmb').show();
              $('#notestypearea_cmb').val('');
              this.setState({
                atcharryDet_cmb: [
                  {
                    attach_id: res.data.result.attachment_list[i]._id,
                    notes_content: res.data.result.attachment_list[i].text,
                    notes_content1: 'Your text goes here',
                  },
                  ...this.state.atcharryDet_cmb,
                ],
              });
            }
            if (res.data.result.attachment_list[i].attachment_type == 'audio') {
              $('.attchLstShow_cmb').show();
              this.setState({
                atcharryDet_cmb: [
                  {
                    _id: res.data.result.attachment_list[i].attachment[0]._id,
                    file_path: res.data.result.attachment_list[i].attachment[0].file_path,
                    second_file_path: res.data.result.attachment_list[i].attachment[0].second_file_path,
                    audio_id: res.data.result.attachment_list[i].attachment[0]._id,
                    audio_url: res.data.result.attachment_list[i].attachment[0].file_url,
                    attach_id: res.data.result.attachment_list[i]._id,
                  },
                  ...this.state.atcharryDet_cmb,
                ],
              });
            }
            if (res.data.result.attachment_list[i].attachment_type == 'video') {
              $('.attchLstShow_cmb').show();
              console.log('video_url: ' + res.data.result.attachment_list[i].attachment[0].file_url);
              this.setState({
                atcharryDet_cmb: [
                  {
                    video_id: res.data.result.attachment_list[i].attachment[0]._id,
                    video_url: res.data.result.attachment_list[i].attachment[0].file_url,
                    attach_id: res.data.result.attachment_list[i]._id,
                    second_file_path: res.data.result.attachment_list[i].attachment[0].second_file_path,
                    file_path: res.data.result.attachment_list[i].attachment[0].file_path,
                  },
                  ...this.state.atcharryDet_cmb,
                ],
              });
            }
            if (res.data.result.attachment_list[i].attachment_type == 'documents') {
              var arrayLength1 = res.data.result.attachment_list[i].attachment.length;

              var flDc_detils = [];
              var k = 1;

              for (var j = 0; j < arrayLength1; j++) {
                flDc_detils.push({
                  file_id: res.data.result.attachment_list[i].attachment[j]._id,
                  file_name: res.data.result.attachment_list[i].attachment[j].display_name,
                  file_url: res.data.result.attachment_list[i].attachment[j].file_url,
                  file_path: res.data.result.attachment_list[i].attachment[j].file_path,
                });

                if (k == arrayLength1) {
                  var fileary = {documents: flDc_detils, attach_id: res.data.result.attachment_list[i]._id};
                  this.setState({atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb]});
                }
                k++;
              }

              $('.attchLstShow_cmb').show();
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
            }
          }
        }
        console.log(this.state.attachment_list);
      })
      .catch((error) => {
        console.log(error);
      });
    $('.individual_contactmessage_div').hide();
    $('.respondmessage_div').show();
  }

  msg_save_cmb() {
    if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: this.state.contact_id,
        from_source: this.state.plivo_phone_number.toString(),
        unique_id: this.state.message_edit == true ? this.state.Rnunique_id_cmb : unqID,
        reply_type: 'save',
        text_id: this.state.text_id,
        parent_unique_id: this.state.parent_unique_id,
        parent_text_message: this.state.parent_text_message,
        parent_shorten_url: this.state.parent_shorten_url,
        parent_contact_total: this.state.parent_contact_total,
      };

      axios.post(API_CALL_ENDPOINT + '/text/receiver/reply/create', message_details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          const success = [];
          success.push(res.data.message);
          this.setState({success});

          setTimeout(() => {
            this.setState({
              success: [],
            });
            Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

            this.setState({
              Rnunique_id_cmb: '',
              atcharryDet_cmb: [],
              cmb_notesedit: false,
              notesValue2_cmb: '',
              max_chars_notes_cmb: 1200,
              chars_left_notes_cmb: 1200,
              message_edit: false,
            });
            clearTimeout(this.savetimer);
            clearTimeout(this.timer);
            this.conversation_list_get();

            $('#modal_confirmsend .close').click();
            $('.individual_contactmessage_div').show();
            $('.viewreceivedmessage_div1').hide();
            $('.sendmsg_attachmentdiv_sm').hide();
            $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
            $('.send_message_div1').hide();
            $('.attchLstShow_cmb').hide();
            $('.respondmessage_div').hide();
          }, 3000);
          $('.scheduledatetime_tgldiv1').hide();
        }
        if (res.data.error) {
        }
      });
    }
  }
  msg_update_save_cmb() {
    if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: this.state.contact_id,
        from_source: this.state.plivo_phone_number.toString(),
        unique_id: this.state.message_edit == true ? this.state.Rnunique_id_cmb : unqID,
        reply_type: 'save',
        text_id: this.state.text_id,
        reply_id: this.state.reply_id,
        parent_unique_id: this.state.parent_unique_id,
        parent_text_message: this.state.parent_text_message,
        parent_shorten_url: this.state.parent_shorten_url,
      };

      axios.post(API_CALL_ENDPOINT + '/text/receiver/reply/update', message_details).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          const success = [];
          success.push(res.data.message);
          this.setState({success});

          setTimeout(() => {
            this.setState({
              success: [],
            });
            Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

            this.setState({
              atcharryDet_cmb: [],
              cmb_notesedit: false,
              notesValue2_cmb: '',
              max_chars_notes_cmb: 1200,
              chars_left_notes_cmb: 1200,
              message_edit: false,
              Rnunique_id_cmb: '',
            });
            clearTimeout(this.savetimer);
            clearTimeout(this.timer);
            this.conversation_list_get();
            $('#modal_confirmsend .close').click();
            $('.individual_contactmessage_div').show();
            $('.viewreceivedmessage_div1').hide();
            $('.sendmsg_attachmentdiv_sm').hide();
            $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
            $('.send_message_div1').hide();
            $('.attchLstShow_cmb').hide();
            $('.respondmessage_div').hide();
          }, 3000);
          $('.scheduledatetime_tgldiv1').hide();
        }
        if (res.data.error) {
        }
      });
    }
  }
  conversation_list_get() {
    const text_conversation = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: this.state.business_id,
        user_id: this.state.user_id,
        phone_number: this.state.plivo_phone_number.toString(),
        source: 'receiver',
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', text_conversation)
      .then((res) => {
        console.log('/text/conversation', res.data);
        if (res.data.status == 'success') {
          this.setState({
            conversation_list: res?.data?.result?.conversation,
            onClick_followup_filter_status: false,
          });

          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
          if (error.response.data.error) {
          }
        }
      });
  }
  httpHtml = (text) => {
    var exp = /(\b((https?|ftp|file):\/\/|(www))[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]*)/gi;
    return text.replace(exp, "<a class='url_redirection' href='javascript:void(0)'>$1</a>");
  };
  msg_send_cmb = () => {
    this.setState({messageSendDisabled: true});
    //alert(this.state.contact_id);
    // if (this.state.messageAttachd) {
    if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({messageSendDisabled: false});
    } else {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: this.state.contact_id,
        from_source: this.state.plivo_phone_number.toString(),
        unique_id: unqID,
        reply_type: 'send',
        text_id: this.state.text_id,
        parent_unique_id: this.state.parent_unique_id,
        parent_text_message: this.state.parent_text_message,
        parent_shorten_url: this.state.parent_shorten_url,
        parent_contact_total: this.state.parent_contact_total,
      };

      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/receiver/reply/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              const success = [];
              success.push(res.data.message);
              this.setState({success: success, attachmentdoc_count_cmb: 0});
              docAttachmentsCount_cmb = 0;
              // this.setState(() => ({
              // 	notify_class: 'alert alert-success',
              // 	notify_message: res.data.message,
              // 	message: ''
              // }));

              this.setState({success: []});
              Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
              $('.attchLstShow').hide();
              this.setState({
                atcharryDet_cmb: [],
                cmb_notesedit: false,
                notesValue2_cmb: '',
                max_chars_notes_cmb: 1200,
                chars_left_notes_cmb: 1200,
                Rnunique_id_cmb: '',
                messageSendDisabled: false,
              });
              clearTimeout(this.savetimer);
              clearTimeout(this.timer);
              this.conversation_list_get();
              $('#modal_confirmsend .close').click();
              $('.individual_contactmessage_div').show();
              $('.viewreceivedmessage_div1').hide();
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              $('.sendmsg_attachmentdiv_sm').hide();
              $('.attchLstShow_cmb').hide();
              $('.send_message_div1').hide();
              $('.respondmessage_div').hide();

              $('.scheduledatetime_tgldiv1').hide();
            }
            if (res.data.error) {
              this.setState(() => ({
                messageSendDisabled: false,
                // notify_class: 'alert alert-danger',
                // notify_message: res.data.message,
              }));
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              if (error.response.data.error) {
                $('#modal_confirmsend .close').click();
                toast.dark('Please add an attachment to proceed', {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState(() => ({
                  messageSendDisabled: false,
                  // notify_class: 'alert alert-danger',
                  // notify_message: res.data.message,
                }));
              }
            }
          });
      }
    }
  };
  msg_update_send_cmb = () => {
    this.setState({messageSendDisabled: true});
    //alert(this.state.contact_id);
    // if (this.state.messageAttachd) {
    if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({messageSendDisabled: false});
    } else {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: this.state.contact_id,
        from_source: this.state.plivo_phone_number.toString(),
        unique_id: this.state.message_edit == true ? this.state.Rnunique_id_cmb : unqID,
        reply_type: 'send',
        text_id: this.state.text_id,
        reply_id: this.state.reply_id,
        parent_unique_id: this.state.parent_unique_id,
        parent_text_message: this.state.parent_text_message,
        parent_shorten_url: this.state.parent_shorten_url,
      };

      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/receiver/reply/update', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              const success = [];
              success.push(res.data.message);
              this.setState({success});

              // this.setState(() => ({
              // 	notify_class: 'alert alert-success',
              // 	notify_message: res.data.message,
              // 	message: ''
              // }));

              this.setState({success: []});
              Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
              $('.attchLstShow').hide();
              this.setState({
                atcharryDet_cmb: [],
                cmb_notesedit: false,
                notesValue2_cmb: '',
                max_chars_notes_cmb: 1200,
                chars_left_notes_cmb: 1200,
                Rnunique_id_cmb: '',
                message_edit: false,
                messageSendDisabled: false,
              });
              clearTimeout(this.savetimer);
              clearTimeout(this.timer);
              this.conversation_list_get();
              $('#modal_confirmsend .close').click();
              $('.individual_contactmessage_div').show();
              $('.viewreceivedmessage_div1').hide();
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              $('.sendmsg_attachmentdiv_sm').hide();
              $('.attchLstShow_cmb').hide();
              $('.send_message_div1').hide();
              $('.respondmessage_div').hide();

              $('.scheduledatetime_tgldiv1').hide();
            }
            if (res.data.error) {
              this.setState(() => ({
                messageSendDisabled: false,
                // notify_class: 'alert alert-danger',
                // notify_message: res.data.message,
              }));
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              if (error.response.data.error) {
                $('#modal_confirmsend .close').click();
                toast.dark('Please add an attachment to proceed', {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState(() => ({
                  messageSendDisabled: false,
                  // notify_class: 'alert alert-danger',
                  // notify_message: res.data.message,
                }));
              }
            }
          });
      }
    }
  };
  nft_transfer = () => {
    this.setState({nftTransferDisabled: true});
    const transfer_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: this.state.plivo_phone_number.toString(),
      //token_id: this.state.nft_token_id !== '' ? this.state.nft_token_id : undefined,
      token_id: this.state.nft_id
    };

    console.log('Transfer details', transfer_details);

    if (this.state.message_cmb != '') {
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/transfer', transfer_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            $('.nft_yes_no').hide();
            $('#show_nft_message').trigger('click');

            const NFT_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              timeline_id: this.state.nft_timelineid,
            };
            //   console.log(tagapply)
            //   return
            axios
              .post(API_CALL_ENDPOINT + '/text/timeline/update', NFT_details)
              .then((res) => {
                if (res.data.status === 'success') {
                }
              })
              .catch((error) => {
                // errors.push(error.message);
                if (error.response) {
                }
              });
            this.setState({
              nftTransferDisabled: false,
            });

            setTimeout(() => {
              this.setState({success: []});
            }, 3000);
          }
          if (res.data.error) {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState(() => ({
              nftTransferDisabled: false,
            }));
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            if (error.response.data.error) {
              $('#modal_confirmsend .close').click();
              toast.dark(error.response.data.error, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState(() => ({
                nftTransferDisabled: false,
                // notify_class: 'alert alert-danger',
                // notify_message: res.data.message,
              }));
            }
          }
        });
    }
  };

  reject_ft = (tid, amnt) => {
    const transfer_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: this.state.plivo_phone_number.toString(),
      ft_transaction_id: tid,
    };

    console.log('Transfer details', transfer_details);
    axios
      .post(API_CALL_ENDPOINT + '/blockchain/fungibletoken/canceltransfer', transfer_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          $('#modal_ft_window').trigger('click');
          // $('#modal_tokentransfer').modal('show');
          // $('.modal_ft_window').trigger("click");
          var tokenanme = this.state?.business_info_det?.fungible_token?.token_name;

          $('.ft_tkn_name').text(tokenanme);
          $('.ft_msg').html(
            '<div class="f_sz16 text-center">Fungible Token Name : <span class="ft_tkn_name"></span>, <span class="fgreen4 font-weight-bold f_sz20 tcknamount">' +
              amnt +
              '</span> rejected </div>',
          );
          $('.ft_tkn_name').text(tokenanme);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          if (error.response.data.error) {
            toast.dark(error.response.data.error, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState(() => ({
              nftTransferDisabled: false,
              // notify_class: 'alert alert-danger',
              // notify_message: res.data.message,
            }));
          }
        }
      });
  };
  accept_ft = (tid, amnt) => {
    const transfer_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: this.state.plivo_phone_number.toString(),
      ft_transaction_id: tid,
    };

    console.log('Transfer details', transfer_details);
    axios
      .post(API_CALL_ENDPOINT + '/blockchain/fungibletoken/transfer', transfer_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          $('#modal_ft_window').trigger('click');
          // $('#modal_tokentransfer').modal('show');
          // $('.modal_ft_window').trigger("click");
          var tokenanme = this.state?.business_info_det?.fungible_token?.token_name;
          $('.ft_msg').html(
            '<div class="f_sz16 text-center">Fungible Token Name : <span class="ft_tkn_name"></span>, <span class="fgreen4 font-weight-bold f_sz20 tcknamount">' +
              amnt +
              '</span> received </div>',
          );
          $('.ft_tkn_name').text(tokenanme);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          if (error.response.data.error) {
            toast.dark(error.response.data.error, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState(() => ({
              nftTransferDisabled: false,
              // notify_class: 'alert alert-danger',
              // notify_message: res.data.message,
            }));
          }
        }
      });
  };

  nft_changestatus = () => {
    this.setState({nftChangeStatusDisabled: true});

    const nft_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      token_id: this.state.nft_id !== '' ? this.state.nft_id : undefined,
      contact_id: this.state.contact_id,
      type: 'reject',
    };

    console.log('nft_details', nft_details);

    if (this.state.nft_id != '') {
      axios
        .post(API_CALL_ENDPOINT + '/blockchain/nft/changestatus', nft_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            /*  toast.dark("W", {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }); */

            this.setState({
              nftChangeStatusDisabled: false,
            });

            setTimeout(() => {
              this.setState({success: []});
              window.location.reload(true);
            }, 3000);
          }
          if (res.data.error) {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState(() => ({
              nftChangeStatusDisabled: false,
            }));
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            if (error.response.data.error) {
              $('#modal_confirmsend .close').click();
              toast.dark(error.response.data.error, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState(() => ({
                nftChangeStatusDisabled: false,
                // notify_class: 'alert alert-danger',
                // notify_message: res.data.message,
              }));
            }
          }
        });
    }
  };
  sendmsgattach_clkNotes2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= 5 ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= 5
    ) {
      const errors = [];
      errors.push(`The allowed attachment limit is 5 per message`);
      this.setState({errors});
      // setTimeout(() => {
      this.setState({
        cmb_notesedit: false,
        // notesValue2_cmb: '',
        max_chars_notes_cmb: 1200,
        chars_left_notes_cmb: 1200,
      });
      clearTimeout(this.savetimer);
      // clearTimeout(this.timer);
      // }, 1000);
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({
          active_edit_id: '',
          notesValue2_cmb: '',
        });
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      // setTimeout(() => {
      this.setState({
        cmb_notesedit: false,
        // notesValue2_cmb: '',
        max_chars_notes_cmb: 1200,
        chars_left_notes_cmb: 1200,
      });
      clearTimeout(this.savetimer);
      // clearTimeout(this.timer);
      $('.sendmsg_attachmentdiv_sm').show();
      $('.sendmsgattach_clkNotes2_cmb').show();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({
          active_edit_id: '',
          notesValue2_cmb: '',
        });
      }
      // }, 1000);
    }
  };

  // notesLetter2_cmb = (event) => {
  //   this.setState({notesValue2_cmb: event?.target.value});
  //   console.log(event?.target.value);
  // };
  notesLetter2_cmb = (value) => {
    if (value.length <= 1200) {
      this.setState({
        notesValue2_cmb: value,
        chars_left_notes_cmb: this.state.max_chars_notes_cmb - value.length,
      });
    }
  };

  saveNotes2_cmb = () => {
    // clearTimeout(this.savetimer);
    // this.savetimer = setTimeout(() => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= 5 ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= 5
    ) {
      const errors = [];
      errors.push(`The allowed attachment limit is 5 per message`);
      this.setState({errors});
      // setTimeout(() => {
      this.setState({
        cmb_notesedit: false,
        // notesValue2_cmb: '',
        max_chars_notes_cmb: 1200,
        chars_left_notes_cmb: 1200,
      });
      clearTimeout(this.savetimer);
      // clearTimeout(this.timer);
      // }, 1000);
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({
          active_edit_id: '',
          notesValue2_cmb: '',
        });
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('#attachLoading_cmb').show();
      $('.attchLstShow_cmb').show();
      console.log(this.state.notesValue2_cmb);
      this.setState({savingprogress: 'Saving...'});
      this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: '<p></p>',
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            clearTimeout(this.savetimer);
            this.setState({messageAttachd_cmb: true});

            this.setState({
              cmb_notesedit: true,
              active_edit_id: res.data?.result?._id,
              notesValue2_cmb: '',
              atcharryDet_cmb: [
                {attach_id: res.data.result._id, notes_content: '<p></p>', notes_content1: 'Your text goes here'},
                ...this.state.atcharryDet_cmb,
              ],
            });
            var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
            this.setState({attachOrderIncrs_cmb: OrderIncr});
            this.setState({savingprogress: '✔ Saved'});
            setTimeout(() => {
              // this.setState({notesValue2: '', chars_left_notes_compose: 0});
              this.setState({savingprogress: ''});
            }, 1000);
            setTimeout(() => {
              //   this.setState({
              //     notesValue2_cmb: '',
              //   chars_left_notes_cmb: 0,
              // });
              $('.editor_cmb_' + res.data?.result?._id).show();
              $('.notecontent_cmb_' + res.data?.result?._id).hide();
              console.log(this.state.atcharryDet_cmb);
            }, 150);

            $('#notestypearea_cmb').val('');
            $('#attachLoading_cmb').hide();
            $('.sendmsgattach_clkNotes2_cmb').hide();
            $('.sendmsg_attachmentdiv_sm').hide();
            // $('#attachLoading_cmb').show("1000");
            $('.attchLstShow_cmb').show();
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv_sm').hide();
              $('#attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                $('.sendmsgattach_clkNotes2_cmb').show();
                $('.sendmsg_attachmentdiv_sm').hide();
                $('#attachLoading_cmb').hide();
                $('.attchLstShow_cmb').show();
                const errors = [];
                errors.push("Notes can't be empty.");
                this.setState({errors});

                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          }
        });
    }
    // }, 3000);
  };
  autoupdateNotes2_cmb = () => {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    //   if (this.state.cmb_notesedit === true) {
    if (this.state.notesValue2_cmb.length !== 0) {
      this.setState({savingprogress: 'Saving...'});
      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        attachment_id: this.state.active_edit_id,
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState({messageAttachd_cmb: true});
            let objIndex = 0;
            let myArray = this.state.atcharryDet_cmb;
            objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
            if (myArray[objIndex].notes_content) {
              myArray[objIndex].notes_content = this.state.notesValue2_cmb;
              myArray[objIndex].notes_content1 = 'Your text goes here';
            }

            this.setState({
              atcharryDet_cmb: myArray,
            });
            this.setState({savingprogress: '✔ Saved'});
            setTimeout(() => {
              console.log(this.state.atcharryDet_cmb);
              this.setState({savingprogress: ''});
            }, 150);
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                const errors = [];
                errors.push("Notes can't be empty.");
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          }
        });
    }
    // }
    // }, 2000);
  };
  updateNotes2_cmb = () => {
    console.log(this.state.notesValue2_cmb);
    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv_sm').hide();
    // $('.attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();
    // this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

    const notes_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_type: 'notes',
      attachment_id: this.state.active_edit_id,
      unique_id:
        this.state.message_edit == false
          ? Rnunique_id_cmb
          : this.state.Rnunique_id_cmb
          ? this.state.Rnunique_id_cmb
          : Rnunique_id_cmb,
      text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
      order: '' + this.state.attachOrderIncrs_cmb + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({messageAttachd_cmb: true});
          let objIndex = 0;
          let myArray = this.state.atcharryDet_cmb;
          objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
          if (myArray[objIndex].notes_content) {
            myArray[objIndex].notes_content = this.state.notesValue2_cmb;
            myArray[objIndex].notes_content1 = 'Your text goes here';
          }

          this.setState({
            atcharryDet_cmb: myArray,
          });
          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
          this.setState({attachOrderIncrs_cmb: OrderIncr});

          setTimeout(() => {
            // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});

            console.log(this.state.atcharryDet_cmb);
          }, 150);

          $('#notestypearea_cmb').val('');
          $('.attachLoading_cmb').hide();
          if (this.state.active_edit_id) {
            $('.editor_cmb_' + this.state.active_edit_id).hide();
            $('.notecontent_cmb_' + this.state.active_edit_id).show();
          }
        }
        if (res.data.error) {
          if (res.data.error.message === 'Text required') {
            $('.sendmsgattach_clkNotes2_cmb').show();
            $('.sendmsg_attachmentdiv_sm').hide();
            $('.attachLoading_cmb').hide();
            $('.attchLstShow_cmb').show();
            const errors = [];
            errors.push("Notes can't be empty.");
            this.setState({errors});

            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            if (error.response.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv_sm').hide();
              $('.attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        }
      });
  };
  sendmsgattach_clkAudio2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= 5 ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= 5
    ) {
      const errors = [];
      errors.push(`The allowed attachment limit is 5 per message`);
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv_sm').show();
      $('.sendmsgattach_clkAudio2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };
  audio_start_cmb = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          $('.audioRecordLoading_cmb').show();

          Mp3Recorder.start()
            .then(() => {
              this.setState({isRecording_cmb: true});
            })
            .catch((e) => {
              this.setState({isRecording_cmb: false});
              $('.sendmsgattach_clkAudio2_cmb').show();
              $('.audioRecordLoading_cmb').hide();
              const errors = [];
              errors.push(
                'Sorry, this browser does not support audio recording. Please try using a different browser.',
              );
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
              // alert('Sorry this browser does not support audio recording yet');
              console.log(e);
            });
        })
        .catch((err) => {
          const errors = [];
          errors.push('Sorry, this browser does not support audio recording. Please try using a different browser.');
          this.setState({errors});

          setTimeout(() => {
            this.setState({errors: []});
          }, 3000);
          console.log(err);
        });
    } else {
      console.log('Sorry, this browser does not support audio recording. Please try using a different browser.');
    }
    // navigator.mediaDevices.getUserMedia({ audio: true },
    //     () => {
    //         console.log('Permission Granted');
    //         this.setState({ isBlocked_cmb: false });
    //     },
    //     () => {
    //         console.log('Permission Denied');
    //         this.setState({ isBlocked_cmb: true })
    //     },
    // );

    // if (this.state.isBlocked_cmb) {
    //     console.log('Permission Denied');
    // } else {
    //     $(".sendmsgattach_clkAudio2_cmb").attr("style", "display:none");
    //     $(".audioRecordLoading_cmb").show();

    //     Mp3Recorder
    //         .start()
    //         .then(() => {
    //             this.setState({ isRecording_cmb: true });
    //         }).catch((e) => console.error(e));
    // }
  };

  audio_stop_cmb = () => {
    $('.audioRecordLoading_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv_sm').hide();
    $('#attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();
    this.setState({lodingicon_cmb: 'fas fa-microphone-alt bg_audio'});

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, Date.now() + '.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        const blobURL = URL.createObjectURL(file);
        // const blobURL = URL.createObjectURL(blob);
        this.setState({blobURL, isRecording_cmb: false});

        var filename = new Date().toISOString();

        const data = new FormData();

        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', file);
        data.append('reference', 'text');
        data.append('source', 'receiver');

        axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
          // console.log(res);
          // console.log(res.data.message);
          // console.log(res.data.result.files);

          if (res.data.status == 'success') {
            var file_url = '';
            var file_id = '';
            var file_path_audio = '';
            var second_file_path = '';
            for (var i in res.data.result.files) {
              console.log(res.data.result.files[i].file_url);
              file_url += res.data.result.files[i].file_url;
              file_id += res.data.result.files[i]._id;
              file_path_audio += res.data.result.files[i].file_path;
              second_file_path += res.data.result.files[i].second_file_path;
              var joined_audioId = this.state.attchmnt_audioId_cmb.concat(res.data.result.files[i]._id);
              this.setState({attchmnt_audioId_cmb: joined_audioId});

              var joined_audioIdUrl = this.state.attchmnt_audioIdUrl_cmb.concat({
                _id: res.data.result.files[i]._id,
                file_path: res.data.result.files[i].file_path,
                audio_id: res.data.result.files[i]._id,
                audio_url: res.data.result.files[i].file_url,
              });
              this.setState({attchmnt_audioIdUrl_cmb: joined_audioId});
            }

            console.log(file_url);

            console.log(this.state.atcharryDet_cmb);

            $('#attachLoading_cmb').hide();
            const audio_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              attachment: file_id,
              attachment_type: 'audio',
              unique_id:
                this.state.message_edit == false
                  ? Rnunique_id_cmb
                  : this.state.Rnunique_id_cmb
                  ? this.state.Rnunique_id_cmb
                  : Rnunique_id_cmb,
              order: '' + this.state.attachOrderIncrs_cmb + '',
            };

            axios.post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details).then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
                this.setState({messageAttachd_cmb: true});
                $('#fileAudiosvBtn').hide();
                this.setState({
                  atcharryDet_cmb: [
                    {
                      _id: file_id,
                      file_path: file_path_audio,
                      second_file_path: second_file_path,
                      audio_id: file_id,
                      audio_url: file_url,
                      attach_id: res.data.result._id,
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
                var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                this.setState({attachOrderIncrs_cmb: OrderIncr});
              }
              if (res.data.error) {
              }
            });
          }
        });
      })
      .catch((e) => console.log(e));
  };
  sendmsgattach_clkVideo2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= 5 ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= 5
    ) {
      const errors = [];
      errors.push(`The allowed attachment limit is 5 per message`);
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv_sm').show();
      $('.sendmsgattach_clkVideo2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    }
  };
  sendmsgattach_clkDocmnts2_cmb = () => {
    $('.sendmsg_attachmentdiv_sm').show();
    $('.sendmsgattach_clkDocmnts2_cmb').show();
    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
  };
  fileGetDetails_cmb_images = (event) => {
    if (event?.target?.files?.length !== 0) {
      this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event?.target?.files?.length - 1)});
      docAttachmentsCount_cmb += event?.target?.files?.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > 5 ||
        event?.target?.files?.length > 5 ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > 5
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event?.target?.files?.length - 1),
        });
        docAttachmentsCount_cmb -= event?.target?.files?.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        const errors = [];
        errors.push(`The allowed attachment limit is 5 per message`);
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-image bg_gallery'});
        console.log(event?.target.files);

        this.setState({DcfilNm_cmb: event?.target.files});

        var file_length = event?.target?.files?.length;
        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();

          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event?.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'receiver');

          if (event?.target?.files?.length != 0) {
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  console.log('File_URL :' + res.data.result.files[0].file_url);
                  console.log('ID_num :' + res.data.result.files[0]._id);
                  var file_name = res.data.result.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data.result.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data.result.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data.result.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);
                  flDc_detils.push({
                    file_id: res.data.result.files[0]._id,
                    file_name: file_name,
                    file_url: res.data.result.files[0].file_url,
                    file_path: res.data.result.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });

                  if (k == file_length) {
                    setTimeout(() => {
                      console.log(this.state.atcharryDet_cmb);
                    }, 200);

                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    console.log(atchId_docnt);

                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'images',
                      attachment_file_type: flDc_type?.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                      console.log(res);
                      console.log(res.data.message);

                      if (res.data.status == 'success') {
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var fileary = {images: flDc_detils, attach_id: res.data.result._id};
                        this.setState({atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb]});
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                      }
                      if (res.data.error) {
                      }
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv_sm').hide();
                    $('#attachLoading_cmb').hide();
                  }
                  k++;
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({
                    attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                  });
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv_sm').hide();
                  // $('.attchLstShow_cmb').hide();
                  $('#attachLoading_cmb').hide();
                }
                $('#file-input2')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    const errors = [];
                    errors.push('Sorry, this file is invalid. Please try using a different file.');
                    this.setState({errors});

                    setTimeout(() => {
                      this.setState({errors: []});
                    }, 3000);
                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv_sm').hide();
                    // $('.attchLstShow_cmb').hide();
                    $('#attachLoading_cmb').hide();
                  }
                }
              });
          }
        }

        setTimeout(() => {}, 6000);
      }
    }
  };
  fileGetDetails_cmb = (event) => {
    if (event?.target?.files?.length !== 0) {
      this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event?.target?.files?.length - 1)});
      docAttachmentsCount_cmb += event?.target?.files?.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > 5 ||
        event?.target?.files?.length > 5 ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > 5
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event?.target?.files?.length - 1),
        });
        docAttachmentsCount_cmb -= event?.target?.files?.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        const errors = [];
        errors.push(`The allowed attachment limit is 5 per message`);
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});
        console.log(event?.target.files);

        this.setState({DcfilNm_cmb: event?.target.files});

        var file_length = event?.target?.files?.length;
        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();

          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event?.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'receiver');

          if (event?.target?.files?.length != 0) {
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  console.log('File_URL :' + res.data.result.files[0].file_url);
                  console.log('ID_num :' + res.data.result.files[0]._id);
                  var file_name = res.data.result.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data.result.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data.result.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data.result.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);
                  flDc_detils.push({
                    file_id: res.data.result.files[0]._id,
                    file_name: file_name,
                    file_url: res.data.result.files[0].file_url,
                    file_path: res.data.result.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });

                  if (k == file_length) {
                    setTimeout(() => {
                      console.log(this.state.atcharryDet_cmb);
                    }, 200);

                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    console.log(atchId_docnt);

                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type?.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                      console.log(res);
                      console.log(res.data.message);

                      if (res.data.status == 'success') {
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var fileary = {documents: flDc_detils, attach_id: res.data.result._id};
                        this.setState({atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb]});
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                      }
                      if (res.data.error) {
                      }
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv_sm').hide();
                    $('#attachLoading_cmb').hide();
                  }
                  k++;
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({
                    attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                  });
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);

                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv_sm').hide();
                  // $('.attchLstShow_cmb').hide();
                  $('#attachLoading_cmb').hide();
                }
                $('#file-input1')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    const errors = [];
                    errors.push('Sorry, this file is invalid. Please try using a different file.');
                    this.setState({errors});

                    setTimeout(() => {
                      this.setState({errors: []});
                    }, 3000);
                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);

                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv_sm').hide();
                    // $('.attchLstShow_cmb').hide();
                    $('#attachLoading_cmb').hide();
                  }
                }
              });
          }
        }

        setTimeout(() => {}, 6000);
      }
    }
  };

  //   DocLclDlete_cmb = (event) => {
  //     console.log(this.state.atcharryDet_cmb);
  //     console.log(event?.target.id);

  //     /*const data = this.state.atcharryDet.filter(
  //                 i => i.documents !== event?.target.id
  //             )
  //             this.setState({ atcharryDet: data }); */
  //     console.log('before for', docAttachmentsCount_cmb);
  //     var array = this.state.atcharryDet_cmb;
  //     var arrayLength = this.state.atcharryDet_cmb.length;
  //     for (var i = 0; i < arrayLength; i++) {
  //       if (array[i].documents) {
  //         for (var j = 0; j < array[i].documents.length; j++) {
  //           console.log(array[i].documents[j]);
  //           if (array[i].documents[j].file_id === event?.target.id) {
  //             array[i].documents.splice(j, 1);
  //             docAttachmentsCount_cmb -= 1;
  //             console.log('inside for', docAttachmentsCount_cmb);
  //           }
  //         }
  //       }
  //     }
  //     console.log('outside for', docAttachmentsCount_cmb);
  //     this.setState({atcharryDet_cmb: array});
  //     if (this.state.atcharryDet_cmb.length == 0) {
  //       $('.attchLstShow_cmb').hide();
  //     }

  //     setTimeout(() => {
  //       console.log(this.state.atcharryDet_cmb);
  //     }, 100);

  //     const attchDel_details = {
  //       apikey: API_KEY,
  //       secret: API_SECRET,
  //       user_id: this.state.user_id,
  //       business_id: this.state.business_id,
  //       file_id: event?.target.id,
  //       attachment_id: event?.target.dataset.id,
  //     };

  //     axios.post(API_CALL_ENDPOINT+'/text/attachment/delete', attchDel_details).then((res) => {
  //       console.log(res);
  //       console.log(res.data.message);

  //       if (res.data.status == 'success') {
  //       }
  //       if (res.data.error) {
  //       }
  //     });
  //   };
  audioAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event?.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event?.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };
  notesAtchDelte_cmb_edit = (id) => {
    console.log('notesAtchDelte_cmb_edit');
    $('.editor_cmb_' + id).hide();
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    $('.notecontent_cmb_show').show();
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      // console.log(res);
      // console.log(res.data.message);
      if (res.data.status == 'success') {
        // $('.sendmsg_attachmentdiv2_cmb').hide();
        // $('.sendmsgattach_clkNotes2_cmb').hide();
        // $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
        // $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
        this.setState({cmb_notesedit: false});
        // window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({active_edit_id: '', notesValue2_cmb: ''});
        clearTimeout(this.savetimer);
        clearTimeout(this.timer);
      }
      if (res.data.error) {
      }
    });
  };
  notesAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event?.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event?.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        if (this.state.active_edit_id) {
          $('.editor_cmb_' + this.state.active_edit_id).hide();
          $('.notecontent_cmb_' + this.state.active_edit_id).show();
        }
        $('.sendmsg_attachmentdiv_sm').hide();
        $('.sendmsgattach_clkNotes2_cmb').hide();
        $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
        $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
        this.setState({cmb_notesedit: false});
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({active_edit_id: '', notesValue2_cmb: ''});
        clearTimeout(this.savetimer);
        clearTimeout(this.timer);
        clearTimeout(this.savetimer);
      }
      if (res.data.error) {
      }
    });
  };
  videAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event?.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event?.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };

  videoLclDelte_cmb = () => {
    $('.sendmsgattach_clkVideo2_cmb').hide();
    $('.sendmsg_attachmentdiv_sm').hide();
  };
  documntAtchDelte_cmb = (event, attachment) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        if (array[i].attach_id === event?.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({
      atcharryDet_cmb: array,
      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - attachment.length + 1,
    });
    docAttachmentsCount_cmb -= attachment.length - 1;
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event?.target.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };

  DocLclDlete_cmb = (event) => {
    console.log(this.state.atcharryDet_cmb);
    console.log(event?.target.id);

    /*const data = this.state.atcharryDet.filter(
                i => i.documents !== event?.target.id 
            )
            this.setState({ atcharryDet: data }); */

    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i].documents) {
        for (var j = 0; j < array[i].documents.length; j++) {
          console.log(array[i].documents[j]);
          if (array[i].documents[j].file_id === event?.target.id) {
            array[i].documents.splice(j, 1);
            docAttachmentsCount_cmb -= 1;
          }
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    setTimeout(() => {
      console.log(this.state.atcharryDet_cmb);
    }, 100);

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      file_id: event?.target.id,
      attachment_id: event?.target.dataset.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };
  notesLclDelte_cmb = () => {
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsg_attachmentdiv_sm').hide();
    this.setState({cmb_notesedit: false});
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id: '', notesValue2_cmb: ''});
    clearTimeout(this.savetimer);
  };
  audioLclDelte_cmb = () => {
    $('.sendmsgattach_clkAudio2_cmb').hide();
    $('.sendmsg_attachmentdiv_sm').hide();
  };

  dragStart_cmb(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }
  dragEnd_cmb(e) {
    this.dragged.style.display = 'block';
    // this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.atcharryDet_cmb;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over?.dataset?.id);
    if (from < to) to--;
    if (from > to) from--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({atcharryDet_cmb: data});
    console.log('Drag drop', this.state.atcharryDet_cmb);
    var array = data;
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        console.log(array[i]);
        const Attachment_update_order = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          order: i + 1,
          attachment_id: array[i].attach_id,
        };

        console.log(Attachment_update_order);

        axios.post(API_CALL_ENDPOINT + '/text/attachment/update', Attachment_update_order).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            console.log('Done');
          }
          if (res.data.error) {
          }
        });
      }
    }
  }
  dragOver_cmb(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === 'placeholder_cmb') return;
    this.over = e.target;
    e.target.parentNode.insertBefore(placeholder_cmb, e.target);
  }

  onClick_followup_filter() {
    const conversationinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.plivo_phone_number.toString(),
        source: 'receiver',
      },
    };
    // console.log(conversationinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          var filtered = [];
          var array = res.data.result.conversation;
          for (var i = 0; i < array.length; i++) {
            if (array[i].followup === 'true') {
              filtered.push(array[i]);
            }
          }
          this.setState({
            conversation_list: this.state.onClick_followup_filter_status == true ? array : filtered,
            onClick_followup_filter_status: this.state.onClick_followup_filter_status == true ? false : true,
            disableconversationsearch: false,
          });

          // console.log(this.state?.conversation_list)
          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
        }
      });
  }
  onClick_followup(conversation) {
    const followup = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: conversation.conversation._id,
    };
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      data: qs.stringify(followup),
      url: '/text/followup/create',
    };

    axios(options)
      .then((res) => {
        console.log(res.data);

        if (res.data.message == 'Text messages followup') {
          document.getElementById('fo_' + conversation.conversation._id).style.display = 'none';
          document.getElementById('fol_' + conversation.conversation._id).style.display = 'inline-block';
          // document.getElementById('fo_'+conversation.conversation._id).display = "fas fa-bookmark forange";
        }
        if (res.data.message == 'Text messages Unfollowup') {
          document.getElementById('fo_' + conversation.conversation._id).style.display = 'inline-block';
          document.getElementById('fol_' + conversation.conversation._id).style.display = 'none';
          // document.getElementById('fo_'+conversation.conversation._id).className = "far fa-bookmark fgrey";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // modal_unsubscribe(business) {
  //   this.setState({
  //     business_subscribe_name: business.business_id.business_name,
  //     unsubscribe_contact_id: business._id,
  //     unsubscribe_business_id: business.business_id._id,
  //   });
  // }
  message_subscribe(business) {
    const message_subscribe = {
      apikey: API_KEY,
      secret: API_SECRET,
      subscribe: 'yes',
      contact_id: business._id,
      business_id: business.business_id._id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/receiver/subscribe', message_subscribe).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        $('.contactdtls_sidebar3').removeClass('ctdtls_show');
        $('.contactdtls_sidebar3').addClass('ctdtls_hide');
        const businessList = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            contact_id: business._id,
            phone_number: this.state.plivo_phone_number.toString(),
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/text/receiver/business/list', businessList)
          .then((res) => {
            console.log('receiver/business/list', res.data);
            // console.log(res.data.result[0].phone_number.length)
            if (res.data.status === 'success') {
              this.setState({business_list: res.data.result});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (res.data.error) {
      }
    });
  }
  message_unsubscribe(business) {
    console.log();
    const message_unsubscribe = {
      apikey: API_KEY,
      secret: API_SECRET,
      subscribe: 'no',
      contact_id: business._id,
      business_id: business.business_id._id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/receiver/subscribe', message_unsubscribe).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        $('.contactdtls_sidebar3').removeClass('ctdtls_show');
        $('.contactdtls_sidebar3').addClass('ctdtls_hide');
        $('#modal_unsubscribe .close').click();
        const businessList = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            contact_id: business._id,
            phone_number: this.state.plivo_phone_number.toString(),
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/text/receiver/business/list', businessList)
          .then((res) => {
            console.log('receiver/business/list', res.data);
            // console.log(res.data.result[0].phone_number.length)
            if (res.data.status === 'success') {
              this.setState({business_list: res.data.result});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (res.data.error) {
      }
    });
  }
  ListTimeLineClk_View = (id, conversation) => {
    console.log('this.state.individual_conversation_list', conversation);

    // console.log("ListTimeLineClk",id);
    $('.TimeLinDivLs_View').toggle();

    const timeline_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: conversation.parent_reply_id ? id + ',' + conversation.parent_reply_id : id,
        contact_id: conversation.contact ? conversation.contact : undefined,
        // parent_reply_id: conversation.parent_reply_id?conversation.parent_reply_id: undefined
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
        this.setState({timeLineListDetls: res.data.result});
      }
      if (res.data.error) {
      }
    });
  };
  ListTimeLineClk = (id, conversation) => {
    console.log(id);
    $('.TimeLinDivLs_Contacts_view_' + id).toggle();

    const timeline_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: conversation.parent_reply_id ? id + ',' + conversation.parent_reply_id : id,
        contact_id: conversation.contact ? conversation.contact : undefined,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
      if (res.data.status == 'success') {
        this.setState({timeLineListDetls: res.data.result});
      }
      if (res.data.error) {
      }
    });
  };
  onMessageDelete(conversation) {
    console.log(conversation);
    const textdelete = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: conversation.conversation._id,
      source: 'receiver',
    };
    //   console.log(tagapply)
    //   return
    axios
      .post(API_CALL_ENDPOINT + '/text/delete', textdelete)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          const success = [];
          success.push('Message has been successfully deleted');
          this.setState({success});
          window.scrollTo(0, 0);
          const conversationinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              phone_number: this.state.plivo_phone_number.toString(),
              source: 'receiver',
            },
          };
          // console.log(conversationinfo);
          axios
            .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
            .then((res) => {
              console.log('/text/conversation', res.data);
              if (res.data.status == 'success') {
                this.setState({
                  conversation_list: res?.data?.result?.conversation,
                  onClick_followup_filter_status: false,
                });

                document.getElementById('conversation_nil').style.display = 'none';
                document.getElementById('jumptobottom').style.display = 'block';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
              if (res.data.status === 'no_record') {
                this.setState({
                  conversation_list: [],
                  disableconversationsearch: true,
                });
                document.getElementById('conversation_nil').style.display = 'block';
                document.getElementById('jumptobottom').style.display = 'none';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
              if (res.data.error) {
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status === 'no_record') {
                  this.setState({
                    conversation_list: [],
                    disableconversationsearch: true,
                  });
                  document.getElementById('conversation_nil').style.display = 'block';
                  document.getElementById('jumptobottom').style.display = 'none';
                  document.getElementById('viewsentmessage_div').style.display = 'none';
                }
                if (error.response.data.error) {
                }
              }
            });
          setTimeout(() => {
            this.setState({success: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  }
  document_read(attachment) {
    console.log('attachment_detail', attachment);
    var fileType = attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key:
          // attachment.file_path ? attachment.file_path : undefined,
          attachment.file_path
            ? attachment?.file_path?.split('.')[attachment.file_path.split('.').length - 1] === 'mp4'
              ? attachment.file_path
              : attachment?.second_file_path?.split('.')[attachment.second_file_path.split('.').length - 1] === 'mp4'
              ? attachment.second_file_path
              : attachment.file_path
            : undefined,
      },
    };

    console.log(document_url_detail);
    console.log('fileType', fileType[fileType.length - 1]);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        if (
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
        ) {
          this.setState({
            video_play_link: res.data,
            Lightbox_open_video:
              attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
              attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
                ? true
                : false,
          });
        } else {
          this.setState({
            document_url: res.data,
            Lightbox_open:
              fileType[fileType.length - 1] === 'jpg' ||
              fileType[fileType.length - 1] === 'jpeg' ||
              fileType[fileType.length - 1] === 'png' ||
              fileType[fileType.length - 1] === 'jfif' ||
              fileType[fileType.length - 1] === 'gif'
                ? true
                : false,
          });
        }
        if (
          fileType[fileType.length - 1] === 'jpg' ||
          fileType[fileType.length - 1] === 'jpeg' ||
          fileType[fileType.length - 1] === 'png' ||
          fileType[fileType.length - 1] === 'jfif' ||
          fileType[fileType.length - 1] === 'gif' ||
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
        ) {
          // null
        } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
          AsyncStorage.setItem('PDF_View_url', res.data);
          window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
        } else {
          window.open(res.data, '_blank');
        }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }
  closeLightbox = () => {
    this.setState({Lightbox_open: false});
  };
  closeLightbox_video = () => {
    this.setState({Lightbox_open_video: false});
  };
  Video_play_url(attachment, attach_det) {
    console.log('attachment_detail', attachment);
    const document_url_detail = {
      params: {
        object_key:
          // attachment.file_path,
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4'
            ? attachment.file_path
            : attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
            ? attachment.second_file_path
            : attachment.file_path,
      },
    };

    console.log(document_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          video_play_link: res.data,
          Lightbox_open_video:
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
              ? true
              : false,
        });
        if (
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
        ) {
        } else {
          window.open(res.data, '_blank');
        }

        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
    const text_conversation_read = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: this.state.business_id,
        user_id: this.state.user_id,
        attachment_id: attach_det._id,
      },
    };
    console.log('text_conversation_read', text_conversation_read);
    axios
      .get(API_CALL_ENDPOINT + '/text/timeline/info', text_conversation_read)
      .then((res) => {
        if (res.data.status == 'success') {
          console.log('/text/timeline/info', res.data);
          const read_details = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: attach_det.business_id,
            timeline_id: res.data.result[0]._id,
          };

          // console.log(read_details);

          axios.post(API_CALL_ENDPOINT + '/text/timeline/update', read_details).then((res) => {
            console.log(res);
            console.log(res.data.message);
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  }
  Video_play_url_preview = (attachment) => {
    console.log('attachment_detail', attachment.file_path.split('.'));
    attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key: attachment.file_path,
      },
    };

    console.log(document_url_detail);

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        console.log('/file/presigned/url', res.data);

        this.setState({
          video_play_link: res.data,
          Lightbox_open_video: true,
        });
      }
      if (res.data.error) {
      }
    });
  };
  // logout(){
  //     localStorage.removeItem("receiver_loggedIn")
  // 	console.log("loggedout")
  // 	window.location.reload(true);
  // 	// this.props.history.push('/signin')

  //   }
  //   onFileChangeHandler = (e) => {

  // 	if (e.target.files && e.target?.files?.length > 0) {
  // 	  const reader = new FileReader();
  // 	  reader.addEventListener('load', () =>
  // 		this.setState({ src: reader.result })
  // 	  );
  // 	  reader.readAsDataURL(e.target.files[0]);
  // 	}

  // 	$('.popup').attr('style','display');

  //     e.preventDefault();
  //     this.setState({
  //         selectedFile: e.target.files[0]
  //     });
  //     const data = new FormData();

  // 	data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
  // 	data.append('secret', 'R1eqD2twI3E4');
  // 	data.append('user_id', this.state.user_id);
  // 	data.append('business_id', this.state.business_id);
  //     data.append('file', e.target.files[0]);

  // 	console.log('Form:'+data);
  // 	console.log("File Values : ",data);
  // 	console.log("file : ",e.target.files[0]);
  //     /*axios.post(API_CALL_ENDPOINT+'/file/upload', data )
  // 	.then(res => {
  // 			console.log(res);
  // 			console.log(res.data.message);
  // 			console.log(res.data.result.files);
  // 			var file_url ='';
  // 			for (var i in res.data.result.files){
  // 				console.log(res.data.result.files[i].file_url);
  // 				file_url+=res.data.result.files[i].file_url;
  // 			}

  // 			console.log(file_url);
  // 			if (res.data.status == "success"){

  // 				const business_upDetails = {
  // 					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
  // 					secret: "R1eqD2twI3E4",
  // 					user_id: this.state.user_id,
  // 					setup : "profile",
  // 					profile_image :file_url

  // 				};

  // 				axios.post(API_CALL_ENDPOINT+'/user/update', business_upDetails )
  // 					.then(res => {
  // 						console.log(res);
  // 						console.log(res.data.message);

  // 				})

  // 			}

  // 	});*/
  // };
  //   	  // If you setState the crop in here you should return false.
  // onImageLoaded = image => {
  // 	this.imageRef = image;
  // };

  // onCropComplete = crop => {
  // 	this.makeClientCrop(crop);
  //   };

  // onCropChange = (crop, percentCrop) => {
  // 	// You could also use percentCrop:
  // 	// this.setState({ crop: percentCrop });
  // 	this.setState({ crop });
  // };

  // async makeClientCrop(crop) {
  // 	if (this.imageRef && crop.width && crop.height) {
  // 	  const croppedImageUrl = await this.getCroppedImg(
  // 		this.imageRef,
  // 		crop,
  // 		'newFile.jpeg'
  // 	  );
  // 	  this.setState({ croppedImageUrl });
  // 	}
  //   }

  // getCroppedImg(image, crop, fileName) {
  // 	const canvas = document.createElement('canvas');
  // 	const scaleX = image.naturalWidth / image.width;
  // 	const scaleY = image.naturalHeight / image.height;
  // 	canvas.width = crop.width;
  // 	canvas.height = crop.height;
  // 	const ctx = canvas.getContext('2d');

  // 	ctx.drawImage(
  // 	  image,
  // 	  crop.x * scaleX,
  // 	  crop.y * scaleY,
  // 	  crop.width * scaleX,
  // 	  crop.height * scaleY,
  // 	  0,
  // 	  0,
  // 	  crop.width,
  // 	  crop.height
  // 	);

  // 	/*return new Promise((resolve, reject) => {
  // 	  canvas.toBlob(blob => {
  // 		if (!blob) {
  // 		  //reject(new Error('Canvas is empty'));
  // 		  console.error('Canvas is empty');
  // 		  return;
  // 		}
  // 		blob.name = fileName;
  // 		window.URL.revokeObjectURL(this.fileUrl);
  // 		this.fileUrl = window.URL.createObjectURL(blob);
  // 		resolve(this.fileUrl);
  // 	  }, 'image/jpeg');
  // 	});*/

  // 	const reader = new FileReader()
  // 		canvas.toBlob(blob => {
  // 		reader.readAsDataURL(blob)
  // 		reader.onloadend = () => {
  // 			this.dataURLtoFile(reader.result, 'cropped.jpg')
  // 		}
  // 	})
  // }

  // dataURLtoFile(dataurl, filename) {
  // 	let arr = dataurl.split(','),
  // 		mime = arr[0].match(/:(.*?);/)[1],
  // 		bstr = atob(arr[1]),
  // 		n = bstr.length,
  // 		u8arr = new Uint8Array(n);

  // 	while(n--){
  // 		u8arr[n] = bstr.charCodeAt(n);
  // 	}
  // 	let croppedImage = new File([u8arr], filename, {type:mime});
  // 	this.setState({croppedImage: croppedImage })
  // }

  // togglePopup = () => {
  // 	$('.popup').attr('style','display');
  // }

  // save_imageCrop = () => {
  // 	//alert(this.state.croppedImageUrl);
  // 	$('.popup').attr('style','display:none');

  // 	console.log(this.state.croppedImage)

  // 	const data = new FormData();

  // 	data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
  // 	data.append('secret', 'R1eqD2twI3E4');
  // 	data.append('user_id', this.state.user_id);
  // 	data.append('business_id', this.state.business_id);
  //     data.append('file', this.state.croppedImage);

  // 	console.log('Form:'+data);
  // 	console.log("File Values : ",data);
  // 	console.log(this.state.business_id);
  //     axios.post(API_CALL_ENDPOINT+'/file/upload', data )
  // 	.then(res => {
  // 			console.log(res);
  // 			console.log(res.data.message);
  // 			console.log(res.data.result.files);

  // 			var file_url ='';
  // 			for (var i in res.data.result.files){
  // 				console.log(res.data.result.files[i].file_url);
  // 				file_url+=res.data.result.files[i].file_url;
  // 			}

  // 			console.log(file_url);
  // 			this.setState({
  // 				receiver_profile_img: file_url,

  // 			});
  // 			if (res.data.status == "success"){

  // 				const Receiver_upDetails = {
  // 					apikey: API_KEY,
  // 					secret: API_SECRET,
  //                     user_id: this.state.user_id,
  //                     receiver_type:"profile",
  //                     contact_id: this.state.contact_id,
  // 					profile_image :file_url

  // 				};

  // 				axios.post(API_CALL_ENDPOINT+'/text/receiver/update', Receiver_upDetails )
  // 					.then(res => {
  // 						console.log(res);
  // 						console.log(res.data.message);

  // 						if (res.data.status == "success"){
  // 							// window.location.reload(true);
  // 						}

  // 				})

  // 			}

  // 	});

  // }

  // close_imageCrop = () => {
  // 	$('.popup').hide();
  // }
  getExtensionFromUrl = (url = '') => {
    if (url) {
      let urlPathArray = url.split('/');
      let extArr = urlPathArray[urlPathArray.length - 1].split('.');
      let extension = extArr[extArr.length - 1];
      return extension;
    }
    return null;
  };
  bytesToKb = (bytes) => {
    let KB = bytes / Math.pow(1024, 1);
    let KBSplit = KB.toString().split('.')[0];
    return KBSplit;
  };
  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };
  onChangesearchconv(e) {
    console.log(e.target.selectedOptions);
    const values = [...e.target.selectedOptions].map((opt) => opt.value);
    // this.props.onChange(values);
    this.setState({search_status_conv: values});

    console.log(this.state.search_status_conv);
    setTimeout(() => {
      this.contacmessage_search();
    }, 500);
  }
  contacmessage_search(e) {
    if (e) {
      e.preventDefault();
    }
    const search_status = this.state.search_status_conv.toString();

    const conversationsearchinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.plivo_phone_number.toString(),
        search_status: this.state.search_status_conv.length == 0 ? undefined : search_status,
        source: 'receiver',
      },
    };
    console.log(conversationsearchinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationsearchinfo)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({
            conversation_list: res?.data?.result?.conversation,
            onClick_followup_filter_status: false,
          });

          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
          if (error.response.data.error) {
          }
        }
      });
    // $('.overallcontactlist_div').hide();
    // $('.individual_contactmessage_div').show();
  }
  convlist_loadmore = (e) => {
    const target = e.target;
    const search_status = this.state.search_status_conv.toString();
    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      $('#jumptobottom').hide();
      const conversationinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.plivo_phone_number.toString(),
          search_status: this.state.search_status_conv.length == 0 ? undefined : search_status,
          source: 'receiver',
          start: this.state.conversation_list.length,
          limit: 20,
        },
      };
      // console.log(conversationinfo);
      axios
        .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (this.state.search_text_conv) {
              this.contacmessage_search();
            } else {
              this.setState({
                conversation_list: [...this.state.conversation_list, ...res.data?.result?.conversation],
                // conversation_list: res.data?.result?.conversation,
                disableconversationsearch: false,
              });
            }
            console.log(res.data?.result?.conversation.length);
            if (res.data?.result?.conversation.length > 9) {
              document.getElementById('jumptobottom').style.display = 'block';
            }
            // if (res.data?.result?.conversation.length < 20) {
            //   document.getElementById('conv_loadmore').style.display = 'none';
            // }
          }
          // if (res.data.status === 'no_record') {
          //   document.getElementById('conv_loadmore').style.display = 'none';
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      $('#jumptobottom').show();
    }
  };
  oneditnotecmb = (attachment) => {
    if (this.state.active_edit_id) {
      $('.editor_cmb_' + this.state.active_edit_id).hide();
      $('.notecontent_cmb_' + this.state.active_edit_id).show();
    }
    $('.sendmsg_attachmentdiv_sm').hide();
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    $('.editor_cmb_' + attachment.attach_id).show();
    $('.notecontent_cmb_' + attachment.attach_id).hide();
    this.setState({cmb_notesedit: true});
    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id: attachment.attach_id, notesValue2_cmb: attachment.notes_content});
    this.autoupdateNotes2_cmb();
  };

  contact_detail_open(business) {
    this.setState({business_info_det_sub: business});
    const business_info = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        business_id: business.business_id._id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/receiver/business/info', business_info)
      .then((res) => {
        console.log('receiver/business/info', res.data);
        if (res.data.status == 'success') {
          this.setState({business_info_det: res.data.result});
          $('body').addClass('control-sidebar-slide-open3');
          $('body').removeClass('control-sidebar-slide-open1');
          $('.contactdtls_sidebar').addClass('ctdtls_hide');

          if ($('body').hasClass('control-sidebar-slide-open3')) {
            $('.accent-custom .control-sidebar3.contactdtls_sidebar3::before').addClass('slider_animation');
            $('.contactdtls_sidebar3').removeClass('ctdtls_hide');
            $('.contactdtls_sidebar3').addClass('ctdtls_show slider_animation');
          }
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });

    //  else {
    //   $('body').toggleClass('control-sidebar-slide-open3');
    // }
  }
  contact_detail_close() {
    $('.contactdtls_sidebar3').removeClass('ctdtls_show');
    $('.contactdtls_sidebar3').addClass('ctdtls_hide');
  }
  handleResize = (e) => {
    const windowSize = window.innerWidth;

    this.setState({
      window_height_conv: window.innerHeight - 130,
      window_height_cont: window.innerHeight - 130,
      window_height_attach: window.innerHeight - 250,
    });
    if (window.innerWidth <= 800 || window.innerHeight <= 600) {
      // $('body').css({overflow: 'auto'});
    }
    if (windowSize >= 992) {
      $('.contacts_listdiv').show();
      $('.conversation_contentback').hide();
      $('.conversation_contentdsply_div').show();
    } else if (windowSize <= 991) {
      // $('.individual_contactmessage_div').show();
      // $('.contacts_listdiv').hide();
      // $('.conversation_contentdsply_div').hide();
      // $('.viewreceivedmessage_div1').hide();
      $('.conversation_contentback').show();
    }

    this.setState((prevState) => {
      return {
        windowSize,
      };
    });
  };
  conversation_contentback = () => {
    $('.contacts_listdiv').show();
    $('.conversation_contentdsply_div').hide();
  };
  calender_update = (attachment, status) => {
    console.log(attachment);
    const calender_update = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      contact_id: this.state.contact_id,
      attachment_id: attachment._id,
      meet_status: status,
      source: 'receiver',
    };

    // console.log(read_details);

    axios
      .post(API_CALL_ENDPOINT + '/text/googlecalendar/update', calender_update)
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.cal_update_optns_' + attachment._id).hide();
        } else if (res.data.status == 'error') {
          toast.error(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'error') {
            toast.error(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  validate_notes = (arr) => {
    var arrayLength = arr.length;

    var count = 0;
    if (arr.length !== 0) {
      for (var i = 0; i < arrayLength; i++) {
        if (arr[i].notes_content1) {
          var string = arr[i].notes_content.replace(/(<([^>]+)>)/gi, '');
          if (string.length == 0) {
            count += 1;
          }
        }
      }
    }
    return count;
  };
  render() {
    const {errors, success} = this.state;
    var moment = require('moment');
    // console.log(this.state.attachment_response)
    // const contact_details = this.state.contactdetails.contact;
    // console.log(this.state.attachment_response.contact)

    return (
      <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
        <div className="wrapper">
          <ToastContainer
            transition={Flip}
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Navbar */}
          <Receiver_Header_component />
          {/* /.navbar */}
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="content-header" style={{display: 'none'}}>
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <div className="header_pagetitle d-block d-sm-block d-md-none">
                      <div className="text-center pt-2">
                        <div className="allheader_profile_drpdwn crsr_pntr">
                          <h4 className="d-inline-block">
                            <span className="fmaroon">(412) 827-9898</span>
                            {/*<span class="f_sz14 d-block">You</span>*/}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <div className="content">
              <div className="container-fluid">
                {/* /.row */}
                <div className="overallcontactlist_div">
                  <div className="row" style={{display: 'none'}}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="contacts_overall_container w-100 d-table">
                        <div className="d-table-row">
                          <div className="d-table-cell align-middle text-left">
                            <div className="dropdown crsr_pntr allboard_gridcounts_drpdwn">
                              <span data-toggle="dropdown" className="allicon overallcount_clck">
                                <i className="fas fa-th" />
                              </span>
                              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                <div className="dropdown-item">
                                  <div className="overallcounts_div">
                                    <div className="overallbox_item bx_shadow_sm brdr_txtsent">
                                      <h4 className="w-100">807</h4>
                                      <div className="fgrey">Sent</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_txtrcvd">
                                      <h4 className="w-100">45</h4>
                                      <div className="fgrey">Received</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_attachments">
                                      <h4 className="w-100">103</h4>
                                      <div className="fgrey">Attachments</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_documents">
                                      <h4 className="w-100">35</h4>
                                      <div className="fgrey">Documents</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_audioattach">
                                      <h4 className="w-100">5</h4>
                                      <div className="fgrey">Audio</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_videoattach">
                                      <h4 className="w-100">90</h4>
                                      <div className="fgrey">Video</div>
                                    </div>
                                    <div className="overallbox_item bx_shadow_sm brdr_rewardsattach">
                                      <h4 className="w-100">132</h4>
                                      <div className="fgrey">Rewards </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-table-cell align-middle">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search for message types or content"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text nobrdr_bg">
                                  <button className="btn btn-sm" type="submit">
                                    <i className="fas fa-search" />
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                  <div className="row position-relative">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="allcontacts_conversediv">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 contacts_listdiv"
                          style={{height: this.state.window_height_cont + 'px', maxHeight: '100vh'}}>
                          <div className="contacts_tbldiv receiver_tbldiv scrollbar_style mt-1">
                            <table
                              className="table table-hover"
                              cellPadding={0}
                              cellSpacing={0}
                              style={{width: '100%'}}>
                              <tbody>
                                {this.state.business_list.map((business, index) => {
                                  return business?.business_id ? (
                                    business.subscribe == 'yes' ? (
                                      <tr
                                        className={
                                          this.state.active_business_id == business?.business_id._id ? 'selected' : null
                                        }>
                                        <td>
                                          <div>
                                            <div
                                              className="ctdtls_tddiv"
                                              onClick={() => this.contacmessage_clck(business)}>
                                              <span
                                                className="usercircle_sm1 workspacecrcle mr-1"
                                                style={{
                                                  backgroundColor: business?.business_id?.color_code
                                                    ? business?.business_id?.color_code
                                                    : '#6f42c1',
                                                }}>
                                                {business?.business_id?.business_name?.substring(1, 0).toUpperCase()}
                                              </span>

                                              <span data-place="bottom" data-tip={business?.business_id?.business_name}>
                                                <ReactTooltip />
                                                {business.business_id.business_name.length >= 25
                                                  ? business?.business_id?.business_name.substring(0, 25) + '...'
                                                  : business?.business_id?.business_name}
                                              </span>
                                            </div>
                                            <div className="ctdtls_actiondiv">
                                              <div className="ctdtls_action_items">
                                                {/* <a class="businessdtls_clck_unsub"><span class="ctdtls_aciconmob"><i class="far fa-building"></i></span><span class="ctdtls_actxtdsk">Details</span></a>
                      <a class="contacmessage_clck"><span class="ctdtls_aciconmob"><i class="far fa-comment-dots"></i></span><span class="ctdtls_actxtdsk">Message</span></a> */}
                                                {/*<a data-toggle="modal" data-target="#modal_unsubscribe" data-backdrop="static"><span class="ctdtls_aciconmob"><i class="far fa-hand-point-down f_sz18"></i></span><span class="ctdtls_actxtdsk">Unsubscribe</span></a>*/}
                                                <div className="dropdown crsr_pntr">
                                                  <span data-toggle="dropdown" className="ctactions">
                                                    <i className="fas fa-ellipsis-v" />
                                                  </span>
                                                  <div className="dropdown-menu dropdown-menu-left">
                                                    {/*<div class="dropdown-item isdisabled">*/}
                                                    <div className="dropdown-item">
                                                      <div onClick={() => this.contact_detail_open(business)}>
                                                        <span>
                                                          <i className="far fa-address-card f_sz15 mr-2" />
                                                          Details
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ) : business.subscribe == 'no' ? (
                                      <tr className="notsubscribed">
                                        <td>
                                          <div>
                                            <div className="ctdtls_tddiv">
                                              <span
                                                className="usercircle_sm1 workspacecrcle mr-1"
                                                style={{backgroundColor: '#ef6144'}}>
                                                {business.business_id.business_name.substring(1, 0).toUpperCase()}
                                              </span>
                                              <span data-place="bottom" data-tip={business?.business_id?.business_name}>
                                                <ReactTooltip />
                                                {business.business_id.business_name.length >= 25
                                                  ? business?.business_id?.business_name.substring(0, 25) + '...'
                                                  : business?.business_id?.business_name}
                                              </span>
                                            </div>
                                            <div className="ctdtls_actiondiv">
                                              <div className="ctdtls_action_items">
                                                {/* <a class="businessdtls_clck_unsub"><span class="ctdtls_aciconmob"><i class="far fa-building"></i></span><span class="ctdtls_actxtdsk">Details</span></a>
                      <a class="contacmessage_clck"><span class="ctdtls_aciconmob"><i class="far fa-comment-dots"></i></span><span class="ctdtls_actxtdsk">Message</span></a> */}
                                                {/*<a data-toggle="modal" data-target="#modal_unsubscribe" data-backdrop="static"><span class="ctdtls_aciconmob"><i class="far fa-hand-point-down f_sz18"></i></span><span class="ctdtls_actxtdsk">Unsubscribe</span></a>*/}
                                                <div className="dropdown crsr_pntr">
                                                  <span data-toggle="dropdown" className="ctactions">
                                                    <i className="fas fa-ellipsis-v" />
                                                  </span>
                                                  <div className="dropdown-menu dropdown-menu-left">
                                                    {/*<div class="dropdown-item isdisabled">*/}
                                                    <div className="dropdown-item">
                                                      <div onClick={() => this.contact_detail_open(business)}>
                                                        <span>
                                                          <i className="far fa-address-card f_sz15 mr-2" />
                                                          Details
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ) : undefined
                                  ) : undefined;
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 conversation_contentdsply_div px-0"
                          style={{display: 'block'}}>
                          <div className="all_innersmallcontent_div px-0 pt-1">
                            <div className="individual_contactmessage_div w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-0">
                                    <div className="card w-100 mb-0 shadow-none ctmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title pl-2">
                                          <span
                                            onClick={() => this.conversation_contentback()}
                                            className="conversation_contentback">
                                            <i className="fas fa-chevron-left fa-2x f_sz20 mr-2 postop_2"></i>
                                          </span>
                                          {this.state.current_business_name}
                                          {/* <span className="businessdtls_clck fmaroon crsr_pntr"><i className="fas fa-info-circle ml-2 f_sz18" /></span> */}
                                          {/* <span className="ml-2" data-toggle="tooltip" data-place="bottom" data-tip data-place="bottom" data-tip="Filter Following"><a href="javascript:void(0)" className="crsr_pntr f_sz15 contact_followup"><i className="fas fa-bookmark fbblue" /></a></span> */}
                                          <span
                                            className="ml-2"
                                            data-place="bottom"
                                            data-tip="Following"
                                            // data-toggle="tooltip" data-place="bottom" data-tip data-place="bottom" data-tip="Filter Following"
                                          >
                                            {/* <ReactTooltip /> */}
                                            <a
                                              onClick={this.onClick_followup_filter}
                                              href="javascript:void(0)"
                                              className="crsr_pntr f_sz15 contact_followup">
                                              <i
                                                className={
                                                  this.state.onClick_followup_filter_status == false
                                                    ? 'far fa-bookmark fgrey'
                                                    : 'fas fa-bookmark fbblue'
                                                }
                                              />
                                            </a>
                                          </span>
                                          <div className="search_filterdiv_pos" style={{display: 'none'}}>
                                            <div className="search_filterdiv">
                                              <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                <select
                                                  // disabled={this.state.disableconversationsearch}
                                                  className="selectpicker"
                                                  id="status_searchid"
                                                  onChange={(e) => this.onChangesearchconv(e)}
                                                  multiple={true}
                                                  title="Status">
                                                  <option value="">All</option>
                                                  <option value="received">Sent</option>
                                                  <option value="sent,delivered">Received</option>
                                                  {/* <option value="saved">Saved</option> */}
                                                  {/* <option value="scheduled">Scheduled</option> */}
                                                </select>
                                              </div>
                                              {/* <form
                                              className="col-8 col-sm-9 col-md-9 col-lg-9"
                                              onSubmit={this.contacmessage_search}>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  disabled={this.state.disableconversationsearch}
                                                  value={this.state.search_text_conv}
                                                  onChange={this.onChangesearchtext}
                                                  className="form-control"
                                                  placeholder="Search for any content"
                                                />
                                                <div className="input-group-append">
                                                  <span className="input-group-text nobrdr_bg">
                                                    <button
                                                      disabled={this.state.disableconversationsearch}
                                                      className="btn btn-sm"
                                                      type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                              </div>
                                            </form> */}
                                            </div>
                                          </div>
                                          {/* <div className="search_filterdiv_pos">
                                                                <div className="search_filterdiv"><div className="col-4 col-sm-3 col-md-3 col-lg-3">
                                                                        <select className="selectpicker" multiple title="Status">
                                                                            <option>All</option>
                                                                            <option>Sent</option>
                                                                            <option>Received</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control" placeholder="Search for any content" />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text nobrdr_bg">
                                                                                    <button className="btn btn-sm" type="submit">
                                                                                        <i className="fas fa-search" />
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                          {/* <span className="ctmessage_close">
                                <i className="fas fa-times" />
                              </span> */}
                                        </h3>
                                      </div>
                                      {errors.map((error) => (
                                        <div id="error" className="alert alert-danger text-center" key={error}>
                                          {error}
                                        </div>
                                      ))}
                                      {success.map((success) => (
                                        <div id="error" className="alert alert-success text-center" key={success}>
                                          {success}
                                        </div>
                                      ))}
                                      <div ref="conversationList" className="card-body pt-md-0 pb-0 px-0">
                                        <div className="all_contentcntr_450" style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={alert_search} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 w-100 d-inline-block">
                                              We couldn't find the relevant conversation with this keyword, please try
                                              with a different one.
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="conversation_nil"
                                          className="all_contentcntr_450"
                                          style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={alert_conversation} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 d-inline-block w-100">
                                              We couldn't find any conversation.
                                            </div>
                                          </div>
                                        </div>
                                        <ul
                                          style={{
                                            height: this.state.window_height_conv + 'px',
                                            maxHeight: '100vh',
                                            minHeight: '300px',
                                            overflowY: 'auto',
                                          }}
                                          onScroll={(e) => this.convlist_loadmore(e)}
                                          ref="convlist_loadmore_ref"
                                          id="convlist_loadmore"
                                          className="products-list product-list-in-card pl-2 pr-2 contact_converselist scrollbar_style">
                                          {/*----------------------------*/}
                                          {this.state?.conversation_list?.map((conversation, index) => {
                                            return conversation.status == 'delivered' ? (
                                              conversation.receiver_reply == 'no' ? (
                                                <li
                                                  onMouseLeave={() => this.status_hide(conversation.campaign)}
                                                  className="item prod_inboundbg ct_inbounditem">
                                                  <div className="product-img">
                                                    {conversation.user_info[0]?.profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img
                                                          src={conversation.user_info[0]?.profile_image}
                                                          border={0}
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {this.state.current_business_name.substring(1, 0).toUpperCase()}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="product-info">
                                                    <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mb-2 mb-md-2 text-left">
                                                      <span className="product_bubble">
                                                        <span className="product-title fgrey">
                                                          {this.state.current_business_name}
                                                          {/* <span className="ml-2" data-toggle="tooltip" data-place="bottom" data-tip data-place="bottom" data-tip="Following"><a href="javascript:void(0)" className="crsr_pntr f_sz15 contact_followup"><i className="fas fa-bookmark fbblue" /></a></span> */}
                                                          <span
                                                            id={'fol_' + conversation._id}
                                                            style={
                                                              conversation.delete === '1'
                                                                ? {display: 'none'}
                                                                : conversation.followup === 'true'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            onClick={() => {
                                                              this.onClick_followup({conversation});
                                                            }}
                                                            class="ml-2"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Following">
                                                            {/* <ReactTooltip />{' '} */}
                                                            <a
                                                              href="javascript:void(0)"
                                                              class="crsr_pntr f_sz15 contact_followup">
                                                              <i class="fas fa-bookmark fbblue"></i>
                                                            </a>
                                                          </span>
                                                        </span>
                                                        <span
                                                          className="product-description"
                                                          style={{wordWrap: 'break-word'}}>
                                                          <span>
                                                            {/*<span class="all_dotlinks"><i class="fas fa-paperclip"></i></span>*/}
                                                            {conversation.text_message}{' '}
                                                            {conversation.shorten_attachment_url ? (
                                                              <>
                                                                <a
                                                                  onClick={() =>
                                                                    conversation.reference === 'auto_unsupport'
                                                                      ? undefined
                                                                      : this.view_sentmessage_click(conversation)
                                                                  }
                                                                  className="fmaroon crsr_pntr viewreceivedmessagebtn_clk1">
                                                                  {conversation.shorten_attachment_url}
                                                                </a>
                                                                <span className="msg_attachrcvd ml-1">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() =>
                                                                      conversation.reference === 'auto_unsupport'
                                                                        ? undefined
                                                                        : this.view_sentmessage_click(conversation)
                                                                    }
                                                                    className="viewreceivedmessagebtn_clk">
                                                                    <span className="flightgrey badge badge-bblack">
                                                                      Attachment Received
                                                                    </span>
                                                                  </a>
                                                                </span>
                                                              </>
                                                            ) : undefined}
                                                          </span>
                                                        </span>
                                                        <span className="contact_converse_allactions">
                                                          <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight mt-2">
                                                            <span className="d-inline-block d-md-inline-block postop_1up">
                                                              <span
                                                                id={'fo_' + conversation._id}
                                                                style={
                                                                  conversation.delete === '1'
                                                                    ? {display: 'none'}
                                                                    : conversation.followup === 'true'
                                                                    ? {display: 'none'}
                                                                    : {display: 'inline-block'}
                                                                }
                                                                onClick={() => {
                                                                  this.onClick_followup({conversation});
                                                                }}
                                                                className="all_dotlinks">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  className="crsr_pntr f_sz16 contact_followup"
                                                                  data-toggle="tooltip"
                                                                  data-place="bottom"
                                                                  data-tip="Follow Up">
                                                                  {/* <ReactTooltip />  */}
                                                                  <i className="far fa-bookmark fgrey" />
                                                                </a>
                                                              </span>
                                                              {conversation.reference ===
                                                              'auto_unsupport' ? undefined : !conversation.attachment_url ? undefined : (
                                                                <span className="all_dotlinks">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    className="viewreceivedmessagebtn_clk1">
                                                                    <span
                                                                      onClick={() =>
                                                                        this.view_sentmessage_click(conversation)
                                                                      }
                                                                      className="f_sz14 crsr_pntr fmaroon"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="View">
                                                                      {/* <ReactTooltip />  */}
                                                                      <i className="far fa-eye mr-1" />
                                                                    </span>
                                                                  </a>
                                                                </span>
                                                              )}

                                                              <span className="all_dotlinks">
                                                                <a
                                                                  onClick={() => this.respondmessage_clk(conversation)}
                                                                  className="respondmessage_clk">
                                                                  <span
                                                                    className="f_sz14 crsr_pntr fmaroon"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Reply">
                                                                    {/* <ReactTooltip />  */}
                                                                    <i className="fa fa-reply mr-1" />
                                                                  </span>
                                                                </a>
                                                              </span>
                                                              {/* <span className="all_dotlinks"><a href="javascript:void(0)"><span className="f_sz14 crsr_pntr fmaroon" data-toggle="tooltip" data-place="bottom" data-tip="Delete"><ReactTooltip /> <i className="fas fa-trash-alt" onClick={() => { this.onMessageDelete({ conversation }) }} /></span></a></span> */}
                                                            </span>
                                                            {/*Timeline*/}
                                                            <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                              <div className="dropdown d-inline-block">
                                                                {/*<span data-toggle="dropdown" class="badge badge-bblack f_sz13"><span class="crsr_pntr">Sent</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                <span
                                                                  onClick={() =>
                                                                    this.ListTimeLineClk(
                                                                      conversation.campaign,
                                                                      conversation,
                                                                    )
                                                                  }
                                                                  className="badge badge-bblue f_sz13 crsr_pntr">
                                                                  <span>
                                                                    <i
                                                                      className="far fa-arrow-alt-circle-down f_sz18"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Received"
                                                                    />
                                                                    {/* <ReactTooltip /> */}{' '}
                                                                  </span>
                                                                  <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                </span>
                                                                <div
                                                                  className={
                                                                    'dropdown-menu  dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                    conversation.campaign
                                                                  }>
                                                                  <div className="dropdown-item">
                                                                    <div className="p-0 d-inline-block w-100 f_sz13">
                                                                      <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                                        {/* The time line */}
                                                                        <div className="timeline">
                                                                          {/* timeline time label */}
                                                                          <div className="time-label">
                                                                            <span className="bg-light border">
                                                                              Status
                                                                            </span>
                                                                          </div>
                                                                          {/* /.timeline-label */}
                                                                          {/* timeline item */}
                                                                          {this.state.timeLineListDetls.map(
                                                                            (TimeLine, index) => {
                                                                              var splitDisNm =
                                                                                TimeLine.file_type == 'documents'
                                                                                  ? TimeLine.display_name.split('.')
                                                                                  : null;
                                                                              var SplitNm = splitDisNm
                                                                                ? splitDisNm[1]
                                                                                : null;
                                                                              var Icondisply =
                                                                                SplitNm == '' || SplitNm == null
                                                                                  ? TimeLine.file_type
                                                                                  : null;
                                                                              return TimeLine.status == 'delivered' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon">
                                                                                        Received
                                                                                      </span>{' '}
                                                                                      from{' '}
                                                                                      {this.state.current_business_name}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.created_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : undefined;
                                                                            },
                                                                          )}
                                                                          {/* <div>
                                                                                                                <i className="far fa-circle" />
                                                                                                                <div className="timeline-item">
                                                                                                                    <h3 className="timeline-header"><span className="fmaroon">Received</span> from Ble Media Solutions</h3>
                                                                                                                    <span className="time">March 25, 2020<span className="ml-2">5.18am</span></span>
                                                                                                                </div>
                                                                                                            </div> */}
                                                                          {/* END timeline item */}
                                                                          <div>
                                                                            <i className="far fa-clock bg-light border" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-right text-md-right resp_dashedbrdr_top">
                                                      </span> */}
                                                  </div>
                                                </li>
                                              ) : undefined
                                            ) : conversation.status == 'received' ? (
                                              <li
                                                onMouseLeave={() => this.status_hide(conversation._id)}
                                                className="item prod_outboundbg">
                                                {conversation.attachment_url == 'true' ? (
                                                  <div className="product-img">
                                                    {conversation.user_info[0]?.profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img
                                                          src={conversation.user_info[0]?.profile_image}
                                                          border={0}
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {this.state.current_business_name.substring(1, 0).toUpperCase()}
                                                      </span>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="product-img">
                                                    <span className="usercircle_md bg_teammember1">
                                                      <i className="far fa-user" />
                                                    </span>
                                                    {/*<span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                  </div>
                                                )}
                                                <div className="product-info">
                                                  <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 text-left">
                                                    <span class="product_bubble">
                                                      <span className="product-title">
                                                        {conversation.attachment_url === 'true'
                                                          ? this.state.current_business_name
                                                          : 'You'}
                                                        <span
                                                          id={'fol_' + conversation._id}
                                                          style={
                                                            conversation.delete === '1'
                                                              ? {display: 'none'}
                                                              : conversation.followup === 'true'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          onClick={() => {
                                                            this.onClick_followup({conversation});
                                                          }}
                                                          class="ml-2"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Following">
                                                          {/* <ReactTooltip /> */}{' '}
                                                          <a
                                                            href="javascript:void(0)"
                                                            class="crsr_pntr f_sz15 contact_followup">
                                                            <i class="fas fa-bookmark fbblue"></i>
                                                          </a>
                                                        </span>
                                                      </span>
                                                      <span
                                                        className="product-description"
                                                        style={{wordWrap: 'break-word'}}>
                                                        <span>
                                                          {conversation.attachment_url === 'true' ? (
                                                            conversation.parent_text_message ? (
                                                              <span class="mr-1">
                                                                {conversation.parent_text_message}
                                                              </span>
                                                            ) : (
                                                              'Currently Unavailable'
                                                            )
                                                          ) : (
                                                            <span class="mr-1">{conversation.text_message}</span>
                                                          )}
                                                          {conversation.attachment_url === 'true' ? (
                                                            <>
                                                              <a class="fmaroon crsr_pntr mr-1">
                                                                {conversation.parent_shorten_url}
                                                              </a>
                                                              <span className="msg_attachrcvd">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() =>
                                                                    this.view_sentmessage_click(conversation)
                                                                  }
                                                                  className="viewreceivedmessagebtn_clk">
                                                                  <span className="flightgrey badge badge-bblack">
                                                                    Reply is submitted
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            </>
                                                          ) : undefined}
                                                        </span>
                                                      </span>
                                                      <span className="contact_converse_allactions">
                                                        <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight mt-2">
                                                          <span className="d-inline-block d-md-inline-block postop_1up">
                                                            <span
                                                              id={'fo_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {display: 'none'}
                                                                  : {display: 'inline-block'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({conversation});
                                                              }}
                                                              className="all_dotlinks">
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz16 contact_followup"
                                                                data-toggle="tooltip"
                                                                data-place="bottom"
                                                                data-tip="Follow Up">
                                                                {/* <ReactTooltip />  */}
                                                                <i className="far fa-bookmark fgrey" />
                                                              </a>
                                                            </span>
                                                            {conversation.parent_unique_id ===
                                                            'unsupport' ? undefined : conversation.parent_unique_id ? (
                                                              <span
                                                                className="all_dotlinks"
                                                                style={
                                                                  conversation.attachment_url == 'true'
                                                                    ? {display: 'inline-block'}
                                                                    : {display: 'none'}
                                                                }>
                                                                <a
                                                                  onClick={() =>
                                                                    this.view_receivedmessage_click(conversation)
                                                                  }
                                                                  className="viewreceivedmessagebtn_clk">
                                                                  <span
                                                                    className="f_sz14 crsr_pntr fmaroon"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="View">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i className="far fa-eye mr-1" />
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            ) : undefined}
                                                            <span
                                                              className="all_dotlinks"
                                                              style={
                                                                conversation.attachment_url == 'true'
                                                                  ? {display: 'inline-block'}
                                                                  : {display: 'none'}
                                                              }>
                                                              <a
                                                                onClick={() =>
                                                                  this.view_sentmessage_click(conversation)
                                                                }
                                                                className="viewsentmessagebtn_clk">
                                                                <span
                                                                  className="f_sz14 crsr_pntr flightgrey2"
                                                                  data-toggle="tooltip"
                                                                  data-place="bottom"
                                                                  data-tip="Show Reply">
                                                                  {/* <ReactTooltip />  */}
                                                                  <i className="fa fa-reply mr-1" />
                                                                </span>
                                                              </a>
                                                            </span>
                                                            {/* <span className="all_dotlinks"><a href="javascript:void(0)"><span className="f_sz14 crsr_pntr fmaroon" data-toggle="tooltip" data-place="bottom" data-tip="Delete"><ReactTooltip /> <i className="fas fa-trash-alt" onClick={() => { this.onMessageDelete({ conversation }) }}/></span></a></span> */}
                                                          </span>
                                                          {/*Timeline*/}
                                                          <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                            <div className="dropdown d-inline-block">
                                                              {/*<span data-toggle="dropdown" class="badge badge-bred f_sz13"><span class="crsr_pntr">Deleted</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                              <span
                                                                onClick={() =>
                                                                  this.ListTimeLineClk(conversation._id, conversation)
                                                                }
                                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                                <span>
                                                                  <i
                                                                    className="far fa-arrow-alt-circle-up f_sz18"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Responded"
                                                                  />
                                                                  {/* <ReactTooltip /> */}{' '}
                                                                </span>
                                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                              </span>
                                                              <div
                                                                className={
                                                                  'dropdown-menu  dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                  conversation._id
                                                                }
                                                                style={{right: '-39px'}}>
                                                                <div className="dropdown-item">
                                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                                      {/* The time line */}
                                                                      <div className="timeline">
                                                                        {/* timeline time label */}
                                                                        <div className="time-label">
                                                                          <span className="bg-light border">
                                                                            Status
                                                                          </span>
                                                                        </div>
                                                                        {/* /.timeline-label */}
                                                                        {/* timeline item */}
                                                                        {this.state.timeLineListDetls.map(
                                                                          (TimeLine, index) => {
                                                                            var splitDisNm =
                                                                              TimeLine.file_type == 'documents'
                                                                                ? TimeLine.display_name.split('.')
                                                                                : null;
                                                                            var SplitNm = splitDisNm
                                                                              ? splitDisNm[1]
                                                                              : null;
                                                                            var Icondisply =
                                                                              SplitNm == '' || SplitNm == null
                                                                                ? TimeLine.file_type
                                                                                : null;
                                                                            return TimeLine.status == 'edited' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon mr-2">
                                                                                      Edited{' '}
                                                                                    </span>{' '}
                                                                                    By You
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : TimeLine.status == 'saved' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon mr-2">
                                                                                      Saved{' '}
                                                                                    </span>{' '}
                                                                                    By You
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : TimeLine.status == 'received' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  {conversation.attachment_url ===
                                                                                  'true' ? (
                                                                                    <h3 className="timeline-header">
                                                                                      {' '}
                                                                                      <span className="fmaroon mr-2">
                                                                                        Responded
                                                                                      </span>
                                                                                      By You
                                                                                    </h3>
                                                                                  ) : (
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        Sent
                                                                                      </span>
                                                                                      By You
                                                                                    </h3>
                                                                                  )}
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : TimeLine.status == 'delivered' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon">
                                                                                      Received
                                                                                    </span>{' '}
                                                                                    from{' '}
                                                                                    {this.state.current_business_name}
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : undefined;
                                                                          },
                                                                        )}

                                                                        <div>
                                                                          <i className="far fa-clock bg-light border" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                    </span> */}
                                                </div>
                                              </li>
                                            ) : conversation.status == 'saved' ? (
                                              <li
                                                onMouseLeave={() => this.status_hide(conversation._id)}
                                                className="item prod_outboundbg">
                                                {conversation.attachment_url == 'true' ? (
                                                  <div className="product-img">
                                                    {conversation.user_info[0]?.profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img
                                                          src={conversation.user_info[0]?.profile_image}
                                                          border={0}
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {this.state.current_business_name.substring(1, 0).toUpperCase()}
                                                      </span>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="product-img">
                                                    <span className="usercircle_md bg_teammember1">
                                                      <i className="far fa-user" />
                                                    </span>
                                                    {/*<span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                  </div>
                                                )}
                                                <div className="product-info">
                                                  <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 text-left">
                                                    <span class="product_bubble">
                                                      <span className="product-title ">
                                                        {conversation.attachment_url === 'true'
                                                          ? this.state.current_business_name
                                                          : 'You'}
                                                        <span className="ml-2 fgreen2 f_sz14">
                                                          <i
                                                            className="far fa-save"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Saved"
                                                          />
                                                        </span>
                                                        {/* <ReactTooltip /> */}
                                                        <span
                                                          id={'fol_' + conversation._id}
                                                          style={
                                                            conversation.delete === '1'
                                                              ? {display: 'none'}
                                                              : conversation.followup === 'true'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          onClick={() => {
                                                            this.onClick_followup({conversation});
                                                          }}
                                                          class="ml-2"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Following">
                                                          {/* <ReactTooltip /> */}{' '}
                                                          <a
                                                            href="javascript:void(0)"
                                                            class="crsr_pntr f_sz15 contact_followup">
                                                            <i class="fas fa-bookmark fbblue"></i>
                                                          </a>
                                                        </span>
                                                      </span>
                                                      <span
                                                        className="product-description"
                                                        style={{wordWrap: 'break-word'}}>
                                                        {conversation.attachment_url === 'true' ? (
                                                          conversation.parent_text_message ? (
                                                            <span>
                                                              <span class="mr-1">
                                                                {conversation.parent_text_message}
                                                              </span>
                                                              <a class="fmaroon crsr_pntr mr-1">
                                                                {conversation.parent_shorten_url}
                                                              </a>{' '}
                                                            </span>
                                                          ) : (
                                                            'Currently Unavailable'
                                                          )
                                                        ) : (
                                                          <span>
                                                            <span class="mr-1">{conversation.text_message}</span>
                                                            <a class="fmaroon crsr_pntr mr-1">
                                                              {conversation.parent_shorten_url}
                                                            </a>{' '}
                                                          </span>
                                                        )}
                                                        {/* <span className="flightgrey">Reply has been saved</span> */}
                                                      </span>
                                                      <span className="contact_converse_allactions">
                                                        <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight mt-2">
                                                          <span className="d-inline-block d-md-inline-block postop_1up">
                                                            <span
                                                              id={'fo_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {display: 'none'}
                                                                  : {display: 'inline-block'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({conversation});
                                                              }}
                                                              className="all_dotlinks">
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz16 contact_followup"
                                                                data-toggle="tooltip"
                                                                data-place="bottom"
                                                                data-tip="Follow Up">
                                                                <i className="far fa-bookmark fgrey" />
                                                              </a>
                                                            </span>
                                                            {/*<span class="all_dotlinks"><a href="javascript:void(0)" class="respondmessage_clk"><span class="f_sz14 crsr_pntr fmaroon" data-toggle="tooltip" data-place="bottom" data-tip="Reply"><i class="fas fa-reply mr-1"></i></span></a></span>*/}
                                                            <span className="all_dotlinks">
                                                              <a
                                                                onClick={() =>
                                                                  this.view_receivedmessage_click(conversation)
                                                                }
                                                                className="viewreceivedmessagebtn_clk2">
                                                                <span
                                                                  className="f_sz14 crsr_pntr fmaroon"
                                                                  data-toggle="tooltip"
                                                                  data-place="bottom"
                                                                  data-tip="View Received Message">
                                                                  {/* <ReactTooltip />  */}
                                                                  <i className="far fa-eye mr-1" />
                                                                </span>
                                                              </a>
                                                            </span>
                                                            <span className="all_dotlinks">
                                                              <a
                                                                onClick={() => this.onMessageEdit(conversation)}
                                                                className="fmaroon crsr_pntr savedmessagebtn_clk">
                                                                <span
                                                                  className="f_sz14 crsr_pntr fgreen1"
                                                                  data-toggle="tooltip"
                                                                  data-place="bottom"
                                                                  data-tip="Edit">
                                                                  {/* <ReactTooltip />  */}
                                                                  <i className="fas fa-pen" />
                                                                </span>
                                                              </a>
                                                            </span>
                                                            {/* <span className="all_dotlinks"><a href="javascript:void(0)"><span className="f_sz14 crsr_pntr fmaroon" data-toggle="tooltip" data-place="bottom" data-tip="Delete"><ReactTooltip /> <i className="fas fa-trash-alt" onClick={() => { this.onMessageDelete({ conversation }) }}/></span></a></span> */}
                                                          </span>
                                                          {/*Timeline*/}
                                                          <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                            <div className="dropdown d-inline-block">
                                                              <span
                                                                onClick={() =>
                                                                  this.ListTimeLineClk(conversation._id, conversation)
                                                                }
                                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                                <span>
                                                                  <i
                                                                    className="far fa-save f_sz18"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Saved"
                                                                  />
                                                                  {/* <ReactTooltip /> */}{' '}
                                                                </span>
                                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                              </span>
                                                              <div
                                                                className={
                                                                  'dropdown-menu  dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                  conversation._id
                                                                }
                                                                style={{right: '-39px'}}>
                                                                <div className="dropdown-item">
                                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                                      {/* The time line */}
                                                                      <div className="timeline">
                                                                        {/* timeline time label */}
                                                                        <div className="time-label">
                                                                          <span className="bg-light border">
                                                                            Status
                                                                          </span>
                                                                        </div>
                                                                        {/* /.timeline-label */}
                                                                        {/* timeline item */}
                                                                        {this.state.timeLineListDetls.map(
                                                                          (TimeLine, index) => {
                                                                            var splitDisNm =
                                                                              TimeLine.file_type == 'documents'
                                                                                ? TimeLine.display_name.split('.')
                                                                                : null;
                                                                            var SplitNm = splitDisNm
                                                                              ? splitDisNm[1]
                                                                              : null;
                                                                            var Icondisply =
                                                                              SplitNm == '' || SplitNm == null
                                                                                ? TimeLine.file_type
                                                                                : null;
                                                                            return TimeLine.status == 'edited' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon mr-2">
                                                                                      Edited{' '}
                                                                                    </span>{' '}
                                                                                    By You
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : TimeLine.status == 'saved' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon mr-2">
                                                                                      Saved{' '}
                                                                                    </span>{' '}
                                                                                    By You
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : TimeLine.status == 'delivered' ? (
                                                                              <div>
                                                                                <i className="far fa-circle" />
                                                                                <div className="timeline-item">
                                                                                  <h3 className="timeline-header">
                                                                                    <span className="fmaroon">
                                                                                      Received
                                                                                    </span>{' '}
                                                                                    from{' '}
                                                                                    {this.state.current_business_name}
                                                                                  </h3>
                                                                                  <span className="time">
                                                                                    {moment(
                                                                                      TimeLine.created_date,
                                                                                    ).format('MMMM DD,YYYY')}
                                                                                    <span className="ml-2">
                                                                                      {moment(
                                                                                        TimeLine.created_date,
                                                                                      ).format('hh:mma')}
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                            ) : undefined;
                                                                          },
                                                                        )}
                                                                        {/* <div>
                                                                                                                            <i className="far fa-circle" />
                                                                                                                            <div className="timeline-item">
                                                                                                                                <h3 className="timeline-header"><span className="fmaroon mr-1">Reply has been saved</span> by You</h3>
                                                                                                                                <span className="time">March 23, 2020<span className="ml-2">10.18am</span></span>
                                                                                                                            </div>
                                                                                                                        </div> */}
                                                                        {/* END timeline item */}
                                                                        {/* timeline item */}
                                                                        {/* <div>
                                                                                                                            <i className="far fa-circle" />
                                                                                                                            <div className="timeline-item">
                                                                                                                                <h3 className="timeline-header"><span className="fmaroon">Received</span> from Ble Media Solutions</h3>
                                                                                                                                <span className="time">March 23, 2020<span className="ml-2">4.18am</span></span>
                                                                                                                            </div>
                                                                                                                        </div> */}
                                                                        {/* END timeline item */}
                                                                        <div>
                                                                          <i className="far fa-clock bg-light border" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                     </span> */}
                                                </div>
                                              </li>
                                            ) : undefined;
                                          })}
                                          {/* <div className="jumptobottom_btn1">
                                            <div
                                              id="jumptobottom"
                                              onClick={this.onClick_scrollbtm}
                                              style={{display: 'none'}}>
                                              <a
                                                href="javascript:void(0)"
                                                className="btn btn-black btn-lg crsr_pntr f_sz16">
                                                <span>
                                                  <i className="fas fa-arrow-down" />
                                                </span>
                                                <span className="d-none d-md-inline-block ml-2">Jump to bottom</span>
                                              </a>
                                            </div>
                                          </div> */}
                                          {/* /.item */}
                                        </ul>
                                      </div>
                                      {/* /.card */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*From CMB - Respond*/}
                            <div className="viewreceivedmessage_div1 w-100">
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-0 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 ">
                                        <h3 className="card-title w-100 d-flex flex-row pl-2">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            <span class="flightgrey3">
                                              {this.state.receiver_reply_made_sideoptns == true ? 'To:' : 'From:'}
                                            </span>{' '}
                                            {this.state.current_business_name}
                                            {/* <span className="businessdtls_clck fmaroon crsr_pntr"><i className="fas fa-info-circle ml-2 f_sz18" /></span> */}
                                            <div className="dropdown d-inline-block ml-3">
                                              {/*<span data-toggle="dropdown" class="badge badge-bred f_sz13"><span class="crsr_pntr">Deleted</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}

                                              <span
                                                onMouseLeave={() => $('.TimeLinDivLs_View').hide()}
                                                data-target="#navItemGame"
                                                onClick={() =>
                                                  this.state.attachment_response.status == 'received'
                                                    ? this.ListTimeLineClk_View(
                                                        this.state.individual_conversation_reply._id,
                                                        this.state.individual_conversation_reply,
                                                      )
                                                    : this.state.attachment_response.status == 'delivered'
                                                    ? this.ListTimeLineClk_View(
                                                        this.state.individual_conversation_reply.campaign,
                                                        this.state.individual_conversation_reply,
                                                      )
                                                    : undefined
                                                }
                                                // data-toggle="dropdown"

                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                <span>
                                                  <i
                                                    className={
                                                      this.state.attachment_response.status == 'received'
                                                        ? 'far fa-arrow-alt-circle-up f_sz18'
                                                        : this.state.attachment_response.status == 'delivered'
                                                        ? 'far fa-arrow-alt-circle-down f_sz18'
                                                        : undefined
                                                    }
                                                    data-toggle="tooltip"
                                                    data-place="bottom"
                                                    data-tip="Received"
                                                  />
                                                  {/* <ReactTooltip /> */}{' '}
                                                </span>
                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                              </span>
                                              <div
                                                className="dropdown-menu dropdown-menu-right TimeLinDivLs_View"
                                                id="navItemGame"
                                                style={{minWidth: 'auto'}}>
                                                <div className="dropdown-item">
                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                      {/* The time line */}
                                                      <div className="timeline">
                                                        {/* timeline time label */}
                                                        <div className="time-label">
                                                          <span className="bg-light border">Status</span>
                                                        </div>
                                                        {/* /.timeline-label */}
                                                        {/* timeline item */}
                                                        {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                          var splitDisNm =
                                                            TimeLine.file_type == 'documents'
                                                              ? TimeLine.display_name.split('.')
                                                              : null;
                                                          var SplitNm = splitDisNm ? splitDisNm[1] : null;
                                                          var Icondisply =
                                                            SplitNm == '' || SplitNm == null
                                                              ? TimeLine.file_type
                                                              : null;
                                                          return TimeLine.status == 'edited' ? (
                                                            <div>
                                                              <i className="far fa-circle" />
                                                              <div className="timeline-item">
                                                                <h3 className="timeline-header">
                                                                  <span className="fmaroon mr-2">Edited </span> By You
                                                                </h3>
                                                                <span className="time">
                                                                  {moment(TimeLine.created_date).format('MMMM DD,YYYY')}
                                                                  <span className="ml-2">
                                                                    {moment(TimeLine.created_date).format('hh:mma')}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          ) : TimeLine.status == 'saved' ? (
                                                            <div>
                                                              <i className="far fa-circle" />
                                                              <div className="timeline-item">
                                                                <h3 className="timeline-header">
                                                                  <span className="fmaroon mr-2">Saved </span> By You
                                                                </h3>
                                                                <span className="time">
                                                                  {moment(TimeLine.created_date).format('MMMM DD,YYYY')}
                                                                  <span className="ml-2">
                                                                    {moment(TimeLine.created_date).format('hh:mma')}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          ) : TimeLine.status == 'received' ? (
                                                            <div>
                                                              <i className="far fa-circle" />
                                                              <div className="timeline-item">
                                                                {this.state.individual_conversation_reply
                                                                  .attachment_url === 'true' ? (
                                                                  <h3 className="timeline-header">
                                                                    {' '}
                                                                    <span className="fmaroon mr-2">Responded</span>By
                                                                    You
                                                                  </h3>
                                                                ) : (
                                                                  <h3 className="timeline-header">
                                                                    <span className="fmaroon mr-2">Sent</span>By You
                                                                  </h3>
                                                                )}
                                                                <span className="time">
                                                                  {moment(TimeLine.created_date).format('MMMM DD,YYYY')}
                                                                  <span className="ml-2">
                                                                    {moment(TimeLine.created_date).format('hh:mma')}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          ) : TimeLine.status == 'delivered' ? (
                                                            <div>
                                                              <i className="far fa-circle" />
                                                              <div className="timeline-item">
                                                                <h3 className="timeline-header">
                                                                  <span className="fmaroon">Received</span> from{' '}
                                                                  {this.state.current_business_name}
                                                                </h3>
                                                                <span className="time">
                                                                  {moment(TimeLine.created_date).format('MMMM DD,YYYY')}
                                                                  <span className="ml-2">
                                                                    {moment(TimeLine.created_date).format('hh:mma')}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          ) : undefined;
                                                        })}
                                                        {/* END timeline item */}
                                                        <div>
                                                          <i className="far fa-clock bg-light border" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span
                                              onClick={this.viewreceivedmessage_close1}
                                              className="viewreceivedmessage_close1">
                                              <i className="fas fa-times mt-2" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      {!this.state.attachment_list ? (
                                        <div className="card-body pb-0 px-0 pt-0">
                                          <div className="w-100 d-inline-block mt-2 mb-4">
                                            {this.state.text_message}
                                          </div>
                                          {errors.map((error) => (
                                            <div id="error" className="alert alert-danger text-center" key={error}>
                                              {error}
                                            </div>
                                          ))}
                                          {success.map((success) => (
                                            <div id="error" className="alert alert-success text-center" key={success}>
                                              {success}
                                            </div>
                                          ))}
                                          <div>
                                            <div className="editrespondmessage_btn">
                                              <a
                                                href="javascript:void(0)"
                                                className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16 savedmessagebtn_clk1">
                                                <span>
                                                  <i className="fas fa-pen" />
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="card-body pb-0 px-0 pt-0 pl-2">
                                          <div
                                            className="w-100 mt-3 mb-4 msgbubble_div bx_shadow_smlight"
                                            style={{
                                              display:
                                                this.state.receiver_reply_made_sideoptns == true
                                                  ? 'none'
                                                  : 'inline-block',
                                            }}>
                                            {this.state.text_message
                                              ? this.state.text_message
                                              : this.state?.individual_conversation_reply?.parent_text_message}
                                          </div>
                                          <div
                                            className="viewreceivedmessage_divhgt scrollbar_style"
                                            style={{
                                              height: this.state.window_height_attach + 'px',
                                              maxHeight: '100vh',
                                              minHeight: '300px',
                                              overflowY: 'auto',
                                              overflowx: 'hidden',
                                            }}>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                                              <div className="attachment_timelinediv">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}
                                                  <div className="time-label">
                                                    {/* <span className="bg-light border">
                                                      {this.state.receiver_reply_made_sideoptns == true
                                                        ? 'Responded With Attachments'
                                                        : 'Attachments Received'}{' '}
                                                      
                                                    </span> */}
                                                  </div>
                                                  {/* /.timeline-label */}
                                                  {/* timeline item */}
                                                  {this.state.attachment_list.map((attachment, index) => {
                                                    if (attachment?.attachment_type === 'nft') {
                                                      if (attachment?.nft_id?.to_wallet_addres) {
                                                        walletaadress =
                                                          wllaetaadr + attachment?.nft_id?.to_wallet_addres;
                                                      } else {
                                                        walletaadress =
                                                          wllaetaadr + attachment?.nft_id?.original_wallet_address;
                                                      }
                                                    }
                                                    return attachment.attachment_type === 'notes' ? (
                                                      <div>
                                                        <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div
                                                            className="timeline-body"
                                                            style={{wordWrap: 'break-word'}}>
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                            <div className="attachmentbody_div">
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html: this.httpHtml(attachment.text),
                                                                }}
                                                              />
                                                              {/* <Linkify
                                                                componentDecorator={(
                                                                  decoratedHref,
                                                                  decoratedText,
                                                                  key,
                                                                ) => (
                                                                  <a target="blank" href={decoratedHref} key={key}>
                                                                    {decoratedText}
                                                                  </a>
                                                                )}>
                                                                <div
                                                                  dangerouslySetInnerHTML={{
                                                                    __html: attachment.text,
                                                                  }}
                                                                /> */}
                                                              {/* {attachment.text} */}
                                                              {/* </Linkify> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type === 'audio' ? (
                                                      <div>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                {/* <div className="d-table-cell w-70 align-top">
																								<span className="attachrecord_icon bg_audio crsr_pntr"><i className="fas fa-microphone-alt" /></span>
																							</div> */}
                                                                <div className="d-table-cell align-middle">
                                                                  <span className="ml-2">
                                                                    <span className="crsr_pntr">
                                                                      <i
                                                                        className={
                                                                          'fas fa-play f_sz24 fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                          attachment.attachment[0]._id
                                                                        }
                                                                        onClick={() =>
                                                                          this.audioPly(
                                                                            attachment.attachment[0],
                                                                            attachment,
                                                                          )
                                                                        }
                                                                        id={attachment.attachment[0]._id}></i>
                                                                      <i
                                                                        className={
                                                                          'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                          attachment.attachment[0]._id
                                                                        }
                                                                        onClick={this.audioPaue}
                                                                        id={attachment.attachment[0]._id}
                                                                        style={{display: 'none'}}></i>
                                                                      <audio
                                                                        style={{display: 'none'}}
                                                                        src={
                                                                          this.state.play_audio_url
                                                                            ? this.state.play_audio_url
                                                                            : undefined
                                                                        }
                                                                        controls="controls"
                                                                        controlsList="nodownload"
                                                                        // hidden
                                                                        id={'audio_' + attachment.attachment[0]._id}
                                                                      />
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type === 'documents' ? (
                                                      <div>
                                                        <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              {/* <span className="font-weight-bold">Documents:</span> */}
                                                              <br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                {attachment.attachment.map((documentList, index) => {
                                                                  var fileType = documentList.display_name.split('.');
                                                                  return (
                                                                    // <a onClick={() => { this.dcReadClk(documentList, attachment._id,attachment.business_id,documentList._id,documentList.file_name,attachment.attachment_type,documentList.display_name,index ) }}>
                                                                    // <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                    // <div className="attachdoc_type">
                                                                    // { fileType[fileType.length - 1] === "pdf" || fileType[fileType.length - 1] === "PDF" ? <i className="far fa-file-pdf attachtype_pdf" /> :
                                                                    // 		fileType[fileType.length - 1] === "docx" || fileType[fileType.length - 1] === "doc" ? <i className="far fa-file-word attachtype_word" /> :
                                                                    // 		fileType[fileType.length - 1] === "jpg" || fileType[fileType.length - 1] === "jpeg" ? <i className="far fa-file-image attachtype_image" /> :
                                                                    // 		fileType[fileType.length - 1] === "png" ? <i className="far fa-file-image attachtype_image" /> :
                                                                    // 		fileType[fileType.length - 1] === "csv" || fileType[fileType.length - 1] === "xls" || fileType[fileType.length - 1] === "xlsx"? <i className="far fa-file-excel attachtype_excel" /> :
                                                                    // 		fileType[fileType.length - 1] === "pptx" || fileType[fileType.length - 1] === "ppt" ? <i className="far fa-file-powerpoint attachtype_ppt" /> :
                                                                    // 		<i className="far fa-file attachtype_otherext" />
                                                                    // 	  }
                                                                    // </div>
                                                                    // <div className="attachdoc_name">{documentList.display_name}</div>
                                                                    // {/* <div className="attachdoc_delete"><a className="crsr_pntr"><i className="far fa-trash-alt f_sz15 fmaroon" onClick={this.DocLclDlete} id={documentList.file_id}></i></a></div> */}
                                                                    // </div>
                                                                    // </a>
                                                                    <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.dcReadClk(
                                                                            documentList,
                                                                            attachment._id,
                                                                            attachment.business_id,
                                                                            documentList._id,
                                                                            documentList.file_name,
                                                                            attachment.attachment_type,
                                                                            documentList.display_name,
                                                                            index,
                                                                          )
                                                                        }>
                                                                        {documentList?.thumbnail_file_url ? (
                                                                          <div className="attachdoc_thumb">
                                                                            <img
                                                                              src={documentList?.thumbnail_file_url}
                                                                              border={0}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <div class="attachdoc_type text-center">
                                                                            <i class="far fa-file-alt flightgrey1"></i>
                                                                          </div>
                                                                        )}

                                                                        <div className="attachdoc_filenmesze py-1 px-2">
                                                                          <table
                                                                            width="100%"
                                                                            cellPadding={0}
                                                                            cellSpacing={0}>
                                                                            <tbody>
                                                                              <tr>
                                                                                <td>
                                                                                  {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                  ) : (
                                                                                    <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                  )}

                                                                                  <span className="f_sz13 mr-2">
                                                                                    {/* {documentList.file_name} */}
                                                                                    {documentList?.display_name
                                                                                      ?.length > 5
                                                                                      ? documentList?.display_name.substring(
                                                                                          0,
                                                                                          5,
                                                                                        ) +
                                                                                        '...' +
                                                                                        this.getExtensionFromUrl(
                                                                                          documentList?.display_name,
                                                                                        )
                                                                                      : documentList?.display_name}
                                                                                  </span>
                                                                                </td>
                                                                                <td align="right">
                                                                                  <span className="f_sz12 flightgrey">
                                                                                    {this.bytesToKb(
                                                                                      documentList?.file_size,
                                                                                    )}{' '}
                                                                                    kB
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </a>
                                                                    </div>
                                                                  );
                                                                })}
                                                              </div>
                                                              {/* <ol className="attachdocuments mt-2">
																								{attachment.attachment.map((documentLst, index) => {
																									var Dcmnsplit=documentLst.file_name.split("_");
																								  return<li>
																											<span className="d-flex w-100 flex-row flex-wrap">
																												<span className="col-12 col-sm-10 col-md-6 col-lg-3 txt_wrap"><span className="f_sz18 mr-2 postop_2"><i className={document.file_type === "image/jpeg" ? "far fa-file-image" : document.file_type === "image/png" ?"far fa-file-image": "far fa-file"} /></span>
																													<a href={documentLst.file_url} target="_blank" >
																														{Dcmnsplit[1]}
																													</a>
																												</span>
																											</span>
																										</li>
																								})}
																							</ol> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type === 'images' ? (
                                                      <div>
                                                        <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              {/* <span className="font-weight-bold">Documents:</span> */}
                                                              <br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                {attachment.attachment.map((documentList, index) => {
                                                                  var fileType = documentList.display_name.split('.');
                                                                  return (
                                                                    <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.dcReadClk(
                                                                            documentList,
                                                                            attachment._id,
                                                                            attachment.business_id,
                                                                            documentList._id,
                                                                            documentList.file_name,
                                                                            attachment.attachment_type,
                                                                            documentList.display_name,
                                                                            index,
                                                                          )
                                                                        }>
                                                                        {documentList?.thumbnail_file_url ? (
                                                                          <div className="attachdoc_thumb">
                                                                            <img
                                                                              src={documentList?.thumbnail_file_url}
                                                                              border={0}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <div class="attachdoc_type text-center">
                                                                            <i class="far fa-file-alt flightgrey1"></i>
                                                                          </div>
                                                                        )}

                                                                        <div className="attachdoc_filenmesze py-1 px-2">
                                                                          <table
                                                                            width="100%"
                                                                            cellPadding={0}
                                                                            cellSpacing={0}>
                                                                            <tbody>
                                                                              <tr>
                                                                                <td>
                                                                                  {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                  ) : (
                                                                                    <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                  )}

                                                                                  <span className="f_sz13 mr-2">
                                                                                    {/* {documentList.file_name} */}
                                                                                    {documentList?.display_name
                                                                                      ?.length > 5
                                                                                      ? documentList?.display_name.substring(
                                                                                          0,
                                                                                          5,
                                                                                        ) +
                                                                                        '...' +
                                                                                        this.getExtensionFromUrl(
                                                                                          documentList?.display_name,
                                                                                        )
                                                                                      : documentList?.display_name}
                                                                                  </span>
                                                                                </td>
                                                                                <td align="right">
                                                                                  <span className="f_sz12 flightgrey">
                                                                                    {this.bytesToKb(
                                                                                      documentList?.file_size,
                                                                                    )}{' '}
                                                                                    kB
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </a>
                                                                    </div>
                                                                  );
                                                                })}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type === 'nft' &&
                                                      this.state.nft_status != 'minted' ? (
                                                      <div>
                                                        {' '}
                                                        <i className="far nft attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body mt-1">
                                                            <div className="attachmentbody_div">
                                                              <div>
                                                                {this.state.nft_status === 'sent' ? (
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3 f_sz16 nft_yes_no">
                                                                    <span className="font-weight-bold mr-2">
                                                                      Accept:
                                                                    </span>
                                                                    {attachment.nft_id.token_transfer_hash == '' &&
                                                                    attachment.nft_id.to_wallet_address == '' ? (
                                                                      <span
                                                                        className="btn btn-gray btnpdng_sm1 crsr_pntr"
                                                                        onClick={this.nft_transfer}
                                                                        style={{
                                                                          pointerEvents: this.state.nftTransferDisabled
                                                                            ? 'none'
                                                                            : 'auto',
                                                                        }}>
                                                                        Yes
                                                                      </span>
                                                                    ) : undefined}{' '}
                                                                    <span
                                                                      className="btn btn-gray btnpdng_sm1 crsr_pntr"
                                                                      onClick={this.nft_changestatus}
                                                                      style={{
                                                                        pointerEvents: this.state
                                                                          .nftChangeStatusDisabled
                                                                          ? 'none'
                                                                          : 'auto',
                                                                      }}>
                                                                      No
                                                                    </span>
                                                                    {/* <button
                                                                      type="button"
                                                                      id="nft_show_popup"
                                                                      data-toggle="modal"
                                                                      data-target="#modal_acceptnft"
                                                                      data-backdrop="static"
                                                                      style={{display: 'none'}}></button> */}
                                                                  </div>
                                                                ) : undefined}
                                                                <div className="form-group mb-4">
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <label className="col-form-label">QR Code</label>
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <div className="nftqrcode_img">
                                                                      <QRCode
                                                                        value={
                                                                          APP_ENDPOINT +
                                                                          '/Nft_Receipt?qrcode=true&trans_id=' +
                                                                          attachment.nft_id?.token_hash
                                                                        }
                                                                        size={200}
                                                                        level={'L'}
                                                                        includeMargin={false}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group mt-3 mb-4">
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <label className="col-form-label">IPFS</label>
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <a
                                                                      target="_blank"
                                                                      href={
                                                                        'https://ipfs.io/ipfs/' + attachment.nft_id.ipfs
                                                                      }
                                                                      className="fmaroon">
                                                                      {attachment.nft_id.ipfs}
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group mt-3 mb-4">
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <label className="col-form-label">
                                                                      Description
                                                                    </label>
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    {attachment.nft_id.nft_description}{' '}
                                                                    {attachment.nft_id.upload_type}
                                                                  </div>
                                                                </div>
                                                                {attachment.nft_id.upload_type === 'image/png' ||
                                                                attachment.nft_id.upload_type === 'image/x-png' ||
                                                                attachment.nft_id.upload_type === 'image/jpeg' ||
                                                                attachment.nft_id.upload_type === 'image/pipeg' ||
                                                                attachment.nft_id.upload_type === 'image/tiff' ||
                                                                attachment.nft_id.upload_type === 'image/svg+xml' ? (
                                                                  <div className="form-group mt-3 mb-4">
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <label className="col-form-label">Image</label>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <div className="nftimage_div">
                                                                        <img src={attachment.nft_id.file_url} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : attachment.nft_id.upload_type === 'audio/mp3' ? (
                                                                  <div className="form-group row mt-3 mb-4 m-2">
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <label className="col-form-label">Audio</label>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <div className="uploaded_audiodiv text-center">
                                                                        <div className="d-flex align-items-center">
                                                                          <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                                          <span className="font-weight-semibold">
                                                                            {attachment.nft_id.file_name}
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : attachment.nft_id.upload_type === 'image/gif' ? (
                                                                  <div className="form-group row mt-3 mb-4 m-2">
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <label className="col-form-label">GIF</label>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                      <div className="nftimage_div mt-3">
                                                                        <img src={attachment.nft_id.file_url} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : undefined}
                                                                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 receipt_div mt-4 p-0">
                                                                      <div className="w-100 d-table">
                                                                      <div className="d-tablerow">
                                                                          <div className="d-tablecell">
                                                                          <div className="receipt_photo" align="center"><img src={sample_painting} border={0} /></div>
                                                                          </div>
                                                                          <div className="d-tablecell">
                                                                          <div className="receipt_nftlogo" align="center"><img src={nft_icon} border={0} /></div>
                                                                          </div>
                                                                          <div className="d-tablecell">
                                                                          <div className="receipt_ethereumlogo" align="center"><img src={ethereum_logo} border={0} /></div>
                                                                          </div>
                                                                      </div>
                                                                      </div>
                                                                  </div> */}

                                                                <div className="mt-4">
                                                                  {attachment.nft_id?.token_key?.length != 0
                                                                    ? attachment.nft_id?.token_key?.map(
                                                                        (data, index) => (
                                                                          <div className="form-group row mt-3 mb-2 m-2">
                                                                            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                                              <label className="col-form-label">
                                                                                {data.key}
                                                                              </label>
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                                              <div className="mt-2">{data.value}</div>
                                                                            </div>
                                                                          </div>
                                                                        ),
                                                                      )
                                                                    : ''}
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="mt-4 mb-1">
                                                                    Your art is available as NFT as of{' '}
                                                                    {attachment.nft_id.updated_date != ''
                                                                      ? moment(attachment.nft_id.updated_date).format(
                                                                          'MMM DD,YYYY h:mm a',
                                                                        )
                                                                      : moment(attachment.nft_id.created_date).format(
                                                                          'MMM DD,YYYY h:mm a',
                                                                        )}
                                                                  </div>
                                                                  <div className="mt-3 mb-1">
                                                                    <span className="font-weight-bold">
                                                                      Wallet Address:
                                                                    </span>{' '}
                                                                    <a
                                                                      target="_blank"
                                                                      href={walletaadress}
                                                                      className="fmaroon">
                                                                      {attachment.nft_id.to_wallet_address != ''
                                                                        ? attachment.nft_id.to_wallet_address
                                                                        : attachment.nft_id.original_wallet_address}
                                                                    </a>
                                                                  </div>
                                                                  <div className="mt-3 mb-1">
                                                                    <span className="font-weight-bold">
                                                                      Transaction Hash:
                                                                    </span>{' '}
                                                                    <a
                                                                      target="_blank"
                                                                      href={
                                                                        'http://blezar.ephrontech.com/transaction/' +
                                                                        attachment.nft_id.token_hash +
                                                                        '?project=redtie_live'
                                                                      }
                                                                      className="fmaroon">
                                                                      {attachment.nft_id.token_hash}
                                                                    </a>
                                                                  </div>
                                                                  <div className="mt-3 mb-1">
                                                                    <span className="font-weight-bold">
                                                                      Token Transfer Hash:
                                                                    </span>{' '}
                                                                    <a
                                                                      target="_blank"
                                                                      href={
                                                                        'http://blezar.ephrontech.com/transaction/' +
                                                                        attachment.nft_id.token_transfer_hash +
                                                                        '?project=redtie_live'
                                                                      }
                                                                      className="fmaroon">
                                                                      {attachment.nft_id.token_transfer_hash}
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>{' '}
                                                      </div>
                                                    ) : attachment.attachment_type === 'fungible_token' ? (
                                                      <div>
                                                        <i className="far ft attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                            <div className="attachmentbody_div">
                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                                                <div className="mb-2">
                                                                  <span className="font-weight-bold fgreen4 f_sz18 mr-1">
                                                                    {attachment?.ft_transaction_id?.amount}
                                                                  </span>
                                                                  {
                                                                    this.state?.business_info_det?.fungible_token
                                                                      ?.token_name
                                                                  }{' '}
                                                                  Fungible Token
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      attachment?.ft_transaction_id?.status == 'pending'
                                                                        ? 'inline-block'
                                                                        : 'none',
                                                                  }}>
                                                                  <span className="font-weight-bold mr-2">Accept:</span>{' '}
                                                                  <input
                                                                    type="hidden"
                                                                    style={{display: 'none'}}
                                                                    data-toggle="modal"
                                                                    data-target="#modal_tokentransfer"
                                                                    data-val="12"
                                                                    data-backdrop="static"
                                                                    id="modal_ft_window"
                                                                  />
                                                                  <a
                                                                    onClick={() =>
                                                                      this.accept_ft(
                                                                        attachment?.ft_transaction_id?._id,
                                                                        attachment?.ft_transaction_id?.amount,
                                                                      )
                                                                    }>
                                                                    <span className="btn btn-gray btnpdng_sm2">
                                                                      Yes
                                                                    </span>
                                                                  </a>{' '}
                                                                  <span
                                                                    onClick={() =>
                                                                      this.reject_ft(
                                                                        attachment?.ft_transaction_id?._id,
                                                                        attachment?.ft_transaction_id?.amount,
                                                                      )
                                                                    }
                                                                    className="btn btn-gray btnpdng_sm2">
                                                                    No
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div
                                                                className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3"
                                                                style={{
                                                                  display:
                                                                    attachment?.ft_transaction_id?.status != 'pending'
                                                                      ? 'inline-block'
                                                                      : 'none',
                                                                }}>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      attachment?.ft_transaction_id?.status ==
                                                                      'completed'
                                                                        ? 'inline-block'
                                                                        : 'none',
                                                                  }}>
                                                                  <span className="font-weight-bold mr-2 fgreen4">
                                                                    Accepted
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      attachment?.ft_transaction_id?.status !==
                                                                        'completed' &&
                                                                      attachment?.ft_transaction_id?.status !==
                                                                        'pending'
                                                                        ? 'inline-block'
                                                                        : 'none',
                                                                  }}>
                                                                  <span className="font-weight-bold mr-2 fbred">
                                                                    Rejected
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type === 'video' ? (
                                                      <div>
                                                        <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              {/* <span className="font-weight-bold">Video:</span> */}
                                                              <br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                <a
                                                                  onClick={() =>
                                                                    this.Video_play_url(
                                                                      attachment.attachment[0],
                                                                      attachment,
                                                                    )
                                                                  }>
                                                                  <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                    <div
                                                                      className="attachvideo_thumb d-flex align-items-center justify-content-center"
                                                                      style={{
                                                                        backgroundImage: `url(${
                                                                          attachment?.attachment[0]?.thumbnail_file_url
                                                                            ? attachment.attachment[0]
                                                                                .thumbnail_file_url
                                                                            : undefined
                                                                        })`,
                                                                      }}>
                                                                      {attachment?.attachment[0]
                                                                        ?.thumbnail_file_url ? undefined : (
                                                                        // <img src={attachment.attachment[0].thumbnail_file_url} border={0} />
                                                                        <i className="fas fa-video fa-3x" />
                                                                      )}
                                                                      {/* <img src={attachment.attachment[0].thumbnail_file_path} border={0} />
                                                <i className="fas fa-video" /> */}
                                                                    </div>
                                                                    <div
                                                                      className="attachvideo_gif"
                                                                      style={{
                                                                        backgroundImage: `url(${
                                                                          attachment?.attachment[0]?.animated_file_url
                                                                            ? attachment.attachment[0].animated_file_url
                                                                            : undefined
                                                                        })`,
                                                                      }}>
                                                                      {attachment?.attachment[0]
                                                                        ?.animated_file_url ? undefined : (
                                                                        <span className="p-2">
                                                                          Click here to play video
                                                                        </span>
                                                                      )}
                                                                      {/* <img src={attachment.attachment[0].thumbnail_file_url} border={0} />
                                                Click here to play video */}
                                                                    </div>
                                                                    <div class="attachvideo_duration">
                                                                      {attachment?.attachment[0]?.video_duration
                                                                        ? this.toHHMMSS(
                                                                            attachment?.attachment[0]?.video_duration,
                                                                          )
                                                                        : undefined}
                                                                    </div>
                                                                  </div>
                                                                </a>
                                                              </div>
                                                            </div>
                                                            {/* <div className="attachmentbody_div d-table">
																						<div className="d-table-row">
																							<div className="d-table-cell align-middle">
																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper" onClick={() => { this.dcReadClk(attachment._id,attachment.business_id,attachment.attachment[0]._id,attachment.attachment[0].file_name,attachment.attachment_type,attachment.attachment[0].display_name,index ) }}>
																										<video width={300} controls playsInline onClick={() => { this.dcReadClk(attachment._id,attachment.business_id,attachment.attachment[0]._id,attachment.attachment[0].file_name,attachment.attachment_type,attachment.attachment[0].display_name,index ) }} className="videoRead">
																								
                                                                                                            <source key={index} src={attachment.attachment[0].file_url} />
																											<source key={index} src={attachment.attachment[0].second_file_url} type="video/mp4"/>
																											<source key={index} src={attachment.attachment[0].third_file_url} type="video/mov"/>																									
																											<source key={index} src={attachment.attachment[0].fourth_file_url} type="video/ogg"/>
																										</video>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type == 'google_calendar' ? (
                                                      <div>
                                                        <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                            <div className="attachmentbody_div">
                                                              <div
                                                                className="all_contentlft_450"
                                                                style={{maxWidth: '454px'}}>
                                                                <div className="form-group row mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Event Name
                                                                  </label>
                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">{attachment.calendar_name}</div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Start Date and Time
                                                                  </label>
                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">
                                                                      {moment(attachment.calendar_startdate).format(
                                                                        'MMMM DD,YYYY',
                                                                      )}
                                                                      <span className="ml-2">
                                                                        {moment(attachment.calendar_startdate).format(
                                                                          'hh:mma',
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    End Date and Time
                                                                  </label>
                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">
                                                                      {moment(attachment.calendar_enddate).format(
                                                                        'MMMM DD,YYYY',
                                                                      )}
                                                                      <span className="ml-2">
                                                                        {moment(attachment.calendar_enddate).format(
                                                                          'hh:mma',
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Time Zone
                                                                  </label>

                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">
                                                                      {attachment.time_zone == 'America/Los_Angeles'
                                                                        ? '(GMT-07:00) Pacific Time'
                                                                        : attachment.time_zone == 'America/New_York'
                                                                        ? '(GMT-04:00) Eastern Time'
                                                                        : attachment.time_zone == 'America/Denver'
                                                                        ? '(GMT -7:00) Mountain Time'
                                                                        : attachment.time_zone == 'America/Anchorage'
                                                                        ? '(GMT-08:00) Alaska Time'
                                                                        : attachment.time_zone == 'America/Chicago'
                                                                        ? '(GMT-05:00) Central Time'
                                                                        : attachment.time_zone == 'Pacific/Honolulu'
                                                                        ? '(GMT-10:00) Hawaii'
                                                                        : attachment.time_zone == 'Asia/Kolkata'
                                                                        ? '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
                                                                        : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  {/* <div className="icheck-darkgrey d-inline-block py-1">
  <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                        <input type="checkbox" id="cttag1" />
                                                        <label htmlFor="cttag1">All Day</label>
                                                      </div> 
                                                            
                                                          </div>*/}
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Recurrence
                                                                  </label>
                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">
                                                                      {attachment.event_recurrence ==
                                                                      'RRULE:FREQ=YEARLY'
                                                                        ? 'Annually'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
                                                                        ? 'Every weekday (Monday to Friday)'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=DAILY'
                                                                        ? 'Daily'
                                                                        : attachment.event_recurrence ==
                                                                          'does_not_repeat'
                                                                        ? 'Does not repeat'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=SU'
                                                                        ? 'Weekly on Sunday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=MO'
                                                                        ? 'Weekly on Monday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=TU'
                                                                        ? 'Weekly on Tuesday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=WE'
                                                                        ? 'Weekly on Wesnesday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=TH'
                                                                        ? 'Weekly on Thursday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=FR'
                                                                        ? 'Weekly on Friday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=SA'
                                                                        ? 'Weekly on Saturday'
                                                                        : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="form-group row mt-3 mb-4">
                                                          <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                            Location
                                                          </label>
                                                          <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                            <input type="text" className="form-control" />
                                                          </div>
                                                        </div> */}
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Description
                                                                  </label>

                                                                  <div class="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div class="mt-2">
                                                                      {attachment.event_description}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="form-group row mt-3 mb-4">
                                                          <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                            Status
                                                          </label>
                                                          <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                            <div className="select_style">
                                                              <select className="form-control f_sz14">
                                                                <option>Confirmed</option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div> */}
                                                              </div>
                                                              <div className="mb-3">
                                                                <span className="font-weight-bold">Meeting Link:</span>
                                                                <span className="fmaroon ml-3 txt_wrap">
                                                                  <a href={attachment.google_event_url} target="_blank">
                                                                    {attachment.google_event_url}
                                                                  </a>
                                                                </span>
                                                              </div>
                                                              {/* {attachment.contacts?.map((contact, index) => {
                                                                
                                                                if(contact.contact == this.state.contact_id){
                                                                  return (                                                              
                                                                  
                                                                  <div className={"cal_update_optns_"+attachment._id } 
                                                                  // style={{display: contact.status == "needAction"? "block": "none"}}
                                                                  >
                                                                  <span className="font-weight-bold mr-2">Going?</span>{' '}
                                                                  <span onClick={()=>this.calender_update(attachment, "accepted")} className="btn btn-gray btnpdng_sm2">Yes</span>{' '}
                                                                  <span onClick={()=>this.calender_update(attachment, "declined")} className="btn btn-gray btnpdng_sm2">No</span>{' '}
                                                                  <span onClick={()=>this.calender_update(attachment, "tentative")} className="btn btn-gray btnpdng_sm2">May be</span>
                                                                </div>
                                                                  );}
                                                              })} */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : attachment.attachment_type == 'quick_meeting' ? (
                                                      <div>
                                                        <i className="fas fa-user-clock bg_meet attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                            <div className="attachmentbody_div">
                                                              <div className="mb-3">
                                                                <span className="font-weight-bold">Meeting Link:</span>
                                                                <span className="fmaroon ml-3 txt_wrap">
                                                                  <a href={attachment.event_link} target="_blank">
                                                                    {attachment.event_link}
                                                                  </a>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : undefined;
                                                  })}

                                                  {/* <div>
                                                    <i
                                                      className="fas fa-circle bg-gray"
                                                      style={{fontSize: 'x-small'}}
                                                    />
                                                  </div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            style={
                                              this.state.receiver_reply_made_sideoptns == true
                                                ? {display: 'none'}
                                                : {display: 'block'}
                                            }>
                                            {/*When already replied show reply icon as greyed out*/}
                                            {/*<div class="respondmessage_btn">
                <a href="javascript:void(0)" class="btn btn-secondary1 btn-lg crsr_pntr f_sz16 viewsentmessage_clk1">
                <span><i class="fas fa-reply" data-toggle="tooltip" data-place="bottom" data-tip="Show Reply"></i></span>
                </a>
               </div>*/}
                                            {/*When not replied show reply icon as active(i.e maroon)*/}
                                            <div className="replymessage_btn">
                                              <div>
                                                <a
                                                  href="javascript:void(0)"
                                                  className={
                                                    this.state.receiver_reply_made == true
                                                      ? 'btn btn-secondary1 btn-lg crsr_pntr f_sz18'
                                                      : 'btn btn-green1 btn-lg crsr_pntr f_sz18 '
                                                  }
                                                  onClick={
                                                    this.state.receiver_reply_made == true
                                                      ? () =>
                                                          this.individual_view_sentmessage_click(
                                                            this.state.individual_conversation_reply1,
                                                          )
                                                      : () =>
                                                          this.individual_respondmessage_clk(
                                                            this.state.individual_conversation_reply,
                                                          )
                                                  }>
                                                  <i
                                                    class="fas fa-share fa-flip-horizontal f_sz16 crsr_pntr"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Go to Message"
                                                    data-original-title="Go to Message"></i>
                                                  {/* <span>
                                                    <i className="fa fa-reply" />
                                                  </span>
                                                  <span className="d-md-inline-block ml-2">
                                                    {this.state.receiver_reply_made == true ? 'Replied' : 'Reply'}
                                                  </span> */}
                                                </a>
                                              </div>
                                            </div>
                                            {/* 
                                                            <div className="respondmessage_btn">
                                                                <a className={this.state.receiver_reply_made == true ? "btn btn-secondary1 btn-lg crsr_pntr f_sz16":"btn btn-maroon_outline btn-lg crsr_pntr f_sz16"}
                                                                onClick={this.state.receiver_reply_made == true ? () => this.individual_view_sentmessage_click(this.state.individual_conversation_reply1) :() => this.individual_respondmessage_clk(this.state.individual_conversation_reply)  }
                                                                >
                                                                    <span><i className="fa fa-reply" /></span>
                                                                </a>
                                                            </div>
                                                            <div className="password_btn" data-toggle="modal" data-target="#modal_setpswd" data-backdrop="static">
                                                                <a href="javascript:void(0)" className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16" data-toggle="tooltip" data-place="bottom" data-tip="Set Password">
                                                                    <span><i className="fas fa-lock" /></span>
                                                                </a>
                                                            </div>
                                                            <div className="emailmessage_btn">
                                                                <span className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16" data-toggle="modal" data-target="#modal_securityprefer_email" data-backdrop="static">
                                                                    <span><i className="fas fa-envelope" data-toggle="tooltip" data-place="bottom" data-tip="Share via Email" /></span>
                                                                </span>
                                                            </div>
                                                            <div className="textmessage_btn">
                                                                <span className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16">
                                                                    <span><i className="fas fa-comment-alt" data-toggle="tooltip" data-place="bottom" data-tip="Share via Text" /></span>
                                                                </span>
                                                            </div>*/}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*Reply Saved*/}
                            <div className="saved_respondmessage_div w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-2 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            Ble Media Solutions{' '}
                                            <span className="businessdtls_clck flightgrey3 crsr_pntr">
                                              <i className="fas fa-info-circle ml-2 f_sz18" />
                                            </span>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span className="saved_respondmessage_close1">
                                              <i className="fas fa-times mt-2" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="card-body pb-0 px-0 pt-0">
                                        <div className="all_contentcntr_450">
                                          <div className="form-horizontal formbiglbl mb-2">
                                            <div className="sendmessage_converseview_slim mt-2 mb-2">
                                              <div className="col-12 col-sm-9 col-md-9 col-lg-9">
                                                <div className="sendmsg_conversesend_slim mt-3">
                                                  {/*<div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-9">
                            <input type="text" class="form-control txtarea_sm sendmsgslim_txtdiv" placeholder="Reply"/>
                          </div>*/}
                                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="attachtypesslim_div">
                                                      <div className="overallattachment_div justify-content-around">
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_notes">
                                                            <i
                                                              className="far fa-sticky-note"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Notes"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_documents">
                                                            <i
                                                              className="far fa-file-alt"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Documents"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_gallery">
                                                            <label className="btn-bs-file">
                                                              {/*Upload*/}
                                                              <i className="far fa-image" />
                                                              <input type="file" />
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_audio">
                                                            <i
                                                              className="fas fa-microphone-alt"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Audio"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_video">
                                                            <i
                                                              className="fas fa-film"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Video"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-3 col-md-3 col-lg-3 position-relative">
                                                {/* <div class="d-flex justify-content-around mt-3 mb-3">
                          <div class="send_iconimg crsr_pntr mt-2 mr-3"><i class="far fa-save fgrey" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Save"></i></div>
                          <div class="send_iconimg crsr_pntr mt-2 mr-3"><i class="far fa-calendar-alt fbblue scheduledatetime_tgl" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Schedule"></i></div>
                          <div class="send_iconimg crsr_pntr mt-1 mr-2" data-toggle="modal" data-target="#modal_confirmsend" data-backdrop="static"><img src="../dist/img/send_icon.png" border="0" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Send"/></div>
                          </div> */}
                                                <div className="d-flex justify-content-around justify-content-md-start mt-3 mb-3">
                                                  <div className="btn-group slimreplybtns_div">
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-black-dark2 f_sz16"
                                                      data-toggle="modal"
                                                      data-target="#modal_confirmsend"
                                                      data-backdrop="static">
                                                      Send
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-black f_sz16 pr-3 dropdown-toggle dropdown-icon"
                                                      data-toggle="dropdown">
                                                      <span className="sr-only">Toggle Dropdown</span>
                                                      <div className="dropdown-menu" role="menu">
                                                        <a className="dropdown-item" href="#">
                                                          Save
                                                        </a>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="scheduledatetime_tgldiv" style={{display: 'none'}}>
                                              <div className="all_contentcntr_400 scheduledatetime_div">
                                                <div className="form-group mb-4">
                                                  <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                                    Schedule Date, Time and Time Zone
                                                  </label>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text nobrdr_bg font-weight-bold">
                                                        <i className="far fa-calendar-alt" />
                                                      </span>
                                                    </div>
                                                    <input type="text" className="form-control text-center" />
                                                  </div>
                                                  <div className="w-100 d-inline-block mt-3">
                                                    <div className="select_style">
                                                      <select className="form-control">
                                                        <option value="GMT-1200">
                                                          (GMT-12:00) Eniwetok, Kwajalein
                                                        </option>
                                                        <option value="GMT-1100">
                                                          (GMT-11:00) Midway Island, Samoa
                                                        </option>
                                                        <option value="GMT-1000">(GMT-10:00) Hawaii</option>
                                                        <option value="GMT-0930">(GMT-9:30) Taiohae</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 px-0">
                                                  <div className="sendmessage_btnsdiv text-center">
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                        Ok
                                                      </a>
                                                    </span>
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                        Reset
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="viewrespondmessage_divhgt scrollbar_style">
                                          <div className="sendmsg_attachmentdiv_sm">
                                            <div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}
                                                  <div className="timeline">
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            Lorem ipsum dolor sit amet consectetur adipiscing elit, urna
                                                            consequat felis vehicula class ultricies mollis dictumst,
                                                            aenean non a in donec nulla. Phasellus ante pellentesque
                                                            erat cum risus{' '}
                                                            <a href="javascript:void(0)">consequat imperdiet aliquam</a>
                                                            , integer placerat et turpis mi eros nec lobortis taciti,
                                                            vehicula nisl litora tellus ligula porttitor metus.
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                        <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                       </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                    {/*<i class="far fa-pause-circle"></i> ----Pls use this for pause----*/}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                        <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                       </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                        <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                       </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <span>
                                                              <span className="d-inline-block">
                                                                <span>
                                                                  <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                                </span>
                                                                <span className="postop_5up">
                                                                  We are processing. Please wait...
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <textarea
                                                            className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                            placeholder="Enter the notes"
                                                            defaultValue={''}
                                                          />
                                                          {/*<div class="attachmentbody_div">
                                    Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus consequat imperdiet aliquam, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus. 
                                    </div>*/}
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                        <span class="attachrecord_icon bg_video crsr_pntr"><i class="fas fa-film"></i></span>
                                       </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                {/*<div class="f_sz16">
                                        <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                        <span class="ml-2">0.10</span>
                                        </div>*/}
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="videoWrapper">
                                                                    <video width={400} controls>
                                                                      <source
                                                                        src="https://www.w3schools.com/html/mov_bbb.mp4"
                                                                        type="video/mp4"
                                                                      />
                                                                      <source
                                                                        src="https://www.w3schools.com/html/mov_bbb.ogg"
                                                                        type="video/ogg"
                                                                      />
                                                                      Your browser does not support HTML video.
                                                                    </video>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell w-70 align-top">
                                                                <span className="attachrecord_icon bg_video crsr_pntr">
                                                                  <i className="fas fa-film" />
                                                                </span>
                                                              </div>
                                                              <div className="d-table-cell align-middle">
                                                                <div className="f_sz16">
                                                                  <span>
                                                                    <i className="fas fa-spinner fa-spin f_sz24" />
                                                                  </span>
                                                                  <span className="ml-2">0.10</span>
                                                                </div>
                                                                {/*<div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                         <div class="videoWrapper">
                                            <video width="400" controls>
                                              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                                              <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg">
                                              Your browser does not support HTML video.
                                            </video>
                                         
                                         </div>
                                        </div>*/}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                            <span className="font-weight-bold">Documents:</span>
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachpdf_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-pdf attachtype_pdf"></i></div>*/}
                                                                {/*<div class="attachdoc_name">https://ourprofile.pdf1</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.pdf
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachword_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-word attachtype_word"></i></div>*/}
                                                                {/*<div class="attachdoc_name">https://ourprofile.doc</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofiledocs.....
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/pexels-photo-1133957.jpeg"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-image attachtype_image"></i></div>
                                           <div class="attachdoc_name">https://ourprofile.jpg</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.jpeg
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachppt_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-powerpoint attachtype_ppt"></i></div>
                                           <div class="attachdoc_name">https://ourprofile.ppt</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.ppt
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            8 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachexcel_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-excel attachtype_excel"></i></div>
                                           <div class="attachdoc_name">https://ourprofile.xls</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.xlx
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            15 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-alt flightgrey1" />
                                                                </div>
                                                                {/*<div class="attachdoc_name">https://ourprofile.txt</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.txt
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            1 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                            <span className="font-weight-bold">Documents:</span>
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-pdf attachtype_pdf" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.pdf1
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-word attachtype_word" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.doc
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-image attachtype_image" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.jpg
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-powerpoint attachtype_ppt" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.ppt
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-excel attachtype_excel" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.xls
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file attachtype_otherext" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.txt
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                                defaultValue={10}
                                                                disabled
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* <div>
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="viewreceivedmessage_div w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-2 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            Ble Media Solutions{' '}
                                            <span className="businessdtls_clck flightgrey3 crsr_pntr">
                                              <i className="fas fa-info-circle ml-2 f_sz18" />
                                            </span>
                                            <div className="dropdown d-inline-block ml-3">
                                              {/*<span data-toggle="dropdown" class="badge badge-bred f_sz13"><span class="crsr_pntr">Deleted</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                              <span
                                                data-toggle="dropdown"
                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                <span>
                                                  <i
                                                    className="far fa-arrow-alt-circle-up f_sz18"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Responded"
                                                  />
                                                </span>
                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                              </span>
                                              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                <div className="dropdown-item">
                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                      {/* The time line */}
                                                      <div className="timeline">
                                                        {/* timeline time label */}
                                                        <div className="time-label">
                                                          <span className="bg-light border">Status</span>
                                                        </div>
                                                        {/* /.timeline-label */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-1">Responded</span> by You
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">8.18pm</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon">Received</span> from Ble Media
                                                              Solutions
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">1.18am</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        <div>
                                                          <i className="far fa-clock bg-light border" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span className="viewreceivedmessage_close">
                                              <i className="fas fa-times mt-2" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="card-body pb-0 px-0 pt-0">
                                        <div className="w-100 d-inline-block mt-2 mb-4">
                                          Welcome Garrett! Good to see you. Hope you have enjoyed a lot. Love to hear
                                          your feedbacks regarding the party organised. Since you're a valuable
                                          customer, we provide rewards for you to explore.&nbsp;
                                          <span className="fmaroon">https://redtie.co/attach_garrett</span>
                                        </div>
                                        <div className="viewreceivedmessage_divhgt scrollbar_style">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                                            <div className="attachment_timelinediv">
                                              {/* The time line */}
                                              <div className="timeline">
                                                {/* timeline time label */}
                                                <div className="time-label">
                                                  <span className="bg-light border">
                                                    Received on March 22, 2020 <span className="ml-2">1.18am</span>
                                                  </span>
                                                </div>
                                                {/* /.timeline-label */}
                                                {/* timeline item */}
                                                <div>
                                                  <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                      <div className="attachmentbody_div">
                                                        Lorem ipsum dolor sit amet consectetur adipiscing elit, urna
                                                        consequat felis vehicula class ultricies mollis dictumst, aenean
                                                        non a in donec nulla. Phasellus ante pellentesque erat cum risus{' '}
                                                        <a href="javascript:void(0)">consequat imperdiet aliquam</a>,
                                                        integer placerat et turpis mi eros nec lobortis taciti, vehicula
                                                        nisl litora tellus ligula porttitor metus.
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div>
                                                  <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="attachmentbody_div d-table">
                                                        <div className="d-table-row">
                                                          {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                     </div>*/}
                                                          <div className="d-table-cell align-middle">
                                                            {/*<span class="f_sz16">
                                      <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                      <span class="ml-2">0:08</span>
                                      </span>*/}
                                                            <span className="d-inline-block">
                                                              <span className="crsr_pntr">
                                                                <i className="far fa-play-circle fa-2x" />
                                                                <span className="ml-2 d-inline-block postop_5up">
                                                                  1:08
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div>
                                                  <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="attachmentbody_div d-table">
                                                        <div className="d-table-row">
                                                          {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                     </div>*/}
                                                          <div className="d-table-cell align-middle">
                                                            {/*<span class="f_sz16">
                                      <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                      <span class="ml-2">0:08</span>
                                      </span>*/}
                                                            <span className="d-inline-block">
                                                              <span className="crsr_pntr">
                                                                <i className="far fa-play-circle fa-2x" />
                                                                <span className="ml-2 d-inline-block postop_5up">
                                                                  3:00
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div>
                                                  <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="attachmentbody_div d-table">
                                                        <div className="d-table-row">
                                                          {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_video crsr_pntr"><i class="fas fa-film"></i></span>
                                     </div>*/}
                                                          <div className="d-table-cell align-middle">
                                                            {/*<div class="f_sz16">
                                      <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                      <span class="ml-2">0.10</span>
                                      </div>*/}
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                              <div className="videoWrapper">
                                                                <video width={400} controls>
                                                                  <source
                                                                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                                                                    type="video/mp4"
                                                                  />
                                                                  <source
                                                                    src="https://www.w3schools.com/html/mov_bbb.ogg"
                                                                    type="video/ogg"
                                                                  />
                                                                  Your browser does not support HTML video.
                                                                </video>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div>
                                                  <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                        {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                        <span className="font-weight-bold">Documents:</span>
                                                        <br />
                                                        <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img src="../dist/img/attachpdf_thumb.png" border={0} />
                                                            </div>
                                                            {/*<div class="attachdoc_type"><i class="far fa-file-pdf attachtype_pdf"></i></div>*/}
                                                            {/*<div class="attachdoc_name">https://ourprofile.pdf1</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.pdf
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img src="../dist/img/attachword_thumb.png" border={0} />
                                                            </div>
                                                            {/*<div class="attachdoc_type"><i class="far fa-file-word attachtype_word"></i></div>*/}
                                                            {/*<div class="attachdoc_name">https://ourprofile.doc</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofiledocs.....
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img
                                                                src="../dist/img/pexels-photo-1133957.jpeg"
                                                                border={0}
                                                              />
                                                            </div>
                                                            {/*<div class="attachdoc_type"><i class="far fa-file-image attachtype_image"></i></div>
                                         <div class="attachdoc_name">https://ourprofile.jpg</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.jpeg
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img src="../dist/img/attachppt_thumb.png" border={0} />
                                                            </div>
                                                            {/*<div class="attachdoc_type"><i class="far fa-file-powerpoint attachtype_ppt"></i></div>
                                         <div class="attachdoc_name">https://ourprofile.ppt</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.ppt
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">8 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img src="../dist/img/attachexcel_thumb.png" border={0} />
                                                            </div>
                                                            {/*<div class="attachdoc_type"><i class="far fa-file-excel attachtype_excel"></i></div>
                                         <div class="attachdoc_name">https://ourprofile.xls</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.xlx
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">15 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-alt flightgrey1" />
                                                            </div>
                                                            {/*<div class="attachdoc_name">https://ourprofile.txt</div>*/}
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.txt
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">1 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="timeline-footer">
                                                      {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                      <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                        Delete
                                                      </a>
                                                      <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                        <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div style={{display: 'none'}}>
                                                  <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                        {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                        <span className="font-weight-bold">Documents:</span>
                                                        <br />
                                                        <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-pdf attachtype_pdf" />
                                                            </div>
                                                            <div className="attachdoc_name">
                                                              https://ourprofile.pdf1
                                                            </div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-word attachtype_word" />
                                                            </div>
                                                            <div className="attachdoc_name">https://ourprofile.doc</div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-image attachtype_image" />
                                                            </div>
                                                            <div className="attachdoc_name">https://ourprofile.jpg</div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-powerpoint attachtype_ppt" />
                                                            </div>
                                                            <div className="attachdoc_name">https://ourprofile.ppt</div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file-excel attachtype_excel" />
                                                            </div>
                                                            <div className="attachdoc_name">https://ourprofile.xls</div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_type">
                                                              <i className="far fa-file attachtype_otherext" />
                                                            </div>
                                                            <div className="attachdoc_name">https://ourprofile.txt</div>
                                                            <div className="attachdoc_delete">
                                                              <a href="javascript:void(0)" className="crsr_pntr">
                                                                <i className="far fa-trash-alt f_sz15" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="timeline-footer">
                                                      {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                      <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                        Delete
                                                      </a>
                                                      <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                        <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* timeline item */}
                                                <div style={{display: 'none'}}>
                                                  <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                  <div className="timeline-item">
                                                    <div className="timeline-body">
                                                      <div className="form-group row">
                                                        <label className="col-form-label pull-left pl-2 f_sz16">
                                                          No. of Rewards
                                                        </label>
                                                        <div className="pull-left ml-3">
                                                          <input
                                                            type="text"
                                                            className="form-control text-center txtbx_sm"
                                                            defaultValue={10}
                                                            disabled
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* END timeline item */}
                                                {/* <div>
                                                  <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                                </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div style={{display: 'none'}}>
                                            {/*When already replied show reply icon as greyed out*/}
                                            <div className="respondmessage_btn">
                                              <a
                                                href="javascript:void(0)"
                                                className="btn btn-secondary1 btn-lg crsr_pntr f_sz16 viewsentmessage_clk1">
                                                <span>
                                                  <i
                                                    className="fas fa-reply"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Show Reply"
                                                  />
                                                </span>
                                              </a>
                                            </div>
                                            {/*When not replied show reply icon as active(i.e maroon)*/}
                                            {/* <div class="respondmessage_btn">
                        <a href="javascript:void(0)" class="btn btn-maroon_outline btn-lg crsr_pntr f_sz16 respondmessage_clk1">
                        <span><i class="fas fa-reply"></i></span>
                        </a>
                       </div>*/}
                                            <div
                                              className="password_btn"
                                              data-toggle="modal"
                                              data-target="#modal_setpswd"
                                              data-backdrop="static">
                                              <a
                                                href="javascript:void(0)"
                                                className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Set Password">
                                                <span>
                                                  <i className="fas fa-lock" />
                                                </span>
                                              </a>
                                            </div>
                                            <div className="emailmessage_btn">
                                              <span
                                                className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16"
                                                data-toggle="modal"
                                                data-target="#modal_securityprefer_email"
                                                data-backdrop="static">
                                                <span>
                                                  <i
                                                    className="fas fa-envelope"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Share via Email"
                                                  />
                                                </span>
                                              </span>
                                            </div>
                                            <div className="textmessage_btn">
                                              <span className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16">
                                                <span>
                                                  <i
                                                    className="fas fa-comment-alt"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Share via Text"
                                                  />
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            {/*When not replied show reply icon as active(i.e green)*/}
                                            {/*<div class="replymessage_btn">
                       <div>
                        <a href="javascript:void(0)" class="btn btn-green1 btn-lg crsr_pntr f_sz18 respondmessage_clk1">
                        <span><i class="fas fa-reply"></i></span><span class="d-none d-md-inline-block ml-2">Reply</span>
                        </a>
                       </div>
                      </div>*/}
                                            {/*When already replied show reply icon as greyed out*/}
                                            <div className="replymessage_btn">
                                              <div>
                                                <a
                                                  href="javascript:void(0)"
                                                  className="btn btn-secondary1 btn-lg crsr_pntr f_sz18 viewsentmessage_clk1">
                                                  <span>
                                                    <i className="fas fa-reply mr-2" />
                                                  </span>
                                                  Replied
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*From View Attachment - Respond*/}
                            <div className="viewsentmessage_div1 w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-2 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            Ble Media Solutions{' '}
                                            <span className="businessdtls_clck flightgrey3 crsr_pntr">
                                              <i className="fas fa-info-circle ml-2 f_sz18" />
                                            </span>
                                            <div className="dropdown d-inline-block ml-3">
                                              {/*<span data-toggle="dropdown" class="badge badge-bred f_sz13"><span class="crsr_pntr">Deleted</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                              <span
                                                data-toggle="dropdown"
                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                <span>
                                                  <i
                                                    className="far fa-arrow-alt-circle-up f_sz18"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Responded"
                                                  />
                                                </span>
                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                              </span>
                                              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                <div className="dropdown-item">
                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                      {/* The time line */}
                                                      <div className="timeline">
                                                        {/* timeline time label */}
                                                        <div className="time-label">
                                                          <span className="bg-light border">Status</span>
                                                        </div>
                                                        {/* /.timeline-label */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-1">Responded</span> by You
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">8.18pm</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon">Received</span> from Ble Media
                                                              Solutions
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">1.18am</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        <div>
                                                          <i className="far fa-clock bg-light border" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span className="viewsentmessage_close1">
                                              <i className="fas fa-times" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      {/* /.card */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="viewsentmessage_divhgt scrollbar_style">
                                <div>
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="attachment_timelinediv">
                                      {/* The time line */}
                                      <div className="timeline">
                                        {/* timeline time label */}
                                        <div className="time-label">
                                          <span className="bg-light border">
                                            Responded on Mar 22, 2020 <span className="ml-2">8.18pm</span>
                                          </span>
                                        </div>
                                        {/* /.timeline-label */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i>11.10pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  {/*<div class="d-table-cell w-70 align-top">
                            <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                          </div>*/}
                                                  <div className="d-table-cell align-middle">
                                                    {/*<span class="f_sz16">
                            <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                            <span class="ml-2">0:08</span>
                            </span>*/}
                                                    <span className="d-inline-block">
                                                      <span className="crsr_pntr">
                                                        <i className="far fa-play-circle fa-2x" />
                                                        <span className="ml-2 d-inline-block postop_5up">1:08</span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i> 10.10pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                <span className="font-weight-bold">Documents:</span>
                                                <br />
                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachppt_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-powerpoint attachtype_ppt"></i></div>
                              <div class="attachdoc_name">https://ourprofile.ppt</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.ppt</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">8 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i>9.30pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                <span className="font-weight-bold">Documents:</span>
                                                <br />
                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachpdf_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-pdf attachtype_pdf"></i></div>*/}
                                                    {/*<div class="attachdoc_name">https://ourprofile.pdf1</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.pdf</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">5 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachexcel_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-excel attachtype_excel"></i></div>
                              <div class="attachdoc_name">https://ourprofile.xls</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.xlx</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">15 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* <div>
                                          <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*From CMB - Respond*/}
                            <div className="viewsentmessage_div w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-2 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            Ble Media Solutions{' '}
                                            <span className="businessdtls_clck flightgrey3 crsr_pntr">
                                              <i className="fas fa-info-circle ml-2 f_sz18" />
                                            </span>
                                            <div className="dropdown d-inline-block ml-3">
                                              {/*<span data-toggle="dropdown" class="badge badge-bred f_sz13"><span class="crsr_pntr">Deleted</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                              <span
                                                data-toggle="dropdown"
                                                className="badge badge-bblue f_sz13 crsr_pntr">
                                                <span>
                                                  <i
                                                    className="far fa-arrow-alt-circle-up f_sz18"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Responded"
                                                  />
                                                </span>
                                                <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                              </span>
                                              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                <div className="dropdown-item">
                                                  <div className="p-0 d-inline-block w-100 f_sz13">
                                                    <div className="statusmsgactvty_timeline_div scrollbar_style">
                                                      {/* The time line */}
                                                      <div className="timeline">
                                                        {/* timeline time label */}
                                                        <div className="time-label">
                                                          <span className="bg-light border">Status</span>
                                                        </div>
                                                        {/* /.timeline-label */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-1">Responded</span> by You
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">8.18pm</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon">Received</span> from Ble Media
                                                              Solutions
                                                            </h3>
                                                            <span className="time">
                                                              March 22, 2020<span className="ml-2">1.18am</span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        <div>
                                                          <i className="far fa-clock bg-light border" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span className="viewsentmessage_close">
                                              <i className="fas fa-times" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      {/* /.card */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="viewsentmessage_divhgt scrollbar_style">
                                <div>
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="attachment_timelinediv">
                                      {/* The time line */}
                                      <div className="timeline">
                                        {/* timeline time label */}
                                        <div className="time-label">
                                          <span className="bg-light border">
                                            Responded on Mar 22, 2020 <span className="ml-2">8.18pm</span>
                                          </span>
                                        </div>
                                        {/* /.timeline-label */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i>11.10pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  {/*<div class="d-table-cell w-70 align-top">
                          <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                        </div>*/}
                                                  <div className="d-table-cell align-middle">
                                                    {/*<span class="f_sz16">
                          <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                          <span class="ml-2">0:08</span>
                          </span>*/}
                                                    <span className="d-inline-block">
                                                      <span className="crsr_pntr">
                                                        <i className="far fa-play-circle fa-2x" />
                                                        <span className="ml-2 d-inline-block postop_5up">1:08</span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i> 10.10pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                <span className="font-weight-bold">Documents:</span>
                                                <br />
                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachppt_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-powerpoint attachtype_ppt"></i></div>
                            <div class="attachdoc_name">https://ourprofile.ppt</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.ppt</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">8 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            {/*<span class="time"><i class="fas fa-clock mr-1"></i>9.30pm</span>*/}
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                <span className="font-weight-bold">Documents:</span>
                                                <br />
                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachpdf_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-pdf attachtype_pdf"></i></div>*/}
                                                    {/*<div class="attachdoc_name">https://ourprofile.pdf1</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.pdf</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">5 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_thumb">
                                                      <img src="../dist/img/attachexcel_thumb.png" border={0} />
                                                    </div>
                                                    {/*<div class="attachdoc_type"><i class="far fa-file-excel attachtype_excel"></i></div>
                            <div class="attachdoc_name">https://ourprofile.xls</div>*/}
                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                      <table width="100%" cellPadding={0} cellSpacing={0}>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                              <span className="f_sz13 mr-2">ourprofile.xlx</span>
                                                            </td>
                                                            <td align="right">
                                                              <span className="f_sz12 flightgrey">15 kB</span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* <div>
                                          <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*From View Attachment - Respond*/}
                            <div className="respondmessage_div1 w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-2 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            Ble Media Solutions{' '}
                                            <span className="businessdtls_clck flightgrey3 crsr_pntr">
                                              <i className="fas fa-info-circle ml-2 f_sz18" />
                                            </span>
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span className="respondmessage_close1">
                                              <i className="fas fa-times mt-2" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="card-body pb-0 px-0 pt-0">
                                        <div className="all_contentcntr_450">
                                          <div className="form-horizontal formbiglbl mb-2">
                                            <div className="sendmessage_converseview_slim mt-2 mb-2">
                                              <div className="col-12 col-sm-9 col-md-9 col-lg-9">
                                                <div className="sendmsg_conversesend_slim mt-3">
                                                  {/*<div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-9">
                            <input type="text" class="form-control txtarea_sm sendmsgslim_txtdiv" placeholder="Reply"/>
                          </div>*/}
                                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="attachtypesslim_div">
                                                      <div className="overallattachment_div justify-content-around">
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_notes">
                                                            <i
                                                              className="far fa-sticky-note"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Notes"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_documents">
                                                            <i
                                                              className="far fa-file-alt"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Documents"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_gallery">
                                                            <label className="btn-bs-file">
                                                              {/*Upload*/}
                                                              <i className="far fa-image" />
                                                              <input type="file" />
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_audio">
                                                            <i
                                                              className="fas fa-microphone-alt"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Audio"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div className="overallattach_icon_sm bg_video">
                                                            <i
                                                              className="fas fa-film"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title
                                                              data-original-title="Video"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-3 col-md-3 col-lg-3 position-relative">
                                                {/* <div class="d-flex justify-content-around mt-3 mb-3">
                          <div class="send_iconimg crsr_pntr mt-2 mr-3"><i class="far fa-save fgrey" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Save"></i></div>
                          <div class="send_iconimg crsr_pntr mt-2 mr-3"><i class="far fa-calendar-alt fbblue scheduledatetime_tgl" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Schedule"></i></div>
                          <div class="send_iconimg crsr_pntr mt-1 mr-2" data-toggle="modal" data-target="#modal_confirmsend" data-backdrop="static"><img src="../dist/img/send_icon.png" border="0" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Send"/></div>
                          </div> */}
                                                <div className="d-flex justify-content-around justify-content-md-start mt-3 mb-3">
                                                  <div className="btn-group slimreplybtns_div">
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-black-dark2 f_sz16"
                                                      data-toggle="modal"
                                                      data-target="#modal_confirmsend"
                                                      data-backdrop="static">
                                                      Send
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-black f_sz16 pr-3 dropdown-toggle dropdown-icon"
                                                      data-toggle="dropdown">
                                                      <span className="sr-only">Toggle Dropdown</span>
                                                      <div className="dropdown-menu" role="menu">
                                                        <a className="dropdown-item" href="#">
                                                          Save
                                                        </a>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="scheduledatetime_tgldiv" style={{display: 'none'}}>
                                              <div className="all_contentcntr_400 scheduledatetime_div">
                                                <div className="form-group mb-4">
                                                  <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                                    Schedule Date, Time and Time Zone
                                                  </label>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text nobrdr_bg font-weight-bold">
                                                        <i className="far fa-calendar-alt" />
                                                      </span>
                                                    </div>
                                                    <input type="text" className="form-control text-center" />
                                                  </div>
                                                  <div className="w-100 d-inline-block mt-3">
                                                    <div className="select_style">
                                                      <select className="form-control">
                                                        <option value="GMT-1200">
                                                          (GMT-12:00) Eniwetok, Kwajalein
                                                        </option>
                                                        <option value="GMT-1100">
                                                          (GMT-11:00) Midway Island, Samoa
                                                        </option>
                                                        <option value="GMT-1000">(GMT-10:00) Hawaii</option>
                                                        <option value="GMT-0930">(GMT-9:30) Taiohae</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 px-0">
                                                  <div className="sendmessage_btnsdiv text-center">
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                        Ok
                                                      </a>
                                                    </span>
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                        Reset
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="viewrespondmessage_divhgt scrollbar_style">
                                          <div className="sendmsg_attachmentdiv_sm">
                                            <div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}
                                                  <div className="timeline">
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <textarea
                                                            className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                            placeholder="Enter the notes"
                                                            defaultValue={''}
                                                          />
                                                          {/*<div class="attachmentbody_div">
                                      Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus consequat imperdiet aliquam, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus. 
                                      </div>*/}
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* <div>
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="sendmsg_attachmentdiv_sm" style={{display: 'none'}}>
                                            <div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}
                                                  <div className="timeline">
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            Lorem ipsum dolor sit amet consectetur adipiscing elit, urna
                                                            consequat felis vehicula class ultricies mollis dictumst,
                                                            aenean non a in donec nulla. Phasellus ante pellentesque
                                                            erat cum risus{' '}
                                                            <a href="javascript:void(0)">consequat imperdiet aliquam</a>
                                                            , integer placerat et turpis mi eros nec lobortis taciti,
                                                            vehicula nisl litora tellus ligula porttitor metus.
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                    </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                    {/*<i class="far fa-pause-circle"></i> ----Pls use this for pause----*/}
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                    </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                    </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="fas fa-record-vinyl fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Start Recording
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'block'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-stop-circle f_sz30" />
                                                                      <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span className="ml-2 d-inline-block postop_3up">
                                                                        0:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span style={{display: 'none'}}>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i className="far fa-play-circle fa-2x" />
                                                                      <span className="ml-2 d-inline-block postop_5up">
                                                                        1:08
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <span>
                                                              <span className="d-inline-block">
                                                                <span>
                                                                  <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                                </span>
                                                                <span className="postop_5up">
                                                                  We are processing. Please wait...
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <textarea
                                                            className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                            placeholder="Enter the notes"
                                                            defaultValue={''}
                                                          />
                                                          {/*<div class="attachmentbody_div">
                                  Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus consequat imperdiet aliquam, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus. 
                                  </div>*/}
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              {/*<div class="d-table-cell w-70 align-top">
                                      <span class="attachrecord_icon bg_video crsr_pntr"><i class="fas fa-film"></i></span>
                                    </div>*/}
                                                              <div className="d-table-cell align-middle">
                                                                {/*<div class="f_sz16">
                                      <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                      <span class="ml-2">0.10</span>
                                      </div>*/}
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="videoWrapper">
                                                                    <video width={400} controls>
                                                                      <source
                                                                        src="https://www.w3schools.com/html/mov_bbb.mp4"
                                                                        type="video/mp4"
                                                                      />
                                                                      <source
                                                                        src="https://www.w3schools.com/html/mov_bbb.ogg"
                                                                        type="video/ogg"
                                                                      />
                                                                      Your browser does not support HTML video.
                                                                    </video>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell w-70 align-top">
                                                                <span className="attachrecord_icon bg_video crsr_pntr">
                                                                  <i className="fas fa-film" />
                                                                </span>
                                                              </div>
                                                              <div className="d-table-cell align-middle">
                                                                <div className="f_sz16">
                                                                  <span>
                                                                    <i className="fas fa-spinner fa-spin f_sz24" />
                                                                  </span>
                                                                  <span className="ml-2">0.10</span>
                                                                </div>
                                                                {/*<div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div class="videoWrapper">
                                          <video width="400" controls>
                                            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                                            <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg">
                                            Your browser does not support HTML video.
                                          </video>
                                      
                                      </div>
                                      </div>*/}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div>
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                            <span className="font-weight-bold">Documents:</span>
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachpdf_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-pdf attachtype_pdf"></i></div>*/}
                                                                {/*<div class="attachdoc_name">https://ourprofile.pdf1</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.pdf
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachword_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-word attachtype_word"></i></div>*/}
                                                                {/*<div class="attachdoc_name">https://ourprofile.doc</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofiledocs.....
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/pexels-photo-1133957.jpeg"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-image attachtype_image"></i></div>
                                        <div class="attachdoc_name">https://ourprofile.jpg</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.jpeg
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            5 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachppt_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-powerpoint attachtype_ppt"></i></div>
                                        <div class="attachdoc_name">https://ourprofile.ppt</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.ppt
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            8 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_thumb">
                                                                  <img
                                                                    src="../dist/img/attachexcel_thumb.png"
                                                                    border={0}
                                                                  />
                                                                </div>
                                                                {/*<div class="attachdoc_type"><i class="far fa-file-excel attachtype_excel"></i></div>
                                        <div class="attachdoc_name">https://ourprofile.xls</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.xlx
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            15 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-alt flightgrey1" />
                                                                </div>
                                                                {/*<div class="attachdoc_name">https://ourprofile.txt</div>*/}
                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                          <span className="f_sz13 mr-2">
                                                                            ourprofile.txt
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            1 kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                            <span className="font-weight-bold">Documents:</span>
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-pdf attachtype_pdf" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.pdf1
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-word attachtype_word" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.doc
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-image attachtype_image" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.jpg
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-powerpoint attachtype_ppt" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.ppt
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file-excel attachtype_excel" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.xls
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachdoc_type">
                                                                  <i className="far fa-file attachtype_otherext" />
                                                                </div>
                                                                <div className="attachdoc_name">
                                                                  https://ourprofile.txt
                                                                </div>
                                                                <div className="attachdoc_delete">
                                                                  <a href="javascript:void(0)" className="crsr_pntr">
                                                                    <i className="far fa-trash-alt f_sz15" />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                                defaultValue={10}
                                                                disabled
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* <div>
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <input
                              style={{display: 'none'}}
                              type="button"
                              id="show_nft_message"
                              data-toggle="modal"
                              data-target="#modal_acceptnft"
                              data-backdrop="static"
                            />
                            <div className="respondmessage_div w-100" style={{display: 'none'}}>
                              <div className="row position-relative">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="all_innersmallcontent_div px-0 pt-0">
                                    <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                        <h3 className="card-title pl-2 w-100 d-flex flex-row">
                                          <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                            {/* {this.state.current_business_name} */}
                                            Attach Files Here
                                            {/* <span className="businessdtls_clck fmaroon crsr_pntr"><i className="fas fa-info-circle ml-2 f_sz18" /></span> */}
                                          </span>
                                          <span className="col-1 col-sm-1 col-md-1 px-0">
                                            <span onClick={this.respondmessage_close} className="respondmessage_close">
                                              <i className="fas fa-times mt-2" />
                                            </span>
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="form-horizontal formbiglbl mb-2">
                                        {errors.map((error) => (
                                          <div id="error" className="alert alert-danger text-center" key={error}>
                                            {error}
                                          </div>
                                        ))}
                                        {success.map((success) => (
                                          <div id="error" className="alert alert-success text-center" key={success}>
                                            {success}
                                          </div>
                                        ))}
                                        <div className="card-body pb-0 px-0 pl-2">
                                          {/* <div className="w-100 d-inline-block mb-3">
                                            
                                            {this.state?.parent_text_message}
                                          </div> */}
                                        </div>
                                        <div className="card-body pb-0 px-0 pt-0">
                                          <div className="all_contentcntr_500">
                                            <div className="sendmessage_converseview_slim mt-2 mb-2">
                                              <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                                <div className="sendmsg_conversesend_slim mt-3">
                                                  {/*<div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-9">
                    <input type="text" class="form-control txtarea_sm sendmsgslim_txtdiv" placeholder="Reply"/>
                   </div>*/}
                                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="attachtypesslim_div" style={{display: 'block'}}>
                                                      <div className="overallattachment_div justify-content-around">
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_notes"
                                                            onClick={this.saveNotes2_cmb}>
                                                            <i
                                                              className="far fa-sticky-note"
                                                              data-place="bottom"
                                                              data-tip="Notes"
                                                            />
                                                          </div>
                                                          {/* <ReactTooltip /> */}
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <label for="file-input1">
                                                            <div
                                                              className="overallattach_icon_sm bg_documents"
                                                              id="fdupld_1">
                                                              <i
                                                                className="far fa-file-alt"
                                                                data-place="bottom"
                                                                data-tip="Documents"
                                                              />

                                                              <input
                                                                id="file-input1"
                                                                type="file"
                                                                name="DcfilNm"
                                                                onChange={this.fileGetDetails_cmb}
                                                                // multiple
                                                              />
                                                            </div>
                                                            {/* <ReactTooltip /> */}
                                                          </label>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <label for="file-input2">
                                                            <div
                                                              className="overallattach_icon_sm bg_gallery"
                                                              id="fdupld_1">
                                                              <i
                                                                className="far fa-image"
                                                                data-place="bottom"
                                                                data-tip="Images"
                                                              />

                                                              <input
                                                                id="file-input2"
                                                                type="file"
                                                                name="DcfilNm"
                                                                accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                                onChange={(event) =>
                                                                  this.fileGetDetails_cmb_images(event)
                                                                }
                                                                // multiple
                                                              />
                                                            </div>
                                                            {/* <ReactTooltip /> */}
                                                          </label>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_audio"
                                                            onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                            <i
                                                              className="fas fa-microphone-alt"
                                                              data-place="bottom"
                                                              data-tip="Audio"
                                                            />
                                                          </div>
                                                          {/* <ReactTooltip /> */}
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_video"
                                                            onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                            <i
                                                              className="fas fa-video"
                                                              data-place="bottom"
                                                              data-tip="Video"
                                                            />
                                                          </div>
                                                          {/* <ReactTooltip /> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-4 col-md-4 col-lg-4 position-relative">
                                                {/* <div className="d-flex justify-content-around mt-3 mb-3">
                                                  <div
                                                    className="send_iconimg crsr_pntr mt-2 mr-3"
                                                    onClick={
                                                      this.state.message_edit == true
                                                        ? this.msg_update_save_cmb
                                                        : this.msg_save_cmb
                                                    }>
                                                    <i
                                                      className="far fa-save fgrey"
                                                      data-toggle="tooltip"
                                                      data-place="bottom"
                                                      data-tip="Save"
                                                    />
                                                    
                                                  </div>
                                                  
                                                  <div
                                                    className="send_iconimg crsr_pntr mt-1 mr-2"
                                                    data-toggle="modal"
                                                    data-target="#modal_confirmsend"
                                                    data-backdrop="static">
                                                    <img
                                                      src={send_icon}
                                                      border={0}
                                                      data-toggle="tooltip"
                                                      data-place="bottom"
                                                      data-tip="Send"
                                                    />
                                                    
                                                  </div>
                                                </div> */}
                                                <div className="d-flex justify-content-center mt-3 mb-3">
                                                  <a
                                                    className="btn btn-lg btn-black-dark2 f_sz16 mx-2 crsr_pntr"
                                                    onClick={
                                                      this.state.message_edit == true
                                                        ? this.msg_update_save_cmb
                                                        : this.msg_save_cmb
                                                    }>
                                                    Save
                                                  </a>
                                                  <a
                                                    className="btn btn-lg btn-black-dark2 f_sz16 mx-2 crsr_pntr"
                                                    data-toggle="modal"
                                                    data-target="#modal_confirmsend"
                                                    data-backdrop="static">
                                                    Send
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="scheduledatetime_tgldiv" style={{display: 'none'}}>
                                              <div className="all_contentcntr_400 scheduledatetime_div">
                                                <div className="form-group mb-4">
                                                  <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                                    Schedule Date, Time and Time Zone
                                                  </label>
                                                  <div className="input-group">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text nobrdr_bg font-weight-bold">
                                                        <i className="far fa-calendar-alt" />
                                                      </span>
                                                    </div>
                                                    <input type="text" className="form-control text-center" />
                                                  </div>
                                                  <div className="w-100 d-inline-block mt-3">
                                                    <div className="select_style">
                                                      <select className="form-control">
                                                        <option value="GMT-1200">
                                                          (GMT-12:00) Eniwetok, Kwajalein
                                                        </option>
                                                        <option value="GMT-1100">
                                                          (GMT-11:00) Midway Island, Samoa
                                                        </option>
                                                        <option value="GMT-1000">(GMT-10:00) Hawaii</option>
                                                        <option value="GMT-0930">(GMT-9:30) Taiohae</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 px-0">
                                                  <div className="sendmessage_btnsdiv text-center">
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                        Ok
                                                      </a>
                                                    </span>
                                                    <span className="sendmessage_btnsspan">
                                                      <a
                                                        href="javascript:void(0)"
                                                        className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                        Reset
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /.card-footer */}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="viewrespondmessage_divhgt scrollbar_style"
                                          style={{
                                            height: this.state.window_height_attach + 'px',
                                            maxHeight: '100vh',
                                            minHeight: '300px',
                                            overflowY: 'auto',
                                            overflowx: 'hidden',
                                          }}>
                                          <div className="sendmsg_attachmentdiv_sm" style={{display: 'none'}}>
                                            <div className="row position-relative">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}

                                                  <div className="timeline">
                                                    {/* timeline item */}
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkAudio2_cmb">
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <span className="ml-2">
                                                                  <span className="crsr_pntr">
                                                                    <i
                                                                      className="fas fa-record-vinyl fa-2x fbred"
                                                                      onClick={this.audio_start_cmb}
                                                                    />
                                                                    <span
                                                                      className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                      onClick={this.audio_start_cmb}>
                                                                      Start Recording
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.audioLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}} className="audioRecordLoading_cmb">
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <span>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i
                                                                        className="far fa-stop-circle f_sz30 fbred"
                                                                        onClick={this.audio_stop_cmb}></i>
                                                                      <span
                                                                        className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                        onClick={this.audio_stop_cmb}>
                                                                        Stop Recording
                                                                      </span>
                                                                    </span>
                                                                    <span className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkNotes2_cmb">
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/* <ReactQuill                                                            
                                                            modules={{
                                                              toolbar: [
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{header: '1'}, {header: '2'}, {font: []}],
                                                                [
                                                                  {list: 'ordered'},
                                                                  {list: 'bullet'},
                                                                  {indent: '-1'},
                                                                  {indent: '+1'},
                                                                ],
                                                                ['link', 'video'],
                                                                ['clean'],
                                                              ],

                                                              clipboard: {
                                                                // toggle to add extra line breaks when pasting HTML:
                                                                matchVisual: false,
                                                              },
                                                            }}
                                                            formats={[
                                                              'header',
                                                              'font',
                                                              'size',
                                                              'bold',
                                                              'italic',
                                                              'underline',
                                                              'strike',
                                                              'list',
                                                              'bullet',
                                                              'indent',
                                                              'link',
                                                              'video',
                                                            ]}
                                                            value={this.state.notesValue2_cmb}
                                                            onChange={this.notesLetter2_cmb}
                                                            maxLength="600"
                                                          /> */}
                                                          <CKEditor
                                                            editor={ClassicEditor}
                                                            data={this.state.notesValue2_cmb}
                                                            id="mytext"
                                                            config={editorConfiguration}
                                                            onReady={(editor) => {
                                                              // You can store the "editor" and use when it is needed.
                                                              // console.log( 'Editor is ready to use!', editor );
                                                            }}
                                                            onChange={(event, editor) => {
                                                              const data = editor.getData();
                                                              // console.log( { event, editor, data } );
                                                              // if (data.length <= 1200) {
                                                              this.setState({
                                                                notesValue2_cmb: data,
                                                                chars_left_notes_cmb:
                                                                  this.state.max_chars_notes_cmb - data.length,
                                                              });
                                                              // }
                                                            }}
                                                            onBlur={(event, editor) => {
                                                              // console.log( 'Blur.', editor );
                                                            }}
                                                            onFocus={(event, editor) => {
                                                              // console.log( 'Focus.', editor );
                                                            }}
                                                          />
                                                          {/* <textarea
                                              className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                              placeholder="Enter the notes"
                                              value={this.state.notesValue2_cmb}
                                              onChange={this.notesLetter2_cmb}
                                              id="notestypearea"
                                              maxLength="200"
                                            /> */}
                                                        </div>
                                                        {/* {this.state.cmb_notesedit === true ? (
                                                          <div className="font-weight-bold flightgrey3   ml-3 text-right text-md-left">
                                                            {this.state.savingprogress}
                                                          </div>
                                                        ) : null} */}
                                                        {/* <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                          
                                                          Maximum 1200 characters
                                                        </div> */}
                                                        <div className="timeline-footer">
                                                          {/* {this.state.cmb_notesedit === true ? (
                                                            <a
                                                              className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                              onClick={() => {
                                                                this.updateNotes2_cmb();
                                                              }}>
                                                              Update
                                                            </a>
                                                          ) : null}                                                      */}
                                                          {/* <a
                                                              className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                              onClick={() => {
                                                                this.updateNotes2_cmb();
                                                                // this.expanded_attach(attachment.attach_id);
                                                              }}>
                                                              {this.state.savingprogress === ""? "Save": this.state.savingprogress }
                                                            </a> */}
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.notesLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div
                                                      style={{display: 'none'}}
                                                      className="sendmsgattach_clkVideo2_cmb">
                                                      <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
                                                                  <VideoRecorder
                                                                    replayVideoAutoplayAndLoopOff={true}
                                                                    // showReplayControls={true}
                                                                    // mimeType="video/mp4"
                                                                    // constraints={{audio: true}}
                                                                    timeLimit={120000}
                                                                    onRecordingComplete={(
                                                                      videoBlob,
                                                                      thumbnailBlob,
                                                                      videoUrl,
                                                                    ) => {
                                                                      $('.sendmsgattach_clkVideo2_cmb').hide();
                                                                      $('.sendmsg_attachmentdiv_sm').hide();
                                                                      $('#attachLoading_cmb').show();
                                                                      $('.attchLstShow_cmb').show();
                                                                      this.setState({
                                                                        lodingicon_cmb: 'fas fa-video bg_video',
                                                                      });

                                                                      // Do something with the video...
                                                                      console.log(
                                                                        'videoBloburl',
                                                                        window.URL.createObjectURL(videoBlob),
                                                                      );
                                                                      console.log('videoBlob', videoBlob);
                                                                      console.log('thumbnailBlob', thumbnailBlob);
                                                                      console.log('videoUrl', videoUrl);
                                                                      var reader = new FileReader();
                                                                      reader.readAsDataURL(videoBlob);
                                                                      reader.onloadend = function () {
                                                                        var base64data = reader.result;
                                                                        // console.log(base64data);
                                                                      };
                                                                      var filename = new Date().toISOString();
                                                                      const data = new FormData();
                                                                      var mp4file = new File(
                                                                        [videoBlob],
                                                                        filename + '.mp4',
                                                                        {
                                                                          type: 'video/mp4',
                                                                          lastModified: new Date(),
                                                                        },
                                                                      );

                                                                      data.append(
                                                                        'apikey',
                                                                        'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                                                                      );
                                                                      data.append('secret', 'R1eqD2twI3E4');
                                                                      data.append('user_id', this.state.user_id);
                                                                      data.append(
                                                                        'business_id',
                                                                        this.state.business_id,
                                                                      );
                                                                      data.append('file', videoBlob, filename);
                                                                      data.append('reference', 'text');
                                                                      data.append('source', 'receiver');
                                                                      // data.append('type', 'video');

                                                                      axios
                                                                        .post(API_CALL_ENDPOINT + '/file/upload', data)
                                                                        .then((res) => {
                                                                          // console.log(res);
                                                                          // console.log(res.data.message);
                                                                          // console.log(res.data.result.files);

                                                                          if (res.data.status == 'success') {
                                                                            var file_url = '';
                                                                            var file_path = '';
                                                                            var second_file_path = '';
                                                                            var file_url2 = '';
                                                                            var file_url3 = '';
                                                                            var file_url4 = '';
                                                                            var file_id = '';
                                                                            for (var i in res.data.result.files) {
                                                                              console.log(
                                                                                res.data.result.files[i].file_url,
                                                                              );
                                                                              file_url +=
                                                                                res.data.result.files[i].file_url;
                                                                              file_path +=
                                                                                res.data.result.files[i].file_path;
                                                                              file_url2 +=
                                                                                res.data.result.files[i]
                                                                                  .second_file_url;
                                                                              second_file_path +=
                                                                                res.data.result.files[i]
                                                                                  .second_file_path;
                                                                              file_url3 +=
                                                                                res.data.result.files[i].third_file_url;
                                                                              file_url4 +=
                                                                                res.data.result.files[i]
                                                                                  .fourth_file_url;
                                                                              file_id += res.data.result.files[i]._id;
                                                                              var joined_videoId =
                                                                                this.state.attchmnt_videoId_cmb.concat(
                                                                                  res.data.result.files[i]._id,
                                                                                );
                                                                              this.setState({
                                                                                attchmnt_videoId_cmb: joined_videoId,
                                                                              });
                                                                            }
                                                                            const atchId_recrd =
                                                                              res.data.result.files[0]._id;
                                                                            console.log(atchId_recrd);

                                                                            setTimeout(() => {
                                                                              console.log(this.state.atcharryDet_cmb);
                                                                            }, 150);

                                                                            $('#attachLoading_cmb').hide();
                                                                            $('#attachLoading_cmb_video').hide();

                                                                            const video_details = {
                                                                              apikey: API_KEY,
                                                                              secret: API_SECRET,
                                                                              user_id: this.state.user_id,
                                                                              business_id: this.state.business_id,
                                                                              attachment: atchId_recrd,
                                                                              attachment_type: 'video',
                                                                              unique_id:
                                                                                this.state.message_edit == false
                                                                                  ? Rnunique_id_cmb
                                                                                  : this.state.Rnunique_id_cmb
                                                                                  ? this.state.Rnunique_id_cmb
                                                                                  : Rnunique_id_cmb,
                                                                              order:
                                                                                '' +
                                                                                this.state.attachOrderIncrs_cmb +
                                                                                '',
                                                                            };

                                                                            axios
                                                                              .post(
                                                                                API_CALL_ENDPOINT +
                                                                                  '/text/attachment/create',
                                                                                video_details,
                                                                              )
                                                                              .then((res) => {
                                                                                console.log(res);
                                                                                console.log(res.data.message);

                                                                                if (res.data.status == 'success') {
                                                                                  this.setState({
                                                                                    messageAttachd_cmb: true,
                                                                                  });
                                                                                  this.setState({
                                                                                    atcharryDet_cmb: [
                                                                                      {
                                                                                        file_path: file_path,
                                                                                        second_file_path:
                                                                                          second_file_path,
                                                                                        video_id: file_id,
                                                                                        video_url: file_url,
                                                                                        video_url2: file_url2,
                                                                                        video_url3: file_url3,
                                                                                        video_url4: file_url4,
                                                                                        attach_id: res.data.result._id,
                                                                                      },
                                                                                      ...this.state.atcharryDet_cmb,
                                                                                    ],
                                                                                  });
                                                                                  var OrderIncr =
                                                                                    this.state.attachOrderIncrs_cmb +
                                                                                    parseInt(1);
                                                                                  this.setState({
                                                                                    attachOrderIncrs_cmb: OrderIncr,
                                                                                  });
                                                                                }
                                                                                if (res.data.error) {
                                                                                }
                                                                              });

                                                                            console.log(file_url);
                                                                          }
                                                                        });
                                                                    }}
                                                                    setVideo={(blob) => {
                                                                      let blobUrl = URL.createObjectURL(blob);
                                                                      console.log(blobUrl);
                                                                      // this.setState({ captureVideoUrl: blobUrl })
                                                                    }}
                                                                  />
                                                                  {/* </div> */}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={this.videoLclDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell w-70 align-top">
                                                                <span className="attachrecord_icon bg_video crsr_pntr">
                                                                  <i className="fas fa-video" />
                                                                </span>
                                                              </div>
                                                              <div className="d-table-cell align-middle">
                                                                <div className="f_sz16">
                                                                  <span>
                                                                    <i className="fas fa-spinner fa-spin f_sz24" />
                                                                  </span>
                                                                  <span className="ml-2" style={{display: 'none'}}>
                                                                    0.10
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}

                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                                defaultValue={10}
                                                                disabled
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          {/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                            Delete
                                                          </a>
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* timeline item */}
                                                    <div style={{display: 'none'}}>
                                                      <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="form-group row">
                                                            <label className="col-form-label pull-left pl-2 f_sz16">
                                                              No. of Rewards
                                                            </label>
                                                            <div className="pull-left ml-3">
                                                              <input
                                                                type="text"
                                                                className="form-control text-center txtbx_sm"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer">
                                                          <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Save
                                                          </a>
                                                          <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                            Delete
                                                          </a>
                                                          {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {/* END timeline item */}
                                                    {/* <div id="attachLoading_cmb_video">
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="attchLstShow_cmb w-100" style={{display: 'none'}}>
                                            <div className="row position-relative">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="attachment_timelinediv">
                                                  {/* The time line */}
                                                  <div
                                                    // onDragOver={this.dragOver_cmb}
                                                    className="timeline"
                                                    id="timelineprepend">
                                                    <div id="attachLoading_cmb" style={{display: 'none'}}>
                                                      <i
                                                        className={
                                                          this.state.lodingicon_cmb + ' attachtype_timelineicon'
                                                        }
                                                      />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <span>
                                                              <span className="d-inline-block">
                                                                <span>
                                                                  <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                                </span>
                                                                <span className="postop_5up">
                                                                  Your attachment is being uploaded
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="timeline-footer"></div>
                                                      </div>
                                                    </div>

                                                    {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                      return attachment.notes_content1 ? (
                                                        <>
                                                          <div
                                                            className={'editor_cmb_' + attachment.attach_id}
                                                            style={{display: 'none'}}>
                                                            <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                            <div className="timeline-item">
                                                              <div
                                                                className="timeline-body"
                                                                // onClick={() => {
                                                                //   this.oneditnotecmb(attachment);
                                                                // }}
                                                                style={{wordWrap: 'break-word'}}>
                                                                <div className="attachmentbody_div">
                                                                  {/* <ReactQuill                                                                    
                                                                    modules={{
                                                                      toolbar: [
                                                                        ['bold', 'italic', 'underline', 'strike'],
                                                                        [{header: '1'}, {header: '2'}, {font: []}],
                                                                        [
                                                                          {list: 'ordered'},
                                                                          {list: 'bullet'},
                                                                          {indent: '-1'},
                                                                          {indent: '+1'},
                                                                        ],
                                                                        ['link', 'video'],
                                                                        ['clean'],
                                                                      ],

                                                                      clipboard: {
                                                                        // toggle to add extra line breaks when pasting HTML:
                                                                        matchVisual: false,
                                                                      },
                                                                    }}
                                                                    formats={[
                                                                      'header',
                                                                      'font',
                                                                      'size',
                                                                      'bold',
                                                                      'italic',
                                                                      'underline',
                                                                      'strike',
                                                                      'list',
                                                                      'bullet',
                                                                      'indent',
                                                                      'link',
                                                                      'video',
                                                                    ]}
                                                                    value={this.state.notesValue2_cmb}
                                                                    onChange={this.notesLetter2_cmb}
                                                                    maxLength="600"
                                                                  /> */}
                                                                  <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={this.state.notesValue2_cmb}
                                                                    id="mytext"
                                                                    config={editorConfiguration}
                                                                    onReady={(editor) => {
                                                                      // You can store the "editor" and use when it is needed.
                                                                      // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                      const data = editor.getData();
                                                                      // console.log( { event, editor, data } );
                                                                      if (data.length <= 1200) {
                                                                        this.setState({
                                                                          notesValue2_cmb: data,
                                                                          chars_left_notes_cmb:
                                                                            this.state.max_chars_notes_cmb -
                                                                            data.length,
                                                                        });
                                                                      }
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                      // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                      // console.log( 'Focus.', editor );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                              {/* {this.state.cmb_notesedit === true ? (
                                                                <div className=" font-weight-bold flightgrey3   ml-3 text-right text-md-left">
                                                                  {this.state.savingprogress}
                                                                </div>
                                                              ) : null} */}

                                                              {/* <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                                
                                                                Maximum 1200 characters
                                                              </div> */}
                                                              <div className="timeline-footer">
                                                                {/* {this.state.cmb_notesedit === true ? (
                                        <a
                                          className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                          onClick={() => {
                                            this.updateNotes2_cmb();
                                          }}>
                                          Update
                                        </a>
                                      ) : null} */}
                                                                <a
                                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                                  onClick={() => {
                                                                    this.updateNotes2_cmb();
                                                                    // this.expanded_attach(attachment.attach_id);
                                                                  }}>
                                                                  {/* {this.state.savingprogress === ""? "Save": this.state.savingprogress } */}
                                                                  Save
                                                                </a>
                                                                <a
                                                                  className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                  onClick={() =>
                                                                    this.notesAtchDelte_cmb_edit(attachment.attach_id)
                                                                  }>
                                                                  Delete
                                                                </a>

                                                                {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            data-id={index}
                                                            key={index}
                                                            // draggable="true"
                                                            // onDragEnd={this.dragEnd_cmb}
                                                            // onDragStart={this.dragStart_cmb}
                                                            className={
                                                              'notecontent_cmb_show notecontent_cmb_' +
                                                              attachment.attach_id
                                                            }>
                                                            <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                            <div className="timeline-item">
                                                              <div
                                                                className="timeline-body"
                                                                style={{wordWrap: 'break-word'}}
                                                                onClick={() => {
                                                                  this.oneditnotecmb(attachment);
                                                                }}>
                                                                <div className="attachmentbody_div">
                                                                  <Linkify
                                                                    componentDecorator={(
                                                                      decoratedHref,
                                                                      decoratedText,
                                                                      key,
                                                                    ) => (
                                                                      <a target="blank" href={decoratedHref} key={key}>
                                                                        {decoratedText}
                                                                      </a>
                                                                    )}>
                                                                    <div
                                                                      dangerouslySetInnerHTML={{
                                                                        __html: attachment.notes_content,
                                                                      }}
                                                                    />
                                                                    {/* {attachment.notes_content} */}
                                                                  </Linkify>
                                                                </div>
                                                              </div>
                                                              <div className="timeline-footer">
                                                                <a
                                                                  className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                  id={attachment.attach_id}
                                                                  onClick={this.notesAtchDelte_cmb}>
                                                                  Delete
                                                                </a>
                                                                {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                                </a> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : attachment.images ? (
                                                        attachment.images.length > 0 ? (
                                                          <div
                                                            data-id={index}
                                                            key={index}
                                                            // draggable="true"
                                                            // onDragEnd={this.dragEnd_cmb}
                                                            // onDragStart={this.dragStart_cmb}
                                                          >
                                                            <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                            <div className="timeline-item">
                                                              <div className="timeline-body">
                                                                <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                  {/* <span className="font-weight-bold">Documents:</span> */}
                                                                  <br />
                                                                  <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                    {attachment.images.map((documentList, index) => {
                                                                      var fileType = documentList.file_name.split('.');
                                                                      return (
                                                                        <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                          <a
                                                                            onClick={() =>
                                                                              this.document_read(documentList)
                                                                            }>
                                                                            {documentList?.thumbnail_file_url ? (
                                                                              <div className="attachdoc_thumb">
                                                                                <img
                                                                                  src={documentList?.thumbnail_file_url}
                                                                                  border={0}
                                                                                />
                                                                              </div>
                                                                            ) : (
                                                                              <div class="attachdoc_type text-center">
                                                                                <i class="far fa-file-alt flightgrey1"></i>
                                                                              </div>
                                                                            )}

                                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                                              <table
                                                                                width="100%"
                                                                                cellPadding={0}
                                                                                cellSpacing={0}>
                                                                                <tbody>
                                                                                  <tr>
                                                                                    <td>
                                                                                      {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                      {fileType[fileType.length - 1] ===
                                                                                      'pdf' ? (
                                                                                        <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'docx' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'doc' ? (
                                                                                        <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'jpg' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'jpeg' ? (
                                                                                        <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'png' ? (
                                                                                        <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'csv' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'xls' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'xlsx' ? (
                                                                                        <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pptx' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'ppt' ? (
                                                                                        <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                      ) : (
                                                                                        <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                      )}

                                                                                      <span className="f_sz13 mr-2">
                                                                                        {/* {documentList.file_name} */}
                                                                                        {documentList?.file_name
                                                                                          ?.length > 5
                                                                                          ? documentList?.file_name.substring(
                                                                                              0,
                                                                                              5,
                                                                                            ) +
                                                                                            '...' +
                                                                                            this.getExtensionFromUrl(
                                                                                              documentList?.file_name,
                                                                                            )
                                                                                          : documentList?.file_name}
                                                                                      </span>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                      <span className="f_sz12 flightgrey">
                                                                                        {this.bytesToKb(
                                                                                          documentList?.file_size,
                                                                                        )}{' '}
                                                                                        kB
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                </tbody>
                                                                              </table>
                                                                            </div>
                                                                          </a>
                                                                          <div className="attachdoc_delete">
                                                                            <a
                                                                              href="javascript:void(0)"
                                                                              className="crsr_pntr">
                                                                              <i
                                                                                className="far fa-trash-alt f_sz15"
                                                                                onClick={this.DocLclDlete_cmb}
                                                                                id={documentList.file_id}
                                                                                data-id={attachment.attach_id}
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    })}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="timeline-footer">
                                                                <a
                                                                  className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                  id={attachment.attach_id}
                                                                  onClick={(e) =>
                                                                    this.documntAtchDelte_cmb(e, attachment.images)
                                                                  }>
                                                                  Delete
                                                                </a>
                                                                {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                                </a> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : undefined
                                                      ) : attachment.documents ? (
                                                        attachment.documents.length > 0 ? (
                                                          <div
                                                            data-id={index}
                                                            key={index}
                                                            // draggable="true"
                                                            // onDragEnd={this.dragEnd_cmb}
                                                            // onDragStart={this.dragStart_cmb}
                                                          >
                                                            <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                            <div className="timeline-item">
                                                              <div className="timeline-body">
                                                                <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                  {/* <span className="font-weight-bold">Documents:</span> */}
                                                                  <br />
                                                                  <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                    {attachment.documents.map((documentList, index) => {
                                                                      var fileType = documentList.file_name.split('.');
                                                                      return (
                                                                        <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                          <a
                                                                            onClick={() =>
                                                                              this.document_read(documentList)
                                                                            }>
                                                                            {documentList?.thumbnail_file_url ? (
                                                                              <div className="attachdoc_thumb">
                                                                                <img
                                                                                  src={documentList?.thumbnail_file_url}
                                                                                  border={0}
                                                                                />
                                                                              </div>
                                                                            ) : (
                                                                              <div class="attachdoc_type text-center">
                                                                                <i class="far fa-file-alt flightgrey1"></i>
                                                                              </div>
                                                                            )}

                                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                                              <table
                                                                                width="100%"
                                                                                cellPadding={0}
                                                                                cellSpacing={0}>
                                                                                <tbody>
                                                                                  <tr>
                                                                                    <td>
                                                                                      {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                      {fileType[fileType.length - 1] ===
                                                                                      'pdf' ? (
                                                                                        <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'docx' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'doc' ? (
                                                                                        <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'jpg' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'jpeg' ? (
                                                                                        <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'png' ? (
                                                                                        <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'csv' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'xls' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'xlsx' ? (
                                                                                        <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                      ) : fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pptx' ||
                                                                                        fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'ppt' ? (
                                                                                        <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                      ) : (
                                                                                        <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                      )}

                                                                                      <span className="f_sz13 mr-2">
                                                                                        {/* {documentList.file_name} */}
                                                                                        {documentList?.file_name
                                                                                          ?.length > 5
                                                                                          ? documentList?.file_name.substring(
                                                                                              0,
                                                                                              5,
                                                                                            ) +
                                                                                            '...' +
                                                                                            this.getExtensionFromUrl(
                                                                                              documentList?.file_name,
                                                                                            )
                                                                                          : documentList?.file_name}
                                                                                      </span>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                      <span className="f_sz12 flightgrey">
                                                                                        {this.bytesToKb(
                                                                                          documentList?.file_size,
                                                                                        )}{' '}
                                                                                        kB
                                                                                      </span>
                                                                                    </td>
                                                                                  </tr>
                                                                                </tbody>
                                                                              </table>
                                                                            </div>
                                                                          </a>
                                                                          <div className="attachdoc_delete">
                                                                            <a
                                                                              href="javascript:void(0)"
                                                                              className="crsr_pntr">
                                                                              <i
                                                                                className="far fa-trash-alt f_sz15"
                                                                                onClick={this.DocLclDlete_cmb}
                                                                                id={documentList.file_id}
                                                                                data-id={attachment.attach_id}
                                                                              />
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    })}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="timeline-footer">
                                                                <a
                                                                  className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                  id={attachment.attach_id}
                                                                  onClick={(e) =>
                                                                    this.documntAtchDelte_cmb(e, attachment.documents)
                                                                  }>
                                                                  Delete
                                                                </a>
                                                                {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                                </a> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : undefined
                                                      ) : attachment.audio_url ? (
                                                        <div
                                                          data-id={index}
                                                          key={index}
                                                          // draggable="true"
                                                          // onDragEnd={this.dragEnd_cmb}
                                                          // onDragStart={this.dragStart_cmb}
                                                        >
                                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <div className="d-table-row">
                                                                  <div className="d-table-cell align-middle">
                                                                    <span>
                                                                      <span className="d-inline-block">
                                                                        <span className="crsr_pntr">
                                                                          <i
                                                                            className={
                                                                              'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                              attachment.audio_id
                                                                            }
                                                                            onClick={() =>
                                                                              this.audioPly_cmb(attachment)
                                                                            }
                                                                            id={attachment.audio_id}
                                                                          />
                                                                          <i
                                                                            className={
                                                                              'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                              attachment.audio_id
                                                                            }
                                                                            onClick={this.audioPause_cmb}
                                                                            id={attachment.audio_id}
                                                                            style={{display: 'none'}}></i>
                                                                          <span
                                                                            className="ml-2 d-inline-block postop_5up"
                                                                            style={{display: 'none'}}></span>
                                                                          <audio
                                                                            style={{display: 'none'}}
                                                                            src={
                                                                              this.state.play_audio_url
                                                                                ? this.state.play_audio_url
                                                                                : undefined
                                                                            }
                                                                            controls="controls"
                                                                            controlsList="nodownload"
                                                                            // hidden
                                                                            id={'audio_' + attachment.audio_id}
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                id={attachment.attach_id}
                                                                onClick={this.audioAtchDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : attachment.video_url ? (
                                                        <div
                                                          data-id={index}
                                                          key={index}
                                                          // draggable="true"
                                                          // onDragEnd={this.dragEnd_cmb}
                                                          // onDragStart={this.dragStart_cmb}
                                                        >
                                                          <i className="fas fa-video bg_video attachtype_timelineicon"></i>
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                {/* <span className="font-weight-bold">Video:</span> */}
                                                                <br />
                                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                  <a
                                                                    onClick={() =>
                                                                      this.Video_play_url_preview(attachment)
                                                                    }>
                                                                    {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="fas fa-video" />
                                                    </div>
                                                    <div className="attachdoc_name">
                                                      Click here to play video
                                                    </div>
                                                  </div> */}
                                                                    <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                      <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                                        {/* <img src={} border={0} /> */}
                                                                        <i className="fas fa-video fa-3x" />
                                                                      </div>
                                                                      <div className="attachvideo_gif">
                                                                        {/* <img src={} border={0} /> */}
                                                                        Click here to play video
                                                                      </div>
                                                                      {/* <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div> */}
                                                                    </div>
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              {/* <div className="attachmentbody_div d-table">
																					<div className="d-table-row">
																						<div className="d-table-cell align-middle">
																							<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																								<div className="videoWrapper">
																									<video width="300" controls playsinline>
																									<source src={attachment.video_url} type="video/webm" />
																									<source src={attachment.video_url2} type="video/mp4" />
																									<source src={attachment.video_url3} type="video/mov" />
																									<source src={attachment.video_url4} type="video/ogg" />
																									</video>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div> */}
                                                            </div>
                                                            <div className="timeline-footer">
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                id={attachment.attach_id}
                                                                onClick={this.videAtchDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                              </a> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : null;
                                                    })}

                                                    {/* <div>
                                                      <i
                                                        className="fas fa-circle bg-gray"
                                                        style={{fontSize: 'x-small'}}
                                                      />
                                                    </div> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* /.card */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                <div className="viewreceivedmessage_div2" style={{display: 'none'}}>
                  <div className="row position-relative">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_innersmallcontent_div px-0 pt-0">
                        <div className="card w-100 mb-0 shadow-none sendmessage_div">
                          <div className="card-header px-0 pt-0 border-bottom-0">
                            <h3 className="card-title w-100 d-flex flex-row">
                              <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                ABC Incorporation LLC{' '}
                                <span className="businessdtls_clck fmaroon crsr_pntr">
                                  <i className="fas fa-info-circle ml-2 f_sz18" />
                                </span>
                                <div className="dropdown d-inline-block ml-3">
                                  <span data-toggle="dropdown" className="badge badge-bblue f_sz13 crsr_pntr">
                                    <span>
                                      <i
                                        className="far fa-save f_sz18"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Saved"
                                      />
                                    </span>
                                    <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                  </span>
                                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                    <div className="dropdown-item">
                                      <div className="p-0 d-inline-block w-100 f_sz13">
                                        <div className="statusmsgactvty_timeline_div scrollbar_style">
                                          {/* The time line */}
                                          <div className="timeline">
                                            {/* timeline time label */}
                                            <div className="time-label">
                                              <span className="bg-light border">Status</span>
                                            </div>
                                            {/* /.timeline-label */}
                                            {/* timeline item */}
                                            <div>
                                              <i className="far fa-circle" />
                                              <div className="timeline-item">
                                                <h3 className="timeline-header">
                                                  <span className="fmaroon mr-1">Reply has been saved</span> by You
                                                </h3>
                                                <span className="time">
                                                  March 23, 2020<span className="ml-2">10.18am</span>
                                                </span>
                                              </div>
                                            </div>
                                            {/* END timeline item */}
                                            {/* timeline item */}
                                            <div>
                                              <i className="far fa-circle" />
                                              <div className="timeline-item">
                                                <h3 className="timeline-header">
                                                  <span className="fmaroon">Received</span> from Ble Media Solutions
                                                </h3>
                                                <span className="time">
                                                  March 23, 2020<span className="ml-2">4.18am</span>
                                                </span>
                                              </div>
                                            </div>
                                            {/* END timeline item */}
                                            <div>
                                              <i className="far fa-clock bg-light border" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                              <span className="col-1 col-sm-1 col-md-1 px-0">
                                <span className="viewreceivedmessage_close2">
                                  <i className="fas fa-times mt-2" />
                                </span>
                              </span>
                            </h3>
                          </div>
                          <div className="card-body pb-0 px-0 pt-0">
                            <div className="w-100 d-inline-block mt-2 mb-4">
                              This is the Ble Media Solutions. Date has not yet confirmed. Please send photocopy of your
                              residence address to send invitation.
                            </div>
                            <div>
                              <div className="editrespondmessage_btn">
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-maroon_outline btn-lg crsr_pntr f_sz16 savedmessagebtn_clk1">
                                  <span>
                                    <i className="fas fa-pen" />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.card */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Reply Saved*/}
                <div className="saved_respondmessage_div1" style={{display: 'none'}}>
                  <div className="row position-relative">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_innersmallcontent_div px-0 pt-0">
                        <div className="card w-100 mb-0 shadow-none sendmessage_div">
                          <div className="card-header px-0 pt-0 border-bottom-0">
                            <h3 className="card-title w-100 d-flex flex-row">
                              <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                Ble Media Solutions{' '}
                                <span className="businessdtls_clck fmaroon crsr_pntr">
                                  <i className="fas fa-info-circle ml-2 f_sz18" />
                                </span>
                              </span>
                              <span className="col-1 col-sm-1 col-md-1 px-0">
                                <span className="saved_respondmessage_close2">
                                  <i className="fas fa-times mt-2" />
                                </span>
                              </span>
                            </h3>
                          </div>
                          <div className="card-body pb-0 px-0 pt-0">
                            <div className="all_contentcntr_450">
                              <div className="form-horizontal formbiglbl mb-2">
                                <div className="sendmessage_converseview_slim mt-2 mb-2">
                                  <div className="col-12 col-sm-9 col-md-9 col-lg-9">
                                    <div className="sendmsg_conversesend_slim mt-3">
                                      {/*<div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-9">
                  <input type="text" class="form-control txtarea_sm sendmsgslim_txtdiv" placeholder="Reply"/>
                 </div>*/}
                                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="attachtypesslim_div">
                                          <div className="overallattachment_div justify-content-around">
                                            <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                              <div className="overallattach_icon_sm bg_notes">
                                                <i
                                                  className="far fa-sticky-note"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Notes"
                                                />
                                              </div>
                                            </div>
                                            <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                              <div className="overallattach_icon_sm bg_documents">
                                                <i
                                                  className="far fa-file-alt"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Documents"
                                                />
                                              </div>
                                            </div>
                                            <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                              <div className="overallattach_icon_sm bg_audio">
                                                <i
                                                  className="fas fa-microphone-alt"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Audio"
                                                />
                                              </div>
                                            </div>
                                            <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                              <div className="overallattach_icon_sm bg_video">
                                                <i
                                                  className="fas fa-film"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Video"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 position-relative">
                                    <div className="d-flex justify-content-around mt-3 mb-3">
                                      <div className="send_iconimg crsr_pntr mt-2 mr-3">
                                        <i
                                          className="far fa-save fgrey"
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title
                                          data-original-title="Save"
                                        />
                                      </div>
                                      {/*<div class="send_iconimg crsr_pntr mt-2 mr-3"><i class="far fa-calendar-alt fbblue scheduledatetime_tgl" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Schedule"></i></div>*/}
                                      <div
                                        className="send_iconimg crsr_pntr mt-1 mr-2"
                                        data-toggle="modal"
                                        data-target="#modal_confirmsend"
                                        data-backdrop="static">
                                        <img
                                          src="../dist/img/send_icon.png"
                                          border={0}
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title
                                          data-original-title="Send"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="scheduledatetime_tgldiv" style={{display: 'none'}}>
                                  <div className="all_contentcntr_400 scheduledatetime_div">
                                    <div className="form-group mb-4">
                                      <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                        Schedule Date, Time and Time Zone
                                      </label>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text nobrdr_bg font-weight-bold">
                                            <i className="far fa-calendar-alt" />
                                          </span>
                                        </div>
                                        <input type="text" className="form-control text-center" />
                                      </div>
                                      <div className="w-100 d-inline-block mt-3">
                                        <div className="select_style">
                                          <select className="form-control">
                                            <option value="GMT-1200">(GMT-12:00) Eniwetok, Kwajalein</option>
                                            <option value="GMT-1100">(GMT-11:00) Midway Island, Samoa</option>
                                            <option value="GMT-1000">(GMT-10:00) Hawaii</option>
                                            <option value="GMT-0930">(GMT-9:30) Taiohae</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 px-0">
                                      <div className="sendmessage_btnsdiv text-center">
                                        <span className="sendmessage_btnsspan">
                                          <a
                                            href="javascript:void(0)"
                                            className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                            Ok
                                          </a>
                                        </span>
                                        <span className="sendmessage_btnsspan">
                                          <a
                                            href="javascript:void(0)"
                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                            Reset
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* /.card-footer */}
                                </div>
                              </div>
                            </div>
                            <div className="viewrespondmessage_divhgt scrollbar_style">
                              <div className="sendmsg_attachmentdiv_sm">
                                <div>
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="attachment_timelinediv">
                                      {/* The time line */}
                                      <div className="timeline">
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                              <div className="attachmentbody_div">
                                                Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat
                                                felis vehicula class ultricies mollis dictumst, aenean non a in donec
                                                nulla. Phasellus ante pellentesque erat cum risus{' '}
                                                <a href="javascript:void(0)">consequat imperdiet aliquam</a>, integer
                                                placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora
                                                tellus ligula porttitor metus.
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  {/*<div class="d-table-cell w-70 align-top">
                            <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                           </div>*/}
                                                  <div className="d-table-cell align-middle">
                                                    <span style={{display: 'none'}}>
                                                      <span className="d-inline-block">
                                                        <span className="crsr_pntr">
                                                          <i className="fas fa-record-vinyl fa-2x" />
                                                          <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                            Start Recording
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span style={{display: 'none'}}>
                                                      <span className="d-inline-block">
                                                        <span className="crsr_pntr">
                                                          <i className="far fa-stop-circle f_sz30" />
                                                          <span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">
                                                            Stop Recording
                                                          </span>
                                                        </span>
                                                        <span className="f_sz16">
                                                          <span>
                                                            <i className="fas fa-spinner fa-spin f_sz24" />
                                                          </span>
                                                          <span className="ml-2 d-inline-block postop_3up">0:08</span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span style={{display: 'block'}}>
                                                      <span className="d-inline-block">
                                                        <span className="crsr_pntr">
                                                          <i className="far fa-play-circle fa-2x" />
                                                          <span className="ml-2 d-inline-block postop_5up">1:08</span>
                                                        </span>
                                                        {/*<i class="far fa-pause-circle"></i> ----Pls use this for pause----*/}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <textarea
                                                className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                placeholder="Enter the notes"
                                                defaultValue={''}
                                              />
                                              {/*<div class="attachmentbody_div">
                        Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus consequat imperdiet aliquam, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus. 
                        </div>*/}
                                            </div>
                                            <div className="timeline-footer">
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div>
                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                <span className="font-weight-bold">Documents:</span>
                                                <br />
                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file-pdf" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.pdf1</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file-word" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.doc</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file-image" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.jpg</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file-powerpoint" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.ppt</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file-excel" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.xls</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type">
                                                      <i className="far fa-file" />
                                                    </div>
                                                    <div className="attachdoc_name">https://ourprofile.txt</div>
                                                    <div className="attachdoc_delete">
                                                      <a href="javascript:void(0)" className="crsr_pntr">
                                                        <i className="far fa-trash-alt f_sz15" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}}>
                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="form-group row">
                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                  No. of Rewards
                                                </label>
                                                <div className="pull-left ml-3">
                                                  <input
                                                    type="text"
                                                    className="form-control text-center txtbx_sm"
                                                    defaultValue={10}
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}}>
                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="form-group row">
                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                  No. of Rewards
                                                </label>
                                                <div className="pull-left ml-3">
                                                  <input type="text" className="form-control text-center txtbx_sm" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* <div>
                                          <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.card */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.container-fluid */}
              {/*Subscribe*/}
              {/* <div className="modal fade" id="modal_acceptnft">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">NFT</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none">
                          <div className="card-body p-0">
                            <div className="text-center f_sz16 mt-3">
                              Thanks for accepting. Your token is transferred.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="modal fade" id="modal_subscribe">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fmaroon">Subscribe</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-4 text-center">
                            <p className="f_sz16">
                              Thank you for subscribing{' '}
                              <span className="fmaroon font-weight-semibold">
                                {this.state.business_info_det_sub?.business_id?.business_name}
                              </span>
                              , please look forward for messages from us.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*<div class="modal-footer justify-content-center">
     <a class="btn btn-black btnpdng_sm crsr_pntr">Unsubscribe</a>
    </div>*/}
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              {/*Unsubscribe*/}
              <div className="modal fade" id="modal_unsubscribe">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fmaroon">Unsubscribe</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-0 text-center">
                            <p className="f_sz16 mb-0">
                              Are you sure you want to unsubscribe from
                              <br />{' '}
                              <span className="fmaroon font-weight-semibold">
                                {this.state.business_info_det_sub.business_id?.business_name}
                              </span>
                              ?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <a
                        onClick={() => this.message_unsubscribe(this.state.business_info_det_sub)}
                        data-dismiss="modal"
                        className="btn btn-black btnpdng_sm crsr_pntr">
                        Unsubscribe
                      </a>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              <div className="modal fade" id="modal_securityprefer_email">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header pb-0 border-bottom-0">
                      <h5 className="modal-title fmaroon">&nbsp;</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="form-horizontal formbiglbl">
                            <div className="card-body p-0">
                              <div className="shareemail_div">
                                <h4 className="fmaroon text-center f_sz18 mb-3">Add Email Address</h4>
                                <div className="form-group row">
                                  <div className="col-12 col-sm-12 col-md-12 mt-2 px-0">
                                    {this.state.team_emails.map((value, index) => (
                                      <FormGroup
                                        inputChange={this.onChangeFormGroupInput.bind(this, index)}
                                        buttonClick={
                                          index === 0
                                            ? this.onClickButtonAdder
                                            : this.onClickFormGroupButton.bind(this, index)
                                        }
                                        buttonDisabled={
                                          this.state.team_members == 1 ? {display: 'none'} : {display: 'block'}
                                        }
                                        buttonIcon={
                                          index === 0
                                            ? 'fas fa-plus-circle mt-3 ml-3 ml-sm-0 f_sz18 crsr_pntr fmaroon'
                                            : 'fas fa-trash-alt mt-3 f_sz17 crsr_pntr fmaroon'
                                        }
                                        value={value}
                                        key={index}
                                      />
                                    ))}
                                  </div>
                                </div>
                                <div className="d-flex w-100 flex-row flex-wrap text-center">
                                  <div onClick={this.onClickButtonSubmit} className="d-block w-100">
                                    <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr">Send</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              {/*Password Protect*/}
              <div className="modal fade" id="modal_setpswd">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header pb-0 border-bottom-0">
                      <h5 className="modal-title fmaroon">&nbsp;</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="form-horizontal formbiglbl">
                            <div className="card-body p-0">
                              <div className="pswrdprotect_div">
                                <h4 className="fmaroon text-center f_sz18 mb-0">Password Protect</h4>
                                <div className="text-center f_sz13 mb-4">
                                  Only allow people with a password to view this page
                                </div>
                                {errors.map((error) => (
                                  <div id="error" className="alert alert-danger text-center" key={error}>
                                    {error}
                                  </div>
                                ))}
                                {success.map((success) => (
                                  <div id="error" className="alert alert-success text-center" key={success}>
                                    {success}
                                  </div>
                                ))}
                                <div className="form-group mb-4">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="input-group">
                                      <input
                                        type={this.state.passwordtype}
                                        value={this.state.password}
                                        onChange={this.onChangepwd}
                                        className="form-control text-center"
                                        placeholder="Enter your Password"
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i
                                            onClick={this.showHide}
                                            className={
                                              this.state.passwordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'
                                            }
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group mb-4">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="input-group">
                                      <input
                                        type={this.state.confirmpasswordtype}
                                        value={this.state.password1}
                                        onChange={this.onChangepwd1}
                                        className="form-control text-center"
                                        placeholder="Enter your Confirm Password"
                                      />
                                      {/* <input type="password" className="form-control text-center" placeholder="Enter Confirm New Password" /> */}
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          <i
                                            onClick={this.showHide1}
                                            className={
                                              this.state.confirmpasswordtype === 'input'
                                                ? 'fa fa-eye'
                                                : 'fa fa-eye-slash'
                                            }
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex flex-row flex-wrap justify-content-center pt-2 mb-3">
                                  <div className="w-100 text-center mb-3">
                                    <a className="crsr_pntr">
                                      <span
                                        style={
                                          this.state.reset_password === true ? {display: 'block'} : {display: 'none'}
                                        }
                                        onClick={this.reset_password}
                                        className="f_sz15 font-weight-semibold fmaroon">
                                        <i className="far fa-trash-alt mr-2" />
                                        Remove Password
                                      </span>
                                    </a>
                                  </div>
                                  <div>
                                    <button
                                      disabled={this.state.isLoading}
                                      onClick={this.onSubmit}
                                      className="btn btn-black btn-lg btnpdng_sm crsr_pntr mr-2">
                                      {this.state.isLoading ? 'Processing...' : 'Save'}
                                    </button>
                                    {/* <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr mr-2">Save</a> */}
                                  </div>
                                  {/*<div class="d-flex w-100 justify-content-center mt-3 all_link">
                  <a class="crsr_pntr">
                  <span class="f_sz15">Update Password</span>
                  </a>
                  </div>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              {/*Confirm Send*/}
              <div className="modal fade" id="modal_confirmsend">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fmaroon">Confirm Send</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-4 text-center">
                            <p className="f_sz16">
                              Are you sure you want to submit?
                              <br /> This will be final.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <a
                        className="btn btn-black btnpdng_sm crsr_pntr"
                        onClick={this.state.message_edit == true ? this.msg_update_send_cmb : this.msg_send_cmb}
                        style={{pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto'}}>
                        Yes
                      </a>
                      {/* <a className="btn btn-black btnpdng_sm crsr_pntr close" data-dismiss="modal">Not yet</a> */}
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>

              <div className="modal fade" id="modal_acceptnft">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">NFT</h5>
                      <button type="button" className="close alert_closing" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none">
                          <div className="card-body p-0">
                            <div className="text-center f_sz16 mt-3">
                              Thanks for accepting. Your token is transferred.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {this.state.Lightbox_open ? (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'imageModal',
                  }}
                  open={true}
                  onClose={this.closeLightbox}
                  center>
                  <img src={this.state.document_url} />
                </Modal>
              ) : (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'imageModal',
                  }}
                  open={false}
                  onClose={this.closeLightbox}
                  center>
                  <img src={this.state.document_url} />
                </Modal>
              )}
              {this.state.Lightbox_open_video ? (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'videoModal',
                  }}
                  open={true}
                  onClose={this.closeLightbox_video}
                  center>
                  <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                    <source src={this.state.video_play_link} type="video/mp4" />
                  </video>
                </Modal>
              ) : (
                <Modal
                  classNames={{
                    overlay: 'customOverlay',
                    modal: 'videoModal',
                  }}
                  open={false}
                  onClose={this.closeLightbox_video}
                  center>
                  <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                    <source src={this.state.video_play_link} type="video/mp4" />
                  </video>
                </Modal>
              )}
              {/* /.modal */}
              {/*Confirm Delete*/}
              <div className="modal fade" id="modal_confirmdelete">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fmaroon">Confirm Delete</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-4 text-center">
                            <p className="f_sz16">
                              Saved Message will be deleted.
                              <br /> Are you sure you want to delete?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <a className="btn btn-black btnpdng_sm crsr_pntr">Yes</a>
                      <a className="btn btn-black btnpdng_sm crsr_pntr">No</a>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>

              <div className="modal fade" id="modal_tokentransfer">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title fmaroon">Transfer Token</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-0 ft_msg">
                            <div className="f_sz16 text-center">
                              <span className="fgreen4 font-weight-bold f_sz20 tcknamount">5</span> FST Fungible Token
                              received
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>

              {/* <div className="newfeatures_announcement bx_shadow_sm"
                style={{ display: "none" }}>
                <div className="newfeatures_ancmntdiv position-relative">
                  <div className="newfeature_close bx_shadow_sm crsr_pntr" onClick={() => $('.newfeatures_announcement').hide(1000)}>
                    <i className="fas fa-times"/>
                  </div>
                  <div className="d-flex flex-flow flex-wrap">
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                      <h3 className="mt-2">
                        <i className="fas fa-space-shuttle fa-rotate-45 mr-2 fbblue" />
                      </h3>
                    </div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                      <h5 className="ln_hgt3">Send and receive text messages with attachments just like email.</h5>
                    </div>
                  </div>
                  <div className="newfeatures_ancmntlist f_sz16">
                    <ul>
                      <li>
                        <i className="fas fa-award fbblue mr-2 f_sz22" />
                        Redtie is an advanced, text-based solution that lets you send attachments just like you would in an email.
                      </li>
                     </ul>
                  </div>
                  <div className="w-100 d-inline-block text-center">
                    <a href="/" target="_blank" className="btn " style={{
                      // backgroundColor: "#333333",
                      // border: "2px solid #ffffff",
                      // color: "#ffffff",
                      // borderRadius: "40px",
                      // MozBorderRadius: "40px",
                      // WebkitBorderRadius: "40px",
                      // fontWeight: "600",

                    }}>
                      Signup Now
                      <i className="fas fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
            {/* /.content */}
          </div>
          {/* /.content-wrapper */}
          {/* Control Sidebar */}
          {/*Subcribe*/}
          <aside className="control-sidebar3 control-sidebar-light contactdtls_sidebar3 ctdtls_hide bx_shadow_sm">
            <div className="all_innersmallcontent_div">
              <div className="card w-100 mb-0 shadow-none ctdetails_div">
                <div className="card-header px-0 pt-0">
                  <h3 className="card-title">
                    {this.state.business_info_det?.business_name}
                    <span className="ctdtls_close" onClick={this.contact_detail_close}>
                      <i className="fas fa-times" />
                    </span>
                  </h3>
                </div>
                <div className="form-horizontal formbiglbl">
                  <div className="card-body px-0">
                    <div className="form-group mb-4">
                      <div>
                        {this.state.business_info_det?.description
                          ? this.state.business_info_det?.description
                          : `${this.state.business_info_det?.business_name} has not updated their information to be shown`}
                      </div>
                    </div>
                  </div>
                  {this.state.business_info_det_sub?.subscribe === 'yes' ? (
                    <div class="card-footer brdrradius_btm text-center bg-transparent mb-2">
                      <a
                        data-toggle="modal"
                        data-target="#modal_unsubscribe"
                        data-backdrop="static"
                        class="btn btn-black crsr_pntr btnpdng_sm">
                        <i class="far fa-hand-point-down f_sz18"></i> Unsubscribe
                      </a>
                    </div>
                  ) : (
                    <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                      <a
                        onClick={() => this.message_subscribe(this.state.business_info_det_sub)}
                        data-toggle="modal"
                        data-target="#modal_subscribe"
                        data-backdrop="static"
                        className="btn btn-black crsr_pntr btnpdng_sm">
                        <i className="far fa-hand-point-up f_sz18 mr-2" />
                        Subscribe
                      </a>
                    </div>
                  )}

                  {/* /.card-footer */}
                </div>
                {/* /.card */}
              </div>
            </div>
          </aside>
          {/* /.control-sidebar */}
          {/*Unsubscribe*/}
          {/* /.control-sidebar */}
          {/* Main Footer */}
          {/* <footer className="main-footer" style={{display: 'none'}}>
            
            <strong>
              Copyright © 2020{' '}
              <a href className="fmaroon">
                Redtie
              </a>
              .
            </strong>{' '}
            All rights reserved.
          </footer> */}
          {/* <div className="receiver_appdownload">
            Get our app from{' '}
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.redtie">
              <img src={redtie_playstore} />
            </a>
            <a target="_blank" href="https://apps.apple.com/us/app/redtie-me/id1528147948#?platform=iphone ">
              <img src={redtie_appstore} />
            </a>
          </div> */}
        </div>
      </div>
    );
  }
}
function FormGroup(props) {
  return (
    <div className="w-100 d-flex flex-row mb-3">
      <div className="col-10 col-sm-10 col-md-11 px-0">
        <input
          value={props.value}
          onChange={props.inputChange}
          type="email"
          className="form-control"
          placeholder="Enter the email address"
          required
          pattern="^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"
          oninvalid="this.setCustomValidity('Please enter a valid email address (example@example.com)')"
        />
      </div>
      <div className="col-1 col-sm-1 col-md-1">
        <a>
          <i style={props.buttonDisabled} onClick={props.buttonClick} tabIndex="-1" className={props.buttonIcon} />
        </a>
      </div>
    </div>
  );
}
export default Attach_receiver;
