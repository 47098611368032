import React, { useEffect, Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import PropTypes from "prop-types";
import AsyncStorage from "@callstack/async-storage";
import PhoneInput from 'react-phone-input-2'
import PhoneInput1 from 'react-phone-input-2'
import PhoneInputnew from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import 'react-phone-input-2/lib/style.css'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect
} from "react-router-dom";
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import transparent from "../assets/img/transparent.png"
import alert_search from"../assets/img/alert_search.png"
import MP3video from"../assets/img/read.mp4"
import alert_conversation from"../assets/img/alert_conversation.png"
import user7 from '../assets/img/user7-128x128.jpg';
import imgalert_contact from '../assets/img/alert_contact.png';
import MicRecorder from 'mic-recorder-to-mp3';
import Multiselect from './multiselect/Multiselect';
import './multiselect/styles.css';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder'
var moment = require('moment'); 
const Mp3Recorder = new MicRecorder({ bitRate: 128 });


var TagLstDtatable='';
var ArchvHstryDtatble='';
var TemDtatable='';


function randDarkColor() {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

class Profile_settings1 extends Component {

	constructor(props) {
		super(props);
		this.start_fn = this.start_fn.bind(this);
		this.dataLst = this.dataLst.bind(this);
		this.archivehistory_tgl = this.archivehistory_tgl.bind(this);
		this.summaryalert_tgl = this.summaryalert_tgl.bind(this);
		this.autotxtrespnse_tgl = this.autotxtrespnse_tgl.bind(this);
		this.voicemsg_tgl = this.voicemsg_tgl.bind(this);
		this.voice_message = this.voice_message.bind(this);
		this.autovoiceresponse_tgl = this.autovoiceresponse_tgl.bind(this);
		this.autovoiceresp = this.autovoiceresp.bind(this);
		this.bdaygreetings_tgl = this.bdaygreetings_tgl.bind(this);
		this.profemailaddr_edit = this.profemailaddr_edit.bind(this);
		this.webhookshistory_tgl1 = this.webhookshistory_tgl1.bind(this);
		this.webhookshistory_tgl2 = this.webhookshistory_tgl2.bind(this);
		this.addwebhook_clk = this.addwebhook_clk.bind(this);
		this.addwebhook_close = this.addwebhook_close.bind(this);
		this.apicreate_clk = this.apicreate_clk.bind(this);
		this.tagcreate_clk = this.tagcreate_clk.bind(this);
		this.addinttag_close = this.addinttag_close.bind(this);
		this.psphrase_addtagname = this.psphrase_addtagname.bind(this);
		this.psphrase_closeaddtagname = this.psphrase_closeaddtagname.bind(this);
		this.qrcodecreate_clk = this.qrcodecreate_clk.bind(this);
		this.addqrcode_close = this.addqrcode_close.bind(this);
		this.qrcodehistory_tgl1 = this.qrcodehistory_tgl1.bind(this);
		this.qrcodehistory_tgl2 = this.qrcodehistory_tgl2.bind(this);
		this.phrasecreate_clk = this.phrasecreate_clk.bind(this);
		this.addintphrase_close = this.addintphrase_close.bind(this);
		this.psqrcode_addtagname = this.psqrcode_addtagname.bind(this);
		this.psqrcode_closeaddtagname = this.psqrcode_closeaddtagname.bind(this);
		this.gmailconnect_clk = this.gmailconnect_clk.bind(this);
		this.qrcodesendmsgattach_clk = this.qrcodesendmsgattach_clk.bind(this);
		this.archiveMode = this.archiveMode.bind(this);
		this.ViewArchiveOnOff = this.ViewArchiveOnOff.bind(this);
		this.onClickTagTxtboxAdder = this.onClickTagTxtboxAdder.bind(this);
		this.onClickTagTxtboxSubmit = this.onClickTagTxtboxSubmit.bind(this);
		this.getTagname = this.getTagname.bind(this);
		this.EditTagNameClick = this.EditTagNameClick.bind(this);
		this.DelTag = this.DelTag.bind(this);
		this.chkInboundtag = this.chkInboundtag.bind(this);
		this.saveSmryalrt = this.saveSmryalrt.bind(this);
		this.chkAllTem = this.chkAllTem.bind(this);
		this.receiveAlrtChk = this.receiveAlrtChk.bind(this);
		this.reSetSmryalrt = this.reSetSmryalrt.bind(this);
		this.AutoTxtPlsclick = this.AutoTxtPlsclick.bind(this);
		this.AutoTxtSaveclick = this.AutoTxtSaveclick.bind(this);
		this.VoiceGrtnaudio_start = this.VoiceGrtnaudio_start.bind(this);
		this.VoiceGrtnaudio_stop = this.VoiceGrtnaudio_stop.bind(this);
		this.VoiceGrtnaudio_play = this.VoiceGrtnaudio_play.bind(this);
		this.VoiceGrtnaudio_pause = this.VoiceGrtnaudio_pause.bind(this);
		this.SaveVoiceGrrtng = this.SaveVoiceGrrtng.bind(this);
		this.AtoVcRspnsSaveClick = this.AtoVcRspnsSaveClick.bind(this);
		this.SaveHngMsgwithout = this.SaveHngMsgwithout.bind(this);
		this.DelVoiceGrrtng = this.DelVoiceGrrtng.bind(this);
		this.atoVcRspnLevMsgDelClk = this.atoVcRspnLevMsgDelClk.bind(this);
		this.DelHngMsgwithout = this.DelHngMsgwithout.bind(this);
		this.saveBrthgrtng = this.saveBrthgrtng.bind(this);
		this.DeltBrthgrtng = this.DeltBrthgrtng.bind(this);
		this.VoiceTypeClick = this.VoiceTypeClick.bind(this);
		this.generateVoice_play = this.generateVoice_play.bind(this);
		this.generateVoice_pause = this.generateVoice_pause.bind(this);
		this.ResetVoiceGenClk = this.ResetVoiceGenClk.bind(this);
		this.voiceGnrteTextSave = this.voiceGnrteTextSave.bind(this);
		this.voiceGnrteTextDelete = this.voiceGnrteTextDelete.bind(this);
		this.AutoTxtTtlDel = this.AutoTxtTtlDel.bind(this);
		this.UnChkInbndTg = this.UnChkInbndTg.bind(this);
		
		this.state = {
			business_id:'',
			user_id:'',
			user_email:'',
			archiveHstry_detail:[],
			tagList:[],
			tagTxtBox: [""],
			TagMsgClss: '',
			TagMsg:'',
			EditTagName:'',
			EditTagId:'',
			SaveTagBtnDsble:false,
			EditTagBtnDsble:false,
			TeamMbrLst:[],
			SummaryAlerts:[],
			TmRcvdalrt:'',
			smryTeamIds:[],
			chkdTemMbrsIds:[],
			TemIdsMebrArry:[],
			VwArchiewDisbl:true,
			AutoTxtArea: [""],
			AutotxtMxRspnsVlue:'',
			isRecording: false,
			VoiceGrtngblobURL: '',
			isBlocked: false,
			VoiceGrtngRecUrl:'',
			ViceGrtMsg:'',
			ViceGrtcls:'',
			LeavAutoVceMsgVle:'',
			LeavAutoVoiceMxmRspnsVlu:'',
			AtoViceResMsg:'',
			AtoViceRescls:'',
			HangMsgValue:'',
			birthdyTxtValue:'',
			bithGrtngTime:'12:00 AM',
			bithGrtngTimeZone:'GMT-1200',
			brtGrtMsg:'',
			brtGrtcls:'',
			bithGrtngTimeSelt:'',
			bithGrtngTimeZoneSelt:'',
			TxtVoiceGrtngvlue:'',
			TxtTypeVoiceUrl:'',
			VoiceLngRegn:'en-US',
			getVoiceTypeMlrFml:'',
			GrntVoiveLngRegin:[],
			VoceLstBsOnLanguge:[],
			generatePlayDisble:true,
			voiceGnrteResetdisble:true,
			VoiceGrtngRecDeltDisble:true,
			VoiceGrtngTxtTypDeltDisble:true,
			leaveMsgDelDisble:true,
			hangupMsgDelDisble:true,
			brthdyGrtngDelDisble:true,
			VoiceClksId:'',
			AutotxtListUniqId:'',
			AtoTxtClsNm:'',
			AtoTxtMsg:'',
			AtoTxDelClkDisble:true,
			
			
		}

	}


	componentDidMount() {
	
		this.start_fn();
		this.dataLst();
		
		AsyncStorage.getItem("business_id").then(business_id => {
			if (business_id) {
				this.setState({ business_id });
				console.log("business_id")
				console.log(business_id)
			}
		});
		AsyncStorage.getItem("user_id").then(user_id => {
			if (user_id) {
				this.setState({ user_id });
				console.log("User_id")
				console.log(user_id)
			}
		});
		
		AsyncStorage.getItem("user_email").then(user_email => {
		if (user_email) {
			this.setState({ user_email });
			console.log("email code")
			console.log(user_email)
		}
	  });
		
		
	  setTimeout(() => {
			const archive_history = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id:this.state.business_id
				}
			};
										
			axios.get('api/business/archive/history', archive_history )
				.then(res => {
					console.log(res.data)
					if(res.data.result){
						this.setState({archiveHstry_detail:res.data.result});
			ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
								  "paging": true,
								  "lengthChange": false,
								  "ordering": false,
								  "searching": false,
								});
						
						

					}
					
					if(res.data.status == "no_record"){
						
						ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
								  "paging": true,
								  "lengthChange": false,
								  "ordering": false,
								  "searching": false,
								});
					
					}
								
			});
			
		}, 100);
		
		
		setTimeout(() => {
			const tagLst_details = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id:this.state.business_id
				}
			};
			
			console.log(tagLst_details);
			axios.get('api/tags/list', tagLst_details )
				.then(res => {
					console.log(res.data)
					if(res.data.result){
						this.setState({tagList:res.data.result});
					
			TagLstDtatable =$('#inttags_tbl').DataTable({
							  "paging": true,
							  "lengthChange": false,
							  "ordering": false,
							  "searching": false,
							  //"responsive": true,
							});
					
					}
								
			});
			
		}, 100);
		
		
		setTimeout(() => {
			const assigned_details = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id:this.state.business_id
				}
			};
			console.log(assigned_details);	
			axios.get('api/user/business/assigned/list', assigned_details )
			.then(res => {
				console.log(res.data)
				if(res.data.result){
					
					this.setState(() => ({
							TeamMbrLst:res.data.result.assigned_user,
							
					}));
					
					var jnAry = [];
					for (var i in res.data.result.assigned_user){
						jnAry.push(res.data.result.assigned_user[i].user_info[0]._id);
					}
					
					this.setState({TemIdsMebrArry:jnAry});
					
					TemDtatable = $('#profteammember').DataTable({
						"paging": true,
						"lengthChange": false,
						"ordering": false,
						"searching": false,
					});
					
					
					
				}
				
								
			});
		}, 100);
		
		
		
		setTimeout(() => {
			const VoiceLanguage_details = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id
				}
			};
			
			console.log(VoiceLanguage_details);
			axios.get('api/voice/voice_list', VoiceLanguage_details )
				.then(res => {
					console.log(res.data)
					if(res.data.result){
						this.setState({GrntVoiveLngRegin:res.data.result.voice_list});
						
						for(var i in res.data.result.voice_list ){
							if(res.data.result.voice_list[i]._id == "en-US"){
								console.log(res.data.result.voice_list[i].voice_list);
								this.setState({VoceLstBsOnLanguge:res.data.result.voice_list[i].voice_list});
								
							}
						}
					
					}
								
			});
			
		}, 100);
		
		
		setTimeout(() => {
			const AutotxtRes_details = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id:this.state.business_id
				}
			};
			
			console.log(AutotxtRes_details);
			axios.get('api/business/autotext/list', AutotxtRes_details )
				.then(res => {
					console.log(res.data)
					if(res.data.result){
						this.setState({AutoTxtArea:res.data.result})
					}
								
			});
			
		}, 100);
		
		
		setTimeout(() => {
			const business_details = {
				params:{
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id:this.state.business_id
				}
			};
			console.log(business_details);	
			axios.get('api/business/info', business_details )
			.then(res => {
				console.log(res.data)
				if(res.data.result){
					
					this.setState(() => ({
							SummaryAlerts:res.data.result.business.summary_alert,
							smryTeamIds:res.data.result.business.summary_alert.team_id,
							TmRcvdalrt:res.data.result.business.summary_alert.receive_alert
					}));
					
					if(res.data.result.business.summary_alert.status == "on"){
						$(".summaryalert_tgldiv").show();
						$("#summaryalert").prop("checked",true);
					}
					
					if(res.data.result.business.summary_alert.status == "off"){
						$(".summaryalert_tgldiv").hide();
					}
					if(res.data.result.business.view_archive == "on"){
						$("#archivemessages").prop("checked",true);
					}
					
					if(res.data.result.business.auto_text_response.status == "on"){
						$("#autotextresponse").prop("checked",true);
						$(".autotxtrespnse_tgldiv").show();
						this.setState({
							AutotxtMxRspnsVlue:res.data.result.business.auto_text_response.maximum_response,
							AtoTxDelClkDisble:false
						});
						$("#autoTxtrespnsDel_id").attr("class","btn btn-black crsr_pntr m-2 btnpdng_sm");
					
					}
					
					if(res.data.result.business.auto_text_response.status == "off"){
						$("#autotextresponse").prop("checked",false);
					}
					
					if(res.data.result.business.voice_greetings.status == "on"){
						$("#voicemsg").prop("checked",true);
						$(".voicemsg_tgldiv").show();
						if(res.data.result.business.voice_greetings.type == "record"){
							this.setState({
								VoiceGrtngblobURL:res.data.result.business.voice_greetings.greeting_url,
								VoiceGrtngRecDeltDisble:false
							});
							$("#audioPlayVoiceGrtng").show();
							$("#audioStrtVoiceGrtng").hide(); 
							$("#voice_record").prop("checked",true);
							$("div.voicedesc").hide();
							$("#voicemsg1").show();
							
						}
						
						if(res.data.result.business.voice_greetings.type == "text"){
							this.setState({
								TxtTypeVoiceUrl:res.data.result.business.voice_greetings.greeting_url,
								generatePlayDisble:false,
								TxtVoiceGrtngvlue:res.data.result.business.voice_greetings.text,
								VoiceLngRegn:res.data.result.business.voice_greetings.language_region,
								voiceGnrteResetdisble:false,
								VoiceGrtngTxtTypDeltDisble:false,
							});
							
							$("#voice_typetxt").prop("checked",true);
							$("div.voicedesc").hide();
							$("#voicemsg2").show();
							
							for(var i in this.state.GrntVoiveLngRegin ){
								if(this.state.GrntVoiveLngRegin[i]._id == res.data.result.business.voice_greetings.language_region){
									console.log(this.state.GrntVoiveLngRegin[i].voice_list);
									this.setState({VoceLstBsOnLanguge:this.state.GrntVoiveLngRegin[i].voice_list});
								}
							}
							$("."+res.data.result.business.voice_greetings.voice).prop("checked",true);
							this.setState({VoiceClksId:res.data.result.business.voice_greetings.voice})
						}
					}
					
					if(res.data.result.business.voice_greetings.status == "off"){
						$("#voicemsg").prop("checked",false);
					}
					
					if(res.data.result.business.auto_voice_response.status == "on"){
						$("#autovoiceresponse").prop("checked",true);
						$(".autovoiceresponse_tgldiv").show();
						
						if(res.data.result.business.auto_voice_response.type =="leave_message"){
							this.setState({
								LeavAutoVceMsgVle:res.data.result.business.auto_voice_response.text_response,
								LeavAutoVoiceMxmRspnsVlu:res.data.result.business.auto_voice_response.maximum_response,
								leaveMsgDelDisble:false
							});
							$("#autovoicemsg1").show();
							$("#autovoicemsg").prop("checked",true);
						}
						
						if(res.data.result.business.auto_voice_response.type =="hangup_message"){
							this.setState({
								HangMsgValue:res.data.result.business.auto_voice_response.maximum_response,
								hangupMsgDelDisble:false
							});
							$("#autovoicemsg2").show();
							$("#autovoicehangup").prop("checked",true);
						}
					}
					
					if(res.data.result.business.auto_voice_response.status == "off"){
						$("#autovoiceresponse").prop("checked",false);
					}
					
					
					if(res.data.result.business.birthday.status == "on"){
						$("#bdaygreetings").prop("checked",true);
						$(".bdaygreetings_tgldiv").show();
						this.setState({
							birthdyTxtValue:res.data.result.business.birthday.text,
							bithGrtngTimeSelt:res.data.result.business.birthday.local_time,
							bithGrtngTimeZoneSelt:res.data.result.business.birthday.offset,
							brthdyGrtngDelDisble:false
						});
						
					}
					
					if(res.data.result.business.birthday.status == "off"){
						$("#bdaygreetings").prop("checked",false);
					}
					
					var archiveMdStatus = res.data.result.business.archive.text;
						
					if(archiveMdStatus == "on"){
						$('#archivemode').prop('checked',true);
						this.setState({VwArchiewDisbl:false});
					}
					if(archiveMdStatus == "off"){
						$('#archivemode').prop('checked',false);
						this.setState({VwArchiewDisbl:true});
					}
					
					
					
					
					var TmIdDtls = res.data.result.business.summary_alert.team_id;
					for(var j in TmIdDtls){
							$('.smryTmIds'+TmIdDtls[j]).prop('checked', true);
					}
					
					
				}
				
								
			});
		}, 100);
		
		
		
		
		
		

	}
	
	
	start_fn = () => {
		//Initialize Select2 Elements
		$('.select2').select2();
		$(".js-select2").select2({
			placeholder: "Pick states",
			theme: "bootstrap4"
		});
		//Initialize Select2 Elements
		$('.select2bs4').select2({
			theme: 'bootstrap4'
		});


	}
	
	dataLst = () => {
		
		$('#emailprofteammember').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		});
		
		$('#webhookshistory_tbl1').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		});
		
		$('#webhookshistory_tbl2').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		});
		
		$('#apikey_tbl').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		  //"responsive": true,
		});
		
		
		
		$('#qrcodehistory_tbl1').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		  
		});
		
		$('#qrcodehistory_tbl2').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		  
		});
		
		$('#intphrases_tbl').DataTable({
		  "paging": true,
		  "lengthChange": false,
		  "ordering": false,
		  "searching": false,
		  //"responsive": true,
		});
		
	}
	
	archivehistory_tgl = () => {
		$(".archivehistory_tgldiv").slideToggle();
		$(".archivemessages_tgldiv").hide();
	}
	
	summaryalert_tgl = () => {
		if($('#summaryalert').is(":checked")) {
			$(".summaryalert_tgldiv").show();
			
			const smryalrt_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				summary:"on",
				setup:"summary",
				receive_alert: this.state.TmRcvdalrt
						
			};
					
			console.log(smryalrt_Details);		
			axios.post('/api/business/profile/settings', smryalrt_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
					}
							
			});
			
		} else {
			$(".summaryalert_tgldiv").hide();
			
			const smryalrt_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				summary:"off",
				setup:"summary",
				receive_alert: this.state.TmRcvdalrt
						
			};
					
			console.log(smryalrt_Details);		
			axios.post('/api/business/profile/settings', smryalrt_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
					}
							
			});
		}
	}
	
	autotxtrespnse_tgl = () => {
		if($('#autotextresponse').is(":checked")) {
			$(".autotxtrespnse_tgldiv").show();
		} else {
			$(".autotxtrespnse_tgldiv").hide();
		}
	}
	
	
	voicemsg_tgl = () => {
		if($('#voicemsg').is(":checked")) {
			$(".voicemsg_tgldiv").show();
		} else {
			$(".voicemsg_tgldiv").hide();
		}
	}
	
	voice_message = (event) => {
		
		var test = event.target.value;

        $("div.voicedesc").hide();
        $("#voicemsg" + test).show();
	}
	
	
	autovoiceresponse_tgl = () => {
		if($('#autovoiceresponse').is(":checked")) {
			$(".autovoiceresponse_tgldiv").show();
		} else {
			$(".autovoiceresponse_tgldiv").hide();
		}
	}
	
	
	autovoiceresp = (event) => {
		
		var test = event.target.value;

        $("div.autovoicedesc").hide();
        $("#autovoicemsg" + test).show()
	
	}
	
	bdaygreetings_tgl = () => {
		if($('#bdaygreetings').is(":checked")) {
			$(".bdaygreetings_tgldiv").show();
		} else {
			$(".bdaygreetings_tgldiv").hide();
		}
	}
	
	profemailaddr_edit = () => {
		$('.profemailaddr_dsplydiv').hide();
		$('.profemailaddr_upddiv').show();
	}
	
	
	webhookshistory_tgl1 = () => {
		$(".webhookshistory_tgldiv1").slideToggle();
	}
	
	webhookshistory_tgl2 = () => {
		$(".webhookshistory_tgldiv2").slideToggle();
	}
	
	
	addwebhook_clk = () => {
		$('.webhookadd_div').hide();
		$('.webhooklist_div').hide();
		$('.addwebhook_div').show();
	}
	
	addwebhook_close = () => {
		$('.webhooklist_div').show();
		$('.addwebhook_div').hide();
	}
	
	
	apicreate_clk = () => {
		$('.apicreate_div').hide();
		$('.apilist_div').show();
	}
	
	tagcreate_clk = () => {
		
		$("#tagFrm").html("Add Tag");
		$('.tagcreate_div').hide();
		$('.addtag_div').show();
		$('.taglist_div').hide();
	}
	
	addinttag_close = () => {
		
		$('.taglist_div').show();
		$('.addtag_div').hide();
		
		this.setState(() => ({
			EditTagName:'',
			EditTagId:'',
		}));
	}
	
	psphrase_addtagname = () => {
		$('.psphrase_entertagname_drpdwn_div').hide();
		$('.psphrase_entertagname_txtbx_div').show();
	}
	
	psphrase_closeaddtagname = () => {
		$('.psphrase_entertagname_txtbx_div').hide();
		$('.psphrase_entertagname_drpdwn_div').show();
	}
	
	qrcodecreate_clk = () => {
		$('.qrcodecreate_div').hide();
		$('.addqrcode_div').show();
		$('.qrcodelist_div').hide();
	}
	
	addqrcode_close = () => {
		$('.qrcodelist_div').show();
		$('.addqrcode_div').hide();
	}	
	
	qrcodehistory_tgl1 = () => {
		$(".qrcodehistory_tgldiv1").slideToggle();
	}
	
	qrcodehistory_tgl2 = () => {
		$(".qrcodehistory_tgldiv2").slideToggle();
	}
	
	phrasecreate_clk = () => {
		$('.phrasecreate_div').hide();
		$('.addphrase_div').show();
		$('.phraselist_div').hide();
	}
	
	addintphrase_close = () => {
		$('.phraselist_div').show();
		$('.addphrase_div').hide();
	}
	
	psqrcode_addtagname = () => {
		$('.psqrcode_entertagname_drpdwn_div').hide();
		$('.psqrcode_entertagname_txtbx_div').show();
	}
	
	psqrcode_closeaddtagname = () => {
		$('.psqrcode_entertagname_txtbx_div').hide();
		$('.psqrcode_entertagname_drpdwn_div').show();
	}
	
	gmailconnect_clk = () => {
		$(".gmail_notconnected_div").hide();
		$(".gmail_connected_div").show();
	}
	
	
	qrcodesendmsgattach_clk = () => {
		$(".qrcodesendmsg_attachmentdiv").show();
	}
	
	archiveMode = () => {
		
		if($('#archivemode').is(":checked")) {
			this.setState({VwArchiewDisbl:false});
			
			
			const archivemode_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				archive: "on",
				setup:"archive"
						
			};
					
					
			axios.post('/api/business/profile/settings', archivemode_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
						
						ArchvHstryDtatble.destroy();
						const archive_history = {
							params:{
								apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
								secret: "R1eqD2twI3E4",
								user_id: this.state.user_id,
								business_id:this.state.business_id
							}
						};
													
						axios.get('api/business/archive/history', archive_history )
							.then(res => {
								console.log(res.data)
								if(res.data.result){
									this.setState({archiveHstry_detail:res.data.result});
						ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
											  "paging": true,
											  "lengthChange": false,
											  "ordering": false,
											  "searching": false,
											});
											
										var archiveMdStatus = res.data.result[0].archive_status;
										
										if(archiveMdStatus == "on"){
											$('#archivemode').prop('checked',true);
											this.setState({VwArchiewDisbl:false});
										}
										if(archiveMdStatus == "off"){
											$('#archivemode').prop('checked',false);
											this.setState({VwArchiewDisbl:true});
										}
										
								
								}
								
								
											
						});
						
						
						
						
					}
							
			});
			
			
		} else {
			
			
			const archivemode_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				archive: "off",
				setup:"archive"
						
			};
					
					
			axios.post('/api/business/profile/settings', archivemode_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
						
						
						
						ArchvHstryDtatble.destroy();
						const archive_history = {
							params:{
								apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
								secret: "R1eqD2twI3E4",
								user_id: this.state.user_id,
								business_id:this.state.business_id
							}
						};
													
						axios.get('api/business/archive/history', archive_history )
							.then(res => {
								console.log(res.data)
								if(res.data.result){
									this.setState({archiveHstry_detail:res.data.result});
						ArchvHstryDtatble = $('#archivehistory_tbl').DataTable({
											  "paging": true,
											  "lengthChange": false,
											  "ordering": false,
											  "searching": false,
											});
											
									var archiveMdStatus = res.data.result[0].archive_status;
									
									if(archiveMdStatus == "on"){
										$('#archivemode').prop('checked',true);
										this.setState({VwArchiewDisbl:false});
									}
									if(archiveMdStatus == "off"){
										$('#archivemode').prop('checked',false);
										this.setState({VwArchiewDisbl:true});
									}
									
								
								}
								
								
											
						});
						
						
						
					}
							
			});
			
			$('#archivemessages').prop('checked',false);
			
			const archiveView_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				view_archive: "off",
				setup:"view_archive"
						
			};
					
					
			axios.post('/api/business/profile/settings', archiveView_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
					}
							
			});
			
			
		}
	}
	
	
	ViewArchiveOnOff = () => {
		
		if($('#archivemessages').is(":checked")) {
			
			const archiveView_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				view_archive: "on",
				setup:"view_archive"
						
			};
					
			console.log(archiveView_Details);
			
			axios.post('/api/business/profile/settings', archiveView_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
					}
							
			});
			
			
		} else {
			
			const archiveView_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				view_archive: "off",
				setup:"view_archive"
						
			};
					
					
			axios.post('/api/business/profile/settings', archiveView_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						// window.location.reload(true);
					}
							
			});
		}
		
	}
	
	
	onClickTagTxtboxAdder(event) {
		console.log(this.state.tagTxtBox.length);
		this.setState({
		  tagTxtBox: [...this.state.tagTxtBox,""]
		});
	}
	
	
	
	
	onChangeTagInput(index, event) {
		let tagTxtBox = [...this.state.tagTxtBox];
		tagTxtBox[index] = event.target.value;
		this.setState({ tagTxtBox });
	}
	
	
	onClickDelInput(index) {
		let tagTxtBox = [...this.state.tagTxtBox];
		tagTxtBox.splice(index, 1);
		this.setState({ tagTxtBox });
	}
	
	
	onClickTagTxtboxSubmit(event) {
		this.setState({ SaveTagBtnDsble:true });
		const filteredValues = this.state.tagTxtBox.filter(value => value);
		const Tag_names = filteredValues.toString();
		
		$('#tag_saveBtb').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled');
		if(Tag_names == ""){
			
			this.setState(() => ({
					TagMsg:'Please enter tag name',
					TagMsgClss:'alert alert-danger',
					tagTxtBox: [""]
			}));
			
			
			setTimeout(() => {
				this.setState(() => ({
					TagMsg:'',
					TagMsgClss:'',
					tagTxtBox: [""],
					SaveTagBtnDsble:false
				}));
				$('#tag_saveBtb').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');
			}, 2500);
			
			
		} else {
			const createtag = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_name: Tag_names,
				tag_source:"contacts"	
			};
			console.log("createtag",createtag)
			axios.post('/api/tags/create', createtag)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					
					this.setState(() => ({
						TagMsg:res.data.message,
						TagMsgClss:'alert alert-success',
						tagTxtBox: [""]
					}));
					
					TagLstDtatable.destroy();
					
					const tagLst_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
				
					console.log(tagLst_details);
					axios.get('api/tags/list', tagLst_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({tagList:res.data.result});
							
					TagLstDtatable=$('#inttags_tbl').DataTable({
									  "paging": true,
									  "lengthChange": false,
									  "ordering": false,
									  "searching": false,
									  //"responsive": true,
									});
							
							}
										
					});
					
					
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
							tagTxtBox: [""],
							SaveTagBtnDsble:false
						}));
						
						$('.taglist_div').show();
						$('.addtag_div').hide();
						$('#tag_saveBtb').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');
					}, 2500);
				}
				
				
				if (res.data.status === "conflict") {
						
						
						this.setState(() => ({
							TagMsg:res.data.message,
							TagMsgClss:'alert alert-danger',
							tagTxtBox: [""]
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								TagMsg:'',
								TagMsgClss:'',
								tagTxtBox: [""],
								SaveTagBtnDsble:false
							}));
						}, 2500);
						
					}
				
				
				if (res.data.error) {
						
						
						this.setState(() => ({
							TagMsg:res.data.error.message,
							TagMsgClss:'alert alert-danger',
							tagTxtBox: [""]
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								TagMsg:'',
								TagMsgClss:'',
								tagTxtBox: [""],
								SaveTagBtnDsble:false
							}));
							
							$('.taglist_div').show();
							$('.addtag_div').hide();
							$('#tag_saveBtb').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');
						}, 2500);
						
					}
					
			}).catch((error) => {          
				console.log(error)
			});
		}
		
	}
	
	
	getTagname = (event) => {
		
		console.log(event.target.id);
		console.log(event.target.dataset.txt);
		$("#tagFrm").html("Edit Tag");
		
		this.setState({
			EditTagName:event.target.dataset.txt,
			EditTagId:event.target.id,
		});
		
		$('.tagcreate_div').hide();
		$('.addtag_div').show();
		$('.taglist_div').hide();
		
		
	}
	
	
	EditTagOnChng =(event) => {
		console.log(event.target.value);
		
		this.setState({
			EditTagName:event.target.value,
		});
	}
	
	
	EditTagNameClick = () => {
		
		this.setState({ EditTagBtnDsble:true });
		$('#tag_update').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled');	
		
		if(this.state.EditTagName === ""){
			
			this.setState(() => ({
				TagMsg:'Please enter the Tag name',
				TagMsgClss:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					TagMsg:'',
					TagMsgClss:'',
					EditTagBtnDsble:false
				}));
				
				$('#tag_update').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');	
					
			}, 2500);
			
		}else {
			
			const updatetag_details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_name: this.state.EditTagName,
				tag_id:this.state.EditTagId	
			};
			console.log(updatetag_details)
			axios.post('/api/tags/update', updatetag_details)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					
					this.setState(() => ({
						TagMsg:res.data.message,
						TagMsgClss:'alert alert-success',
					}));
					
					TagLstDtatable.destroy();
					
					const tagLst_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
				
					console.log(tagLst_details);
					axios.get('api/tags/list', tagLst_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({tagList:res.data.result});
							
					TagLstDtatable=$('#inttags_tbl').DataTable({
									  "paging": true,
									  "lengthChange": false,
									  "ordering": false,
									  "searching": false,
									  //"responsive": true,
									});
							
							}
										
					});
					
					
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
							EditTagBtnDsble:false,
							EditTagName:'',
							EditTagId:'',
					}));
						
						$('.taglist_div').show();
						$('.addtag_div').hide();
						$('#tag_update').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');	
					}, 2500);
				}
				
				
				if (res.data.error) {
					
					
					this.setState(() => ({
						TagMsg:res.data.error.message,
						TagMsgClss:'alert alert-danger',
					}));
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
							EditTagBtnDsble:false,
							EditTagName:'',
							EditTagId:'',
					}));
						
						$('.taglist_div').show();
						$('.addtag_div').hide();
						$('#tag_update').attr('class','btn btn-black crsr_pntr m-2 btnpdng_sm');	
					}, 2500);
					
				}
					
			}).catch((error) => {          
				console.log(error)
			});
			
		}
		
		
	}
	
	
	
	DelTag = (event) => {
		
		console.log(event.target.id);
		
			const deltag_details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_id:event.target.id
			};
			console.log(deltag_details)
			axios.post('/api/tags/delete', deltag_details)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					
					this.setState(() => ({
						TagMsg:res.data.message,
						TagMsgClss:'alert alert-success',
					}));
					
					TagLstDtatable.destroy();
					
					const tagLst_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
				
					console.log(tagLst_details);
					axios.get('api/tags/list', tagLst_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({tagList:res.data.result});
							
					TagLstDtatable=$('#inttags_tbl').DataTable({
									  "paging": true,
									  "lengthChange": false,
									  "ordering": false,
									  "searching": false,
									  //"responsive": true,
									});
							
							}
										
					});
					
					
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
							EditTagBtnDsble:false
						}));
					}, 2500);
				}
				
				
				if (res.data.error) {
					
					
					this.setState(() => ({
						TagMsg:res.data.error.message,
						TagMsgClss:'alert alert-danger',
					}));
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
							EditTagBtnDsble:false
						}));
					}, 2500);
					
				}
					
			}).catch((error) => {          
				console.log(error)
			});
		
	}
	
	chkInboundtag = (event) => {
		console.log(event.target.id);
		
		$("#"+event.target.id).prop('checked', true);
			
			const inBndSetUs_details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_id: event.target.id,
				inbound_tag:"true"	
			};
			console.log("createtag",inBndSetUs_details)
			axios.post('/api/tags/inbound/update', inBndSetUs_details)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					
					this.setState(() => ({
						TagMsg:res.data.message,
						TagMsgClss:'alert alert-success',
					}));
					TagLstDtatable.destroy()
					const tagLst_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
					
					console.log(tagLst_details);
					axios.get('api/tags/list', tagLst_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({tagList:res.data.result});
							
					TagLstDtatable =$('#inttags_tbl').DataTable({
									  "paging": true,
									  "lengthChange": false,
									  "ordering": false,
									  "searching": false,
									  //"responsive": true,
									});
							
							}
										
					});
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
						}));
						
					}, 2500);
				}
				
				
				
				if (res.data.error) {
						
						
						this.setState(() => ({
							TagMsg:res.data.error.message,
							TagMsgClss:'alert alert-danger',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								TagMsg:'',
								TagMsgClss:'',
							}));
							
						}, 2500);
						
					}
					
				}).catch((error) => {          
				console.log(error)
				});
		
	}
	
	
	UnChkInbndTg = (event) => {
		
		console.log(event.target.id);
		$("#"+event.target.id).prop('checked', false);
		
			const inBndSetUs_details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_id: event.target.id,
				inbound_tag:"false"	
			};
			console.log("createtag",inBndSetUs_details)
			axios.post('/api/tags/inbound/update', inBndSetUs_details)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					
					this.setState(() => ({
						TagMsg:res.data.message,
						TagMsgClss:'alert alert-success',
					}));
					
					
					TagLstDtatable.destroy()
					const tagLst_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
					
					console.log(tagLst_details);
					axios.get('api/tags/list', tagLst_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({tagList:res.data.result});
							
					TagLstDtatable =$('#inttags_tbl').DataTable({
									  "paging": true,
									  "lengthChange": false,
									  "ordering": false,
									  "searching": false,
									  //"responsive": true,
									});
							
							}
										
					});
					
					
					setTimeout(() => {
						this.setState(() => ({
							TagMsg:'',
							TagMsgClss:'',
						}));
						
					}, 2500);
				}
				
				
				
				if (res.data.error) {
						
						
						this.setState(() => ({
							TagMsg:res.data.error.message,
							TagMsgClss:'alert alert-danger',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								TagMsg:'',
								TagMsgClss:'',
							}));
							
						}, 2500);
						
					}
					
				}).catch((error) => {          
				console.log(error)
				});
		
	}
	
	
	
	chkAllTem = () => {
		
	 if($('#allteam').is(":checked")) {
		 
		for (var i in this.state.TemIdsMebrArry){
			$('.smryTmIds'+this.state.TemIdsMebrArry[i]).prop('checked', true);
		}
	 }else {
		 for (var i in this.state.TemIdsMebrArry){
			$('.smryTmIds'+this.state.TemIdsMebrArry[i]).prop('checked', false);
		}
	 }
	 
	}
	
	
	receiveAlrtChk = (event) => {
		console.log(event.target.dataset.id);
		this.setState({TmRcvdalrt:event.target.dataset.id});
	}
	
	
	saveSmryalrt = () => {
		
		console.log(this.state.TemIdsMebrArry);
		var tmArry = [];
		for (var i in this.state.TemIdsMebrArry){
			
			if($('.smryTmIds'+this.state.TemIdsMebrArry[i]).is(":checked")) {
				
				var chdTmids = $('.smryTmIds'+this.state.TemIdsMebrArry[i]).attr('id');
				tmArry.push(chdTmids);
			}
		}
		
		console.log("Checked Team List: "+tmArry);
		var chkTmIdDe = tmArry.filter(value => value);
		var chkTmIdDetails = chkTmIdDe.toString();
		
		
			const smryalrt_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				summary:"on",
				setup:"summary",
				receive_alert: this.state.TmRcvdalrt,
				team_id:chkTmIdDetails
						
			};
					
			console.log(smryalrt_Details);		
			axios.post('/api/business/profile/settings', smryalrt_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						
						this.setState(() => ({
							SmryAlrtMsg:res.data.message,
							SmryAlrtCls:'alert alert-success',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								SmryAlrtMsg:'',
								SmryAlrtCls:'',
							}));
							
						}, 2500);
					}
					
					
					if(res.data.error){
					  
					  this.setState(() => ({
							SmryAlrtMsg:res.data.error.message,
							SmryAlrtCls:'alert alert-danger',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								SmryAlrtMsg:'',
								SmryAlrtCls:'',
							}));
							
						}, 2500);
					  
				  }
				  
							
			});
		
		
	}
	
	
	reSetSmryalrt = () => {
		
		for (var i in this.state.TemIdsMebrArry){
			$('.smryTmIds'+this.state.TemIdsMebrArry[i]).prop('checked', false);
		}
		
		this.setState({TmRcvdalrt:'week'});
		
		const smryalrt_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				summary:"on",
				setup:"summary",
				receive_alert: 'week',
				team_id:''
						
			};
					
			console.log(smryalrt_Details);		
			axios.post('/api/business/profile/settings', smryalrt_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
							
					if (res.data.status == "success"){
						
						this.setState(() => ({
							SmryAlrtMsg:res.data.message,
							SmryAlrtCls:'alert alert-success',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								SmryAlrtMsg:'',
								SmryAlrtCls:'',
							}));
							
						}, 2500);
					}
					
				  if(res.data.error){
					  
					  this.setState(() => ({
							SmryAlrtMsg:res.data.error.message,
							SmryAlrtCls:'alert alert-danger',
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								SmryAlrtMsg:'',
								SmryAlrtCls:'',
							}));
							
						}, 2500);
					  
				  }
							
			});
		
		
	}
	
	
	AutoTxtPlsclick(event) {
		console.log(this.state.AutoTxtArea.length);
		this.setState({
		  AutoTxtArea: [...this.state.AutoTxtArea,""]
		});
	}
	
	AutoTxtDelonClick(index,_id) {
		let AutoTxtArea = [...this.state.AutoTxtArea];
		AutoTxtArea.splice(index, 1);
		this.setState({ AutoTxtArea });
		
		if(_id){
			var autotxtDel_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				autotext_id:_id,
								
			}
					
			console.log(autotxtDel_Details);		
			axios.post('/api/business/autotext/delete', autotxtDel_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
									
				if (res.data.status == "success"){
								
					this.setState(() => ({
						AtoTxtMsg:res.data.message,
						AtoTxtClsNm:'alert alert-success',
						
					}));
					
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
						
									
					}, 2500);
				}
							
				if(res.data.error){
							  
					this.setState(() => ({
						AtoTxtMsg:res.data.error.message,
						AtoTxtClsNm:'alert alert-danger',
					}));
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
									
					}, 2500);
							  
				}
									
			}); 
		
		}
	}
	
	AutoTxtOnChng(index,_id, event) {
		
		
		if(_id){ 
			console.log(_id);
			let AutoTxtArea = [...this.state.AutoTxtArea];
			AutoTxtArea[index] = {"text_response":event.target.value,"_id":_id};
			this.setState({ AutoTxtArea });
		}else{
			let AutoTxtArea = [...this.state.AutoTxtArea];
			console.log(AutoTxtArea);
		
			AutoTxtArea[index] = event.target.value;
			this.setState({ AutoTxtArea });
		}
	}
	
	
	AutoTxtMaxRspnsOnChg = (event) => {
		console.log(event.target.value);
		var value = event.target.value.replace(/^0+/, '');
		this.setState({ AutotxtMxRspnsVlue:value });
	}
	
	
	AutoTxtSaveclick(event) {
		var regex = /^[0-9]+$/;
		var ValidNum = regex.test(this.state.AutotxtMxRspnsVlue);
		
		if(this.state.AutoTxtArea[0] == ""){
					
					this.setState(() => ({
						AtoTxtMsg:'1st field is require',
						AtoTxtClsNm:'alert alert-danger',
					}));
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
									
					}, 2500);
					
		}
		else if(ValidNum){
			console.log(this.state.AutoTxtArea);
			const filteredValues = this.state.AutoTxtArea.filter(value => value);
			//alert(filteredValues);
			var AryTxtRespn = [];
			var i;
			for(i=0; i< this.state.AutoTxtArea.length; i++){
				var obj = {};
				if(this.state.AutoTxtArea[i]._id){
					
					obj["id"]=this.state.AutoTxtArea[i]._id;
					obj["text_response"]=this.state.AutoTxtArea[i].text_response;
				}else if(this.state.AutoTxtArea[i] != ""){
					obj["id"]=this.state.AutotxtListUniqId;
					obj["text_response"]=this.state.AutoTxtArea[i];
				}
				AryTxtRespn.push(obj);
			}
			
			var AryTxtRespnToObj = JSON.stringify(AryTxtRespn)
			console.log(typeof(AryTxtRespn));
			console.log(AryTxtRespn);
			
			console.log(AryTxtRespnToObj);
			
			
			var autoTxtsav_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				setup:"auto_text",
				auto_text:"on",
				maximum_response:this.state.AutotxtMxRspnsVlue,
				text_response_array: AryTxtRespnToObj,
								
			}
					
			console.log(autoTxtsav_Details);		
			axios.post('/api/business/profile/settings', autoTxtsav_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
									
				if (res.data.status == "success"){
								
					this.setState(() => ({
						AtoTxtMsg:res.data.message,
						AtoTxtClsNm:'alert alert-success',
						AtoTxDelClkDisble:false
					}));
					
					const AutotxtRes_details = {
						params:{
							apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
							secret: "R1eqD2twI3E4",
							user_id: this.state.user_id,
							business_id:this.state.business_id
						}
					};
					
					console.log(AutotxtRes_details);
					axios.get('api/business/autotext/list', AutotxtRes_details )
						.then(res => {
							console.log(res.data)
							if(res.data.result){
								this.setState({AutoTxtArea:res.data.result})
							}
										
					});
					
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
						
									
					}, 2500);
				}
							
				if(res.data.error){
							  
					this.setState(() => ({
						AtoTxtMsg:res.data.error.message,
						AtoTxtClsNm:'alert alert-danger',
					}));
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
									
					}, 2500);
							  
				}
									
			}); 
			
			
		}else{
			this.setState(() => ({
				AtoTxtMsg:'Max No. of Response allow only numbers',
				AtoTxtClsNm:'alert alert-danger',
			}));
						
			setTimeout(() => {
				this.setState(() => ({
					AtoTxtMsg:'',
					AtoTxtClsNm:'',
				}));
							
			}, 2500);
			
		}
		
		
	}
	
	
	AutoTxtTtlDel = () => {
		
		var TextareLngth =this.state.AutoTxtArea.length;
		var MinusTextareLngth = TextareLngth-parseInt(1);
		var i;
		for( i=0; i<TextareLngth; i++){
			
		
			var autotxtDel_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				autotext_id:this.state.AutoTxtArea[i]._id,
								
			}
					
			console.log(autotxtDel_Details);		
			axios.post('/api/business/autotext/delete', autotxtDel_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
									
				if (res.data.status == "success"){
								
					this.setState(() => ({
						AtoTxtMsg:res.data.message,
						AtoTxtClsNm:'alert alert-success',
						AutoTxtArea:[""],
						AutotxtMxRspnsVlue:'',
						AtoTxDelClkDisble:true
						
					}));
					
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
						
									
					}, 2500);
				}
							
				if(res.data.error){
							  
					this.setState(() => ({
						AtoTxtMsg:res.data.error.message,
						AtoTxtClsNm:'alert alert-danger',
					}));
					setTimeout(() => {
						this.setState(() => ({
							AtoTxtMsg:'',
							AtoTxtClsNm:'',
						}));
									
					}, 2500);
							  
				}
									
			}); 
			
			
			var autoTxtOff_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				setup:"auto_text",
				auto_text:"off",
								
			}
			
			console.log(autoTxtOff_Details);		
			axios.post('/api/business/profile/settings', autoTxtOff_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
									
				if (res.data.status == "success"){
				
				}
								
			}); 
			
		}
		
	}
	
	
	VoiceGrtnaudio_start = () => {
		$("#audioStrtVoiceGrtng").hide();
		$("#audioStopVoiceGrtng").show();

		navigator.mediaDevices.getUserMedia({ audio: true },
			() => {
				console.log('Permission Granted');
				this.setState({ isBlocked: false });
			},
			() => {
				console.log('Permission Denied');
				this.setState({ isBlocked: true })
			},
		);

		if (this.state.isBlocked) {
			console.log('Permission Denied');
		} else {
			
			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ isRecording: true });
				}).catch((e) => console.error(e));
		}
	}
	
	
	
	VoiceGrtnaudio_stop = () => {
		$("#audioStopVoiceGrtng").hide();
		$("#audioPlayVoiceGrtng").show();
		
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				const VoiceGrtngblobURL = URL.createObjectURL(blob)
				this.setState({ VoiceGrtngblobURL, isRecording: false });
				
				
				var filename = new Date().toISOString();


				const data = new FormData();

				data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
				data.append('secret', 'R1eqD2twI3E4');
				data.append('user_id', this.state.user_id);
				data.append('business_id', this.state.business_id);
				data.append('file', blob, filename);
				data.append('reference', 'text');



				axios.post('/api/file/upload', data)
					.then(res => {
						console.log(res);
						console.log(res.data.message);
						console.log(res.data.result.files);


						if (res.data.status == "success") {

							var	file_url = res.data.result.files[0].file_url;
							var	file_id =res.data.result.files[0]._id;
							
							console.log(file_url);
						
							this.setState({VoiceGrtngRecUrl:file_url});
						}

					});
				
				
	
			}).catch((e) => console.log(e));
	}
	
	
	VoiceGrtnaudio_play = () => {
		$("#audioPlayVoiceGrtng").hide();
		$("#audioPauseVoiceGrtng").show();
		var playx = document.getElementById("voiceGretngRcrd"); 
		playx.play();
		playx.onended = function() {
			$('#audioPauseVoiceGrtng').hide();
			$('#audioPlayVoiceGrtng').show();
		}
	}
	
	VoiceGrtnaudio_pause = (e) => {
		var pausex = document.getElementById("voiceGretngRcrd");  
		pausex.pause();
		$('#audioPauseVoiceGrtng').hide();
		$('#audioPlayVoiceGrtng').show();
		
	}
	
	
	
	SaveVoiceGrrtng = () => {
		
		console.log("Voice Greeting Save");
		
		if(this.state.VoiceGrtngRecUrl != ""){
			$("#VoiceGrrtngRecSv_id").html("Saving...");
			$("#VoiceGrrtngRecSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled");
				var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					voice_greetings_status:"on",
					setup:"voice_greetings",
					voice_greetings_type: 'record',
					greeting_url:this.state.VoiceGrtngRecUrl
							
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								ViceGrtMsg:res.data.message,
								ViceGrtcls:'alert alert-success',
								VoiceGrtngRecDeltDisble:false,
								TxtVoiceGrtngvlue:'',
								voiceGnrteResetdisble:true,
								VoiceLngRegn:'en-US',
								TxtTypeVoiceUrl:'',
								generatePlayDisble:true
							}));
							
							
							for(var i in this.state.GrntVoiveLngRegin ){
								if(this.state.GrntVoiveLngRegin[i]._id == "en-US"){
									console.log(this.state.GrntVoiveLngRegin[i].voice_list);
									this.setState({VoceLstBsOnLanguge:this.state.GrntVoiveLngRegin[i].voice_list});
								}
							}
							
							setTimeout(() => {
								this.setState(() => ({
									ViceGrtMsg:'',
									ViceGrtcls:'',
								}));
							$("#VoiceGrrtngRecSv_id").html("Save");
							$("#VoiceGrrtngRecSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								ViceGrtMsg:res.data.error.message,
								ViceGrtcls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									ViceGrtMsg:'',
									ViceGrtcls:'',
								}));
							$("#VoiceGrrtngRecSv_id").html("Save");
							$("#VoiceGrrtngRecSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
							
								
							}, 2500);
						  
					  }
								
				});
			
			
		}  else {
			
			this.setState(() => ({
				ViceGrtMsg:'Please record after save',
				ViceGrtcls:'alert alert-danger',
			}));
							
			setTimeout(() => {
				this.setState(() => ({
					ViceGrtMsg:'',
					ViceGrtcls:'',
				}));
				
			}, 2500);
			
		}
		

	}
	
	
	DelVoiceGrrtng = () => {
		
			var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					voice_greetings_status:"off",
					setup:"voice_greetings",
					voice_greetings_type: 'record',
							
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								ViceGrtMsg:'Voice Greetings Delete Successfully',
								ViceGrtcls:'alert alert-success',
								VoiceGrtngRecDeltDisble:true,
							}));
							$("#audioStrtVoiceGrtng").show();
							$("#audioPlayVoiceGrtng").hide();
							setTimeout(() => {
								this.setState(() => ({
									ViceGrtMsg:'',
									ViceGrtcls:'',
								}));
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								ViceGrtMsg:res.data.error.message,
								ViceGrtcls:'alert alert-danger',
							}));
							$("#audioStrtVoiceGrtng").show();
							$("#audioPlayVoiceGrtng").hide();
							setTimeout(() => {
								this.setState(() => ({
									ViceGrtMsg:'',
									ViceGrtcls:'',
								}));
								
							}, 2500);
						  
					  }
								
				});
		
	}
	
	
	
	TxtVoiceGrtngOnChg = (event) => {
		console.log(event.target.value);
		this.setState({TxtVoiceGrtngvlue:event.target.value});
	}
	
	LngRgnOnChg = (event) => {
		console.log(event.target.value);
		this.setState({
			VoiceLngRegn:event.target.value,
			TxtTypeVoiceUrl:'',
			generatePlayDisble:true
		});
		
		if(this.state.getVoiceTypeMlrFml != ""){
			$("."+this.state.getVoiceTypeMlrFml).prop("checked",false);
		}
		
		for(var i in this.state.GrntVoiveLngRegin ){
			if(this.state.GrntVoiveLngRegin[i]._id == event.target.value){
				console.log(this.state.GrntVoiveLngRegin[i].voice_list);
				this.setState({VoceLstBsOnLanguge:this.state.GrntVoiveLngRegin[i].voice_list});
			}
		}
	}
	
	
	VoiceTypeClick = (event) => {
		
		
		console.log(event.target.value);
		console.log(event.target.id);
		this.setState({VoiceClksId:event.target.id});
		
		if(this.state.TxtVoiceGrtngvlue == ""){
			
			$("#"+event.target.id).prop("checked",false);
			this.setState(() => ({
				ViceGrtMsg:'Type text is required',
				ViceGrtcls:'alert alert-danger',
			}));
							
			setTimeout(() => {
				this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
				}));
								
			}, 2500);
			
		} else {
			
			$("#voiceGenerate_loding").show();
			$("#id_generateVoiceplay").hide();
			this.setState({
				getVoiceTypeMlrFml:event.target.value,
				voiceGnrteResetdisble:false
			});
				var voiceType_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					language:this.state.VoiceLngRegn,
					voice:event.target.value,
					voice_message:this.state.TxtVoiceGrtngvlue
							
				}
				
				console.log(voiceType_Details);		
				axios.post('/api/voice/generate', voiceType_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							$("#voiceGenerate_loding").hide();
							$("#id_generateVoiceplay").show();
							console.log(res.data.result.file)
							this.setState({
								TxtTypeVoiceUrl:res.data.result.file,
								generatePlayDisble:false
							});
						}
						
					  		
				});
		}
	}
	
	
	generateVoice_play = () => {
		$("#id_generateVoiceplay").hide();
		$("#id_generateVoicepause").show();
		var playx = document.getElementById("generateVoiceUrl"); 
		playx.play();
		playx.onended = function() {
			$('#id_generateVoicepause').hide();
			$('#id_generateVoiceplay').show();
		}
	}
	
	generateVoice_pause = (e) => {
		var pausex = document.getElementById("generateVoiceUrl");
		pausex.pause();
		$('#id_generateVoicepause').hide();
		$('#id_generateVoiceplay').show();
		
	}
	
	
	ResetVoiceGenClk = () => {
		
		this.setState({
			TxtVoiceGrtngvlue:'',
			voiceGnrteResetdisble:true,
			VoiceLngRegn:'en-US'
		});
		
		$("."+this.state.VoiceClksId).prop("checked",false);
		
		for(var i in this.state.GrntVoiveLngRegin ){
			if(this.state.GrntVoiveLngRegin[i]._id == "en-US"){
				console.log(this.state.GrntVoiveLngRegin[i].voice_list);
				this.setState({VoceLstBsOnLanguge:this.state.GrntVoiveLngRegin[i].voice_list});
			}
		}
		
		var VoiceGrtng_Details = {
			apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
			secret: "R1eqD2twI3E4",
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			voice_greetings_status:"off",
			setup:"voice_greetings",
			voice_greetings_type: 'record',
							
		}
				
		console.log(VoiceGrtng_Details);		
		axios.post('/api/business/profile/settings', VoiceGrtng_Details )
			.then(res => {
				console.log(res);
				console.log(res.data.message);
								
			if (res.data.status == "success"){
							
				this.setState(() => ({
					ViceGrtMsg:'Voice Greetings Reset Successfully',
					ViceGrtcls:'alert alert-success',
				}));
				setTimeout(() => {
					this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
					}));
					
				$("#TxtVoiceGrtngvlue_id").val();
				
								
				}, 2500);
			}
						
			if(res.data.error){
						  
				this.setState(() => ({
					ViceGrtMsg:res.data.error.message,
					ViceGrtcls:'alert alert-danger',
				}));
				setTimeout(() => {
					this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
					}));
								
				}, 2500);
						  
			}
								
		});
	
	}
	
	
	voiceGnrteTextSave = () => {
		
		if(this.state.TxtVoiceGrtngvlue == ""){
			
			this.setState(() => ({
				ViceGrtMsg:'Type text is required',
				ViceGrtcls:'alert alert-danger',
			}));
							
			setTimeout(() => {
				this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
				}));
								
			}, 2500);
			
		} else if(this.state.TxtTypeVoiceUrl == "") {
		
			this.setState(() => ({
				ViceGrtMsg:'Please Choose Voice Type',
				ViceGrtcls:'alert alert-danger',
			}));
							
			setTimeout(() => {
				this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
				}));
								
			}, 2500);
		
		}else {
			$("#voiceGnrteTextSv_id").html("Saving...");
			$("#voiceGnrteTextSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled");
			var VoiceGrtng_Details = {
				apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
				secret: "R1eqD2twI3E4",
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				setup:"voice_greetings",
				voice_greetings_status:"on",
				voice_greetings_type: "text",
				language_region:this.state.VoiceLngRegn,
				voice:this.state.getVoiceTypeMlrFml,
				voice_greetings_text:this.state.TxtVoiceGrtngvlue,
				greeting_url:this.state.TxtTypeVoiceUrl
								
			}
					
			console.log(VoiceGrtng_Details);		
			axios.post('/api/business/profile/settings', VoiceGrtng_Details )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
									
					if (res.data.status == "success"){
								
						this.setState(() => ({
							ViceGrtMsg:res.data.message,
							ViceGrtcls:'alert alert-success',
							VoiceGrtngTxtTypDeltDisble:false
						}));
						
						setTimeout(() => {
							this.setState(() => ({
								ViceGrtMsg:'',
								ViceGrtcls:'',
							}));
						$("#voiceGnrteTextSv_id").html("Save");
						$("#voiceGnrteTextSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
						$("#audioPlayVoiceGrtng").hide();
						$("#audioStrtVoiceGrtng").show();
						
						}, 2500);
					}
							
					if(res.data.error){
							  
						this.setState(() => ({
							ViceGrtMsg:res.data.error.message,
							ViceGrtcls:'alert alert-danger',
						}));
									
						setTimeout(() => {
							this.setState(() => ({
								ViceGrtMsg:'',
								ViceGrtcls:'',
							}));
						$("#voiceGnrteTextSv_id").html("Save");
						$("#voiceGnrteTextSv_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
						
										
						}, 2500);
							  
					}
									
			});
			
		}
	
	}
	
	
	voiceGnrteTextDelete = () => {
		
		this.setState({
			TxtVoiceGrtngvlue:'',
			voiceGnrteResetdisble:true,
			VoiceLngRegn:'en-US'
		});
		
		$("."+this.state.VoiceClksId).prop("checked",false);
		
		for(var i in this.state.GrntVoiveLngRegin ){
			if(this.state.GrntVoiveLngRegin[i]._id == "en-US"){
				console.log(this.state.GrntVoiveLngRegin[i].voice_list);
				this.setState({VoceLstBsOnLanguge:this.state.GrntVoiveLngRegin[i].voice_list});
			}
		}
		
		var VoiceGrtng_Details = {
			apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
			secret: "R1eqD2twI3E4",
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			voice_greetings_status:"off",
			setup:"voice_greetings",
			voice_greetings_type: 'record',
							
		}
				
		console.log(VoiceGrtng_Details);		
		axios.post('/api/business/profile/settings', VoiceGrtng_Details )
			.then(res => {
				console.log(res);
				console.log(res.data.message);
								
			if (res.data.status == "success"){
							
				this.setState(() => ({
					ViceGrtMsg:'Voice Greetings Delete Successfully',
					ViceGrtcls:'alert alert-success',
					VoiceGrtngTxtTypDeltDisble:true
				}));
				
				setTimeout(() => {
					this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
					}));
								
				}, 2500);
			}
						
			if(res.data.error){
						  
				this.setState(() => ({
						ViceGrtMsg:res.data.error.message,
						ViceGrtcls:'alert alert-danger',
				}));
				setTimeout(() => {
					this.setState(() => ({
						ViceGrtMsg:'',
						ViceGrtcls:'',
					}));
								
				}, 2500);
						  
			}
								
		});
	
	}
	
	
	AtoVoicLeaveMSgonChng = (event) => {
		console.log(event.target.value);
		this.setState({LeavAutoVceMsgVle:event.target.value})
		
	}
	
	
	AtoVoicLeaveMSgMxmRspnsOnChang = (event) => {
		console.log(event.target.value);
		var value = event.target.value.replace(/^0+/, '');
		this.setState({LeavAutoVoiceMxmRspnsVlu:value});
		
	}
	
	
	AtoVcRspnsSaveClick = () => {
		
		var regex = /^[0-9]+$/;
		var ValidNum = regex.test(this.state.LeavAutoVoiceMxmRspnsVlu);
		
		if(this.state.LeavAutoVceMsgVle == ""){
			
			this.setState(() => ({
				AtoViceResMsg:'Please enter the leave message',
				AtoViceRescls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					AtoViceResMsg:'',
					AtoViceRescls:'',
				}));
								
			}, 2500);
			
			
			
		}else if(this.state.LeavAutoVoiceMxmRspnsVlu == ""){
			
			this.setState(() => ({
				AtoViceResMsg:'Please Enter Max No.of Response',
				AtoViceRescls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					AtoViceResMsg:'',
					AtoViceRescls:'',
				}));
								
			}, 2500);
			
		}else if(!ValidNum){
			
			this.setState(() => ({
				AtoViceResMsg:'Max No. of Response allow only number',
				AtoViceRescls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					AtoViceResMsg:'',
					AtoViceRescls:'',
				}));
								
			}, 2500);
			
		}else{
			
			$("#AtoVcRspnsSave_id").html("Saving...");
			$("#AtoVcRspnsSave_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled");
			
			
				var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					auto_voice:"on",
					setup:"auto_voice",
					auto_voice_type: 'leave_message',
					text_response:this.state.LeavAutoVceMsgVle,
					maximum_response:this.state.LeavAutoVoiceMxmRspnsVlu
							
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								AtoViceResMsg:res.data.message,
								AtoViceRescls:'alert alert-success',
								leaveMsgDelDisble:false,
								HangMsgValue:''
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
							$("#AtoVcRspnsSave_id").html("Save");
							$("#AtoVcRspnsSave_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								AtoViceResMsg:res.data.error.message,
								AtoViceRescls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
							$("#AtoVcRspnsSave_id").html("Save");
							$("#AtoVcRspnsSave_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
							}, 2500);
						  
					  }
								
				});
			
			
		}
		
	}
	
	
	atoVcRspnLevMsgDelClk = () => {
		
				var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					auto_voice:"off",
					setup:"auto_voice",
					auto_voice_type: 'leave_message'		
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								AtoViceResMsg:res.data.message,
								AtoViceRescls:'alert alert-success',
								leaveMsgDelDisble:true,
								LeavAutoVceMsgVle:''
								
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
									LeavAutoVceMsgVle:'',
									LeavAutoVoiceMxmRspnsVlu:'',
									
									
								}));
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								AtoViceResMsg:res.data.error.message,
								AtoViceRescls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
								
							}, 2500);
						  
					  }
								
				});
	}
	
	hangmsgOnChang = (event) =>{
		console.log(event.target.value);
		var value = event.target.value.replace(/^0+/, '');
		this.setState({HangMsgValue : value});
	}
	
	
	SaveHngMsgwithout = () =>{
		
		
		var regex = /^[0-9]+$/;
		var ValidNum = regex.test(this.state.HangMsgValue);
		
		if(this.state.HangMsgValue == ""){
			
			this.setState(() => ({
				AtoViceResMsg:'Please enter the leave message',
				AtoViceRescls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					AtoViceResMsg:'',
					AtoViceRescls:'',
				}));
								
			}, 2500);
			
			
			
		}else if(!ValidNum){
			
			this.setState(() => ({
				AtoViceResMsg:'Max No. of Response allow only number',
				AtoViceRescls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					AtoViceResMsg:'',
					AtoViceRescls:'',
				}));
								
			}, 2500);
			
		}else{
			
			$("#SaveHngMsgwithout_id").html("Saving...");
			$("#SaveHngMsgwithout_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled");
			
				var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					auto_voice:"on",
					setup:"auto_voice",
					auto_voice_type: 'hangup_message',
					maximum_response:this.state.HangMsgValue
							
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								AtoViceResMsg:res.data.message,
								AtoViceRescls:'alert alert-success',
								hangupMsgDelDisble:false,
								LeavAutoVceMsgVle:'',
								LeavAutoVoiceMxmRspnsVlu:''
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
							$("#SaveHngMsgwithout_id").html("Save");
							$("#SaveHngMsgwithout_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								AtoViceResMsg:res.data.error.message,
								AtoViceRescls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
							$("#SaveHngMsgwithout_id").html("Save");
							$("#SaveHngMsgwithout_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
								
							}, 2500);
						  
					  }
								
				});
			
			
		}
		
	}
	
	DelHngMsgwithout = () => {
		
				var VoiceGrtng_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					auto_voice:"off",
					setup:"auto_voice",
					auto_voice_type: 'hangup_message',
					
							
				}
				
				console.log(VoiceGrtng_Details);		
				axios.post('/api/business/profile/settings', VoiceGrtng_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								AtoViceResMsg:res.data.message,
								AtoViceRescls:'alert alert-success',
								HangMsgValue:'',
								hangupMsgDelDisble:true,
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								AtoViceResMsg:res.data.error.message,
								AtoViceRescls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									AtoViceResMsg:'',
									AtoViceRescls:'',
								}));
								
							}, 2500);
						  
					  }
								
				});
		
	}
	
	
	birthdyTxtOnChg = (event) => {
		console.log(event.target.value);
		this.setState({birthdyTxtValue:event.target.value});
	}
	
	bithGrtngTimeOnchng = (event) => {
		console.log(event.target.value);
		this.setState({bithGrtngTime:event.target.value});
	}
	
	bithGrtngTimeZoneOnchng = (event) => {
		console.log(event.target.value);
		this.setState({bithGrtngTimeZone:event.target.value});
	}
	
	saveBrthgrtng = () => {
		
		if(this.state.birthdyTxtValue == ""){
			
			this.setState(() => ({
				brtGrtMsg:'Please enter the Birthday Text',
				brtGrtcls:'alert alert-danger',
			}));
			
			setTimeout(() => {
				this.setState(() => ({
					brtGrtMsg:'',
					brtGrtcls:'',
				}));
								
			}, 2500);
			
			
			
		}else{
			
			$("#saveBrthgrtng_id").html("Saving...");
			$("#saveBrthgrtng_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2 isdisabled");
			
				var brthday_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					birthday_status:"on",
					setup:"birthday",
					birthday_time_utc:this.state.bithGrtngTime,
					birthday_time_local:this.state.bithGrtngTime,
					birthday_offset:this.state.bithGrtngTimeZone,
					birthday_text:this.state.birthdyTxtValue
							
				}
				
				console.log(brthday_Details);		
				axios.post('/api/business/profile/settings', brthday_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								brtGrtMsg:res.data.message,
								brtGrtcls:'alert alert-success',
								brthdyGrtngDelDisble:false
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									brtGrtMsg:'',
									brtGrtcls:'',
								}));
							$("#saveBrthgrtng_id").html("Save");
							$("#saveBrthgrtng_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								brtGrtMsg:res.data.error.message,
								brtGrtcls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									brtGrtMsg:'',
									brtGrtcls:'',
								}));
								
							$("#saveBrthgrtng_id").html("Save");
							$("#saveBrthgrtng_id").attr("class","btn btn-black crsr_pntr btnpdng_sm mr-2");
			
								
							}, 2500);
						  
					  }
								
				});
			
			
		}
	}
	
	DeltBrthgrtng = () => {
		
		
				var brthday_Details = {
					apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
					secret: "R1eqD2twI3E4",
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					birthday_status:"off",
					setup:"birthday",
							
				}
				
				console.log(brthday_Details);		
				axios.post('/api/business/profile/settings', brthday_Details )
					.then(res => {
						console.log(res);
						console.log(res.data.message);
								
						if (res.data.status == "success"){
							
							this.setState(() => ({
								brtGrtMsg:'Birthday greetings successfully deleted',
								brtGrtcls:'alert alert-success',
								birthdyTxtValue:'',
								bithGrtngTime:'12:00 AM',
								bithGrtngTimeZone:'GMT-1200',
								brthdyGrtngDelDisble:true
								
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									brtGrtMsg:'',
									brtGrtcls:'',
								}));
								
							}, 2500);
						}
						
					  if(res.data.error){
						  
						  this.setState(() => ({
								brtGrtMsg:res.data.error.message,
								brtGrtcls:'alert alert-danger',
							}));
							
							setTimeout(() => {
								this.setState(() => ({
									brtGrtMsg:'',
									brtGrtcls:'',
								}));
								
							}, 2500);
						  
					  }
								
				});
		
	}
	
	
	
	
	
	render() {
		
		var moment = require('moment');
		
		const isTagFormGroupDeletionAllowed =
        this.state.tagTxtBox.length > 1 ? true : false;
		
		return (
		
<div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
  <div className="wrapper">
    {/* Navbar */}
    <Header_component />
    {/* /.navbar */}
    {/* Content Wrapper. Contains page content */}
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="header_pagetitle d-block d-sm-block d-md-none">
                <div className="text-center pt-2">
                  <div className="allheader_profile_drpdwn crsr_pntr">
                    <h4 className="d-inline-block"><span className="fmaroon">Ble Media Solutions</span></h4> 
                    <div className="dropdown d-inline-block">
                      <div data-toggle="dropdown"><i className="fas fa-angle-down postop_2 f_sz20" /></div>
                      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div className="dropdown-item">
                          <div className="allheader_profile_drplist text-center">
                            <div className="allheader_profile_drpitem"><a href="#">EphronSystems Pvt. Ltd</a></div>
                            <div className="dropdown-divider" />
                            <div className="allheader_profile_drpitem"><a href="#">EphronTech LLC.</a></div>
                          </div>
                        </div>   
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <div className="content">
        <div className="container-fluid">
          <div className="row mt-3 position-relative">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
              <div className="card flex-lg-row acctstngs_profstngs_card profilesettings_card shadow-none">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs" id="custom-tabs-profilestngs-tab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="custom-tabs-general-tab" data-toggle="pill" href="#custom-tabs-general" role="tab" aria-controls="custom-tabs-general" aria-selected="false"><span className="d-block d-md-inline-block mr-0 mr-md-2"><i className="fas fa-sliders-h" /></span><span className="d-none d-md-inline-block">General</span></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="custom-tabs-autoresponse-tab" data-toggle="pill" href="#custom-tabs-autoresponse" role="tab" aria-controls="custom-tabs-autoresponse" aria-selected="false"><span className="d-block d-md-inline-block mr-0 mr-md-2"><i className="fas fa-reply-all" /></span><span className="d-none d-md-inline-block">Auto Responses</span></a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="custom-tabs-integrations-tab" data-toggle="pill" href="#custom-tabs-integrations" role="tab" aria-controls="custom-tabs-integrations" aria-selected="true"><span className="d-block d-md-inline-block mr-0 mr-md-2"><i className="fas fa-draw-polygon" /></span><span className="d-none d-md-inline-block">Integrations</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                  <div className="card-body px-0 py-3 py-lg-0">
                    <div className="tab-content" id="custom-tabs-profilestngs-tabContent">
                      <div className="tab-pane fade active show" id="custom-tabs-general" role="tabpanel" aria-labelledby="custom-tabs-general-tab">
                        <div className="card w-100 shadow-none integrations_card"> 
                          <div className="card-header px-2 py-2 border-bottom-0">
                            {/* START TABS DIV */}
                            <div className="tabbable-responsive">
                              <div className="tabbable">
                                <ul className="nav nav-tabs border-bottom" id="custom-tabs-profgeneralsettings-tab" role="tablist">
                                  <li className="nav-item">
                                    <a className="nav-link active" id="custom-tabs-psarchive-tab" data-toggle="pill" href="#custom-tabs-psarchive" role="tab" aria-controls="custom-tabs-psarchive" aria-selected="false"><span className="d-inline-block">Archive Mode</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-pstags-tab" data-toggle="pill" href="#custom-tabs-pstags" role="tab" aria-controls="custom-tabs-pstags" aria-selected="false"><span className="d-inline-block">Tags</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-pssummaryalert-tab" data-toggle="pill" href="#custom-tabs-pssummaryalert" role="tab" aria-controls="custom-tabs-pssummaryalert" aria-selected="true"><span className="d-inline-block">Summary Alert</span></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="card-body px-0">  
                            <div className="tab-content" id="custom-tabs-profgeneralsettings-tabContent">
                              <div className="tab-pane fade active show" id="custom-tabs-psarchive" role="tabpanel" aria-labelledby="custom-tabs-psarchive-tab">
                                <div className="all_contentflex_div">   
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700"> 
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div>
                                            <div className="form-group row mt-3 mb-4">
                                              <label className="col-12 col-sm-12 col-md-5 col-lg-5 col-form-label">Archive Mode</label>
                                              <div className="col-12 col-sm-12 col-md-7 col-lg-7 pt-1">
                                                <div className="float-left switchbtn_wdth">
                                                  <div className="custom-control custom-switch">
                                                    <input type="checkbox" className="custom-control-input" id="archivemode" onClick={this.archiveMode} />
                                                    <label className="custom-control-label" htmlFor="archivemode" />
                                                  </div>
                                                </div> 
                                                <div className="d-inline-block mt-1"><span className="fmaroon f_sz13 archivehistory_tgl crsr_pntr" onClick={this.archivehistory_tgl}>View History</span></div>
                                              </div>
                                            </div>
                                            <div className="archivehistory_tgldiv" style={{display: 'none'}}>
                                              <div className="all_contentcntr_700"> 
                                                <div className="all_contentflex_div">
                                                  <div className="card w-100 mt-2 mb-5">
                                                    <div className="card-header">
                                                      <h5 className="fmaroon f_sz15">History</h5>
                                                    </div>
                                                    <div className="card-body p-0">
                                                      <div className="table-responsive">
                                                        <table className="table table-hover tbl_valignmdle tbl_archivehistory border-bottom" id="archivehistory_tbl" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                          <thead>
                                                            <tr>
																<th>Date</th>
																<th>Time</th>
																<th>Status</th>
                                                            </tr></thead>
                                                          <tbody>
														  {this.state.archiveHstry_detail.map((archiveHstry, index) => {
															  var stsMod =archiveHstry.archive_status.toUpperCase();
															return(
															<tr>
                                                              <td>{(moment(archiveHstry.updated_date).format('MM/DD/YYYY'))}</td>
                                                              <td>{(moment(archiveHstry.updated_date).format('HH:mm:ss'))}</td>
															  <td>
															  {archiveHstry.archive_status == "on" ?
															  <span className="font-weight-bold fgreen4">{stsMod}</span>:
															  <span className="font-weight-bold fred">{stsMod}</span>
															  }
															  </td>
                                                            </tr>
															)
														  })}
                                                            
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> 
                                          <div>
                                            <div className="form-group row mt-3 mb-4">
                                              <label className="col-12 col-sm-12 col-md-5 col-lg-5 col-form-label">View Archived</label>
                                              <div className="col-12 col-sm-12 col-md-7 col-lg-7 pt-1">
                                                <div className="float-left switchbtn_wdth">
                                                  <div className="custom-control custom-switch">
                                                    <input type="checkbox" className="custom-control-input" id="archivemessages" onClick={this.ViewArchiveOnOff} disabled={this.state.VwArchiewDisbl}/>
                                                    <label className="custom-control-label" htmlFor="archivemessages" />
                                                  </div>
                                                </div> 
                                              </div>
                                            </div>
                                          </div>
                                        </div> 
                                      </div>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-pstags" role="tabpanel" aria-labelledby="custom-tabs-pstags-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
								  { this.state.tagList.length === 0 ?
									<div className="tagcreate_div">
                                      <div className="card w-100 shadow-none"> 
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">Tags</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <a onClick={this.tagcreate_clk}><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm tagcreate_clk" onClick={this.tagcreate_clk}>Create Tag</span></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>:null
									}
									
									{ this.state.tagList.length > 0 ?
                                    <div className="taglist_div">
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2">Tags</h5>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                            <a className="btn btn-black crsr_pntr btn-sm mt-1 tagcreate_clk" onClick={this.tagcreate_clk}>Add New</a>
                                          </div>
                                        </div>
                                        <div className="card-body p-0">
										  <div className={this.state.TagMsgClss}>{this.state.TagMsg}</div>
                                          <div className="table-responsive">
                                            <table className="table table-hover tbl_valignmdle tbl_inttags border-bottom" id="inttags_tbl" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                              <thead>
                                                <tr><th>Tags</th>
                                                  <th className="text-center">Set as Inbound</th>
                                                  <th className="text-center">Action</th>
                                                </tr></thead>
                                              <tbody>
											  {this.state.tagList.map((tglst_details, index) => {
												  return(
													
													<tr>
													  <td>{tglst_details.tag_name}</td>
													  <td align="center">
														<div className="icheck_lbl">
														  <div className="icheck-darkgrey">
															<input type="radio" name="inboundtag" className="unChkBox" id={tglst_details._id} onClick={tglst_details.inbound_tag === "true" ? this.UnChkInbndTg: this.chkInboundtag} checked={tglst_details.inbound_tag === "true" ? true:false}/>
															<label htmlFor={tglst_details._id}></label>
														  </div>
														</div> 
													  </td>
													  <td align="center">
														<div className="tbl_actionbtns">
														  <a className="tagcreate_clk crsr_pntr" ><i className="fas fa-pen" title="Edit" id={tglst_details._id} onClick={this.getTagname}  data-txt={tglst_details.tag_name}/></a>
														  <a><i className="far fa-trash-alt" title="Delete" id={tglst_details._id} onClick={this.DelTag}/></a>
														</div>
													  </td>
													</tr>
												  
												  )
											  })}
                                                
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>:null
									}
                                    <div className="addtag_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                          <h5>
                                            <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2" id="tagFrm">Add Tag</span>
                                            <span className="addinttag_close mr-2 mt-1"><i className="fas fa-times" onClick={this.addinttag_close}/></span>
                                          </h5>
                                        </div>
										
                                        <div className="all_contentcntr_700">
                                          <div className="form-horizontal formbiglbl">
                                            <div className="card-body">
												<div className={this.state.TagMsgClss}>{this.state.TagMsg}</div>
                                              {/*Element to be Looped*/}
											  {this.state.EditTagId !="" ?
											  
											  <div className="form-group row mt-3 mb-4">
													 <div className="col-12 col-sm-12 col-md-12 col-lg-12">
														   <input type="text" className="form-control" placeholder="Enter the New Tag" onChange={this.EditTagOnChng} value={this.state.EditTagName}/>
													 </div>
													 
											 </div>:
											  
											  <>
                                              {this.state.tagTxtBox.map((value, index) => (
												  <TagTxtbxFormGroup
													TaginputChange={this.onChangeTagInput.bind(this, index)}
													TagDelonClick={this.onClickDelInput.bind(this, index)}
													TagPlsclick={this.onClickTagTxtboxAdder.bind(this, index)}
													TagbuttonDisabled={index === 0 ? !isTagFormGroupDeletionAllowed : undefined}
													value={value}
													key={index}
													ttllngth={this.state.tagTxtBox.length}
													rw={index}
												  />
												  
											  ))}
											  </>
											  
											  }
											  
                                              {/*Element to be Looped*/}
                                            </div>
                                          </div>
                                          <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
										  {this.state.EditTagId !="" ?
											<a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.EditTagNameClick} disable={this.state.EditTagBtnDsble} id="tag_update">Update</a>:
											<TagTxtbxAddButton TagSaveclick={this.onClickTagTxtboxSubmit} />
                                          }
										  </div>
                                        </div> 
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-pssummaryalert" role="tabpanel" aria-labelledby="custom-tabs-pssummaryalert-tab">
                                <div className="all_contentflex_div">   
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700">   
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div className="form-group row mt-4 mb-4">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Summary Alert</label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-7 pt-1">
                                              <div>
                                                <div className="custom-control custom-switch">
                                                  <input type="checkbox" className="custom-control-input summaryalert_tgl" id="summaryalert" onClick={this.summaryalert_tgl}/>
                                                  <label className="custom-control-label" htmlFor="summaryalert" />
                                                </div>
                                              </div> 
                                            </div>
                                          </div>
                                          <div className="summaryalert_tgldiv" style={{display: 'none'}}>
                                            <div className="card w-100 mt-2 mb-5">
                                              <div className="card-body py-0">
												<div className={this.state.SmryAlrtCls}>{this.state.SmryAlrtMsg}</div>
                                                <div className="form-group row mt-2 mb-2">
                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label pdngtop_10"><span className="f_sz15">Receive Alert</span></label>
                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                                    <div className="all_contentflex_div icheck_lbl">
                                                      <div className="col-6 col-sm-4 col-md-4 col-lg-4 pl-0">
                                                        <div className="icheck-darkgrey d-inline-block py-2">
                                                          <input type="radio" name="sumryalert" id="sumrydaily" checked={this.state.TmRcvdalrt === "day" ? true:false } onClick={this.receiveAlrtChk} data-id="day"/>
                                                          <label htmlFor="sumrydaily">Daily</label>
                                                        </div>
                                                      </div>
                                                      <div className="col-6 col-sm-4 col-md-4 col-lg-4">   
                                                        <div className="icheck-darkgrey d-inline-block py-2">
                                                          <input type="radio" name="sumryalert" id="sumryweekly" checked={this.state.TmRcvdalrt === "week" ? true:false } onClick={this.receiveAlrtChk} data-id="week"/>
                                                          <label htmlFor="sumryweekly">Weekly</label>
                                                        </div>
                                                      </div>
                                                      <div className="col-6 col-sm-4 col-md-4 col-lg-4 pl-0 pl-sm-2">   
                                                        <div className="icheck-darkgrey d-inline-block py-2">
                                                          <input type="radio" name="sumryalert" id="sumrymonthly" checked={this.state.TmRcvdalrt === "month" ? true:false } onClick={this.receiveAlrtChk} data-id="month" />
                                                          <label htmlFor="sumrymonthly">Monthly</label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>      
                                                </div>
                                                <div className="form-group row mt-2 mb-2">
                                                  <label className="col-12 col-sm-12 col-md-12 col-lg-12 col-form-label"><span className="f_sz15">Team</span></label>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="table-responsive">
                                                      <table className="table table-hover tbl_valignmdle tbl_teammember border-bottom" id="profteammember" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                        <thead>
                                                          <tr><th className="text-center">
                                                              <div className="icheck-darkgrey d-inline-block">
                                                                <input type="checkbox" id="allteam" onClick={this.chkAllTem}/>
                                                                <label htmlFor="allteam">&nbsp;</label>
                                                              </div>
                                                            </th>
                                                            <th>Photo</th>
                                                            <th>Email Address</th>
                                                          </tr></thead>
                                                        <tbody>
														{this.state.TeamMbrLst.map( (teamList,index) => {
															
															var frst_ltr = teamList.user_info[0].email_address.charAt(0);
															var frstLtrCaps =frst_ltr.toUpperCase();
															var randomClr = randDarkColor();

															
															return(
															
																<tr>
																	<td align="center">
																	  <div className="icheck-darkgrey d-inline-block">
																		<input type="checkbox" id={teamList.user_info[0]._id} className={"smryTmIds"+teamList.user_info[0]._id}  />
																		<label htmlFor={teamList.user_info[0]._id}>&nbsp;</label>
																	  </div>
																	</td>
																	{teamList.user_info[0].profile_image !="" ?
																	<td><span className="usercircle_md bg_teammember1"><img src={teamList.user_info[0].profile_image} border="0" /></span></td>:
																	<td><span className="usercircle_md bg_teammember1" style={{backgroundColor: randomClr }}>{frstLtrCaps}</span></td>
																	}
																	<td>{teamList.user_info[0].email_address}</td>
																</tr>
															
															);
														 })}
                                                          
                                                        </tbody>
                                                      </table>
                                                    </div>    
                                                  </div>      
                                                </div>
                                              </div>
                                              <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                                <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.saveSmryalrt}>Save</a>
                                                <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.reSetSmryalrt}>Reset</a>
                                              </div>
                                            </div>
                                          </div>
                                        </div> 
                                      </div>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>  
                        </div>
                      </div>
                      <div className="tab-pane fade" id="custom-tabs-autoresponse" role="tabpanel" aria-labelledby="custom-tabs-autoresponse-tab">
                        {/*Auto-Responses*/}
                        <div className="all_contentflex_div">   
                          <div className="card w-100 bx_shadow_sm"> 
                            <div className="all_contentcntr_700">   
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body">
								<div className={this.state.AtoTxtClsNm}>{this.state.AtoTxtMsg}</div>
                                  <div>
                                    <div className="form-group row mt-3 mb-5">
                                      <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Auto Text Response</label>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-7 pt-1">
                                        <div>
                                          <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input autotxtrespnse_tgl" id="autotextresponse" onClick={this.autotxtrespnse_tgl}/>
                                            <label className="custom-control-label" htmlFor="autotextresponse" />
                                          </div>
                                        </div> 
                                      </div>
                                    </div>
                                    <div className="autotxtrespnse_tgldiv" style={{display: 'none'}}>
                                      <div className="card w-100 mt-2 mb-5">
                                        <div className="card-body py-0">
										
										{this.state.AutoTxtArea.map((value, index) => (
											
										  <AutoTxtRespnsFrmGrp
											AutoTxtOnChngFrm={this.AutoTxtOnChng.bind(this, index,value._id)}
											AutoTxtPlsFrm={this.AutoTxtPlsclick.bind(this, index)}
											AutoTxtDelFrm={this.AutoTxtDelonClick.bind(this, index,value._id)}
											value={value.text_response ? value.text_response:value}
											key={index}
											arealabel ={index}
											ttl_lgth={this.state.AutoTxtArea.length}
										  />
										
										 ))}
										 
                                          <div className="form-group row mt-4 mb-2">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Max No. of Response</span></label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                              <input type="text" className="form-control text-center txtbx_sm" onChange={this.AutoTxtMaxRspnsOnChg} value={this.state.AutotxtMxRspnsVlue} maxlength="2"/> 
                                            </div>      
                                          </div> 
                                          <div className="form-group w-100 mt-4 mb-2 text-center">
                                            <AutoRspnsSaveButton AutoSvePrps={this.AutoTxtSaveclick} />
											{this.state.AtoTxDelClkDisble ?
												<a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled"  id="autoTxtrespnsDel_id">Delete</a>:
												<a className="btn btn-black crsr_pntr m-2 btnpdng_sm " onClick={this.AutoTxtTtlDel} id="autoTxtrespnsDel_id">Delete</a>
											}
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-group row mt-3 mb-4">
                                      <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Voice Greetings</label>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                        <div>
                                          <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input voicemsg_tgl" id="voicemsg" onClick={this.voicemsg_tgl}/>
                                            <label className="custom-control-label" htmlFor="voicemsg" />
                                          </div>
                                        </div>  
                                      </div>
                                    </div>
                                    <div className="voicemsg_tgldiv" style={{display: 'none'}}>
                                      <div className="card w-100 mt-3">
                                        <div className="card-body py-0">
                                          <div className="form-group mt-2 mb-2">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                                              <div className="all_contentflex_div icheck_lbl">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="voice_message" id="voice_record" defaultValue={1} onClick={this.voice_message}/>
                                                    <label htmlFor="voice_record">Record</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">   
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="voice_message" id="voice_typetxt" defaultValue={2} onClick={this.voice_message} />
                                                    <label htmlFor="voice_typetxt">Type a text</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>      
                                          </div>
                                          <div className="voicedesc" id="voicemsg1" style={{display: 'none'}}>
                                            <div className="all_contentflex_div">
                                              <div className="card w-100 mt-2 mb-4 shadow-none">
                                                <div className="card-header">
                                                  <h5 className="fmaroon f_sz15">Record</h5>
                                                </div>
                                                <div className="card-body">
												  <div className={this.state.ViceGrtcls}>{this.state.ViceGrtMsg}</div>
                                                  <div className="d-table w-100">
                                                    <div className="d-table-row">
                                                      <div className="d-table-cell w-70 align-top">
                                                        <span className="attachrecord_icon bg_audio crsr_pntr"><i className="fas fa-microphone-alt" /></span>
                                                      </div>
                                                      <div className="d-table-cell align-middle">
                                                        <span style={{display: 'none'}}>
                                                          <span className="d-inline-block">
                                                            <span className="crsr_pntr"><i className="fas fa-record-vinyl fa-2x" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Start Recording</span></span>
                                                          </span>
                                                        </span>
                                                        <span style={{display: 'none'}}>
                                                          <span className="d-inline-block">
                                                            <span className="crsr_pntr"><i className="far fa-stop-circle f_sz30" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Stop Recording</span></span>
                                                            <span className="f_sz16">
                                                              <span><i className="fas fa-spinner fa-spin f_sz24" /></span>
                                                              <span className="ml-2 d-inline-block postop_3up">0.08</span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span style={{display: 'block'}}>
                                                          <span className="d-inline-block">
                                                            <span className="crsr_pntr">
																<i className="far fa-play-circle fa-2x" style={{display: 'none'}} onClick={this.VoiceGrtnaudio_play } id="audioPlayVoiceGrtng"/>
																<i className="far fa-stop-circle f_sz30" onClick={this.VoiceGrtnaudio_stop} id="audioStopVoiceGrtng" style={{display: 'none'}} />
																<i className="fas fa-record-vinyl fa-2x" onClick={this.VoiceGrtnaudio_start} id="audioStrtVoiceGrtng"/>
																<i className="fas fa-pause f_sz24"  onClick={this.VoiceGrtnaudio_pause}  id="audioPauseVoiceGrtng" style={{display: 'none'}} />
																<audio hidden src={this.state.VoiceGrtngblobURL} controls="controls" id="voiceGretngRcrd" />
																{/*<span className="ml-2 d-inline-block postop_5up">1:08</span>*/}
															</span>
                                                            {/*<i class="far fa-pause-circle"></i> ----Pls use this for pause----*/}
                                                          </span>
                                                        </span>  
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="card-footer bg-transparent text-center ">
                                                  <a className="btn btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.SaveVoiceGrrtng} id="VoiceGrrtngRecSv_id">Save</a>
												  {this.state.VoiceGrtngRecDeltDisble ?
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled">Delete</a> :
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.DelVoiceGrrtng}>Delete</a>
												  }
												</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="voicedesc" id="voicemsg2" style={{display: 'none'}}>
                                            <div className="all_contentflex_div">
                                              <div className="card w-100 mt-2 mb-4 shadow-none">
                                                <div className="card-header">
                                                  <h5 className="fmaroon f_sz15">Type a Text</h5>
                                                </div>
                                                <div className="card-body">
												  
												  <div className={this.state.ViceGrtcls}>{this.state.ViceGrtMsg}</div>
                                                  <div className="form-group row mt-2 mb-2">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                      <textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter Text Message" value={this.state.TxtVoiceGrtngvlue}  onChange={this.TxtVoiceGrtngOnChg} id="TxtVoiceGrtngvlue_id"/>     
                                                    </div>      
                                                  </div>
                                                  <div className="form-group row mt-4 mb-2">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Language and Region</span></label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="select_style">
                                                        <select className="form-control" onChange={this.LngRgnOnChg}>
															{/*<option>--Select--</option>*/}
																{ this.state.GrntVoiveLngRegin.map((languageV, index) => {
																	return(
																		<option value={languageV.language_info[0].language_code} selected={this.state.VoiceLngRegn == languageV.language_info[0].language_code ? true:false}>{languageV.language_info[0].language}</option>
																	);
																})}
																	
															
															
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-4 mb-2">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Voice</span></label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8 px-0">
                                                      <div className="all_contentflex_div icheck_lbl">
														{ this.state.VoceLstBsOnLanguge.map((voice, index) => {
															return(
															<div className="col-6 col-sm-6 col-md-6 col-lg-6">
															  <div className="icheck-darkgrey d-inline-block py-2">
																<input type="radio" name="vcelang" id={voice.voice} value={voice.voice} onClick={this.VoiceTypeClick} className={voice.voice} />
																<label htmlFor={voice.voice}>{voice.voice+", "+voice.gender}</label>
															  </div>
															</div>
															)
														})}
														
														
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-4 mb-2">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"><span className="f_sz15">Play</span></label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="w-100 d-inline-block mt-2">
                                                        <span style={{display: 'block'}}>
                                                          <span className="d-inline-block">
                                                            <span className="fgrey mr-2">
																<i className="fas fa-play f_sz24 mr-2" title="Play" onClick={this.state.generatePlayDisble ? null:this.generateVoice_play } id="id_generateVoiceplay" />
																<i className="fas fa-spinner fa-spin f_sz24 mr-2" style={{display: 'none'}} id="voiceGenerate_loding"/>
																<i className="fas fa-pause f_sz24"  onClick={this.generateVoice_pause}  id="id_generateVoicepause" style={{display: 'none'}} />
																<audio hidden src={this.state.TxtTypeVoiceUrl} controls="controls" id="generateVoiceUrl" />
																	{/*<span className="postop_3up">1.05</span>*/}
																
															</span>
															<span className="postop_3up">
																or <a><span className="fmaroon crsr_pntr" onClick={this.state.voiceGnrteResetdisble ? null :this.ResetVoiceGenClk}>{this.state.voiceGnrteResetdisble ? "Generating":"Reset"}</span></a>
															</span>
                                                          </span>
                                                        </span>
                                                        <span style={{display: 'none'}}>
                                                          <span className="d-inline-block">
                                                            <span className="fgrey mr-2"><span><i className="fas fa-spinner fa-spin f_sz24 mr-2" /></span><span className="postop_3up">Generating</span></span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="card-footer bg-transparent text-center ">
                                                  <a className="btn btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.voiceGnrteTextSave} id="voiceGnrteTextSv_id">Save</a>
                                                  {this.state.VoiceGrtngTxtTypDeltDisble ?
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled">Delete</a>:
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.voiceGnrteTextDelete}>Delete</a>
												  }
												</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-group row mt-5 mb-4">
                                      <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Auto Voice Response by Text</label>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-7 pt-1">
                                        <div className="custom-control custom-switch">
                                          <input type="checkbox" className="custom-control-input autovoiceresponse_tgl" id="autovoiceresponse" onClick={this.autovoiceresponse_tgl}/>
                                          <label className="custom-control-label" htmlFor="autovoiceresponse" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="autovoiceresponse_tgldiv" style={{display: 'none'}}>
                                      <div className="card w-100 mt-3">
                                        <div className="card-body py-0">
										  <div className={this.state.AtoViceRescls}>{this.state.AtoViceResMsg}</div>
                                          <div className="form-group mt-2 mb-2">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                                              <div className="all_contentflex_div icheck_lbl">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="autovoiceresp" id="autovoicemsg" defaultValue={1} onClick={this.autovoiceresp}/>
                                                    <label htmlFor="autovoicemsg">Leave a message</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">   
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="autovoiceresp" id="autovoicehangup" defaultValue={2} onClick={this.autovoiceresp} />
                                                    <label htmlFor="autovoicehangup">Hangup without message</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>      
                                          </div>
                                          <div className="autovoicedesc" id="autovoicemsg1" style={{display: 'none'}}>
                                            <div className="all_contentflex_div">
                                              <div className="card w-100 mt-2 mb-4 shadow-none">
                                                <div className="card-header">
                                                  <h5 className="fmaroon f_sz15">Leave a message</h5>
                                                </div>
                                                <div className="card-body">
                                                  <div className="form-group row mt-2 mb-2">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                      <textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter the Message" value={this.state.LeavAutoVceMsgVle} onChange={this.AtoVoicLeaveMSgonChng}/>     
                                                    </div>      
                                                  </div>
                                                  <div className="form-group row mt-4 mb-2">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Max No. of Response</span></label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <input type="text" className="form-control text-center txtbx_sm" value={this.state.LeavAutoVoiceMxmRspnsVlu} onChange={this.AtoVoicLeaveMSgMxmRspnsOnChang} maxlength="2"/> 
                                                    </div>      
                                                  </div>
                                                </div>
                                                <div className="card-footer bg-transparent text-center ">
                                                  <a className="btn btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.AtoVcRspnsSaveClick} id="AtoVcRspnsSave_id">Save</a>
												  {this.state.leaveMsgDelDisble ?
                                                  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled">Delete</a>:
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.atoVcRspnLevMsgDelClk}>Delete</a>
												  }
												</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="autovoicedesc" id="autovoicemsg2" style={{display: 'none'}}>
                                            <div className="all_contentflex_div">
                                              <div className="card w-100 mt-2 mb-4 shadow-none">
                                                <div className="card-header">
                                                  <h5 className="fmaroon f_sz15">Hangup without message</h5>
                                                </div>
                                                <div className="card-body">
                                                  <div className="form-group row mt-2 mb-2">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Max No. of Response</span></label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <input type="text" className="form-control text-center txtbx_sm" value={this.state.HangMsgValue} onChange={this.hangmsgOnChang} maxlength="2"/> 
                                                    </div>      
                                                  </div>
                                                </div>
                                                <div className="card-footer bg-transparent text-center ">
                                                  <a className="btn btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.SaveHngMsgwithout} id="SaveHngMsgwithout_id">Save</a>
												  {this.state.hangupMsgDelDisble ?
												  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled" >Delete</a>:
                                                  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.DelHngMsgwithout}>Delete</a>
												  }
												</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-group row mt-5 mb-4">
                                      <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Birthday Greetings</label>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-7 pt-1">
                                        <div>
                                          <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input bdaygreetings_tgl" id="bdaygreetings" onClick={this.bdaygreetings_tgl} />
                                            <label className="custom-control-label" htmlFor="bdaygreetings" />
                                          </div>
                                        </div> 
                                      </div>
                                    </div>
                                    <div className="bdaygreetings_tgldiv" style={{display: 'none'}}>
                                      <div className="card w-100 mt-2 mb-5">
                                        <div className="card-body py-0">
										  <div className={this.state.brtGrtcls}>{this.state.brtGrtMsg}</div>
                                          <div className="form-group row mt-2 mb-2">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Birthday Text</span></label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                              <textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter Text Message" value={this.state.birthdyTxtValue} onChange={this.birthdyTxtOnChg}/>     
                                            </div>      
                                          </div>
                                          <div className="form-group row mt-4 mb-2">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Time</span></label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                              <div className="select_style selectbx_sm">
                                                <select className="form-control" onChange={this.bithGrtngTimeOnchng}>
													<option value="12:00 AM" selected={this.state.bithGrtngTimeSelt == "12:00 AM" ? true:false}>12:00 AM</option>
                                                    <option value="1:00 AM" selected={this.state.bithGrtngTimeSelt == "1:00 AM" ? true:false}>01:00 AM</option>
                                                    <option value="2:00 AM" selected={this.state.bithGrtngTimeSelt == "2:00 AM" ? true:false}>02:00 AM</option>
                                                    <option value="3:00 AM" selected={this.state.bithGrtngTimeSelt == "3:00 AM" ? true:false}>03:00 AM</option>
                                                    <option value="4:00 AM" selected={this.state.bithGrtngTimeSelt == "4:00 AM" ? true:false}>04:00 AM</option>
                                                    <option value="5:00 AM" selected={this.state.bithGrtngTimeSelt == "5:00 AM" ? true:false}>05:00 AM</option>
                                                    <option value="6:00 AM" selected={this.state.bithGrtngTimeSelt == "6:00 AM" ? true:false}>06:00 AM</option>
                                                    <option value="7:00 AM" selected={this.state.bithGrtngTimeSelt == "7:00 AM" ? true:false}>07:00 AM</option>
                                                    <option value="8:00 AM" selected={this.state.bithGrtngTimeSelt == "8:00 AM" ? true:false}>08:00 AM</option>
                                                    <option value="9:00 AM" selected={this.state.bithGrtngTimeSelt == "9:00 AM" ? true:false}>09:00 AM</option>
                                                    <option value="10:00 AM" selected={this.state.bithGrtngTimeSelt == "10:00 AM" ? true:false}>10:00 AM</option>
                                                    <option value="11:00 AM" selected={this.state.bithGrtngTimeSelt == "11:00 AM" ? true:false}>11:00 AM</option>
                                                    <option value="12:00 PM" selected={this.state.bithGrtngTimeSelt == "12:00 PM" ? true:false}>12:00 PM</option>
                                                    <option value="1:00 PM" selected={this.state.bithGrtngTimeSelt == "1:00 PM" ? true:false}>01:00 PM</option>
                                                    <option value="2:00 PM" selected={this.state.bithGrtngTimeSelt == "2:00 PM" ? true:false}>02:00 PM</option>
                                                    <option value="3:00 PM" selected={this.state.bithGrtngTimeSelt == "3:00 PM" ? true:false}>03:00 PM</option>
                                                    <option value="4:00 PM" selected={this.state.bithGrtngTimeSelt == "4:00 PM" ? true:false}>04:00 PM</option>
                                                    <option value="5:00 PM" selected={this.state.bithGrtngTimeSelt == "5:00 PM" ? true:false}>05:00 PM</option>
                                                    <option value="6:00 PM" selected={this.state.bithGrtngTimeSelt == "6:00 PM" ? true:false}>06:00 PM</option>
                                                    <option value="7:00 PM" selected={this.state.bithGrtngTimeSelt == "7:00 PM" ? true:false}>07:00 PM</option>
                                                    <option value="8:00 PM" selected={this.state.bithGrtngTimeSelt == "8:00 PM" ? true:false}>08:00 PM</option>
                                                    <option value="9:00 PM" selected={this.state.bithGrtngTimeSelt == "9:00 PM" ? true:false}>09:00 PM</option>
                                                    <option value="10:00 PM" selected={this.state.bithGrtngTimeSelt == "10:00 PM" ? true:false}>10:00 PM</option>
                                                    <option value="11:00 PM" selected={this.state.bithGrtngTimeSelt == "11:00 PM" ? true:false}>11:00 PM</option>
                                                </select>
                                              </div>
                                            </div>      
                                          </div>  
                                          <div className="form-group row mt-4 mb-2">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">Time Zone</span></label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                              <div className="select_style">
                                                <select className="form-control" onChange={this.bithGrtngTimeZoneOnchng}>
													<option value="GMT-1200" selected={this.state.bithGrtngTimeZoneSelt == "GMT-1200" ? true:false}>(GMT -12:00) Eniwetok, Kwajalein</option>
                                                    <option value="GMT-1100" selected={this.state.bithGrtngTimeZoneSelt == "GMT-1100" ? true:false}>(GMT -11:00) Midway Island, Samoa</option>
                                                    <option value="GMT-1000" selected={this.state.bithGrtngTimeZoneSelt == "GMT-1000" ? true:false}>(GMT -10:00) Hawaii</option>
                                                    <option value="GMT-0930" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0930" ? true:false}>(GMT -9:30) Taiohae</option>
                                                    <option value="GMT-0900" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0900" ? true:false}>(GMT -9:00) Alaska</option>
                                                    <option value="GMT-0800" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0800" ? true:false}>(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                                    <option value="GMT-0700" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0700" ? true:false}>(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                                    <option value="GMT-0600" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0600" ? true:false}>(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                                    <option value="GMT-0500" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0500" ? true:false}>(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                                    <option value="GMT-0430" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0430" ? true:false}>(GMT -4:30) Caracas</option>
                                                    <option value="GMT-0400" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0400" ? true:false}>(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                                    <option value="GMT-0330" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0330" ? true:false}>(GMT -3:30) Newfoundland</option>
                                                    <option value="GMT-0300" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0300" ? true:false}>(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                                    <option value="GMT-0200" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0200" ? true:false}>(GMT -2:00) Mid-Atlantic</option>
                                                    <option value="GMT-0100" selected={this.state.bithGrtngTimeZoneSelt == "GMT-0100" ? true:false}>(GMT -1:00) Azores, Cape Verde Islands</option>
                                                    <option value="GMT+0000" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0000" ? true:false}>(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                                    <option value="GMT+0100" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0100" ? true:false}>(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                    <option value="GMT+0200" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0200" ? true:false}>(GMT +2:00) Kaliningrad, South Africa</option>
                                                    <option value="GMT+0300" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0300" ? true:false}>(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                                    <option value="GMT+0330" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0330" ? true:false}>(GMT +3:30) Tehran</option>
                                                    <option value="GMT+0400" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0400" ? true:false}>(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                                    <option value="GMT+0430" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0430" ? true:false}>(GMT +4:30) Kabul</option>
                                                    <option value="GMT+0500" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0500" ? true:false}>(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                                    <option value="GMT+0530" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0530" ? true:false}>(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                                    <option value="GMT+0545" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0545" ? true:false}>(GMT +5:45) Kathmandu, Pokhara</option>
                                                    <option value="GMT+0600" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0600" ? true:false}>(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                                    <option value="GMT+0630" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0630" ? true:false}>(GMT +6:30) Yangon, Mandalay</option>
                                                    <option value="GMT+0700" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0700" ? true:false}>(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                                    <option value="GMT+0800" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0800" ? true:false}>(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                                    <option value="GMT+0845" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0845" ? true:false}>(GMT +8:45) Eucla</option>
                                                    <option value="GMT+0900" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0900" ? true:false}>(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                                    <option value="GMT+0930" selected={this.state.bithGrtngTimeZoneSelt == "GMT+0930" ? true:false}>(GMT +9:30) Adelaide, Darwin</option>
                                                    <option value="GMT+1000" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1000" ? true:false}>(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                                    <option value="GMT+1030" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1030" ? true:false}>(GMT +10:30) Lord Howe Island</option>
                                                    <option value="GMT+1100" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1100" ? true:false}>(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                                    <option value="GMT+1130" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1130" ? true:false}>(GMT +11:30) Norfolk Island</option>
                                                    <option value="GMT+1200" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1200" ? true:false}>(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                                    <option value="GMT+1245" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1245" ? true:false}>(GMT +12:45) Chatham Islands</option>
                                                    <option value="GMT+1300" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1300" ? true:false}>(GMT +13:00) Apia, Nukualofa</option>
                                                    <option value="GMT+1400" selected={this.state.bithGrtngTimeZoneSelt == "GMT+1400" ? true:false}>(GMT +14:00) Line Islands, Tokelau</option>
                                                  </select>
                                              </div>
                                            </div>      
                                          </div> 
                                          <div className="form-group w-100 mt-4 mb-2 text-center">
                                            <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.saveBrthgrtng} id="saveBrthgrtng_id">Save</a> 
											{this.state.brthdyGrtngDelDisble ?
                                            <a className="btn btn-black crsr_pntr m-2 btnpdng_sm isdisabled">Delete</a>:
											<a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={this.DeltBrthgrtng}>Delete</a>  
											}
										  </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3" style={{display: 'none'}}>
                                  <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Reset</a>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                      <div className="tab-pane fade" id="custom-tabs-integrations" role="tabpanel" aria-labelledby="custom-tabs-integrations-tab">
                        {/*Integrations*/}
                        <div className="card w-100 shadow-none integrations_card"> 
                          <div className="card-header px-2 py-2 border-bottom-0">
                            {/* START TABS DIV */}
                            <div className="tabbable-responsive">
                              <div className="tabbable">
                                <ul className="nav nav-tabs border-bottom" id="custom-tabs-profilesettings-tab" role="tablist">
                                  <li className="nav-item">
                                    <a className="nav-link active" id="custom-tabs-psemail-tab" data-toggle="pill" href="#custom-tabs-psemail" role="tab" aria-controls="custom-tabs-psemail" aria-selected="false"><span className="d-inline-block">Email</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-pschat-tab" data-toggle="pill" href="#custom-tabs-pschat" role="tab" aria-controls="custom-tabs-pschat" aria-selected="false"><span className="d-inline-block">Chat</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-psslack-tab" data-toggle="pill" href="#custom-tabs-psslack" role="tab" aria-controls="custom-tabs-psslack" aria-selected="true"><span className="d-inline-block">Slack</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-pswebhooks-tab" data-toggle="pill" href="#custom-tabs-pswebhooks" role="tab" aria-controls="custom-tabs-pswebhooks" aria-selected="false"><span className="d-inline-block">Webhooks</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-psapi-tab" data-toggle="pill" href="#custom-tabs-psapi" role="tab" aria-controls="custom-tabs-psapi" aria-selected="false"><span className="d-inline-block">API</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-psphrases-tab" data-toggle="pill" href="#custom-tabs-psphrases" role="tab" aria-controls="custom-tabs-psphrases" aria-selected="true"><span className="d-inline-block">Phrases</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-psqrcodes-tab" data-toggle="pill" href="#custom-tabs-psqrcodes" role="tab" aria-controls="custom-tabs-psqrcodes" aria-selected="false"><span className="d-inline-block">QR Codes</span></a>
                                  </li>
                                  <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-psgmail-tab" data-toggle="pill" href="#custom-tabs-psgmail" role="tab" aria-controls="custom-tabs-psgmail" aria-selected="false"><span className="d-inline-block">Gmail</span></a>
                                  </li>
                                  {/*<li class="nav-item">
                  <a class="nav-link" id="custom-tabs-psrewards-tab" data-toggle="pill" href="#custom-tabs-psrewards" role="tab" aria-controls="custom-tabs-psrewards" aria-selected="false"><span class="d-inline-block">Rewards</span></a>
                </li>*/}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="card-body px-0">  
                            <div className="tab-content" id="custom-tabs-profilesettings-tabContent">
                              <div className="tab-pane fade active show" id="custom-tabs-psemail" role="tabpanel" aria-labelledby="custom-tabs-psemail-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700">   
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div className="form-group row mt-3 mb-4">
                                            <label className="col-12 col-sm-12 col-md-6 col-lg-5 col-form-label">Profile Email Address</label>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-7">
                                              <div className="profemailaddr_dsplydiv">
                                                <span className="mt-2 d-inline-block mr-2 f_sz16">6463511256@redtiemail.com{/*[phone-number]@redtiemail.com*/}</span><span className="fmaroon crsr_pntr profemailaddr_edit"><i className="fas fa-pen" onClick={this.profemailaddr_edit} /></span>
                                              </div>
                                              <div className="profemailaddr_upddiv" style={{display: 'none'}}>
                                                <div className="w-100 d-flex flex-row mb-3">
                                                  <div className="col-11 col-sm-10 col-md-10 pl-0">
                                                    <div className="input-group">
                                                      <input type="text" className="form-control" defaultValue={6463511256} />
                                                      <div className="input-group-append">
                                                        <span className="input-group-text nobrdr_bg">@redtiemail.com</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-1 col-sm-2 col-md-2 px-0">
                                                    <a><i className="far fa-save mt-3 f_sz17 crsr_pntr fmaroon" /></a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group row mt-2 mb-2">
                                            <label className="col-12 col-sm-12 col-md-12 col-lg-12 col-form-label"><span className="f_sz15">Permitted Team Members</span></label>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="card w-100 bx_shadow_sm mt-3">
                                                <div className="card-body p-0">
                                                  <div className="table-responsive">
                                                    <table className="table table-hover tbl_valignmdle tbl_teammember border-bottom" id="emailprofteammember" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                      <thead>
                                                        <tr><th className="text-center">
                                                            <div className="icheck-darkgrey d-inline-block">
                                                              <input type="checkbox" id="emailallteam" />
                                                              <label htmlFor="emailallteam">&nbsp;</label>
                                                            </div>
                                                          </th>
                                                          <th>Photo</th>
                                                          <th>Email Address</th>
                                                        </tr></thead>
                                                      <tbody>
                                                        <tr>
                                                          <td align="center">
                                                            <div className="icheck-darkgrey d-inline-block">
                                                              <input type="checkbox" id="emailteam1" />
                                                              <label htmlFor="emailteam1">&nbsp;</label>
                                                            </div>
                                                          </td>
                                                          <td><span className="usercircle_md bg_teammember1">A</span></td>
                                                          <td>alexanderpierce@gmail.com</td>
                                                        </tr>
                                                        <tr>
                                                          <td align="center">
                                                            <div className="icheck-darkgrey d-inline-block">
                                                              <input type="checkbox" id="emailteam2" />
                                                              <label htmlFor="emailteam2">&nbsp;</label>
                                                            </div>
                                                          </td>
                                                          <td><span className="usercircleimg_md"><img src="../dist/img/user8-128x128.jpg" border={0} /></span></td>
                                                          <td>leohatter@gmail.com</td>
                                                        </tr>
                                                        <tr>
                                                          <td align="center">
                                                            <div className="icheck-darkgrey d-inline-block">
                                                              <input type="checkbox" id="emailteam3" />
                                                              <label htmlFor="emailteam3">&nbsp;</label>
                                                            </div>
                                                          </td>
                                                          <td><span className="usercircle_md bg_teammember3">V</span></td>
                                                          <td>vincy@gmail.com</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </div>    
                                                </div>
                                              </div>
                                            </div>      
                                          </div>
                                        </div>
                                        <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                          <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Save</a>
                                        </div>
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-pschat" role="tabpanel" aria-labelledby="custom-tabs-pschat-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700">   
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div className="form-group row mt-3 mb-4">
                                            <label className="col-12 col-sm-12 col-md-12 col-lg-12 col-form-label">Chat Widget</label>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                                              <textarea className="form-control textarea_hgt80 txtarea_resizenone f_sz13" defaultValue={"<script type=\"text/javascript\" language=\"javascript\" src=\"https://www.redtie.co/v4/app/chat/widget.js\" key=\"5dbc00d70361b4034aa50122\"></script>\n                                         "} />
                                              <small className="text-gray d-inline-block mt-1">Note: You can copy the code snippet and paste it on any of your websites and start providing support to your end users.</small>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                          <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Preview</a>
                                          <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Copy</a>
                                        </div>
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-psslack" role="tabpanel" aria-labelledby="custom-tabs-psslack-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700">   
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div className="form-group row mt-5 mb-4">
                                            <h5 className="col-12 col-sm-12 text-center fmaroon">Connect with Slack</h5>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="text-center mt-3">
                                                <a><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm"><i className="fab fa-slack f_sz22 postop_2 mr-3" />Connect</span></a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-pswebhooks" role="tabpanel" aria-labelledby="custom-tabs-pswebhooks-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="webhookadd_div">
                                      <div className="card w-100 shadow-none"> 
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">Webhook URL</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <a><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm addwebhook_clk" onClick={this.addwebhook_clk}>Add</span></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="webhooklist_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2">Webhook URL's</h5>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                            <a className="btn btn-black crsr_pntr btn-sm mt-1 addwebhook_clk"><i className="fas fa-plus mr-2" />Add New</a>
                                          </div>
                                        </div>
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body pb-0">
                                            <div className="form-group row">
                                              <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                                                <div>
                                                  <div className="w-100 d-flex flex-row flex-wrap mb-3">
                                                    <div className="col-12 col-sm-12 col-md-9 col-lg-10 pl-0">
                                                      <div className="d-table webhookdedtls">
                                                        <div className="d-table-row">
                                                          <div className="d-table-cell align-middle">1.</div>
                                                          <div className="d-table-cell">
                                                            <span className="d-inline-block mr-2 f_sz14 txt_wrap w-100">https://www.redtie.co/v4/app/webhook/textmsgs_QWe236Ilp</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                                      <div className="text-right text-md-center mt-2 mt-md-0 mr-3">
                                                        <span className="d-inline-block fmaroon f_sz16 webhookshistory_tgl1 crsr_pntr mr-3"><i className="fas fa-history" onClick={this.webhookshistory_tgl1}/></span>
                                                        <span className="d-inline-block fmaroon crsr_pntr"><i className="far fa-trash-alt f_sz16" /></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="webhookshistory_tgldiv1" style={{display: 'none'}}>
                                                    <div className="all_contentflex_div">
                                                      <div className="card w-100 mt-2 mb-5">
                                                        <div className="card-header">
                                                          <h5 className="fmaroon f_sz15">History</h5>
                                                        </div>
                                                        <div className="card-body p-0">
                                                          <div className="table-responsive">
                                                            <table className="table table-hover tbl_valignmdle tbl_webhookshistory border-bottom" id="webhookshistory_tbl1" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                              <thead>
                                                                <tr><th>Date Time</th>
                                                                  <th>IPAddress</th>
                                                                </tr></thead>
                                                              <tbody>
                                                                <tr>
                                                                  <td>05/25/2020 7.53pm</td>
                                                                  <td>152.145.1.1</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/14/2020 5.00am</td>
                                                                  <td>152.142.1.1</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 3.00pm</td>
                                                                  <td>147.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.00am</td>
                                                                  <td>111.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 7.00pm</td>
                                                                  <td>147.145.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 6.45pm</td>
                                                                  <td>122.475.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.23am</td>
                                                                  <td>123.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 7.45pm</td>
                                                                  <td>784.145.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.00am</td>
                                                                  <td>485.123.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 4.00am</td>
                                                                  <td>125.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 6.00am</td>
                                                                  <td>142.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 9.00am</td>
                                                                  <td>147.142.1.0</td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div className="w-100 d-flex flex-row flex-wrap mb-3">
                                                    <div className="col-12 col-sm-12 col-md-9 col-lg-10 pl-0">
                                                      <div className="d-table webhookdedtls">
                                                        <div className="d-table-row">
                                                          <div className="d-table-cell align-middle">2.</div>
                                                          <div className="d-table-cell">
                                                            <span className="d-inline-block mr-2 f_sz14 txt_wrap w-100">https://www.redtie.co/v4/app/webhook/textmsgs_tdre_363OLT</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                                      <div className="text-right text-md-center mt-2 mt-md-0 mr-3">
                                                        <span className="d-inline-block fmaroon f_sz16 webhookshistory_tgl2 crsr_pntr mr-3"><i className="fas fa-history" onClick={this.webhookshistory_tgl2}/></span>
                                                        <span className="d-inline-block fmaroon crsr_pntr"><i className="far fa-trash-alt f_sz16" /></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="webhookshistory_tgldiv2" style={{display: 'none'}}>
                                                    <div className="all_contentflex_div">
                                                      <div className="card w-100 mt-2 mb-5">
                                                        <div className="card-header">
                                                          <h5 className="fmaroon f_sz15">History</h5>
                                                        </div>
                                                        <div className="card-body p-0">
                                                          <div className="table-responsive">
                                                            <table className="table table-hover tbl_valignmdle tbl_webhookshistory border-bottom" id="webhookshistory_tbl2" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                              <thead>
                                                                <tr><th>Date Time</th>
                                                                  <th>IPAddress</th>
                                                                </tr></thead>
                                                              <tbody>
                                                                <tr>
                                                                  <td>05/23/2020 7.53pm</td>
                                                                  <td>152.145.1.1</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>02/14/2020 5.00am</td>
                                                                  <td>152.142.1.1</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 3.00pm</td>
                                                                  <td>147.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.00am</td>
                                                                  <td>111.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 7.00pm</td>
                                                                  <td>147.145.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 6.45pm</td>
                                                                  <td>122.475.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.23am</td>
                                                                  <td>123.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 7.45pm</td>
                                                                  <td>784.145.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 8.00am</td>
                                                                  <td>485.123.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 4.00am</td>
                                                                  <td>125.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 6.00am</td>
                                                                  <td>142.155.1.0</td>
                                                                </tr>
                                                                <tr>
                                                                  <td>04/10/2020 9.00am</td>
                                                                  <td>147.142.1.0</td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>  
                                      </div>
                                    </div>
                                    <div className="addwebhook_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                          <h5>
                                            <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Add Webhook URL(s)</span>
                                            <span className="addwebhook_close mr-2 mt-1"><i className="fas fa-times" onClick={this.addwebhook_close}/></span>
                                          </h5>
                                        </div>
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-3 mb-4">
                                              <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                                <input type="text" className="form-control" placeholder="Enter Webhook URL" />
                                                <small className="text-gray d-inline-block mt-1">Eg: https://www.redtie.co/v4/app/webhook</small>
                                              </div>
                                              <div className="col-2 col-sm-2 col-md-2 col-lg-2 pr-0">
                                                <a><i className="fas fa-trash-alt mt-2 mr-3 f_sz16 crsr_pntr fmaroon" /></a>
                                                <a><i className="fas fa-plus-circle mt-2 f_sz16 crsr_pntr fmaroon" /></a>                              
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                          <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Save</a>
                                        </div>
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-psapi" role="tabpanel" aria-labelledby="custom-tabs-psapi-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="apicreate_div">
                                      <div className="card w-100 shadow-none"> 
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">API Keys</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <a><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm apicreate_clk" onClick={this.apicreate_clk}>Create</span></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="apilist_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2"><span className="mr-3">API Keys</span> <a href="#"><span className="f_sz14 forange d-block d-md-inline-block mt-2 mt-md-0">API Reference<i className="fas fa-long-arrow-alt-right ml-1" /></span></a></h5>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                            <a className="btn btn-black crsr_pntr btn-sm mt-1">Create New</a>
                                          </div>
                                        </div>
                                        <div className="card-body p-0">
                                          <div className="table-responsive">
                                            <table className="table table-hover tbl_valignmdle tbl_api border-bottom" id="apikey_tbl" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                              <thead>
                                                <tr><th className="text-center">S.No</th>
                                                  <th>ID and Secret Key</th>
                                                  <th className="text-center">Action</th>
                                                </tr></thead>
                                              <tbody>
                                                <tr>
                                                  <td align="center">
                                                    1
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <span className="font-weight-semibold mr-2">ID:</span>zUBuFTh9QIK8sTr9mLDieFxSXR3hdPBzvqZJZctm9PYXyH87xveKaFls1wbK</div>
                                                    <div><span className="font-weight-semibold mr-2">Secret Key:</span> gwdjWgoO5hfQ </div>
                                                  </td>
                                                  <td align="center">
                                                    <div className="tbl_actionbtns">
                                                      <div className="d-inline-block switchbtn_wdth postop_5up">
                                                        <div className="custom-control custom-switch pl-0">
                                                          <input type="checkbox" className="custom-control-input" id="apikey1" />
                                                          <label className="custom-control-label" htmlFor="apikey1" />
                                                        </div>
                                                      </div> 
                                                      <div className="d-inline-block mt-2 mt-md-0"> 
                                                        <a href="#"><i className="far fa-trash-alt postop_2up" /></a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="center">
                                                    2
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <span className="font-weight-semibold mr-2">ID:</span>juLOpTh9QIK8sTr9mLDieFxSXR3hdPBzvqZJZctm9PYXyH87xveKaKILOPgh</div>
                                                    <div><span className="font-weight-semibold mr-2">Secret Key:</span> WgoOgwdj5hfQ </div>
                                                  </td>
                                                  <td align="center">
                                                    <div className="tbl_actionbtns">
                                                      <div className="d-inline-block switchbtn_wdth postop_5up">
                                                        <div className="custom-control custom-switch pl-0">
                                                          <input type="checkbox" className="custom-control-input" id="apikey2" defaultChecked="checked" />
                                                          <label className="custom-control-label" htmlFor="apikey2" />
                                                        </div>
                                                      </div>  
                                                      <div className="d-inline-block mt-2 mt-md-0"> 
                                                        <a href="#"><i className="far fa-trash-alt postop_2up" /></a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-psphrases" role="tabpanel" aria-labelledby="custom-tabs-psphrases-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="phrasecreate_div">
                                      <div className="card w-100 shadow-none"> 
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">Phrases</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <a onClick={this.phrasecreate_clk}><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm phrasecreate_clk" onClick={this.phrasecreate_clk}>Create Phrase</span></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="phraselist_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2">Phrases</h5>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                            <a className="btn btn-black crsr_pntr btn-sm mt-1 phrasecreate_clk" onClick={this.phrasecreate_clk}>Add New</a>
                                          </div>
                                        </div>
                                        <div className="card-body p-0">
                                          <div className="table-responsive">
                                            <table className="table table-hover tbl_valignmdle tbl_intphrases border-bottom" id="intphrases_tbl" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                              <thead>
                                                <tr><th>Phrase</th>
                                                  <th>Auto Response Message</th>
                                                  <th>Tags</th>
                                                  <th className="text-center">Action</th>
                                                </tr></thead>
                                              <tbody>
                                                <tr>
                                                  <td>DIWALI</td>
                                                  <td>Thanks for joining our Diwali list. We will send Diwali specials from merchants. You can send 'STOP DIWALI' any time to unsubscribe.</td>
                                                  <td><span className="badge badge-info f_sz12 mr-1">Diwali 2020</span><span className="badge badge-info f_sz12 mr-1">Crackers 2020</span><span className="badge badge-info f_sz12 mr-1">Funny Diwlali 2020</span></td>
                                                  <td align="center">
                                                    <div className="tbl_actionbtns">
                                                      <a className="phrasecreate_clk" ><i className="fas fa-pen" title="Edit" onClick={this.phrasecreate_clk}/></a>
                                                      <a href="#"><i className="far fa-trash-alt" title="Delete" /></a>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>STUDAI</td>
                                                  <td>Thanks for joining our Students list. We will send updates on AI. You can send 'STOP STUDAI' any time to unsubscribe.</td>
                                                  <td><span className="badge badge-info f_sz12 mr-1">Students IIL 2020</span><span className="badge badge-info f_sz12 mr-1">StudAI 2020</span></td>
                                                  <td align="center">
                                                    <div className="tbl_actionbtns">
                                                      <a className="phrasecreate_clk"><i className="fas fa-pen" title="Edit" /></a>
                                                      <a href="#"><i className="far fa-trash-alt" title="Delete" /></a>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Hackathon</td>
                                                  <td>Thanks for joining Hackathon. We will send updates on the events. You can send 'STOP Hackathon' any time to unsubscribe.</td>
                                                  <td><span className="badge badge-info f_sz12 mr-1">Hackathon 2020</span></td>
                                                  <td align="center">
                                                    <div className="tbl_actionbtns">
                                                      <a className="phrasecreate_clk"><i className="fas fa-pen" title="Edit" onClick={this.phrasecreate_clk}/></a>
                                                      <a href="#"><i className="far fa-trash-alt" title="Delete" /></a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="addphrase_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                          <h5>
                                            <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Add Phrase</span>
                                            <span className="addintphrase_close mr-2 mt-1"><i className="fas fa-times" onClick={this.addintphrase_close}/></span>
                                          </h5>
                                        </div>
                                        <div className="all_contentcntr_700">
                                          <div className="form-horizontal formbiglbl">
                                            <div className="card-body">
                                              {/*Element to be Looped*/}
                                              <div>
                                                <div className="form-group mt-3 mb-4">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input type="text" className="form-control" placeholder="Enter the New Phrase" />
                                                  </div>
                                                </div>
                                                <div className="form-group mt-3 mb-2"> 
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">  
                                                    <textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter Auto Response Message" defaultValue={""} />
                                                  </div>
                                                </div> 
                                                <div className="form-group mt-3 mb-4">
                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                                                    <div className="psphrase_entertagname_drpdwn_div">
                                                      <span className="allselectdropdwn">
                                                        <select className="selectpicker w-100 border-bottom f_sz16" multiple data-live-search="true">
                                                          <option selected="selected">--Choose Tag(s)--</option>
                                                          <option>Conference IIL 2020</option>
                                                          <option>Friends and Relatives</option>
                                                          <option>Innovation Strategy</option>
                                                          <option>Design and Development</option>
                                                        </select>
                                                      </span>
                                                      <div className="col-12 px-0 text-right pt-2"><span className="fmaroon f_sz13 psphrase_addtagname crsr_pntr" ><i className="fas fa-plus f_sz13 mr-2" onClick={this.psphrase_addtagname} />Add New Tag</span></div>
                                                    </div>
                                                    <div className="psphrase_entertagname_txtbx_div" style={{display: 'none'}}>
                                                      <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Enter the new tag name" />
                                                        <div className="input-group-append">
                                                          <span className="input-group-text nobrdr_bg">
                                                            <span className="psphrase_closeaddtagname crsr_pntr">
                                                              <i className="fas fa-times f_sz13" onClick={this.psphrase_closeaddtagname}/>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-right brdrbtm_dashed pb-2">
                                                  <a><i className="fas fa-trash-alt mt-2 mr-3 f_sz16 crsr_pntr fmaroon" /></a>
                                                  <a><i className="fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon" /></a>                              
                                                </div>
                                              </div>
                                              {/*Element to be Looped*/}
                                            </div>
                                          </div>
                                          <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                            <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Save</a>
                                          </div>
                                        </div> 
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-psqrcodes" role="tabpanel" aria-labelledby="custom-tabs-psqrcodes-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="qrcodecreate_div">
                                      <div className="card w-100 shadow-none"> 
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">QR Codes</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm qrcodecreate_clk" onClick={this.qrcodecreate_clk}>Create</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="qrcodelist_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2">QR Codes</h5>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                            <a className="btn btn-black crsr_pntr btn-sm mt-1 qrcodecreate_clk" onClick={this.qrcodecreate_clk}>Add New</a>
                                          </div>
                                        </div>
                                        <div className="card-body p-0">
                                          <div>
                                            <div className="w-100 d-flex flex-row flex-wrap mb-3">
                                              <div className="col-12 col-sm-12 col-md-9 col-lg-10 pl-0">
                                                <div className="d-table qrcodedtls">
                                                  <div className="d-table-row">
                                                    <div className="d-table-cell align-middle">1.</div>
                                                    <div className="d-table-cell">
                                                      <div className="d-inline-block f_sz14 w-100">
                                                        <span className="d-inline-block w-100 font-weight-semibold pb-2">Conference IIL 2020</span>
                                                        <span className="d-inline-block w-100 pb-2"><i className="far fa-comment-alt mr-3 postop_2 forange f_sz15" />Thanks for attending the Conference IIL 2020. We will send the updates regarding the conference soon.</span>
                                                        <span className="d-inline-block w-100"><img src="../dist/img/tags_orange.png" border={0} className="mr-3 img-size-16" /><span className="badge badge-info f_sz12 mr-1">Conference IIL 2020</span><span className="badge badge-info f_sz12 mr-1">IIL 2020</span></span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                                <div className="f_sz16 mt-2 mt-md-5 text-right text-md-center mr-3">
                                                  <span className="d-inline-block fmaroon qrcodehistory_tgl1 crsr_pntr mr-3"><i className="fas fa-history" onClick={this.qrcodehistory_tgl1}/></span>
                                                  <span className="d-inline-block fmaroon crsr_pntr"><i className="far fa-trash-alt" /></span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="qrcodehistory_tgldiv1" style={{display: 'none'}}>
                                              <div className="all_contentflex_div">
                                                <div className="card w-100 mt-2">
                                                  <div className="card-header">
                                                    <h5 className="fmaroon f_sz15">History</h5>
                                                  </div>
                                                  <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                      <table className="table table-hover tbl_valignmdle tbl_qrcodehistory border-bottom" id="qrcodehistory_tbl1" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                        <thead>
                                                          <tr><th>Date Time</th>
                                                            <th>IPAddress</th>
                                                            <th>Location</th>
                                                            <th>Contact</th>
                                                          </tr></thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>05/23/2020 7.53pm</td>
                                                            <td>152.145.1.1</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>(415) 123-1253</td>
                                                          </tr>
                                                          <tr>
                                                            <td>02/14/2020 5.00am</td>
                                                            <td>152.142.1.1</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>(412) 789-6396</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 3.00pm</td>
                                                            <td>147.155.1.0</td>
                                                            <td>New York, New York</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.00am</td>
                                                            <td>111.155.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>(415) 741-7885</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 7.00pm</td>
                                                            <td>147.145.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>(789) 452-4125</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 6.45pm</td>
                                                            <td>122.475.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.23am</td>
                                                            <td>123.155.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 7.45pm</td>
                                                            <td>784.145.1.0</td>
                                                            <td>New York, New York</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.00am</td>
                                                            <td>485.123.1.0</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>(748) 526-9638</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 4.00am</td>
                                                            <td>125.155.1.0</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>(748) 789-9638</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 6.00am</td>
                                                            <td>142.155.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 9.00am</td>
                                                            <td>147.142.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>(748) 456-7489</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                          <div>
                                            <div className="w-100 d-flex flex-row flex-wrap mb-3">
                                              <div className="col-12 col-sm-12 col-md-9 col-lg-10 pl-0">
                                                <div className="d-table qrcodedtls">
                                                  <div className="d-table-row">
                                                    <div className="d-table-cell align-middle">2.</div>
                                                    <div className="d-table-cell">
                                                      <div className="d-inline-block f_sz14 w-100">
                                                        <span className="d-inline-block w-100 font-weight-semibold pb-2">Students IIL 2020</span>
                                                        <span className="d-inline-block w-100 pb-2"><i className="far fa-comment-alt mr-3 postop_2 forange f_sz15" />Thanks for attending the Students IIL 2020. We will send the updates regarding the conference soon.</span>
                                                        <span className="d-inline-block w-100"><img src="../dist/img/tags_orange.png" border={0} className="mr-3 img-size-16" /><span className="badge badge-info f_sz12 mr-1">IIL 2020 Students</span><span className="badge badge-info f_sz12 mr-1">IIL 2020 Stud</span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                                                <div className="f_sz16 mt-2 mt-md-5 text-right text-md-center mr-3">
                                                  <span className="d-inline-block fmaroon qrcodehistory_tgl2 crsr_pntr mr-3"><i className="fas fa-history" onClick={this.qrcodehistory_tgl2}/></span>
                                                  <span className="d-inline-block fmaroon crsr_pntr"><i className="far fa-trash-alt" /></span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="qrcodehistory_tgldiv2" style={{display: 'none'}}>
                                              <div className="all_contentflex_div">
                                                <div className="card w-100 mt-2">
                                                  <div className="card-header">
                                                    <h5 className="fmaroon f_sz15">History</h5>
                                                  </div>
                                                  <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                      <table className="table table-hover tbl_valignmdle tbl_qrcodehistory border-bottom" id="qrcodehistory_tbl2" cellPadding={0} cellSpacing={0} style={{width: '100%'}}>
                                                        <thead>
                                                          <tr><th>Date Time</th>
                                                            <th>IPAddress</th>
                                                            <th>Location</th>
                                                            <th>Contact</th>
                                                          </tr></thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>05/23/2020 7.53pm</td>
                                                            <td>152.145.1.1</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>(415) 123-1253</td>
                                                          </tr>
                                                          <tr>
                                                            <td>02/14/2020 5.00am</td>
                                                            <td>152.142.1.1</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 3.00pm</td>
                                                            <td>147.155.1.0</td>
                                                            <td>New York, New York</td>
                                                            <td>(745) 123-7896</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.00am</td>
                                                            <td>111.155.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 7.00pm</td>
                                                            <td>147.145.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>(789) 452-4125</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 6.45pm</td>
                                                            <td>122.475.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.23am</td>
                                                            <td>123.155.1.0</td>
                                                            <td>Los Angeles, California</td>
                                                            <td>-</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 7.45pm</td>
                                                            <td>784.145.1.0</td>
                                                            <td>New York, New York</td>
                                                            <td>(418) 256-9639</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 8.00am</td>
                                                            <td>485.123.1.0</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>(748) 526-9638</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 4.00am</td>
                                                            <td>125.155.1.0</td>
                                                            <td>Chicago, Illinois</td>
                                                            <td>(748) 789-9638</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 6.00am</td>
                                                            <td>142.155.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>(748) 748-1253</td>
                                                          </tr>
                                                          <tr>
                                                            <td>04/10/2020 9.00am</td>
                                                            <td>147.142.1.0</td>
                                                            <td>Houston, Texas</td>
                                                            <td>(748) 456-7489</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="addqrcode_div" style={{display: 'none'}}>
                                      <div className="card w-100 shadow-none">
                                        <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                                          <h5>
                                            <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">Create QR Code</span>
                                            <span className="addqrcode_close mr-2 mt-1" onClick={this.addqrcode_close}><i className="fas fa-times" onClcik={this.addqrcode_close}/></span>
                                          </h5>
                                        </div>
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body px-0">
                                            {/*Element to be Looped*/}
                                            <div>
                                              <div className="form-group mt-3 mb-4">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <input type="text" className="form-control" placeholder="Enter the Title" />
                                                </div>
                                              </div>
                                              <div className="form-group mt-3 mb-4"> 
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">  
                                                  <textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter Auto Response Message" defaultValue={""} />
                                                </div>
                                                <div className="sendmessage_btnsdiv">
                                                  <div className="dropdown attachment_drpdwn d-inline-block">
                                                    <span data-toggle="dropdown" className="sendmessage_btnsspan">
                                                      <span className="btn btn-lg btn-black crsr_pntr">
                                                        <i className="fas fa-paperclip" />
                                                      </span>
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                                      <div className="dropdown-item">
                                                        <div className="overallattachment_div">
                                                          {/*<div class="overallattach_item qrcodesendmsgattach_clk">
                                            <div class="overallattach_icon bg_rewards bx_shadow_sm">
                                             <i class="far fa-star"></i>
                                            </div>
                                            <div class="fgrey">Rewards</div>
                                           </div>*/}
                                                          <div className="overallattach_item qrcodesendmsgattach_clk" onClick={this.qrcodesendmsgattach_clk}> 
                                                            <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                              <i className="far fa-sticky-note" />
                                                            </div>
                                                            <div className="fgrey">Notes</div>
                                                          </div>
                                                          <div className="overallattach_item qrcodesendmsgattach_clk" onClick={this.qrcodesendmsgattach_clk}>
                                                            <div className="overallattach_icon bg_documents bx_shadow_sm">
                                                              <i className="far fa-file-alt" />
                                                            </div>
                                                            <div className="fgrey">Documents</div>
                                                          </div>
                                                          <div className="overallattach_item qrcodesendmsgattach_clk" onClick={this.qrcodesendmsgattach_clk}>
                                                            <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                              <i className="fas fa-microphone-alt" />
                                                            </div>
                                                            <div className="fgrey">Audio</div>  
                                                          </div>
                                                          <div className="overallattach_item qrcodesendmsgattach_clk" onClick={this.qrcodesendmsgattach_clk}> 
                                                            <div className="overallattach_icon bg_video bx_shadow_sm">
                                                              <i className="fas fa-film" />
                                                            </div>
                                                            <div className="fgrey">Video</div>   
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> 
                                              <div className="qrcodesendmsg_attachmentdiv" style={{display: 'none'}}>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                                                  <div className="attachment_timelinediv">
                                                    {/* The time line */}
                                                    <div className="timeline">
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}     
                                                            <div className="attachmentbody_div">
                                                              Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus <a href="javascript:void(0)">consequat imperdiet aliquam</a>, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus.
                                                            </div> 
                                                          </div>
                                                          <div className="timeline-footer">
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                {/*<div class="d-table-cell w-70 align-top">
                                                  <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                                 </div>*/}
                                                                <div className="d-table-cell align-middle">
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="fas fa-record-vinyl fa-2x" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Start Recording</span></span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-stop-circle f_sz30" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Stop Recording</span></span>
                                                                      <span className="f_sz16">
                                                                        <span><i className="fas fa-spinner fa-spin f_sz24" /></span>
                                                                        <span className="ml-2 d-inline-block postop_3up">0.08</span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'block'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-play-circle fa-2x" /><span className="ml-2 d-inline-block postop_5up">1:08</span></span>
                                                                      {/*<i class="far fa-pause-circle"></i> ----Pls use this for pause----*/}
                                                                    </span>
                                                                  </span> 
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                {/*<div class="d-table-cell w-70 align-top">
                                                  <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                                 </div>*/}
                                                                <div className="d-table-cell align-middle">
                                                                  <span style={{display: 'block'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="fas fa-record-vinyl fa-2x" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Start Recording</span></span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-stop-circle f_sz30" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Stop Recording</span></span>
                                                                      <span className="f_sz16">
                                                                        <span><i className="fas fa-spinner fa-spin f_sz24" /></span>
                                                                        <span className="ml-2 d-inline-block postop_3up">0:08</span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-play-circle fa-2x" /><span className="ml-2 d-inline-block postop_5up">1:08</span></span>
                                                                    </span>
                                                                  </span> 
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                {/*<div class="d-table-cell w-70 align-top">
                                                  <span class="attachrecord_icon bg_audio crsr_pntr"><i class="fas fa-microphone-alt"></i></span>
                                                 </div>*/}
                                                                <div className="d-table-cell align-middle">
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="fas fa-record-vinyl fa-2x" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Start Recording</span></span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'block'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-stop-circle f_sz30" /><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon">Stop Recording</span></span>
                                                                      <span className="f_sz16">
                                                                        <span><i className="fas fa-spinner fa-spin f_sz24" /></span>
                                                                        <span className="ml-2 d-inline-block postop_3up">0.08</span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span style={{display: 'none'}}>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr"><i className="far fa-play-circle fa-2x" /><span className="ml-2 d-inline-block postop_5up">1:08</span></span>
                                                                    </span>
                                                                  </span> 
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <span>
                                                                <span className="d-inline-block">
                                                                  <span><i className="fas fa-spinner fa-spin f_sz24 mr-2" /></span><span className="postop_5up">We are processing. Please wait...</span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <textarea className="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes" defaultValue={""} />
                                                            {/*<div class="attachmentbody_div">
                                              Lorem ipsum dolor sit amet consectetur adipiscing elit, urna consequat felis vehicula class ultricies mollis dictumst, aenean non a in donec nulla. Phasellus ante pellentesque erat cum risus consequat imperdiet aliquam, integer placerat et turpis mi eros nec lobortis taciti, vehicula nisl litora tellus ligula porttitor metus. 
                                              </div>*/}
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                {/*<div class="d-table-cell w-70 align-top">
                                                  <span class="attachrecord_icon bg_video crsr_pntr"><i class="fas fa-film"></i></span>
                                                 </div>*/}
                                                                <div className="d-table-cell align-middle">
                                                                  {/*<div class="f_sz16">
                                                  <span><i class="fas fa-spinner fa-spin f_sz24"></i></span>
                                                  <span class="ml-2">0.10</span>
                                                  </div>*/}
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <div className="videoWrapper">
                                                                      <video width={400} controls>
                                                                        <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                                                        <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg" />
                                                                        Your browser does not support HTML video.
                                                                      </video>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="fas fa-film bg_video attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                <div className="d-table-cell w-70 align-top">
                                                                  <span className="attachrecord_icon bg_video crsr_pntr"><i className="fas fa-film" /></span>
                                                                </div>
                                                                <div className="d-table-cell align-middle">
                                                                  <div className="f_sz16">
                                                                    <span><i className="fas fa-spinner fa-spin f_sz24" /></span>
                                                                    <span className="ml-2">0.10</span>
                                                                  </div>
                                                                  {/*<div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                   <div class="videoWrapper">
                                                      <video width="400" controls>
                                                        <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                                                        <source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg">
                                                        Your browser does not support HTML video.
                                                      </video>
                                                   
                                                   </div>
                                                  </div>*/}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div>
                                                        <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              {/*<span class="attachments_icon bg_documents mr-2"><i class="far fa-file-alt"></i></span>*/}
                                                              <span className="font-weight-bold">Documents:</span><br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file-pdf" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.pdf1</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file-word" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.doc</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file-image" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.jpg</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file-powerpoint" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.ppt</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file-excel" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.xls</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <div className="attachdoc_type"><i className="far fa-file" /></div>
                                                                  <div className="attachdoc_name">https://ourprofile.txt</div>
                                                                  <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div style={{display: 'none'}}>
                                                        <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="form-group row">
                                                              <label className="col-form-label pull-left pl-2 f_sz16">No. of Rewards</label>
                                                              <div className="pull-left ml-3">
                                                                <input type="text" className="form-control text-center txtbx_sm" defaultValue={10} disabled />
                                                              </div>
                                                            </div>   
                                                          </div>
                                                          <div className="timeline-footer">
                                                            {/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>*/}
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      {/* timeline item */}
                                                      <div style={{display: 'none'}}>
                                                        <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="form-group row">
                                                              <label className="col-form-label pull-left pl-2 f_sz16">No. of Rewards</label>
                                                              <div className="pull-left ml-3">
                                                                <input type="text" className="form-control text-center txtbx_sm" />
                                                              </div>
                                                            </div>   
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                            <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
                                                            {/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* END timeline item */}
                                                      <div>
                                                        <i className="fas fa-clock bg-gray" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="form-group mt-3 mb-4">
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                                                  <div className="psqrcode_entertagname_drpdwn_div">
                                                    <span className="allselectdropdwn">
                                                      <select className="selectpicker w-100 border-bottom f_sz16" multiple data-live-search="true">
                                                        <option selected="selected">--Select Tag--</option>
                                                        <option>Conference IIL 2020</option>
                                                        <option>Friends and Relatives</option>
                                                        <option>Innovation Strategy</option>
                                                        <option>Design and Development</option>
                                                      </select>
                                                    </span>
                                                    <div className="col-12 px-0 text-right pt-2"><span className="fmaroon f_sz13 psqrcode_addtagname crsr_pntr"><i className="fas fa-plus f_sz13 mr-2" onClick={this.psqrcode_addtagname}/>Add New Tag</span></div>
                                                  </div>
                                                  <div className="psqrcode_entertagname_txtbx_div" style={{display: 'none'}}>
                                                    <div className="input-group">
                                                      <input type="text" className="form-control" placeholder="Enter the new tag name" />
                                                      <div className="input-group-append">
                                                        <span className="input-group-text nobrdr_bg">
                                                          <span className="psqrcode_closeaddtagname crsr_pntr" onClick={this.psqrcode_closeaddtagname}>
                                                            <i className="fas fa-times f_sz13" />
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-3 pb-3 py-sm-2">
                                                <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Generate Code</a>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-right brdrbtm_dashed pb-2">
                                                <a><i className="fas fa-trash-alt mt-2 mr-3 f_sz16 crsr_pntr fmaroon" /></a>
                                                <a><i className="fas fa-plus-circle mt-2 f_sz17 crsr_pntr fmaroon" /></a>                              
                                              </div>
                                            </div>
                                            {/*Element to be Looped*/}
                                          </div>
                                          <div className="card-footer bg-transparent text-center pt-3 pb-5 py-sm-3">
                                            <a className="btn btn-black crsr_pntr m-2 btnpdng_sm">Save</a>
                                          </div>
                                        </div>
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="custom-tabs-psgmail" role="tabpanel" aria-labelledby="custom-tabs-psgmail-tab">
                                <div className="all_contentflex_div"> 
                                  <div className="card w-100 bx_shadow_sm"> 
                                    <div className="all_contentcntr_700">   
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <div className="gmail_notconnected_div">
                                            <div className="form-group row mt-5 mb-4">
                                              <h5 className="col-12 col-sm-12 text-center fmaroon">Connect with Gmail</h5>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <a><span className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm gmailconnect_clk" onClick={this.gmailconnect_clk}><i className="fab fa-google f_sz22 postop_2 mr-3" onClick={this.gmailconnect_clk}/>Connect</span></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="gmail_connected_div" style={{display: 'none'}}>
                                            <div className="form-group row mt-2 mb-4">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center mt-3">
                                                  <div><span className="btn btn-green1 btn-lg crsr_pntr m-2 btnpdng_sm"><i className="fab fa-google f_sz22 postop_2 mr-3" />Connected</span></div>
                                                  <div className="mb-2 fmaroon f_sz15">alexanderpierce@gmail.com</div>
                                                  <div className="mb-4"><span className="font-weight-semibold">Verified:</span> March 18, 2020<span className="ml-2">9.15am</span></div>
                                                  <div>
                                                    <a><span className="btn btn-black crsr_pntr m-2 btnpdng_sm">Reauthorize Connection</span></a>
                                                  </div>
                                                  <div>
                                                    <a><span className="crsr_pntr flightgrey">Disconnect</span></a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </div>
                              {/* <div class="tab-pane fade" id="custom-tabs-psrewards" role="tabpanel" aria-labelledby="custom-tabs-psrewards-tab">
                  
                 Needs to be done after the inputs are ready.
                 
                   
                </div>*/}
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </div>  
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content */}
    </div>  
    {/* /.content-wrapper */}
    {/* Main Footer */}
    <footer className="main-footer">
      {/* Default to the left */}
      <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved.
    </footer>
  </div>
</div>

			
		);
	}
}




function TagTxtbxFormGroup(props) {
	var rowNumber =props.rw+1;
	var ttl_length =props.ttllngth;
	
  return (
    <div className="form-group row mt-3 mb-4">
		 <div className="col-10 col-sm-10 col-md-10 col-lg-10">
			   <input type="text" className="form-control" placeholder="Enter the New Tag" value={props.value} onChange={props.TaginputChange}/>
		 </div>
		 <div className="col-2 col-sm-2 col-md-2 col-lg-2">
				{rowNumber > 1 ?
					<a className="crsr_pntr"><i className="fas fa-trash-alt mt-3 mr-3 f_sz16 crsr_pntr fmaroon" onClick={props.TagDelonClick}/></a>:null
				}
				{rowNumber == ttl_length ?
					<a className="crsr_pntr"><i className="fas fa-plus-circle mt-3 f_sz18 crsr_pntr fmaroon" onClick={props.TagPlsclick} /></a>:null                           
				}
		 </div>
	</div>
  );
}


function TagTxtbxAddButton(props) {
  return (
    <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={props.TagSaveclick} id="tag_saveBtb">Save</a>
  );
}


function AutoTxtRespnsFrmGrp(props) {
	var numbr = props.arealabel+1;
	var LastVlu = props.ttl_lgth;
	if(LastVlu == numbr) { var labl = ordinal_suffix_of(numbr)+ " onwards";}
	else { var labl = ordinal_suffix_of(numbr); }
	
	//if(numbr == "1"){ var labl = numbr+"st"; }
	//(numbr > 1 && numbr < 4 ){ var labl = numbr+"nd Onwards"; }
	//if(numbr > 3 && numbr < 13){ var labl = numbr+"th Onwards"; }
	//if(numbr > 12 && numbr < 19){ var labl = numbr+"th Onwards"; }										
  return (
  <>
    <div className="form-group row mt-2 mb-2">
		<label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label pdngtop_10"><span className="f_sz15">{labl}</span></label>
		<div className="col-12 col-sm-12 col-md-6 col-lg-8">
			<textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter Text Message" value={props.value} onChange={props.AutoTxtOnChngFrm}/>     
        </div>      
    </div>
    <div className="addicondiv1">
		<div className="w-100 d-inline-block text-right">
			<span className="fmaroon crsr_pntr autotxtresp_clk">
				<i className="far fa-trash-alt f_sz18 mr-3" onClick={props.AutoTxtDelFrm} hidden={numbr == 1 ? true:false}/>
				{LastVlu == numbr ?
					<i className="fas fa-plus-circle f_sz18" onClick={props.AutoTxtPlsFrm} /> : null
				}
			</span>
        </div>
    </div>
 </>
  );
}


function AutoRspnsSaveButton(props) {
  return (
    <a className="btn btn-black crsr_pntr m-2 btnpdng_sm" onClick={props.AutoSvePrps}>Save</a>
  );
}


function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}


export default Profile_settings1;