import firebase from 'firebase/app';
import 'firebase/messaging';
import axios from 'axios';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
export const initializeFirebase = () => {
  firebase.initializeApp({
     apiKey: "AIzaSyC6zefEdUgLpxkFIslSr3HOPQubvSjeyEI",
    authDomain: "redtie-receiver.firebaseapp.com",
    databaseURL: "https://redtie-receiver.firebaseio.com",
    projectId: "redtie-receiver",
    storageBucket: "redtie-receiver.appspot.com",
    messagingSenderId: "587825604663",
    appId: "1:587825604663:web:5e171204f2e21f4091b7b2",
    measurementId: "G-35FBGDQC5Q"
  });

  // use other service worker
  // navigator.serviceWorker
  //   .register('/my-sw.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {

    const messaging = firebase.messaging();

    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('user token: ', token);

    return token;
  } catch (error) {
    console.error(error);
  }
}

export const findingTokens = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    console.log('user_token', token);
    return token;
  } catch (error) {
    console.error(error);
  }
};

export const removeToken = async (user_id) =>{
  try {
    console.log("user_id",user_id);
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    console.log('user token: ', token);
    const notification_update_token = {
      user_id: user_id,
      device_token: token,
      apikey: API_KEY,
      secret: API_SECRET,
    };
    axios.post(API_CALL_ENDPOINT + '/notification/token/remove', notification_update_token).then((res) => {
      if (res?.data?.status == 'success') {
        window.location.reload(true);
      }
      if (res.data.error) {
      }
    });
    return true;
  } catch (error) {
    console.error("Removetoken error",error);
    window.location.reload(true);
    return false;
  }
}