import React, {Component} from 'react';
// import '../assets/css/innerpages_customstyle.css';
import $ from 'jquery';

export default class BusinessVerificationButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }
  show_business_verification() {
    $('.modal_business_verification').show();
  }
  render() {
    return (
      <a
        data-toggle="modal"
        data-target="#modal_business_verification"
        // onClick={() => this.show_business_verification()}
        className={this.props.class}
        style={this.props.style}
        >
        {this.props.name}
      </a>
    );
  }
}
