import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import PhoneInput from 'react-phone-input-2';
import PhoneInput1 from 'react-phone-input-2';
import PhoneInputnew from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import 'react-phone-input-2/lib/style.css';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import transparent from '../assets/img/transparent.png';
import alert_search from '../assets/img/alert_search.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import user7 from '../assets/img/user7-128x128.jpg';
import imgalert_contact from '../assets/img/alert_contact.png';
import redtie_logo from '../assets/img/redtie_logo.png';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

class Pack_Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Invoice_id: '',
      user_id: '',
      business_id: '',
      user_info: {},
      invoice_det: [],
    };
  }

  componentDidMount() {
    document.getElementById('spinner').style.display = 'none';

    this.start_fn();

    AsyncStorage.getItem('Invoice_id_cart').then((Invoice_id) => {
      if (Invoice_id) {
        this.setState({Invoice_id});
        console.log('Invoice Id : ' + Invoice_id);
      }
    });

    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User Id : ' + user_id);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });

    setTimeout(() => {
      const Invoice_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          invoice_id: this.state.Invoice_id,
        },
      };

      axios.get(API_CALL_ENDPOINT+'/booster/invoice/info', Invoice_details).then((res) => {
        console.log('/booster/invoice/info', res.data);
        if (res.data.result) {
          this.setState({
            user_info: res.data.result[0]?.user_id,
            invoice_det: res.data.result,
          });
        }
      });
    }, 100);
  }

  start_fn = () => {
    //Initialize Select2 Elements
    $('.select2').select2();
    $('.js-select2').select2({
      placeholder: 'Pick states',
      theme: 'bootstrap4',
    });
    //Initialize Select2 Elements
    $('.select2bs4').select2({
      theme: 'bootstrap4',
    });
  };

  render() {
    var moment = require('moment');

    return (
      <>
        <div style={{maxWidth: '960px', margin: '0 auto'}}>
          <table
            width="100%"
            cellPadding={10}
            cellSpacing={0}
            style={{fontFamily: 'Arial', fontSize: '16px', color: '#333333', lineHeight: '25px'}}>
            <tbody>
              <tr>
                <td valign="top">
                  <img src={redtie_logo} alt="Redtie" style={{maxWidth: '60px'}} />
                </td>
                <td valign="top">
                  <div style={{textAlign: 'right'}}>
                    <span style={{color: '#a4002a', fontSize: '20px'}}>
                      <strong>Redtie</strong>
                    </span>
                    <br />
                    +1 484-874-5535
                    <br />
                    getredtie@redtiemail.com
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td valign="top">
                  <div>
                    <div style={{fontSize: '18px', paddingBottom: '30px'}}>
                      <strong>Recipient</strong>
                    </div>
                    <span style={{fontSize: '17px', color: '#fca311'}}>
                      <strong>
                        {this.state.user_info?.first_name} {this.state.user_info?.last_name}
                      </strong>
                    </span>
                    <br />
                    {this.state.user_info?.email_address}
                    <br />
                  </div>
                </td>
                <td valign="top">
                  <div style={{textAlign: 'right'}}>
                    <div style={{fontSize: '34px', paddingBottom: '30px', color: '#fca311'}}>
                      <strong>Invoice</strong>
                    </div>
                    <div style={{paddingBottom: '20px'}}>
                      <span style={{fontSize: '18px'}}>
                        <strong>Invoice No</strong>
                      </span>
                      <br />
                      {this.state.invoice_det[0]?.invoice}
                    </div>
                    <div>
                      <span style={{fontSize: '18px'}}>
                        <strong>Invoice Date</strong>
                      </span>
                      <br />
                      {/* created date */}
                      {moment(this.state.invoice_det[0]?.updated_date).format('MM/DD/YYYY')}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table width="100%" cellPadding={10} cellSpacing={0}>
                    <tbody>
                      <tr>
                        <th align="left" style={{borderBottom: '1px solid #dee2e6'}}>
                          S.No
                        </th>
                        <th align="left" style={{borderBottom: '1px solid #dee2e6'}}>
                          Profile
                        </th>
                        <th align="left" style={{borderBottom: '1px solid #dee2e6'}}>
                          Pack
                        </th>
                        <th align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                          Price
                        </th>
                        <th align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                          Quantity
                        </th>
                        <th align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                          Total Count
                        </th>
                        <th align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                          Amount
                        </th>
                      </tr>
                      {this.state.invoice_det.map((invoice, index) => {
                        return (
                          <tr>
                            <td style={{borderBottom: '1px solid #dee2e6'}}>{index}</td>
                            <td style={{borderBottom: '1px solid #dee2e6'}}>{invoice.business_id?.business_name}</td>
                            <td style={{borderBottom: '1px solid #dee2e6'}}>{invoice.master_pack_id?.pack_name}</td>
                            <td align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                              ${invoice.master_pack_id?.dollar}
                            </td>
                            <td align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                              {invoice.pack_quantity}
                            </td>
                            <td align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                              {invoice.order_data_count}
                            </td>
                            <td align="right" style={{borderBottom: '1px solid #dee2e6'}}>
                              ${invoice.order_doller}
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td style={{borderBottom: '1px solid #dee2e6', fontSize: 20}}>
                          <strong>Total</strong>
                        </td>
                        <td align="right" style={{borderBottom: '1px solid #dee2e6', fontSize: 30, color: '#fca311'}}>
                          <strong>${this.state.invoice_det[0]?.invoice_dollar}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default Pack_Invoice;
