import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import queryString from 'query-string';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';

import $ from 'jquery';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

export default class NotificationRedirect extends Component {
  // export default function CreateProfile1() {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      errors: [],
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      business_id: '',
      user_email: '',
      user_id: '',
      test_mode: '',
      test_mode_number: '',
      limit: 7,
      region: '',
      isLoading: false,
      run: false,
      region_search_val: '',
      temp_business_id: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  logout() {
    console.log('localStorage1', localStorage);
    localStorage.removeItem('token');
    localStorage.removeItem('notification_token');
    localStorage.setItem('notification_token', 'notification_token_removed');
    // console.log('loggedout');
    //window.location.reload(true);

    // this.props.history.push('/signin')
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    let url = this.props.location.search;
    let params = queryString.parse(url);

    const config = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/config', config)
      .then((res) => {
        console.log(res.data.result.test_mode.type);
        this.setState({test_mode: res.data.result.test_mode.type});
        this.setState({test_mode_number: res.data.result.test_mode.phone_number});
      })
      .catch((error) => {
        console.log(error);
      });
    // useScript('../assets/plugins/bootstrap/js/bootstrap.bundle.min.js');
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(this.state.user_id);
        const myArray = params?.user_id.split('-');
        console.log('myArray', myArray);
        if (myArray.includes(user_id)) {
          if (params?.user_id && params?.bid && params?.cid) {
            AsyncStorage.setItem('user_id', user_id);
            AsyncStorage.setItem('business_id', params?.bid);
            this.setState({
              business_id: params?.bid,
            });
            this.props.history.push('/board?user_id=' + user_id + '&bid=' + params?.bid + '&cid=' + params.cid);
          }
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('notification_token');
          localStorage.setItem('notification_token', 'notification_token_removed');
          window.location.reload(true);
        }
      }
    });
    AsyncStorage.getItem('temp_business_id').then((temp_business_id) => {
      if (temp_business_id) {
        this.setState({temp_business_id});
        console.log('temp_business_id code');
        console.log(temp_business_id);
      }
    });
    // if(this.state.user_id){
    setTimeout(() => {}, 500);

    // }
  }

  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(this.state.user_id)
    const {errors} = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }
    return (
      <>
        <Helmet>
          <title>Redtie - Redirect</title>
        </Helmet>
        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="processing_div">
                <div className="w-100 f_sz18">
                  <i className="fas fa-spinner fa-spin fa-3x mb-3" />
                  <br />
                  Please wait
                  <br /> do not refresh this page or press the back button.
                  <div id="payment_content"></div>
                </div>
              </div>
              {/* /.content-header */}
              {/* Main content */}
            </div>
            {/* /.content-wrapper */}
            {/* Main Footer */}
            {/* <Footer_component /> */}
          </div>

          {/* ./wrapper */}
        </div>
      </>
    );
  }
}
