import React, { useEffect, useState, Component } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import AsyncStorage from '@callstack/async-storage';
import { detect } from 'detect-browser';

import { Helmet } from 'react-helmet';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, { loadCSS, removeCSS } from '../utils/useScript';
const browser_name = detect();
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;


const Gumroad = (props) => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let saleId = params.get("sale_id");

  const [state, setState] = React.useState("");
  const [businessId, setBusinessId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");

  function redirectTo() {
    setTimeout(() => {
      props.history.push("/create-profile1");
    }, 3000);
  }


  useEffect(() => {

    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');

    document.getElementById("spinner").style.display = "none";

    setTimeout(() => {
      axios
        .get('https://api.ipify.org/?format=json')
        .then((res) => {
          if (res.data) {
            console.log('Ip address', res.data.ip);
            console.log("navigator.userAgent", navigator.userAgent);
            console.log("browser_name.name", browser_name.name);

            AsyncStorage.setItem('user_ip_location', res?.data?.ip);

            axios.post(API_CALL_ENDPOINT + '/user/gumroad/verify', {
              "sale_id": saleId,
              ip_address: res?.data?.ip,
              country: res?.data?.country,
              agent: navigator?.userAgent,
              browser: browser_name?.name,
            })
              .then(function (response) {
                // console.log(response);
                let status = response.data.status;
                setState(status);
                if (status == "success") {
                  let businessId = response.data.business_id;
                  let userId = response.data.user_id;
                  let businessName = response.data.business_name;
                  console.log({ businessId, })

                  console.log({ businessId, userId, businessName });
                  setBusinessId(businessId);
                  setUserId(userId);
                  AsyncStorage.setItem('user_id', userId);
                  AsyncStorage.setItem('business_id', businessId);
                  // AsyncStorage.setItem('user_email', "porselvan+10@blemediasolutions.com");
                  AsyncStorage.setItem('token', "loggedIn");
                  AsyncStorage.setItem('business_name', businessName);
                  redirectTo();
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }, 10000)

  }, []);

  useEffect(() => {
    console.log({ state, businessId, userId, userEmail });
  }, [state, businessId, userId, userEmail]);

  return (

    <>
      <Helmet>
        <title>Redtie - Gumroad</title>
      </Helmet>
      {/* <div style={
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100wh"
        }
      }>
        <i class="fas fa-spinner fa-spin" ></i><br />
        <p style={{ margin: 0 }}> Please wait we are verifying your payment. </p>
      </div> */}
      <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
        <div className="wrapper">
          {/* /.navbar */}
          {/* Main Sidebar Container */} {/*Can be used when needed*/}
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="processing_div">
              <div className="w-100 f_sz18">
                <i className="fas fa-spinner fa-spin fa-3x mb-3" />
                <br />
                Please wait we are verifying payment details.
                <br /> Do not refresh this page or press the back button.
                <div id="payment_content"></div>
              </div>
            </div>
            {/* /.content-header */}
            {/* Main content */}
          </div>
          {/* /.content-wrapper */}
          {/* Main Footer */}
          {/* <Footer_component /> */}
        </div>

        {/* ./wrapper */}
      </div>



    </>

  )


}
export default Gumroad;
