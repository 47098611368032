import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import signin_account from '../assets/img/signin_account.png';
import redtie_google_btn_sm from '../assets/img/redtie_google_btn_sm.png';
import redtie_microsoft_btn_sm from '../assets/img/redtie_microsoft_btn_sm.png';
// import redtie_yahoo_btn_sm from '../assets/img/redtie_yahoo_btn_sm.png';
import MicrosoftLogin from 'react-microsoft-login';
import {detect} from 'detect-browser';
import store from 'store2';
// axios.defaults.baseURL = "https://stagingrv4.redtie.co:1221";
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const browser_name = detect();
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
function validate(password, user_email) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (password.length === 0 && user_email.length === 0) {
    errors.push("Password and email fields can't be empty");
    return errors;
  }
  if (user_email.length === 0) {
    errors.push("Email can't be empty");
    return errors;
  }
  if (password.length === 0) {
    errors.push("Password can't be empty");
    return errors;
  }

  return errors;
}

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.onChangepassword = this.onChangepassword.bind(this);
    this.onChangeemail = this.onChangeemail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.authHandler = this.authHandler.bind(this);
    this.state = {
      user_email: '',
      password: '',
      loggedIn,
      success: [],
      errors: [],
      passwordtype: 'password',
      isLoading: false,
      ip_location: {},
      user_agent: '',
      browser_name: '',
      slack_contact_id: '',
      slack_user_id: '',
      slack_business_id: '',
      plan_name: '',
    };
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input',
    });
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '357140609134-0mcqna5qmqqk4dv1l6uectm1dhtuns12.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
    axios
      .get('https://www.cloudflare.com/cdn-cgi/trace%27')
      .then((res) => {
        let data = res.data
          .trim()
          .split('\n')
          .reduce(function (obj, pair) {
            pair = pair.split('=');
            return (obj[pair[0]] = pair[1]), obj;
          }, {});
        console.log('data', data);
        if (data) {
          this.setState({
            ip_location: data,
            user_agent: data.uag,
            browser_name: browser_name.name,
          });
          AsyncStorage.setItem('user_ip_location', data.ip);
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // axios
    //   .get('https://api.ipify.org/?format=json')
    //   .then((res) => {
    //     if (res.data) {
    //       console.log('Ip address', res.data.ip);
    //       this.setState({
    //         ip_location: res.data,
    //         user_agent: navigator.userAgent,
    //         browser_name: browser_name.name,
    //       });
    //       AsyncStorage.setItem('user_ip_location', res?.data?.ip);
    //     }
    //     if (res.data.error) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log('params', params);
    if (params?.signin === 'true' && params?.user) {
      AsyncStorage.setItem('user_email', params?.email);
      AsyncStorage.setItem('user_id', params?.user);
      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: params?.user,
        },
      };

      axios
        .get(API_CALL_ENDPOINT+'/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          // console.log(res.data.result[0].phone_number.length)
          if (res.data.status === 'success') {
            var array = res.data.result;
            var arrayLength = res.data.result.length;
            for (var i = 0; i < arrayLength; i++) {
              if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                this.businessInfo(res.data.result[i]._id);
                if (
                  res.data.result[i].phone_number.length === 0

                  // && res.data.result[i].business_name.length === 0
                ) {
                  console.log('phone empty');
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                  if (this.state.plan_name === 'Bring your own messaging service provider') {
                    this.props.history.push('/createProfileServiceProvider');
                  } else {
                  this.props.history.push('/create-profile1');
                }
                }
                if (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length === 0) {
                  console.log('phone not empty');
                  console.log(res.data.result[i].phone_number);
                  AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                  if (this.state.plan_name === 'Bring your own messaging service provider') {
                    this.props.history.push('/createProfileServiceProvider');
                  } else {
                  this.props.history.push('/create-profile2');
                }
                }
                if (
                  (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                  !res.data.result[i].subscription_id
                ) {
                  console.log('phone not empty');
                  console.log(res.data.result[i].phone_number);
                  AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                  AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                  // window.location.reload(true);
                  // setTimeout(() => {
                  //   this.props.history.push('/board');
                  // }, 500);
                 setTimeout(
                    () => this.props.history.push('/dashboard'), 
                    1500
                  );
                }
              } else if (
                (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                !res.data.result[i].subscription_id
              ) {
                console.log('phone not empty');
                console.log(res.data.result[i].phone_number);
                AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                AsyncStorage.setItem('business_id', res.data.result[i]._id);
                AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                //alert("2");
                // window.location.reload(true);
                // setTimeout(() => {
                //   this.props.history.push('/board');
                // }, 500);
                setTimeout(
                  () => this.props.history.push('/dashboard'), 
                  1500
                );
              }
            }
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if(error.response){
            if (error.response.data.status === 'no_record') {
              this.props.history.push('/create-profile');
            }
          }
        });
      localStorage.setItem('token', 'loggedIn');
      this.setState({loggedIn: true});
    }
    if (params?.signup_type === 'gmail' && params?.uid) {
      AsyncStorage.setItem('user_email', params?.email);
      AsyncStorage.setItem('user_id', params?.uid);
      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: params?.uid,
        },
      };

      axios
        .get(API_CALL_ENDPOINT+'/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          // console.log(res.data.result[0].phone_number.length)
          if (res.data.status === 'success') {
            var array = res.data.result;
            var arrayLength = res.data.result.length;
            for (var i = 0; i < arrayLength; i++) {
              if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                this.businessInfo(res.data.result[i]._id);
                if (
                  res.data.result[i].phone_number?.length === 0 &&
                  res.data.result[i].subscription_id?.length === 0 &&
                  res.data.result[i].business_name?.length === 0
                ) {
                  document.body.classList.remove('sidebar-mini');
                  console.log('phone empty');
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                  if (this.state.plan_name === 'Bring your own messaging service provider') {
                    this.props.history.push('/createProfileServiceProvider');
                  } else {
                  this.props.history.push('/create-profile1');
                }
                }
                if (res.data.result[i].phone_number?.length === 0 && res.data.result[i].subscription_id?.length !== 0) {
                  document.body.classList.remove('sidebar-mini');
                  console.log('phone empty');
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                  if (this.state.plan_name === 'Bring your own messaging service provider') {
                    this.props.history.push('/createProfileServiceProvider');
                  } else {
                  this.props.history.push('/create-profile1');
                }
                }
                if (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length === 0) {
                  console.log('phone not empty');
                  console.log(res.data.result[i].phone_number);
                  AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                  if (this.state.plan_name === 'Bring your own messaging service provider') {
                    this.props.history.push('/createProfileServiceProvider');
                  } else {
                  this.props.history.push('/create-profile2');
                }
                }
                if (
                  (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                  !res.data.result[i].subscription_id
                ) {
                  console.log('phone not empty');
                  console.log(res.data.result[i].phone_number);
                  AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                  AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                  AsyncStorage.setItem('business_id', res.data.result[i]._id);
                  AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                  //alert("3");
                  // window.location.reload(true);
                  // setTimeout(() => {
                  //   this.props.history.push('/board');
                  // }, 500);
                  setTimeout(
                    () => this.props.history.push('/dashboard'), 
                    1500
                  );
                }
              } else if (
                (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                !res.data.result[i].subscription_id
              ) {
                console.log('phone not empty');
                console.log(res.data.result[i].phone_number);
                AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                AsyncStorage.setItem('business_id', res.data.result[i]._id);
                AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                //alert("4");
                // window.location.reload(true);
                // setTimeout(() => {
                //   this.props.history.push('/board');
                // }, 500);
                setTimeout(
                  () => this.props.history.push('/dashboard'), 
                  1500
                );
              }
            }
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if(error.response){
            if (error.response.data.status === 'no_record') {
              this.props.history.push('/create-profile');
            }
          }
        });
      localStorage.setItem('token', 'loggedIn');
      this.setState({loggedIn: true});
    }
    AsyncStorage.getItem('slack_user_id').then((slack_user_id) => {
      if (slack_user_id) {
        this.setState({slack_user_id});
        console.log('slack_user_id');
        console.log(slack_user_id);
      }
    });
    AsyncStorage.getItem('slack_business_id').then((slack_business_id) => {
      if (slack_business_id) {
        this.setState({slack_business_id});
        console.log('slack_business_id');
        console.log(slack_business_id);
      }
    });
    AsyncStorage.getItem('slack_contact_id').then((slack_contact_id) => {
      if (slack_contact_id) {
        this.setState({slack_contact_id});
        console.log('slack_contact_id');
        console.log(slack_contact_id);
      }
    });
    this.googleSDK();
  }
  prepareLoginButton = () => {
    // console.log(this.refs.googleLoginBtn);

    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
        const userAuth = {
          email_address: profile.getEmail(),
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          signup_type: 'gmail',
          ip_address: this.state?.ip_location?.ip,
          country: this.state?.ip_location?.country_name,
          agent: this.state?.user_agent,
          browser: this.state?.browser_name,
        };

        axios
          .post(API_CALL_ENDPOINT + '/onboard/user/signup', userAuth)
          .then((res) => {
            console.log(res.data);
            localStorage.setItem('token', 'loggedIn');
            this.setState({loggedIn: true});
            const success = [];
            success.push('Gmail login Successfull');
            console.log(this.state.success);
            this.setState({success});
            // window.location.reload(true);
            if (res.data.status == 'success') {
              var success_message = res.data.message;
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              if (res.data.result?.skip_simplified == '0') {
                AsyncStorage.setItem('user_first_time', true);
                const user_update_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: res.data.result.user_id,
                  setup: 'simplified',
                  skip_simplified: '1',
                };
                axios.post(API_CALL_ENDPOINT + '/user/update', user_update_details).then((res) => {
                  console.log(res);
                  console.log(res.data.message);

                  if (res.data.status == 'success') {
                  }
                });
              }else{
                AsyncStorage.setItem('user_first_time', '');
              }
              const businessList = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.user_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT+'/business/list', businessList)
                .then((res) => {
                  console.log(res.data);
                  // console.log(res.data.result[0].phone_number.length)
                  if (res.data.status === 'success') {
                    var array = res.data.result;
                    var arrayLength = res.data.result.length;
                    for (var i = 0; i < arrayLength; i++) {
                      if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                        this.businessInfo(res.data.result[i]._id);
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length === 0 &&
                          res.data.result[i].business_name?.length === 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile1');
                        }
                        }
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length !== 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile1');
                        }
                        }
                        if (
                          res.data.result[i].phone_number.length > 0 &&
                          res.data.result[i].business_name.length === 0
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile2');
                        }
                        }
                        if (
                          (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                          !res.data.result[i].subscription_id
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                         // alert("5");
                          // window.location.reload(true);
                          // setTimeout(() => {
                          //   this.props.history.push('/board');
                          // }, 500);
                          if(success_message == "User has been created successfully."){
                            AsyncStorage.setItem('user_first_time', true);
                            setTimeout(() => this.props.history.push('/board'), 1500);
                          }else{
                            setTimeout(() => this.props.history.push('/dashboard'), 1500);
                        }
                        }
                      } else if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        //alert("6");
                        // window.location.reload(true);
                        // setTimeout(() => {
                        //   this.props.history.push('/board');
                        // }, 500);
                        if(success_message == "User has been created successfully."){
                            AsyncStorage.setItem('user_first_time', true);
                            setTimeout(() => this.props.history.push('/board'), 1500);
                          }else{
                            setTimeout(() => this.props.history.push('/dashboard'), 1500);
                          }
                      }
                    }
                  }
                  if (res.data.status === 'no_record') {
                    this.props.history.push('/create-profile');
                  }
                })
                .catch((error) => {
                  if(error.response){
                    if (error.response.data.status === 'no_record') {
                      this.props.history.push('/create-profile');
                    }
                  }
                });
              localStorage.setItem('token', 'loggedIn');
              this.setState({loggedIn: true});

              // setTimeout(() => {
              //   this.setState({ isLoading: false });
              //   this.props.history.push('/create-profile')
              //   // document.getElementById('error').style.display='none'
              // }, 3000)
            }
            console.log(res.data);
            // alert("opt has been resent successfully")
          })
          .catch((error) => {
            if (error.response) {
              const errors = [];
              errors.push(error.response.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 4000);
            }
          });
      },
      (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      },
    );
  };
  authHandler = (err, data) => {
    // const email_address = "";
    // console.log(err, data);
    try {
      if (err) {
        const errors = [];
        console.log(err);
        errors.push('Please try again');
        this.setState({errors});
        setTimeout(() => {
          this.setState({errors: []});
          // document.getElementById("error").remove();
          // document.getElementById('error').style.display = 'none'
        }, 3000);
        return errors;
      } else {
        const userAuth = {
          email_address: data.authResponseWithAccessToken.account.userName,
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          signup_type: 'microsoft',
          ip_address: this.state?.ip_location?.ip,
          country: this.state?.ip_location?.country_name,
          agent: this.state?.user_agent,
          browser: this.state?.browser_name,
        };

        axios
          .post(API_CALL_ENDPOINT + '/onboard/user/signup', userAuth)
          .then((res) => {
            console.log(res.data);
            localStorage.setItem('token', 'loggedIn');
            this.setState({loggedIn: true});
            if (res.data.status == 'success') {
              const success = [];
              success.push('Microsoft Login successfully');
              console.log(this.state.success);
              this.setState({success});
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              var success_message = res.data.message;
              if (res.data.result?.skip_simplified == '0') {
                AsyncStorage.setItem('user_first_time', true);
                const user_update_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: res.data.result.user_id,
                  setup: 'simplified',
                  skip_simplified: '1',
                };
                axios.post(API_CALL_ENDPOINT + '/user/update', user_update_details).then((res) => {
                  console.log(res);
                  console.log(res.data.message);

                  if (res.data.status == 'success') {
                  }
                });
              }else{
                AsyncStorage.setItem('user_first_time', '');
              }
              const businessList = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.user_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT+'/business/list', businessList)
                .then((res) => {
                  console.log(res.data);
                  // console.log(res.data.result[0].phone_number.length)
                  if (res.data.status === 'success') {
                    var array = res.data.result;
                    var arrayLength = res.data.result.length;
                    for (var i = 0; i < arrayLength; i++) {
                      if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                        this.businessInfo(res.data.result[i]._id);
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length === 0 &&
                          res.data.result[i].business_name?.length === 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile1');
                        }
                        }
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length !== 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile1');
                        }
                        }
                        if (
                          res.data.result[i].phone_number.length > 0 &&
                          res.data.result[i].business_name.length === 0
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                          this.props.history.push('/create-profile2');
                        }
                        }
                        if (
                          (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                          !res.data.result[i].subscription_id
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          //alert("7");
                          // window.location.reload(true);
                          // setTimeout(() => {
                          //   this.props.history.push('/board');
                          // }, 500);
                          if(success_message == "User has been created successfully."){
                            AsyncStorage.setItem('user_first_time', true);
                            setTimeout(() => this.props.history.push('/board'), 1500);
                          }else{
                            setTimeout(() => this.props.history.push('/dashboard'), 1500);
                        }
                        }
                      } else if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        
                        // window.location.reload(true);
                        // setTimeout(() => {
                        //   this.props.history.push('/board');
                        // }, 500);
                        if(success_message == "User has been created successfully."){
                          AsyncStorage.setItem('user_first_time', true);
                          setTimeout(() => this.props.history.push('/board'), 1500);
                        }else{
                          setTimeout(() => this.props.history.push('/dashboard'), 1500);
                      }
                    }
                  }
                  }
                  if (res.data.status === 'no_record') {
                    this.props.history.push('/create-profile');
                  }
                })
                .catch((error) => {
                  if(error.response){
                    if (error.response.data.status === 'no_record') {
                      this.props.history.push('/create-profile');
                    }
                  }
                });
              localStorage.setItem('token', 'loggedIn');
              this.setState({loggedIn: true});

              // setTimeout(() => {
              //   this.setState({ isLoading: false });
              //   this.props.history.push('/create-profile')
              //   // document.getElementById('error').style.display='none'
              // }, 3000)
            }
            console.log(res.data);
            // alert("opt has been resent successfully")
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  onChangepassword(e) {
    this.setState({password: e.target.value});
  }
  onChangeemail(e) {
    this.setState({user_email: e.target.value});
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  
    onSubmit(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {password, user_email} = this.state;
    if (!this.state.login) {
      const errors = validate('*********', user_email);
      console.log('errors', errors);
      if (errors.length > 0) {
        this.setState({errors});
        this.setState({isLoading: false});
        setTimeout(() => {
          this.setState({errors: []});
          // this.props.history.push('/')
          // document.getElementById('error').style.display='none'
        }, 3000);
        return;
      } else {
        const userObject = {
          email_address: this.state.user_email,
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          // password: this.state.password,
        };

        axios
          .post(API_CALL_ENDPOINT + '/onboard/login/check', userObject)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              if (res.data.result.type === 'otp') {
                AsyncStorage.setItem('user_email', this.state.user_email);
                AsyncStorage.setItem('user_id', res.data.result._id);
                this.props.history.push('/SignUp-Emailcode');
              } else if (res.data.result.type === 'password') {
                this.setState({isLoading: false, login: true});
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status == 'no_record') {
                this.setState({isLoading: false});
                const errors = [];
                errors.push('Sorry We cant Find You Try to Signup');
                console.log(this.state.errors);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // this.props.history.push('/')
                  // document.getElementById('error').style.display='none'
                }, 3000);
              }
              if (error.response.data.error) {
                if (error.response.data.error.error_type == 'password_mismatch') {
                  this.setState({isLoading: false});
                  const errors = [];
                  errors.push('The password that you entered is incorrect');
                  console.log(this.state.errors);
                  this.setState({errors});
                  setTimeout(() => {
                    this.setState({errors: []});
                    // this.props.history.push('/')
                    // document.getElementById('error').style.display='none'
                  }, 3000);
                }
                if (error.response.data.error.error_type === 'invalid_email') {
                  const errors = [];
                  errors.push(error.response.data.error.message);
                  console.log(this.state.errors);
                  this.setState({errors});
                  console.log(this.state.errors);
                  this.setState({isLoading: false});

                  setTimeout(() => {
                    this.setState({errors: []});
                    // document.getElementById("error").remove();
                    // document.getElementById('error').style.display = 'none'
                  }, 3000);
                  return errors;
                }
              }
            }
          });
      }
    } else {
    const errors = validate(password, user_email);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      const userObject = {
        email_address: this.state.user_email,
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        password: this.state.password,
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/auth', userObject)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (res.data.result.status === 'inactive' || res.data.result.status === 'deleted') {
              this.setState({isLoading: false});
              const errors = [];
              errors.push(
                res.data?.result?.signup_type === 'appsumo'
                  ? 'Your AppSumo deal expired, please contact us @ support@getredtie.com'
                  : 'Please contact our administrator at support@getredtie.com or call +1 415-886-7167',
              );
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 5000);
            } else {
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result._id);
              if (res?.data?.result?._id === this.state.slack_user_id) {
                localStorage.setItem('token', 'loggedIn');
                this.setState({loggedIn: true});

                this.props.history.push(
                  `/board?uid=${this.state.slack_user_id}&bid=${this.state.slack_business_id}&cid=${this.state.slack_contact_id}`,
                );
                //window.location.reload(true);
              } else {
                const businessList = {
                  params: {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: res?.data?.result?._id,
                  },
                };

                axios
                  .get(API_CALL_ENDPOINT + '/business/list', businessList)
                  .then((res) => {
                    console.log(res.data);
                    // console.log(res.data.result[0].phone_number.length)
                    if (res.data.status === 'success') {
                      // window.location.reload(true);
                      var array = res.data.result;
                      var arrayLength = res.data.result.length;
                      for (var i = 0; i < arrayLength; i++) {
                        console.log('stephansam+64@captechin.com', i);
                        if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                          if (
                            res.data.result[i].phone_number?.length === 0 &&
                            res.data.result[i].subscription_id?.length === 0 &&
                            res.data.result[i].business_name?.length === 0
                          ) {
                            console.log('phone empty11');
                            document.body.classList.remove('sidebar-mini');
                            console.log('phone empty');
                            AsyncStorage.setItem('business_id', res.data.result[i]._id);
                            AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                            this.props.history.push('/create-profile1');
                          }
                          if (
                            res.data.result[i].phone_number?.length === 0 &&
                            res.data.result[i].subscription_id?.length !== 0
                          ) {
                            console.log('phone empty1122');
                            document.body.classList.remove('sidebar-mini');
                            console.log('phone empty');
                            AsyncStorage.setItem('business_id', res.data.result[i]._id);
                            AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                            this.props.history.push('/create-profile1');
                          }
                          if (
                            res.data.result[i].phone_number.length > 0 &&
                            res.data.result[i].business_name.length === 0
                          ) {
                            console.log('phone empty1133');
                            console.log('phone not empty');
                            console.log(res.data.result[i].phone_number);
                            AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                            AsyncStorage.setItem('business_id', res.data.result[i]._id);
                            AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                            this.props.history.push('/create-profile2');
                          }
                          if (
                            (res.data.result[i].phone_number.length > 0 &&
                              res.data.result[i].business_name.length > 0) ||
                            !res.data.result[i].subscription_id
                          ) {
                            console.log('phone empty1144');
                            console.log('phone not empty');
                            console.log(res.data.result[i].phone_number);
                            AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                            AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                            AsyncStorage.setItem('business_id', res.data.result[i]._id);
                            AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                            localStorage.setItem('token', 'loggedIn');
                            this.setState({loggedIn: true});
                            // window.location.reload(true);

                            setTimeout(() => {
                              this.props.history.push('/dashboard');
                            }, 1500);
                          }
                        } else if (
                          (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                          !res.data.result[i].subscription_id
                        ) {
                          console.log('phone empty1155');
                          // alert('phone not empty8');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          //  window.location.reload(true);
                          //  setTimeout(() => {
                          //     this.props.history.push('/dashboard');
                          //  }, 500);
                          setTimeout(() => this.props.history.push('/dashboard'), 1500);
                        } else {
                          console.log('Hello Test', i);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          //  window.location.reload(true);
                          //  setTimeout(() => {
                          //     this.props.history.push('/dashboard');
                          //  }, 500);
                          setTimeout(() => this.props.history.push('/dashboard'), 1500);
                        }
                      }
                    }
                    if (res.data.status === 'no_record') {
                      this.props.history.push('/create-profile');
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.status === 'no_record') {
                        this.props.history.push('/create-profile');
                      }
                    }
                  });

                localStorage.setItem('token', 'loggedIn');
                this.setState({loggedIn: true});

                // setTimeout(() => {
                //   this.setState({ isLoading: false });
                //   this.props.history.push('/create-profile')
                //   // document.getElementById('error').style.display='none'
                // }, 3000)
              }
            }
          }
          if (res.data.status) {
            if (res.data.status == 'no_record') {
              this.setState({isLoading: false});
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup');
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
          }
          if (res.data.error) {
            if (res.data.error.error_type == 'password_mismatch') {
              this.setState({isLoading: false});
              const errors = [];
              errors.push('The password that you entered is incorrect');
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
            if (res.data.error.error_type === 'invalid_email') {
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              console.log(this.state.errors);
              this.setState({isLoading: false});

              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              this.setState({isLoading: false});
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup');
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
            if (error.response.data.error) {
              if (error.response.data.error.error_type == 'password_mismatch') {
                this.setState({isLoading: false});
                const errors = [];
                errors.push('The password that you entered is incorrect');
                console.log(this.state.errors);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // this.props.history.push('/')
                  // document.getElementById('error').style.display='none'
                }, 3000);
              }
              if (error.response.data.error.error_type === 'invalid_email') {
                const errors = [];
                errors.push(error.response.data.error.message);
                console.log(this.state.errors);
                this.setState({errors});
                console.log(this.state.errors);
                this.setState({isLoading: false});

                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          }
        });
    }
  }
  }

  businessInfo = (bid) => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: bid,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({plan_name: res.data?.result?.business?.plan_id?.plan_name});
        } else {
          this.setState({plan_name: ''});
        }
      })
      .catch((error) => {});
  };

  // onSubmit(e) {
  //   this.setState({isLoading: true});
  //   e.preventDefault();
  //   const {password, user_email} = this.state;

  //   const errors = validate(password, user_email);
  //   if (errors.length > 0) {
  //     this.setState({errors});
  //     this.setState({isLoading: false});
  //     setTimeout(() => {
  //       this.setState({errors: []});
  //       // this.props.history.push('/')
  //       // document.getElementById('error').style.display='none'
  //     }, 3000);
  //     return;
  //   } else {
  //     const userObject = {
  //       email_address: this.state.user_email,
  //       apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //       secret: 'R1eqD2twI3E4',
  //       password: this.state.password,
  //     };

  //     axios
  //       .post(API_CALL_ENDPOINT+'/user/auth', userObject)
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.status === 'success') {
  //           if (res.data.result.status === 'inactive' || res.data.result.status === 'deleted') {
  //             this.setState({isLoading: false});
  //             const errors = [];
  //             errors.push(
  //               res.data?.result?.signup_type === 'appsumo'
  //                 ? 'Your AppSumo deal expired, please contact us @ support@getredtie.com'
  //                 : 'Please contact our administrator at support@getredtie.com or call +1 415-886-7167',
  //             );
  //             console.log(this.state.errors);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // this.props.history.push('/')
  //               // document.getElementById('error').style.display='none'
  //             }, 5000);
  //           } else {
  //             AsyncStorage.setItem('user_email', res.data.result.email_address);
  //             AsyncStorage.setItem('user_id', res.data.result._id);
  //             if (res?.data?.result?._id === this.state.slack_user_id) {
  //               localStorage.setItem('token', 'loggedIn');
  //               this.setState({loggedIn: true});

  //               this.props.history.push(
  //                 `/board?uid=${this.state.slack_user_id}&bid=${this.state.slack_business_id}&cid=${this.state.slack_contact_id}`,
  //               );
  //              // window.location.reload(true);
  //             } else {
  //               const businessList = {
  //                 params: {
  //                   apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //                   secret: 'R1eqD2twI3E4',
  //                   user_id: res?.data?.result?._id,
  //                 },
  //               };

  //               axios
  //                 .get(API_CALL_ENDPOINT+'/business/list', businessList)
  //                 .then((res) => {
  //                   console.log(res.data);
  //                   // console.log(res.data.result[0].phone_number.length)
  //                   if (res.data.status === 'success') {
  //                    // window.location.reload(true);
  //                     var array = res.data.result;
  //                     var arrayLength = res.data.result.length;
  //                     for (var i = 0; i < arrayLength; i++) {
  //                       if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
  //                         this.businessInfo(res.data.result[i]._id);
  //                         if (
  //                           res.data.result[i].phone_number?.length === 0 &&
  //                           res.data.result[i].subscription_id?.length === 0 &&
  //                           res.data.result[i].business_name?.length === 0
  //                         ) {
  //                           document.body.classList.remove('sidebar-mini');
  //                           console.log('phone empty');
  //                           AsyncStorage.setItem('business_id', res.data.result[i]._id);
  //                           AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

  //                           if (this.state.plan_name === 'Bring your own messaging service provider') {
  //                             this.props.history.push('/createProfileServiceProvider');
  //                           } else {
  //                           this.props.history.push('/create-profile1');
  //                         }
  //                         }
  //                         if (
  //                           res.data.result[i].phone_number?.length === 0 &&
  //                           res.data.result[i].subscription_id?.length !== 0
  //                         ) {
  //                           document.body.classList.remove('sidebar-mini');
  //                           console.log('phone empty');
  //                           AsyncStorage.setItem('business_id', res.data.result[i]._id);
  //                           AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

  //                           if (this.state.plan_name === 'Bring your own messaging service provider') {
  //                             this.props.history.push('/createProfileServiceProvider');
  //                           } else {
  //                           this.props.history.push('/create-profile1');
  //                         }
  //                         }
  //                         if (
  //                           res.data.result[i].phone_number.length > 0 &&
  //                           res.data.result[i].business_name.length === 0
  //                         ) {
  //                           console.log('phone not empty');
  //                           console.log(res.data.result[i].phone_number);
  //                           AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
  //                           AsyncStorage.setItem('business_id', res.data.result[i]._id);
  //                           AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
  //                           if (this.state.plan_name === 'Bring your own messaging service provider') {
  //                             this.props.history.push('/createProfileServiceProvider');
  //                           } else {
  //                           this.props.history.push('/create-profile2');
  //                         }
  //                         }
  //                         if (
  //                           (res.data.result[i].phone_number.length > 0 &&
  //                             res.data.result[i].business_name.length > 0) ||
  //                           !res.data.result[i].subscription_id
  //                         ) {
  //                           console.log('phone not empty');
  //                           console.log(res.data.result[i].phone_number);
  //                           AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
  //                           AsyncStorage.setItem('business_name', res.data.result[i].business_name);
  //                           AsyncStorage.setItem('business_id', res.data.result[i]._id);
  //                           AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
  //                           localStorage.setItem('token', 'loggedIn');
  //                           this.setState({loggedIn: true});
  //                           // window.location.reload(true);

  //                           setTimeout(() => {
  //                             this.props.history.push('/dashboard')
  //                           }, 500);
  //                         }
  //                       } else if (
  //                         (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
  //                         !res.data.result[i].subscription_id
  //                       ) {
  //                         console.log('phone not empty');
  //                         console.log(res.data.result[i].phone_number);
  //                         AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
  //                         AsyncStorage.setItem('business_name', res.data.result[i].business_name);
  //                         AsyncStorage.setItem('business_id', res.data.result[i]._id);
  //                         AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
  //                       //  window.location.reload(true);
  //                       //  setTimeout(() => {
  //                       //     this.props.history.push('/board');
  //                       //  }, 500);
  //                       setTimeout(
  //                         () => this.props.history.push('/dashboard'), 
  //                         1500
  //                       );
  //                       }
  //                     }
  //                   }
  //                   if (res.data.status === 'no_record') {
  //                     this.props.history.push('/create-profile');
  //                   }
  //                 })
  //                 .catch((error) => {
  //                   if(error.response){
  //                     if (error.response.data.status === 'no_record') {
  //                       this.props.history.push('/create-profile');
  //                     }
  //                   }
  //                 });

  //               localStorage.setItem('token', 'loggedIn');
  //               this.setState({loggedIn: true});

  //               // setTimeout(() => {
  //               //   this.setState({ isLoading: false });
  //               //   this.props.history.push('/create-profile')
  //               //   // document.getElementById('error').style.display='none'
  //               // }, 3000)
  //             }
  //           }
  //         }
  //         if (res.data.status) {
  //           if (res.data.status == 'no_record') {
  //             this.setState({isLoading: false});
  //             const errors = [];
  //             errors.push('Sorry We cant Find You Try to Signup');
  //             console.log(this.state.errors);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // this.props.history.push('/')
  //               // document.getElementById('error').style.display='none'
  //             }, 3000);
  //           }
  //         }
  //         if (res.data.error) {
  //           if (res.data.error.error_type == 'password_mismatch') {
  //             this.setState({isLoading: false});
  //             const errors = [];
  //             errors.push('The password that you entered is incorrect');
  //             console.log(this.state.errors);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // this.props.history.push('/')
  //               // document.getElementById('error').style.display='none'
  //             }, 3000);
  //           }
  //           if (res.data.error.error_type === 'invalid_email') {
  //             const errors = [];
  //             errors.push(res.data.error.message);
  //             console.log(this.state.errors);
  //             this.setState({errors});
  //             console.log(this.state.errors);
  //             this.setState({isLoading: false});

  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // document.getElementById("error").remove();
  //               // document.getElementById('error').style.display = 'none'
  //             }, 3000);
  //             return errors;
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         if(error.response){
  //           if (error.response.data.status == 'no_record') {
  //             this.setState({isLoading: false});
  //             const errors = [];
  //             errors.push('Sorry We cant Find You Try to Signup');
  //             console.log(this.state.errors);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // this.props.history.push('/')
  //               // document.getElementById('error').style.display='none'
  //             }, 3000);
  //           }
  //           if (error.response.data.error) {
  //             if (error.response.data.error.error_type == 'password_mismatch') {
  //               this.setState({isLoading: false});
  //               const errors = [];
  //               errors.push('The password that you entered is incorrect');
  //               console.log(this.state.errors);
  //               this.setState({errors});
  //               setTimeout(() => {
  //                 this.setState({errors: []});
  //                 // this.props.history.push('/')
  //                 // document.getElementById('error').style.display='none'
  //               }, 3000);
  //             }
  //             if (error.response.data.error.error_type === 'invalid_email') {
  //               const errors = [];
  //               errors.push(error.response.data.error.message);
  //               console.log(this.state.errors);
  //               this.setState({errors});
  //               console.log(this.state.errors);
  //               this.setState({isLoading: false});
  
  //               setTimeout(() => {
  //                 this.setState({errors: []});
  //                 // document.getElementById("error").remove();
  //                 // document.getElementById('error').style.display = 'none'
  //               }, 3000);
  //               return errors;
  //             }
  //           }
  //         }
  //       });
  //   }
  // }
  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  render() {
    const {errors, success} = this.state;
    // if (this.state.loggedIn) {
    //   return <Redirect to="/board" />;
    // }

    return (
      <>
        <Helmet>
          <title>Redtie - Sign In</title>
        </Helmet>
        <div className="hold-transition new_signup_bodywrapper">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="new_signup_bodydiv">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden visible-xs">
                    <a href='https://www.getredtie.com/' target='_blank'>
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                    </a>
                  </div>

                  <div className="new_overall_signup_container">
                    <div className="new_overall_signup_bodydiv bg_white">
                      <div className="col-xs-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                        <div className="new_signup_leftcontainer">
                          <div className="new_signup_titleicon text-center">
                            <img src={signin_account} border={0} alt="Login to your Redtie account" />
                          </div>
                          <div className="new_signup_title text-center">
                            <h1>Login to your Redtie account</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="new_signup_rightcontainer">
                          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                          <a href='https://www.getredtie.com/' target='_blank'>
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                    </a>
                          </div>
                          <div
                            align="center"
                            className="col-12 col-sm-12 mrgntop_20 mb-3 mb-mb-0 clearfix pdnglftrgt_0">
                            <div className="new_signup_link">
                              Are you a new user?
                              <span className="ml-2">
                                <a href="/signup" className="fmaroon">
                                  Sign up here
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="new_signup_innerdiv new_logindiv">
                            {/*<h3 class="text-center mrgnbtm_25">Email Address</h3>*/}
                            {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                            <form onSubmit={this.onSubmit}>
                              {errors.map((error) => (
                                <div id="error" className="alert alert-danger text-center" key={error}>
                                  {error}
                                </div>
                              ))}
                              {success.map((success) => (
                                <div id="success" className="alert alert-success text-center" key={success}>
                                  {success}
                                </div>
                              ))}
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-envelope" />
                                  </span>
                                </div>
                                <input
                                  type="email"
                                  value={this.state.user_email}
                                  onChange={this.onChangeemail}
                                  className="form-control"
                                  placeholder="Email Address"
                                />
                              </div>
                              {/*<div class="form-group has-feedback lft0 clearfix mrgnbtm_35">
                <span class="fa fa-envelope form-control-feedback lftpos"></span>
                <input type="text" class="form-control" placeholder="Email Address"/>
               </div>
               */}
                              <div className="input-group mrgnbtm_20" style={{display: this.state.login ? '' : 'none'}}>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <input
                                  type={this.state.passwordtype}
                                  value={this.state.password}
                                  onChange={this.onChangepassword}
                                  className="form-control"
                                  placeholder="Password"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i
                                      onClick={this.showHide}
                                      className={this.state.passwordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                    />
                                  </span>
                                </div>
                              </div>
                              {/*<div class="form-group has-feedback lft0 clearfix mrgnbtm_10">
                <span class="fa fa-lock form-control-feedback lftpos"></span>
                <input type="password" class="form-control" placeholder="Password"/>
                <span class="fa fa-eye-slash form-control-feedback"></span>
               </div>*/}
                              <div className="form-group row clearfix mrgnbtm_25">
                                <div className="col-6 col-sm-6 col-md-6">
                                  <input type="checkbox" />
                                  <span className="new_signup_rember_forgotpwd mrgnlft_5">Remember Me</span>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6">
                                  <div className="new_signup_rember_forgotpwd txt_right">
                                    <a href="forgot-password">Forgot Password?</a>
                                  </div>
                                </div>
                              </div>
                              <div
                                align="center"
                                className="col-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
                                {/* <a href="create-profile" className="btn btn-black btnpdng f_sz18 fw_osemibold">Login</a> */}
                                <button
                                  disabled={this.state.isLoading}
                                  className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                  {this.state.isLoading ? 'Processing...' : 'Login'}
                                </button>
                              </div>
                            </form>
                            <div
                              align="center"
                              className="col-12 col-sm-12 mrgntop_20 mrgnbtm_10 clearfix pdnglftrgt_0">
                              <h4 className="signup_ssotxt">
                                <span>Or Sign In with</span>
                              </h4>
                            </div>
                            {/*<div align="center" class="col-xs-12 col-sm-12 clearfix pdnglftrgt_0">
                <div class="newreg_google"><img src="dist/img/redtie_google_signin_btn.png" border="0" alt="Redtie" title="Redtie"/></div>
               </div>*/}
                            {/* <div align="center" class="col-xs-12 col-sm-12 mrgntop_15 clearfix pdnglftrgt_0">
                <div class="newreg_google"><img src="dist/img/redtie_office365_btn.png" border="0" alt="Redtie" title="Redtie"/></div>
               </div>
               
                <div align="center" class="col-xs-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
                <div class="newreg_google"><img src="dist/img/redtie_yahoo_btn.png" border="0" alt="Redtie" title="Redtie"/></div>
               </div>*/}
                            <div align="center" className="col-xs-12 col-sm-12 clearfix mrgntop_15 pdnglftrgt_0">
                              <div className="newreg_otherbtns">
                                <img
                                  className="social_signin"
                                  style={{cursor: 'pointer'}}
                                  src={redtie_google_btn_sm}
                                  ref="googleLoginBtn"
                                  border={0}
                                  alt="Google"
                                  title="Google"
                                />
                                {/* <img src={redtie_google_btn_sm} border={0} alt="Redtie" title="Redtie" /> */}
                                <MicrosoftLogin
                                  buttonTheme="light_short"
                                  clientId="2841e3dc-ba01-4bd2-9da8-bd5c74c0f8fc"
                                  authCallback={this.authHandler}>
                                  <img
                                    className="social_signin"
                                    style={{cursor: 'pointer'}}
                                    src={redtie_microsoft_btn_sm}
                                    border="0"
                                    alt="Microsoft"
                                    title="Microsoft"
                                  />
                                </MicrosoftLogin>
                                {/* <img src={redtie_office365_btn_sm} border={0} alt="Redtie" title="Redtie" /> */}
                                {/* <img className="social_signin" src={redtie_yahoo_btn_sm} border={0} alt="Redtie" title="Redtie" /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div align="center" className="col-xs-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                    <div className="new_signup_link"><a href="/">Get Started with your account <i className="fa fa-long-arrow-right" /></a></div>
                  </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.register-box */}
                </div>
              </div>
            </div>
            {/* <footer className="main-footer"> 
      <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved. 
    </footer> */}
          </div>
          {/* jQuery 3 */}
          {/* Bootstrap 3.3.7 */}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
