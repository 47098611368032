import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import 'react-phone-input-2/lib/style.css';
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import screens_paraphrase from '../assets/img/app/screens_paraphrase.jpg';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {askForPermissioToReceiveNotifications, findingTokens} from '../../push-notification';
import queryString from 'query-string';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
const notification_token = localStorage.getItem('notification_token');
const moment = extendMoment(Moment);
var today = new Date();
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
class ParaphraseApp extends Component {
  state = {
    dates: null,
    value: '',
    states: '',
    setYear: new Date().getFullYear(),
    setMonth: new Date().getMonth(),
    date: new Date(),
    start: '',
    end: '',
    showNewMonth: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      business_id: '',
      prfImag: '',
      errors: [],
      success: [],
      business_info_array: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    var that = this;
    const socket = socketIOClient(ENDPOINT);
    console.log('notification_token', notification_token);
    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    console.log('Notification', Notification.permission);
    if (Notification?.permission) {
      if (
        (Notification?.permission === 'granted' || Notification?.permission === 'denied') &&
        notification_token != 'notification_token_removed'
      ) {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
      }
    });

    setTimeout(() => {
      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('AAA');
            this.setState({
              business_info_array: res.data?.result,
              // business_name1: res.data?.result?.business.business_name,
              // business_color_code: res.data?.result?.business.color_code,
              // view_archive: res.data?.result?.business.view_archive,
              // prfImag: res?.data?.result?.business?.profile_image,
              // busines_info_details: res.data?.result,
              // business_user_id: res.data?.result?.business.user_id,
            });
            // this.monthDiff(new Date(),res.data?.result?.business?.created_date);
            var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
              (usr) => usr.user_id === this.state.user_id,
            );
            if (assigned_role_restrict) {
              this.user_roles_info(assigned_role_restrict.role_id);
            } else {
              console.log('assigned_role_restrict not found', assigned_role_restrict);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
          }
        });
    }, 100);

    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
    }, 500);

    // this.start_fn();
    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({run: true});
      }
    });
    var that = this;
  }
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      prfImag: '',
      errors: [],
      success: [],
      business_info_array: [],
    });

    setTimeout(() => {
      AsyncStorage.getItem('business_id').then((business_id) => {
        if (business_id) {
          this.setState({business_id});
        }
      });
      AsyncStorage.getItem('user_email').then((user_email) => {
        if (user_email) {
          this.setState({user_email});
        }
      });
      AsyncStorage.getItem('user_id').then((user_id) => {
        if (user_id) {
          this.setState({user_id});
        }
      });
    }, 100);
    setTimeout(() => {
      this.Business_info();
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {}

  // Grid View
  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
    }
  };

  onEnablenotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios.post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token).then((res) => {
        if (res?.data?.status == 'success') {
          localStorage.setItem('notification_token', 'notification_token_added');
          this.onclosenotification();
        }
        if (res.data.error) {
        }
      });
    }
  };

  Business_info = () => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('BBB');
          this.setState({
            business_info_array: res.data?.result,
            // business_name1: res.data?.result?.business.business_name,
            // business_color_code: res.data?.result?.business.color_code,
            // view_archive: res.data?.result?.business.view_archive,
            // prfImag: res?.data?.result?.business?.profile_image,
            // business_user_id: res.data?.result?.business.user_id,
            // busines_info_details: res.data?.result,
          });

          var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
            (usr) => usr.user_id === this.state.user_id,
          );
          if (assigned_role_restrict) {
            this.user_roles_info(assigned_role_restrict.role_id);
          } else {
          }
        }
      })
      .catch((error) => {});
  };

  goto_account_dashboard = (contact) => {
    if (!$('.sidebar-mini').hasClass('sidebar-collapse')) {
      $('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
    }

    this.props.history.push('/account_dashboard');
  };
  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };

  redirectToPage = (value) => {
    this.props.history.push(value);
  };

  enableParaphrase = () => {
    // $('.paraphrase_installing').show();
    // $('.paraphrase_install').hide();
    const paraphrase_enabling = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'paraphrase',
    };
    axios.post(API_CALL_ENDPOINT + '/business/profile/settings', paraphrase_enabling).then((res) => {
      if (res?.data?.status == 'success') {
        this.Business_info('paraphrase');
      }
      if (res.data.error) {
      }
    });
  };

  render() {
    const {errors, success, convcontact, run, steps} = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Dashboard</title>
        </Helmet>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <Header_component
                redirectToPage={this.redirectToPage.bind(this)}
                data={this.update.bind(this)}
                cart_data={this.cart_update.bind(this)}
                trigger={this.without_refresh.bind(this)}
                title="true"
              />
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>

              <div className="content-wrapper" style={{minHeight: '368.438px'}}>
                {/* Main content */}

                <div className="content">
                  <div className="container-fluid">
                    <div className="row mt-3 position-relative">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="app_view p-3 mb-3">
                          <div className="app_detail">
                            <div className="row">
                              <div className="col-12 mt-4 mb-4">
                                <div className="d-flex align-item-center justify-content-between">
                                  <h4> The Redtie Paraphraser </h4>
                                  {this.state.business_info_array?.business?.paraphrase == 'enabled' ? (
                                    <a
                                      href="/board?compose_messages=true"
                                      class="btn app_instal_sus crsr_pntr mt-2 mb-2 btnpdng_sm float-right">
                                      GET
                                    </a>
                                  ) : this.state.business_info_array?.business?.fungible_token?.status == undefined ? (
                                    ''
                                  ) : (
                                    <a
                                      onClick={() => this.enableParaphrase()}
                                      href="javascript:void(0)"
                                      className="btn app_instal crsr_pntr btn-sm1 mt-2 mb-2 btnpdng_sm float-right paraphrase_install">
                                      {' '}
                                      Install
                                    </a>
                                  )}
                                  {/* <a
                                    href="app-ft.html"
                                    className="btn app_instal crsr_pntr btn-sm1 mx-1 mt-2 mb-1 btnpdng_sm float-right">
                                    {' '}
                                    Create{' '}
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div className="row invoice-info">
                              <div className="col-sm-12 invoice-col mb-5">
                                <div className="featured">
                                  <a href="#">
                                    {' '}
                                    <span>
                                      {' '}
                                      <i className="fas fa-flag mb-3 pr-1" style={{}} /> Featured{' '}
                                    </span>{' '}
                                  </a>
                                </div>
                                <p className="f_sz16">
                                  The Redtie Paraphraser is a fast and easy-to-use app for composing your text message
                                  campaigns. Our AI is well taught and programmed to improve the fluency and readability
                                  of your text messages while showing you information like, typos, punctuation errors,
                                  word count, percent change, and much more.
                                </p>
                              </div>
                            </div>
                            <div className="card box_shdw">
                              <div className="card-header d-flex p-0">
                                <ul className="nav nav-pills ml-auto mrgn_auto_cntr">
                                  <li className="nav-item mr-3">
                                    <a className="nav-link active" href="#tab_1" data-toggle="tab">
                                      Overview
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="card-body">
                                <div className="tab-content">
                                  <div className="tab-pane active" id="tab_1">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                      <ol className="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to={0} className />
                                        {/* <li
                                          data-target="#carouselExampleIndicators"
                                          data-slide-to={1}
                                          className="active"
                                        />
                                        <li data-target="#carouselExampleIndicators" data-slide-to={2} className /> */}
                                      </ol>
                                      <div className="carousel-inner">
                                        <div className="carousel-item active">
                                          <img className="d-block img-fluid" src={screens_paraphrase} alt="Screen One" />
                                        </div>
                                      </div>
                                      <a
                                        className="carousel-control-prev"
                                        href="#carouselExampleIndicators"
                                        role="button"
                                        data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                                        <span className="sr-only">Previous</span>
                                      </a>
                                      <a
                                        className="carousel-control-next"
                                        href="#carouselExampleIndicators"
                                        role="button"
                                        data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true" />
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.row */}
                  </div>

                  {/* /.container-fluid */}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ParaphraseApp;
