import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';

import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';

import 'react-phone-input-2/lib/style.css';

import $ from 'jquery';
import Header_component from '../includes/Header_component';

import transparent from '../assets/img/transparent.png';
import alert_plan from '../assets/img/alert_plan.png';
import dashboard_appsumo from '../assets/img/dashbrd_appsumodeal.png';
import alert_search from '../assets/img/alert_search.png';
import send_icon from '../assets/img/send_icon.png';
import send_icon_green from '../assets/img/send_icon_green.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import imgalert_contact from '../assets/img/alert_contact.png';
import redtie_bot1 from '../assets/img/Redtie-AI.gif';
import redtie_bot from '../assets/img/redtie_bot.png';
import redtie_logo from '../assets/img/redtie_logo.png';
import nft_green from '../assets/img/nft_green.png';
import ft_green from '../assets/img/ft_green.png';
import prof_webhooks from '../assets/img/prof_webhooks.png';
import MicRecorder from 'mic-recorder-to-mp3';
import PinInput from 'react-pin-input';
import './multiselect/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import TrialPayment from './TrialPayment';
// import TrialPaymentButton from './TrialPaymentButton';
import NftTables from './NftTables';
// import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/innerpages_customstyle.css';
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {askForPermissioToReceiveNotifications, findingTokens} from '../../push-notification';
import Linkify from 'react-linkify';
// import FileUploadProgress from 'react-fileupload-progress';
import queryString from 'query-string';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
// import 'mediaelement';
// import 'mediaelement/build/mediaelementplayer.css';
// import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import {Chart, registerables} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {id} from 'date-fns/locale';
import addDays from 'date-fns/addDays';
import ConversionHelpers from '@ckeditor/ckeditor5-engine/src/conversion/conversionhelpers';

import DateRangePicker from 'react-daterange-picker';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';
import Calendar from 'react-calendar';
import DataTable, {createTheme} from 'react-data-table-component';
import plivo_logo from '../assets/img/plivo_logo.png';
import twilio_logo from '../assets/img/twilio_logo.png';
import bandwidth_logo from '../assets/img/bandwidth_logo.png';
import qr_code from '../assets/img/app/qr_code.png';
import nft from '../assets/img/app/nft.png';
import ft from '../assets/img/app/ft.png';
import android from '../assets/img/app/android.jpg';
import app_store from '../assets/img/app/app_store.jpg';
import paraphrase from '../assets/img/app/paraphrase.png';
// import {VectorMap} from 'react-jvectormap';
const notification_token = localStorage.getItem('notification_token');
const moment = extendMoment(Moment);
var packs_details = 0;
var appsumo_packs_details = 0;
var QRCode = require('qrcode.react');
var countryLists = ['in', 'us', 'ca'];
function randDarkColor() {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
};

var tdata = [];

const tcolumns = [
  {
    name: 'Name',
    selector: 'Name',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];

const history_columns = [
  {
    id: 'historyDate',
    name: 'From Date',
    selector: (row) => moment(row.historyDate).unix(),
    cell: (row) => moment(row.historyDate).format('MM/DD/YYYY'),
    sortable: true,
  },
  {
    id: 'ToDate',
    name: 'To Date',
    selector: (row) => moment(row.ToDate).unix(),
    cell: (row) => moment(row.ToDate).format('MM/DD/YYYY'),
    sortable: true,
  },
  {
    name: 'Usage',
    selector: 'Usage',
    sortable: true,
    left: true,
  },
  {
    name: 'Sent',
    selector: 'Sent',
    sortable: true,
    left: true,
  },
  {
    name: 'Received',
    selector: 'Received',
    sortable: true,
    left: true,
  },
];

const billingstatus_columns = [
  {
    name: 'Plan',
    selector: 'Plan',
    sortable: true,
    left: true,
  },
  {
    id: 'paidDate',
    name: 'Paid On',
    selector: (row) => moment(row.Paidon).unix(),
    cell: (row) => moment(row.Paidon).format('MM/DD/YYYY'),
    sortable: true,
  },
  {
    id: 'NextDate',
    name: 'Next Renewal',
    selector: (row) => moment(row.NextRenewal).unix(),
    cell: (row) => moment(row.NextRenewal).format('MM/DD/YYYY'),
    sortable: true,
  },
];

const pack_history_columns = [
  {
    id: 'historypacksDate',
    name: 'Date',
    selector: (row) => moment(row.Date).unix(),
    cell: (row) => moment(row.Date).format('MM/DD/YYYY'),
    sortable: true,
    width: '200px',
  },
  {
    name: 'Packs',
    selector: 'Packs',
    sortable: true,
    left: true,
    width: '200px',
  },
  {
    name: 'Quantity',
    selector: 'Quantity',
    sortable: true,
    left: true,
    width: '140px',
  },
  {
    name: 'Usage',
    selector: 'Usage',
    sortable: true,
    left: true,
  },
  {
    name: 'Sent',
    selector: 'Sent',
    sortable: true,
    left: true,
  },
  {
    name: 'Received',
    selector: 'Received',
    sortable: true,
    left: true,
  },
];

const teamcolumns = [
  {
    name: 'Photo',
    selector: 'photo',
    sortable: false,
    left: true,
  },
  {
    name: 'Email Address',
    selector: 'email',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];
//import "./DatePicker.css";

const stateDefinitions = {
  available: {
    color: null,
    label: 'Available',
  },
  enquire: {
    color: '#ffd200',
    label: 'Enquire',
  },
  booking: {
    color: '#3BEFDF',
    label: 'Booking',
  },
  waiting: {
    color: '#FF4D4D',
    label: 'Waiting',
  },
  registration: {
    color: '#B0B0F7',
    label: 'Registration',
  },
  delivery: {
    color: '#EFAD9F',
    label: 'Delivery',
  },
  testing: {
    color: '#66ff66',
    label: 'Testing',
  },
  upgrade: {
    color: '#BB6FCD',
    label: 'Upgrade',
  },
  trip: {
    color: '#5066F2',
    label: 'Trip',
  },
  renovation: {
    color: '#F3B230',
    label: 'Renovation',
  },
  cleaning: {
    color: '#ff4d4d',
    label: 'Cleaning',
  },
  carSale: {
    color: '#8bd2da',
    label: 'Car Sale',
  },
  newCar: {
    color: '#40bf80',
    label: 'New Car',
  },
  unavailable: {
    // selectable: false,
    color: '#ff80ff',
    label: 'Unavailable',
  },
};
const dateRanges = [
  {
    state: 'enquire',
    range: moment.range(moment('2019-10-01'), moment('2019-10-31')),
  },
  {
    state: 'unavailable',
    range: moment.range(moment('2019-11-01'), moment('2019-11-30')),
  },
  {
    state: 'booking',
    range: moment.range(moment('2019-12-01'), moment('2019-12-31')),
  },
  {
    state: 'waiting',
    range: moment.range(moment('2020-01-01'), moment('2020-01-31')),
  },
  {
    state: 'registration',
    range: moment.range(moment('2020-02-01'), moment('2020-02-29')),
  },
  {
    state: 'delivery',
    range: moment.range(moment('2020-03-01'), moment('2020-03-31')),
  },
  {
    state: 'testing',
    range: moment.range(moment('2020-04-01'), moment('2020-04-30')),
  },
  {
    state: 'upgrade',
    range: moment.range(moment('2020-05-01'), moment('2020-05-31')),
  },
  {
    state: 'trip',
    range: moment.range(moment('2020-06-01'), moment('2020-06-30')),
  },
  {
    state: 'renovation',
    range: moment.range(moment('2020-07-01'), moment('2020-07-31')),
  },
  {
    state: 'cleaning',
    range: moment.range(moment('2020-08-01'), moment('2020-08-31')),
  },
  {
    state: 'carSale',
    range: moment.range(moment('2020-09-01'), moment('2020-09-30')),
  },
  {
    state: 'newCar',
    range: moment.range(moment('2020-10-01'), moment('2020-10-30')),
  },
];
var today = new Date();
var end_date;
var start_date;
var ival = 0;
var chart_labels = [];
var WorkFlowTable = '';
var WorkFlowTablestep = true;
var contactVl_id = [];
var tablearr = [];
var tablearrcount = 1;
var TemDtatable = '';
var AddMoreCount = 0;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
const qs = require('qs');
//var moment = require('moment');
const Mp3Recorder = new MicRecorder({bitRate: 128});
var placeholder = document.createElement('div');
placeholder.className = 'placeholder';
var placeholder_cmb = document.createElement('div');
placeholder.className = 'placeholder_cmb';
const rand = () => Math.floor(Math.random() * 255);
const rand1 = () => Math.floor(Math.random() * 50);
const ctypes = ['Sent', 'Received', 'Notes', 'Audio', 'Video', 'Images', 'Meeting', 'Documents'];
// const [startDate, setStartDate] = useState(new Date("2014/02/08"));
// const [endDate, setEndDate] = useState(new Date("2014/02/10"));
const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Auguest', 'September'],
  datasets: [
    {
      type: 'line',
      label: 'Sent',
      borderColor: `#60c8c8`,
      borderWidth: 2,
      fill: false,
      data: ['15', '10', '25', '20', '50', '75', '35', '55', '90'],
    },
    {
      type: 'line',
      label: 'Received',
      borderColor: `#ffa245`,
      borderWidth: 2,
      fill: false,
      data: [rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1()],
    },

    {
      type: 'bar',
      label: 'Notes',
      backgroundColor: `#ffb1c1`,
      stack: 1,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Audio',
      stack: 1,
      backgroundColor: `#9ad0f5`,
      data: ['10', '15', '35', '30', '90', '80', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Video',
      stack: 1,
      backgroundColor: `#ffcf9f`,
      data: ['25', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Images',
      stack: 1,
      backgroundColor: `#ccb2ff`,
      data: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Meeting',
      stack: 1,
      backgroundColor: `#a5dfdf`,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
  ],
};

const options = {
  responsive: true,
  legend: {
    display: false,
  },
  type: 'bar',
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

let Rnunique_id = Math.random().toString(36).substr(2, 16);
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

let docAttachmentsCount = 0;
let docAttachmentsCount_cmb = 0;
let checkedValue_one_multiselect = [];
var first_emoji = false;
function validate(first_name, last_name, phone_number) {
  const errors = [];
  if (first_name.length === 0) {
    errors.push("First name can't be empty");
    return errors;
  }

  if (phone_number.length === 0) {
    errors.push("Phone Number can't be empty");
    return errors;
  }

  return errors;
}

class Dashboard extends Component {
  state = {
    dates: null,
    value: '',
    states: '',
    setYear: new Date().getFullYear(),
    setMonth: new Date().getMonth(),
    date: new Date(),
    start: '',
    end: '',
    showNewMonth: true,
  };
  dateRangePickerSelect = (range, states, dates, start, end, value) => {
    this.setState({dates});
    this.setState({
      value: range,
      states: states,
    });
    setTimeout(() => {
      let selectedStartDate = this.state.value.start.format('DD/MMM/YYYY');
      let selectedEndDate = this.state.value.end.format('DD/MMM/YYYY');
      var diffInMs = Math.abs(new Date(selectedStartDate) - new Date(selectedEndDate));
      var diffindays = diffInMs / (1000 * 60 * 60 * 24);
      if (diffindays > 1) {
        this.setState({hoursDisabled: true});
      } else {
        this.setState({hoursDisabled: false});
      }
      // $("#buttons_div").
      var default_dateval =
        moment(this.state.value.start).format('MMM DD, yyyy') +
        ' - ' +
        moment(this.state.value.end).format('MMM DD, yyyy');
      $('#default_dateval').val(default_dateval);
      this.getAllBusinessDetails(
        moment(this.state.value.start).format('MM-DD-yyyy'),
        moment(this.state.value.end).format('MM-DD-yyyy'),
        this.state.selectedBtnType,
      );
      this.setState({
        start: selectedStartDate,
        end: selectedEndDate,
      });
    }, 1000);
  };

  constructor(props) {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    super(props);
    this.state = {
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      ...props,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: new Date(),
      endDate: '',
      worflow_details: [],
      history_details: [],
      billing_status: [],
      pack_history_details: [],
      teammembers_details: [],
      triggeredchange: false,
      dates: null,
      value: '',
      states: '',
      setYear: new Date().getFullYear(),
      setMonth: new Date().getMonth(),
      date: new Date(),
      start: '',
      end: '',
      showNewMonth: true,
      selectedBtnType: 3,
      hoursDisabled: true,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: '',
      busines_info_details: {},
      qr_code_list: {},
      appsumo_redeem: '0',
      user_views_list_arr: [],
      rbot_conversation: [],
      dashboard_extra_details: '',
      dashboard_billing_details: '',
      nft_token: '',
      email_address_otp: '',
      phone_number_otp: '',
      send_otp: '',
      password: '',
      cpassword: '',
      enabled_country_lists: '',
      passwordtype: 'password',
      confirmpasswordtype: 'password',
      workspace_edit: '',
      user_info_details: [],
      business_type_details: [],
      user_phone_number_code: '',
      user_phone_number: '',
      usr_name: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      // enabled_country_lists: '',
      action_required: false,
      evaluation_id: '',
      user_otp: '',
      business_name: '',
      business_type_value: '',
      business_type_name_value: '',
      tax_number: '',
      address: '',
      city: '',
      state_value: '',
      zip_code: '',
      additional_information: '',
      otp_time: true,
      phone_verification: true,
      otp_verification: false,
      form_verification: false,
      business_expired_date: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    //console.log(this.state.startDate)
  }

  upgradeWorkspace = (url) => {
    if (this.state.business_info_array.business.appstore_transaction_id != '') {
      $('.close').trigger('click');
      $('#popup_ios_modal_payment').trigger('click');
      return false;
    }
    window.location.href = url;
  };

  status_hide(id, text_id) {
    $('.TimeLinDivLs_Contacts_view_' + id).hide();
    $('.resend_list_' + text_id).hide();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    var that = this;
    $(document).on('paste change', '.team_email', function () {
      $('.team_email').each(function () {
        var $self = $(this);
        setTimeout(function () {
          var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if (!pattern.test($self.val())) {
            that.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: 'Please enter valid email address.',
            }));
            $('.add_team_members_btn').css({'pointer-events': 'none'});
          } else {
            that.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
            $('.add_team_members_btn').css({'pointer-events': 'auto'});
          }
        }, 100);
      });
      setTimeout(() => {
        that.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    });
    $(document).on('change', '#business_type_drpdn', function () {
      var $self = $(this);
      var business_typeval = $self.val();
      if (business_typeval == 'others') {
        $('.other_type').show();
      } else {
        $('.other_type').hide();
      }
    });
    $(document).on('click', '.ws_pen', function () {
      $('.ws_nme_edit').toggle();
      $('#get_wsnme').val(that.state.business_info_array?.business?.business_name);
    });
    $(document).on('keydown', '#get_wsnme', function (e) {
      var key = e.which;
      if (key == 13) {
        if (that.state.workspace_edit?.length == 0) {
          toast.dark('Workspace name is required.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        var WorkspaceDetails = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_description: '',
          business_id: that.state.business_id,
          setup: 'info',
          business_name: that.state.workspace_edit,
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/profile/settings', WorkspaceDetails)
          .then((res) => {
            if (res.data.status === 'success') {
              toast.dark('Your Workspace name has been updated successfully.', {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              $('.wrkspce_name').text(that.state.workspace_edit);
              $('.wrkspce_name').attr('data-original-title', that.state.workspace_edit);
              $('.wrkspce_name').attr('title', that.state.workspace_edit);
              $('.ws_nme_edit').hide();
              that.Business_info();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data?.error?.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    });
    $(document).on('click', '.open_nft_receipt', function () {
      var $self = $(this);
      const token_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_id: that.state.business_id,
          flag: 'single',
          tran_hash: $self.data('id'),
        },
      };
      console.log('token_list_params', token_list_params);
      axios
        .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('nftres.datasss', res.data.result[0]);
            that.setState({nft_token: res.data.result[0]});
            $('.nftmintedlist_div').hide();
            $('.receiptnft_div').show();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
            }
          }
        });
    });

    $(document).on('click', '.viewbillinghistory_clk', function () {
      $('.viewbillinghistory_div').show();
      $('.viewusage_div').hide();
    });
    $(document).on('click', '.viewbillingstatus_clk', function () {
      $('.viewbillinghistory_div').hide();
      $('.viewbillingstatus_div').show();
      $('.viewusage_div').hide();
    });
    $(document).on('click', '.viewpackshistory_clk', function () {
      $('.viewbillingpackhistory_div').show();
      $('.viewusage_div').hide();
      $('.viewpackshistory_clk').hide();
      $('.viewbillinghistory_div').hide();
      $('.viewbillingstatus_div').hide();
      $('.viewcurrentbilling_clk').show();
    });
    $(document).on('click', '.viewusage_clk, .viewcurrentbilling_clk', function () {
      $('.viewbillingpackhistory_div').hide();
      $('.viewbillinghistory_div').hide();
      $('.viewusage_div').show();
      $('.viewpackshistory_clk').show();
      $('.viewcurrentbilling_clk').hide();
      $('.viewbillingstatus_div').hide();
    });

    $(document).on('click', '.integration_details', function () {
      var dataid = $(this).data('id');
      that.integrationDetails(dataid);
    });
    $(document).on('click', '.view_workflow', function () {
      var dataid = $(this).data('id');
      that.workFlowDetails(dataid);
      // window.location.href = '/profile_settings?workflow=true&workflow_id=' + dataid + '';
    });
    $('.chart_dropdown').on('click', function (e) {
      e.stopPropagation();
    });
    $('.date_range_filter').on('click', function () {
      $('.dashchartfilterdrpdwn_pos').toggle();
    });

    $('#default_dateval').on('click', function () {
      $('.dashchartfiltercalendardrpdwn_pos').toggle();
    });

    today = new Date();
    end_date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    var start = new Date('1/1/' + new Date().getFullYear());
    start_date = start.getMonth() + 1 + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format('MMM DD, yyyy') + ' - ' + moment(today).format('MMM DD, yyyy');
    $('#default_dateval').val(default_dateval);
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.handleResize);
    this.setState({window_height_conv: window.innerHeight - 200, window_height_cont: window.innerHeight - 200});
    $('.cmb_sendmessage_btn').hide();

    const socket = socketIOClient(ENDPOINT);

    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification?.permission) {
      if (
        (Notification?.permission === 'granted' || Notification?.permission === 'denied') &&
        notification_token != 'notification_token_removed'
      ) {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
      }
    });
    if (notification_token == 'notification_token_removed') {
      this.saveToken();
    }
    this.getAllBusinessDetails(start_date, end_date, 3);
    setTimeout(() => {
      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})
            this.setState({
              business_info_array: res.data?.result,
              business_name1: res.data?.result?.business.business_name,
              business_color_code: res.data?.result?.business.color_code,
              view_archive: res.data?.result?.business.view_archive,
              prfImag: res?.data?.result?.business?.profile_image,
              busines_info_details: res.data?.result,
              business_user_id: res.data?.result?.business.user_id,
              business_expired_date: new Date(res.data?.result?.business.expire_date),
            });
            var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
              (usr) => usr.user_id === this.state.user_id,
            );
            if (assigned_role_restrict) {
              this.user_roles_info(assigned_role_restrict.role_id);
            } else {
              console.log('assigned_role_restrict not found', assigned_role_restrict);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
          }
        });
      const enabled_country_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          type: 'text',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/admin/enable/plivo/country', enabled_country_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            var country = [];
            console.log('countryListss', res.data.result?.country?.length);
            for (let i = 0; i < res.data.result?.country?.length; i++) {
              console.log('country_iso', res.data.result?.country[i].country_iso.toLowerCase());
              country.push(res.data.result?.country[i].country_iso.toLowerCase());
            }
            countryLists = country;
            console.log('countryLists', countryLists);
            this.setState({enabled_country_lists: countryLists});
            $('.iti-flag').show();
            $('.intl-tel-input .flag-container .arrow').show();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              this.setState({enabled_country_lists: []});
            }
          }
        });
      const user_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
        if (res.data.result) {
          this.setState({
            user_info_details: res.data.result.user,
            user_email: res.data.result.user.email_address,
          });
        }
      });
    }, 100);

    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
    }, 500);

    setTimeout(() => {
      const business_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios
        .get(API_CALL_ENDPOINT + '/business/business_type/list', business_details)
        .then((res) => {
          console.log('/business/business_type/list', res.data);
          if (res.data.result) {
            this.setState({business_type_details: res.data?.result});
          }
        })
        .catch((error) => {
          console.log(error);
        });
      const enabled_country_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          type: 'text',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/admin/enable/plivo/country', enabled_country_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            let country = [];
            for (let i = 0; i < res.data.result?.country?.length; i++) {
              country.push(res.data.result?.country[i].country_iso.toLowerCase());
            }
            this.setState({enabled_country_lists: country});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              that.setState({enabled_country_lists: []});
            }
          }
        });
    }, 100);

    // this.start_fn();
    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({run: true});
      }
    });
    var that = this;
    $('.cmb_schedule').click(function () {
      that.scheduledatetime_tgl1();
    });
    $('.cmb_save').click(function () {
      that.msg_save_cmb();
    });
    $('.comp_schedule').click(function () {
      that.scheduledatetime_tgl();
    });
    $('.comp_save').click(function () {
      this.msg_save();
    });
    $('.comp_update').click(function () {
      this.msg_update();
    });
    /**********Contacts Popup**********/

    $('.addcontact_modalclk').click(function () {
      $('.searchcontacts_modaldiv').hide();
      $('.addcontacts_modaldiv').show();
      $('.addcontacts_modaltxt').hide();
      $('.searchcontacts_modaltxt').show();
    });

    $('.searchcontact_modalclk').click(function () {
      $('.addcontacts_modaldiv').hide();
      $('.searchcontacts_modaldiv').show();
      $('.searchcontacts_modaltxt').hide();
      $('.addcontacts_modaltxt').show();
    });

    /**********Template Popup**********/

    $('.addtemplate_modalclk').click(function () {
      $('.searchtemplates_modaldiv').hide();
      $('.addtemplate_modaldiv').show();
      $('.addtemplates_modaltxt').hide();
      $('.searchtemplates_modaltxt').show();
    });

    // $('.searchtemplate_modalclk').click(function () {
    //   $('.addtemplate_modaldiv').hide();
    //   $('.searchtemplates_modaldiv').show();
    //   $('.searchtemplates_modaltxt').hide();
    //   $('.addtemplates_modaltxt').show();
    // });

    /**********Tag Popup**********/

    $('.addtag_modalclk').click(function () {
      $('.searchtag_modaldiv').hide();
      $('.addtag_modaldiv').show();
      $('.addtags_modaltxt').hide();
      $('.searchtags_modaltxt').show();
    });

    $('.searchtag_modalclk').click(function () {
      $('.addtag_modaldiv').hide();
      $('.searchtag_modaldiv').show();
      $('.searchtags_modaltxt').hide();
      $('.addtags_modaltxt').show();
    });

    /**********Mobile Preview**********/

    $('.viewsentmessage_phone_close').click(function () {
      $('.preview_txtmsg_wideview').hide();
      $('.preview_txtmsg').show();
    });
    /**********add property**********/
    $('.addproperty_clk').on('click', function () {
      $('.addproperty_div').show();
    });
    //disable copy
    // $('body').bind('copy', function (e) {
    //   e.preventDefault();
    //   return false;
    // });
    setTimeout(() => {
      $('.cmb_buttons_hide').show();
    }, 1000);
  }
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      businessTeamDetails: {},
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: '',
      endDate: '',
      worflow_details: [],
      history_details: [],
      billing_status: [],
      pack_history_details: [],
      teammembers_details: [],
      selectedBtnType: 3,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: '',
      busines_info_details: {},
      qr_code_list: {},
      // appsumo_redeem: '0'
      user_views_list_arr: [],
      rbot_conversation: [],
      dashboard_extra_details: '',
      dashboard_billing_details: '',
      nft_token: '',
      email_address_otp: '',
      phone_number_otp: '',
      send_otp: '',
      password: '',
      cpassword: '',
      // enabled_country_lists: '',
      passwordtype: 'password',
      confirmpasswordtype: 'password',
      workspace_edit: '',
      // user_info_details: [],
      business_type_details: [],
      user_phone_number_code: '',
      user_phone_number: '',
      usr_name: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      // enabled_country_lists: '',
      action_required: false,
      evaluation_id: '',
      user_otp: '',
      business_name: '',
      business_type_value: '',
      business_type_name_value: '',
      tax_number: '',
      address: '',
      city: '',
      state_value: '',
      zip_code: '',
      additional_information: '',
      otp_time: true,
      phone_verification: true,
      otp_verification: false,
      form_verification: false,
      usr_name: '',
      business_expired_date: '',
      // user_info_details: [],
    });
    today = new Date();
    end_date = today.getMonth() + 1 + '-' + today.getDate() + '-' + today.getFullYear();
    var start = new Date('1/1/' + new Date().getFullYear());
    start_date = start.getMonth() + 1 + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format('MMM DD, yyyy') + ' - ' + moment(today).format('MMM DD, yyyy');
    $('#default_dateval').val(default_dateval);
    setTimeout(() => {
      AsyncStorage.getItem('business_id').then((business_id) => {
        if (business_id) {
          this.setState({business_id});
        }
      });
      AsyncStorage.getItem('user_email').then((user_email) => {
        if (user_email) {
          this.setState({user_email});
        }
      });
      AsyncStorage.getItem('user_id').then((user_id) => {
        if (user_id) {
          this.setState({user_id});
        }
      });
    }, 100);
    setTimeout(() => {
      this.getAllBusinessDetails(start_date, end_date, 3);
      this.Business_info();
    }, 1000);
    // WorkFlowTable = '';
    // WorkFlowTablestep = true;
  }

  teamMeberReload = (val) => {
    if (val === '0') {
      $('.addMoreEmail').hide();
    } else {
      $('.addMoreEmail').show();
    }

    setTimeout(() => {
      this.setState(() => ({
        emailAssgnClss: '',
        emailAssgnMSg: '',
        showModalTeamadd: false,
      }));
    }, 2500);
  };

  filterBtnClick = (val) => {
    var type = val;
    var chart;
    this.setState({selectedBtnType: type});
    var default_dateval = $('#default_dateval').val();
    var trainindIdArray = default_dateval.split('-');
    var bstartdate = moment(trainindIdArray[0]).format('MM-DD-yyyy');
    var benddate = moment(trainindIdArray[1]).format('MM-DD-yyyy');
    this.getAllBusinessDetails(bstartdate, benddate, type);
  };
  smallCalenderChange = (date) => {
    this.setState({date});
    let newD = new Date(date);
    this.setState({
      setMonth: newD.getMonth(),
      setYear: newD.getFullYear(),
    });
  };
  componentDidUpdate(prevProps, prevState) {
    //  if (this.state.setMonth !== _state.setMonth) {
    // this.setState({ showNewMonth: false }, () =>
    //   this.setState({ showNewMonth: true })
    // );
    //  }
    if (prevState !== this.state) {
      //
    }
    if (
      prevState.scSelectedContacts !== this.state.scSelectedContacts ||
      prevState.scSelectedCategories !== this.state.scSelectedCategories
    ) {
      this.scSearchConversation();
    }

    if (
      prevState.attachmentlimit_count !== this.state.attachmentlimit_count ||
      this.state.atcharryDet_cmb ||
      prevState.attachmentdoc_count_cmb !== this.state.attachmentdoc_count_cmb
    ) {
      //
    }
    if (prevState.notesValue2_cmb !== this.state.notesValue2_cmb) {
      if (this.state.cmb_notesedit === false) {
        if (this.state.notesValue2_cmb?.length >= 13) {
          this.saveNotes2_cmb();
        }
      }

      this.autoupdateNotes2_cmb();
    }
    if (prevState.notesValue2 !== this.state.notesValue2) {
      if (this.state.comp_notesedit === false) {
        if (this.state.notesValue2?.length >= 13) {
          this.saveNotes2();
        }
      }

      this.autoupdateNotes2();
    }
  }

  workFlowDetails = (workflow_id) => {
    this.props.history.push('/profile_settings?workflow=true&workflow_id=' + workflow_id + '');
    //window.location.reload(true);
  };

  appsumoReedem = () => {
    this.props.history.push('/manage_account?bsid=true&bis_id=' + this.state.business_id + '');
    //window.location.reload(true);
  };
  integrationDetails = (type) => {
    this.props.history.push('/profile_settings?integrations=true&type=' + type + '');
    //window.location.reload(true);
  };

  onChangesearchconv = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);
    // this.props.onChange(values);
    this.setState({search_status_conv: values});

    setTimeout(() => {
      this.contacmessage_search();
    }, 500);
  };
  onChangesearchtext = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({search_text_conv: e.target.value});
  };

  onChangeaddfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({first_name: e.target.value});
  };
  onChangeaddlname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({last_name: e.target.value});
  };
  onChangeaddphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
    this.setState({phone_number: e.target.value});
  };

  onChangeupfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dfirst_name: e.target.value});
  };
  onChangeuplname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dlast_name: e.target.value});
  };
  onChangeupphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
  };
  onChangeupemail = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({demail_address: e.target.value});
  };
  onChangeupdob = (e) => {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();

    this.setState({ddob: e.target.value});
  };
  onChangeCompanyid = (e) => {
    this.setState({company_id: e.target.value});
  };
  onChangecompname = (e) => {
    this.setState({company_name: e.target.value});
  };

  addNewtagonchange = (e) => {
    this.setState({newtagname: e.target.value});
  };
  onChangebusiness = (business) => {
    AsyncStorage.setItem('business_id', business.business._id);
    window.location.reload(true);
  };
  scheduledChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  opnDrpwn() {
    $('.search-result').toggle();
  }
  closeLightbox = (e) => {
    this.setState({Lightbox_open: false});
    if (this.state.template_document_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_document_click: false});
    }
  };
  closeLightbox_video = () => {
    this.setState({Lightbox_open_video: false});
    if (this.state.template_video_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_video_click: false});
    }
  };

  // Grid View
  gridView = () => {
    $('#v-pills-list1').removeClass('active');
    $('#v-pills-list1').removeClass('show');
    $('#v-pills-grid1').addClass('show');
    $('#v-pills-grid1').addClass('active');
  };
  listView1 = () => {
    $('#v-pills-list2').addClass('active');
    $('#v-pills-list2').addClass('show');
    $('#v-pills-grid2').removeClass('show');
    $('#v-pills-grid2').removeClass('active');
  };

  // Grid View
  gridView1 = () => {
    $('#v-pills-list2').removeClass('active');
    $('#v-pills-list2').removeClass('show');
    $('#v-pills-grid2').addClass('show');
    $('#v-pills-grid2').addClass('active');
  };
  new_attach_message = () => {
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1').show();
    $('#custom-tabs-medialibrary').hide();
    $('#custom-tabs-medialibrary1').addClass('active');
    $('#custom-tabs-medialibrary1').addClass('show');
    $('#custom-tabs-medialibrary').removeClass('show');
    $('#custom-tabs-medialibrary').removeClass('active');
  };
  lib_attach_message = () => {
    this.colapse_all_attachments_comp();
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary').show();
    $('#custom-tabs-medialibrary1').hide();
    $('#custom-tabs-medialibrary').addClass('active');
    $('#custom-tabs-medialibrary').addClass('show');
    $('#custom-tabs-medialibrary1').removeClass('show');
    $('#custom-tabs-medialibrary1').removeClass('active');
    this.attachment_admin_list();
    this.attachment_receiver_list();
  };

  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };

  colapsed_attach = (id) => {
    $('.attachment_colapsed').show();
    $('.attachment_colapsed_' + id).hide();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).show();
  };
  expanded_attach = (id) => {
    $('.attachment_colapsed').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).hide();
  };
  colapse_all_attachments = () => {
    $('.attachment_colapsed').show();
    $('.attachment_expanded').hide();
  };
  colapsed_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_colapsed_comp_' + id).hide();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).show();
  };
  expanded_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).hide();
  };
  colapse_all_attachments_comp = () => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_expanded_comp').hide();
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
    }
  };

  contactDetails = (
    id,
    birthday,
    first_name,
    last_name,
    phone_number,
    phone_list,
    email_address,
    company,
    website,
    title,
  ) => {
    $('#f_name').val(first_name);
    $('#l_name').val(last_name);
    $('#phone_number').val(phone_number[0]);
    $('#email_address').val(email_address[0]);
    if (birthday.date != 0 && birthday.month != 0 && birthday.date != null && birthday.month != null) {
      $('#dob').val(birthday.month + '/' + birthday.date);
    }
  };

  tagonChange(event, tagID) {
    var tag_array = this.state.contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected = this.selected || {};
    if (event.target.checked) {
      this.selected[tagID] = true;
    } else {
      delete this.selected[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      filtered_tag_list_up: Object.keys(this.selected),
      contact_tag_list: tag_array,
    });
  }
  tagonChange_dtls(event, tagID) {
    var tag_array = this.state.dtls_contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected_dtls = this.selected_dtls || {};
    if (event.target.checked) {
      this.selected_dtls[tagID] = true;
    } else {
      delete this.selected_dtls[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      dtls_filtered_tag_list_up: Object.keys(this.selected_dtls),
      dtls_contact_tag_list: tag_array,
    });
  }

  getAllBusinessDetails = (start_date, end_date, chart_type) => {
    $('#table_with_data').hide();
    $('.table_without_data_subclass').hide();
    var type = chart_type;
    var chart;
    if (type == 1) {
      chart = 'hourly';
    } else if (type == 2) {
      chart = 'day_month';
    } else if (type == 3) {
      chart = 'week_year';
    } else if (type == 4) {
      chart = 'month_year';
    } else {
      chart = 'month_year';
    }
    if (!this.state.triggeredchange) {
      this.setState({triggeredchange: true});
      this.setState({
        chart_data: '',
      });
      ival = 0;
      tablearr = [];
      setTimeout(() => {
        const dashboard_billing = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            is_current: true,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/dashboard/billing', dashboard_billing)
          .then((res) => {
            if (res.data.status === 'success') {
              var history = [];
              var billingstatus = [];
              var packhistory = [];
              $('.plan_details_div').show();
              this.setState({dashboard_billing_details: res.data.result});
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                this.setState({dashboard_billing_details: ''});
              }
            }
          });
        const businessprofiledetails = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            chart_type: chart,
            from_date: start_date,
            to_date: end_date,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/business/dashboard/details', businessprofiledetails)
          .then((res) => {
            if (res.data.status === 'success') {
              if (res.data.result) {
                packs_details = 0;
                appsumo_packs_details = 0;
                this.setState({
                  businessprofiledetails: res.data.result,
                  businessprofileplandetails: res.data?.result?.plan,
                  business_team_details: res.data?.result?.team_details,
                });
                if (res.data.result?.worflow_details?.length === 0) {
                  $('#table_with_data').hide();
                  $('#table_without_data').show();
                  $('.table_without_data_subclass').show();
                }

                if (res.data.result.worflow_details?.length != 0) {
                  var tdataarr = [];
                  var incval = 0;
                  var appsumo_redeemval = 0;
                  $.each(res.data.result?.pack_details, function (indexs, vals) {
                    if (vals?.purchase_type === 'appsumo') {
                      appsumo_redeemval = 1;
                    }
                  });
                  this.setState({appsumo_redeem: appsumo_redeemval});
                  $.each(res.data.result?.worflow_details, function (index, values) {
                    incval++;
                    var ststus = (
                      <span
                        className={
                          values?.workflow_status === 'deleted'
                            ? 'badge badge-paused'
                            : values?.workflow_status === 'deactive'
                            ? 'badge badge-inactive'
                            : values?.workflow_status === 'active'
                            ? 'badge badge-active'
                            : 'badge badge-paused'
                        }>
                        {values?.workflow_status === 'deactive' ? 'Inactive' : values?.workflow_status}
                      </span>
                    );
                    var action = (
                      <a
                        href={void 0}
                        data-id={values?.workflow_id}
                        className="view_workflow WorkspaceSettingsWorkflow">
                        <i
                          className="far fa-eye fmaroon f_sz15 crsr_pntr WorkspaceSettingsWorkflow"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="View"
                        />
                      </a>
                    );
                    var newdata = {id: values._id, Name: values.workflow_name, Status: ststus, Action: action};
                    tdataarr.push(newdata);
                  });
                  this.setState({
                    worflow_details: tdataarr,
                  });
                  $('#table_with_data').show();
                  $('#table_without_data').hide();
                } else {
                  this.setState({
                    worflow_details: [],
                  });
                  $('#table_with_data').hide();
                  $('#table_without_data').show();
                  $('.table_without_data_subclass').show();
                }
                var months = [];
                var csent = [];
                var creceive = [];
                var cnotes = [];
                var caudio = [];
                var cvideo = [];
                var cimages = [];
                var cmeetimgs = [];
                var cdocuments = [];
                var ccolours;
                var countsval = 0;
                var datarrr = [];
                var datavals = [];
                var datarrrfinal = [];
                var datarrrfinals = [];
                var nearr = [];
                var ctype;
                $.each(res.data.result.month_list, function (index, values) {
                  if (res.data.result.sent_total.length !== 0) {
                    if (res.data.result.sent_total[0]?.data.hasOwnProperty(values)) {
                      csent.push(res.data.result.sent_total[0].data[values].toString());
                    } else {
                      csent.push('0');
                    }
                  } else {
                    csent.push('0');
                  }
                  if (res.data.result.receive_total.length !== 0) {
                    if (res.data.result.receive_total[0]?.data.hasOwnProperty(values)) {
                      creceive.push(res.data.result.receive_total[0].data[values].toString());
                    } else {
                      creceive.push('0');
                    }
                  } else {
                    creceive.push('0');
                  }
                  if (res.data.result.video_total.length !== 0) {
                    if (res.data.result.video_total[0]?.data.hasOwnProperty(values)) {
                      cvideo.push(res.data.result.video_total[0].data[values].toString());
                    } else {
                      cvideo.push('0');
                    }
                  } else {
                    cvideo.push('0');
                  }
                  if (res.data.result.audio_total.length !== 0) {
                    if (res.data.result.audio_total[0]?.data.hasOwnProperty(values)) {
                      caudio.push(res.data.result.audio_total[0].data[values].toString());
                    } else {
                      caudio.push('0');
                    }
                  } else {
                    caudio.push('0');
                  }
                  if (res.data.result.image_total.length !== 0) {
                    if (res.data.result.image_total[0]?.data.hasOwnProperty(values)) {
                      cimages.push(res.data.result.image_total[0].data[values].toString());
                    } else {
                      cimages.push('0');
                    }
                  } else {
                    cimages.push('0');
                  }
                  if (res.data.result.notes_total.length !== 0) {
                    if (res.data.result.notes_total[0]?.data.hasOwnProperty(values)) {
                      cnotes.push(res.data.result.notes_total[0].data[values].toString());
                    } else {
                      cnotes.push('0');
                    }
                  } else {
                    cnotes.push('0');
                  }
                  if (res.data.result.meeting_total.length !== 0) {
                    if (res.data.result.meeting_total[0]?.data.hasOwnProperty(values)) {
                      cmeetimgs.push(res.data.result.meeting_total[0].data[values].toString());
                    } else {
                      cmeetimgs.push('0');
                    }
                  } else {
                    cmeetimgs.push('0');
                  }
                  if (res.data.result.documents_attach_total.length !== 0) {
                    if (res.data.result.documents_attach_total[0]?.data.hasOwnProperty(values)) {
                      cdocuments.push(res.data.result.documents_attach_total[0].data[values].toString());
                    } else {
                      cdocuments.push('0');
                    }
                  } else {
                    cdocuments.push('0');
                  }
                });
                var scount = 0;
                $.each(ctypes, function (i, val) {
                  if (val == 'Sent') {
                    ccolours = `#a4002a`;
                    datarrr = csent;
                  } else if (val == 'Received') {
                    ccolours = `#2bcb9c`;
                    datarrr = creceive;
                  } else if (val == 'Notes') {
                    ccolours = `#ffb1c1`;
                    datarrr = cnotes;
                  } else if (val == 'Audio') {
                    ccolours = `#9ad0f5`;
                    datarrr = caudio;
                  } else if (val == 'Video') {
                    ccolours = `#ffcf9f`;
                    datarrr = cvideo;
                  } else if (val == 'Images') {
                    ccolours = `#ccb2ff`;
                    datarrr = cimages;
                  } else if (val == 'Meeting') {
                    ccolours = `#ff937e`;
                    datarrr = cmeetimgs;
                  } else if (val == 'Documents') {
                    ccolours = `#f6f38ffa`;
                    datarrr = cdocuments;
                  }
                  var newarray = {};
                  newarray.type = val == 'Sent' ? 'line' : val == 'Received' ? 'line' : 'bar';

                  newarray.label = val;

                  newarray.fill = 'false';
                  if (val == 'Sent' || val == 'Received') {
                    newarray.borderWidth = 2;
                    newarray.borderColor = ccolours;
                  } else {
                    newarray.backgroundColor = ccolours;
                    newarray.stack = 1;
                  }
                  newarray.data = datarrr;
                  datarrrfinal.push(newarray);
                  scount++;
                });
                if (res.data.result.chart_type == 'month_year') {
                  chart_labels = res.data.result.month_list;
                } else if (res.data.result.chart_type == 'week_year') {
                  var week_yeararr = [];
                  var weelcntval = 0;
                  $.each(res.data.result.month_list, function (index, values) {
                    weelcntval++;
                    var lableval = values;
                    var lablevalarray = lableval.split('-');
                    var newweek = 'Week - ' + weelcntval;
                    week_yeararr.push(newweek);
                  });
                  chart_labels = week_yeararr;
                } else if (res.data.result.chart_type == 'day_month') {
                  chart_labels = res.data.result.month_list;
                } else if (res.data.result.chart_type == 'hourly') {
                  var week_yeararr = [];
                  var weelcntval = 0;
                  $.each(res.data.result.month_list, function (index, values) {
                    weelcntval++;
                    var lableval = values;
                    var lablevalarray = lableval.split('-');
                    var newweek =
                      (lablevalarray[1].length == 1 ? '0' + lablevalarray[1] : lablevalarray[1]) +
                      ':00 - ' +
                      (lablevalarray[1].length == 1 ? '0' + lablevalarray[1] : lablevalarray[1]) +
                      ':59';
                    week_yeararr.push(newweek);
                  });
                  chart_labels = week_yeararr;
                }
                const datasss = {
                  labels: chart_labels,
                  datasets: datarrrfinal,
                };
                this.setState({
                  chart_data: datasss,
                });
                $('.dashchartfiltercalendardrpdwn_pos').hide();
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
              }
            }
          });

        const dashboard_extra = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/dashboard/extradetails', dashboard_extra)
          .then((res) => {
            if (res.data.status === 'success') {
              var history = [];
              this.setState({dashboard_extra_details: res.data.result});
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                this.setState({dashboard_extra_details: ''});
              }
            }
          });

        this.reFreshRbot();
      }, 100);

      setTimeout(() => {
        const dashboard_billing = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/dashboard/billing', dashboard_billing)
          .then((res) => {
            if (res.data.status === 'success') {
              var history = [];
              var billingstatus = [];
              var packhistory = [];
              $('.plan_details_div').show();
              this.setState({dashboard_billing_details: res.data.result});
              if (res.data.result?.billing_history?.length != 0) {
                $.each(res.data.result?.billing_history, function (index, values) {
                  if (values.pack === 'message') {
                    var newdata = {
                      Date: values.date,
                      Packs: values.message_pack_name,
                      Quantity: values.quantity,
                      Usage: values.usage + '/' + values.message_total,
                      Sent: values.sent,
                      Received: values.received,
                    };
                    packhistory.push(newdata);
                  } else if (values.pack === 'plan') {
                    var newdata = {
                      historyDate: values.date,
                      ToDate: values.date_to,
                      Usage: values.usage,
                      Sent: values.sent,
                      Received: values.received,
                    };
                    history.push(newdata);
                  }
                });
                $.each(res.data.result?.business_payment, function (index, values) {
                  var newdata = {
                    Plan: values.interval == 'year' ? 'Annually' : 'Monthly',
                    Paidon: values.created_date,
                    NextRenewal: values.end_date,
                  };
                  billingstatus.push(newdata);
                });
                this.setState({
                  history_details: history,
                  pack_history_details: packhistory,
                  billing_status: billingstatus,
                });
              } else {
                this.setState({
                  history_details: [],
                  pack_history_details: [],
                  billing_status: [],
                });
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                this.setState({dashboard_billing_details: ''});
              }
            }
          });
      }, 1000);
      this.setState({triggeredchange: false});
    }
  };

  multiSort = (array, sortObject = {}) => {
    const sortKeys = Object.keys(sortObject);

    // Return array if no sort object is supplied.
    if (!sortKeys.length) {
      return array;
    }

    // Change the values of the sortObject keys to -1, 0, or 1.
    for (let key in sortObject) {
      sortObject[key] =
        sortObject[key] === 'desc' || sortObject[key] === -1
          ? -1
          : sortObject[key] === 'skip' || sortObject[key] === 0
          ? 0
          : 1;
    }

    const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;

      if (a === b) {
        // If the values are the same, do not switch positions.
        return 0;
      }

      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
    };

    return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
        const key = sortKeys[index];

        if (key) {
          const direction = sortObject[key];

          sorted = keySort(a[key], b[key], direction);
          index++;
        }
      }

      return sorted;
    });
  };

  reFreshRbot = () => {
    const conversationDetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/rbot/conversation', conversationDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({
            rbot_conversation: res.data.result.conversation,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
          }
        }
      });
  };
  hideproplabel = (prop_id, prop_name) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    this.setState({exist_prop_id: prop_id});
    $('.prop_input_' + prop_id).show();
    $('.prop_lab_' + prop_id).hide();
    $('#prop_up_' + prop_id).val(prop_name);
    $('#prop_' + prop_id).val(prop_name);
  };
  tagonChange_send = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);

    this.setState({
      send_filtered_tag_list_up: values,
    });
  };

  onchange_contact_notes = (event) => {
    this.setState({
      contact_notes_value: event.target.value,
      chars_left_notes: this.state.max_chars_notes - event.target.value.length,
    });
  };

  onClick_contact_notes(dcontact_id) {
    const noteslist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: dcontact_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/contacts/notes/list', noteslist)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({contact_notes_list: res.data?.result?.notes});
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {});
  }

  addcpyname = () => {
    $('.entercpyname_drpdwn_div').hide();
    $('.entercpyname_txtbx_div').show();
  };

  saveToken = async () => {
    const token = await findingTokens();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios
        .post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token)
        .then((res) => {
          if (res?.data?.status == 'success') {
            localStorage.setItem('notification_token', 'notification_token_added');
            this.onclosenotification();
          }
          if (res.data.error) {
          }
        })
        .catch((error) => {
          this.onclosenotification();
        });
    }
  };

  onEnablenotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios.post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token).then((res) => {
        if (res?.data?.status == 'success') {
          localStorage.setItem('notification_token', 'notification_token_added');
          this.onclosenotification();
        }
        if (res.data.error) {
        }
      });
    }
  };

  Business_info = () => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({
            business_info_array: res.data?.result,
            business_name1: res.data?.result?.business.business_name,
            business_color_code: res.data?.result?.business.color_code,
            view_archive: res.data?.result?.business.view_archive,
            prfImag: res?.data?.result?.business?.profile_image,
            business_user_id: res.data?.result?.business.user_id,
            busines_info_details: res.data?.result,
            business_expired_date: new Date(res.data?.result?.business.expire_date),
          });

          var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
            (usr) => usr.user_id === this.state.user_id,
          );
          if (assigned_role_restrict) {
            this.user_roles_info(assigned_role_restrict.role_id);
          } else {
          }
        }
      })
      .catch((error) => {});
  };

  planUpgrade() {
    this.props.history.push('/manage_account?upgrade=true');
  }

  toDateOnchange(date) {
    if (!this.state.startDate && !this.state.endDate) {
      this.setState({startDate: date});
    } else if (this.state.startDate && !this.state.endDate) {
      if (this.state.startDate < date) {
        this.setState({endDate: date});
        var default_dateval =
          moment(this.state.startDate).format('MMM DD, yyyy') + ' - ' + moment(date).format('MMM DD, yyyy');
        $('#default_dateval').val(default_dateval);
        this.getAllBusinessDetails(
          moment(this.state.startDate).format('MM-DD-yyyy'),
          moment(date).format('MM-DD-yyyy'),
          this.state.selectedBtnType,
        );
      } else {
        this.setState({startDate: date});
      }
    }
    if (this.state.startDate && this.state.endDate) {
      this.setState({
        startDate: date,
        endDate: null,
      });
    }
  }

  handleChange = (date) => {
    const {format} = this.props;
    if (date) {
      if (!this.state.date) {
        return this.setState({date});
      }
      this.setState({
        endDate: date,
        rangeDate: `${this.state.date.format(format)} -> ${date.format(format)}`,
      });
      return this.props.onDateChange(this.state.date.format(format), date.format(format));
    }
    this.props.onDateChange(null);
  };

  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };

  chartReload = () => {
    var sdate = $('#cstart_date').val();
    var edate = $('#cend_date').val();
    sdate = moment(sdate).format('MM-DD-yyyy');
    edate = moment(edate).format('MM-DD-yyyy');
    this.getAllBusinessDetails(sdate, edate, this.state.selectedBtnType);
  };

  set_reminder = (id, contact, time) => {
    console.log('id', id);
    console.log('contact', contact);
    console.log('time', time);
    const parameters = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: id,
      reminder: time,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/rbot/reminder', parameters)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.reFreshRbot();
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          //this.setState({ user_views_list_arr: [] });
        }
      });
  };
  goto_message = (contact, textid) => {
    console.log('contact', contact);
    this.props.history.push('/board?conversation=true&c_id=' + contact + '&t_id=' + textid + '');
  };

  goto_inbox = () => {
    this.props.history.push('/board');
  };

  goto_account_dashboard = (contact) => {
    if (!$('.sidebar-mini').hasClass('sidebar-collapse')) {
      $('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
    }

    this.props.history.push('/account_dashboard');
  };
  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };

  user_roles_info = (role_id_data) => {
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        role_id: role_id_data,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/roles/info', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          // this.setState({user_roles_info_arr: res.data.result});

          // var assigned_views = res?.data?.result?.find((view) => view.views === "WorkspaceSettings");
          for (let i = 0; i < res?.data?.result?.views?.length; i++) {
            // var assigned_role_restrict = res?.data?.result?.views[i]?.views_id.views
            if (res?.data?.result?.views[i]?.views_id.views === 'WorkspaceSettings') {
              var restricted_permission = res?.data?.result?.views[i]?.permission;
              var all_permission = this.state.user_views_list_arr;
              var array3 = all_permission.filter(function (obj) {
                return restricted_permission.indexOf(obj) == -1;
              });
              // console.log("array3",array3)
              for (var k in array3) {
                if (array3[k] == 'IntegrationEmail') {
                  $('#custom-tabs-psemail').removeClass('active show');
                } else if (array3[k] == 'GeneralInfo') {
                  $('#custom-tabs-psinfodtls').removeClass('active show');
                }
                console.log('array3 each', array3[k]);
                $('.' + array3[k]).attr('style', 'pointer-events: none !important; color: #afafaf !important');
              }
              //     for (let j = 0; i < array3?.length; j++) {

              //       $('.'+array3[j]).hide();
              // }
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_roles_info_arr: []});
        }
      });
  };

  display_contcat_count = (count) => {
    if (count >= 1000) {
      var val = count / 1000;
      if (!Number.isInteger(val)) {
        return parseInt(val) + 'K +';
      } else {
        return parseInt(val) + 'K';
      }
    } else {
      return parseInt(count);
    }
  };

  copyToClipboard = (elementId) => {
    var copyText = document.getElementById(elementId);
    copyText.select();
    //copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    toast.dark('Copied successfully', {
      position: 'top-right',
      transition: Flip,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  redirectToPage = (value) => {
    this.props.history.push(value);
  };

  Voice_Ply = (conversation) => {
    var PlyIdn = conversation._id;

    var playx = document.getElementById('audio_' + conversation._id);
    this.setState({
      audio_play_id: conversation._id,
    });
    playx.play();
    $('.audio_' + conversation._id).show();
    $('.voiPlyICn_' + conversation._id).hide();
    $('.voiPusIcn_' + conversation._id).hide();

    playx.onended = function () {
      $('.voiPusIcn_' + PlyIdn).hide();
      $('.voiPlyICn_' + PlyIdn).show();
      $('.audio_' + PlyIdn).hide();
    };
  };

  Voice_Pause = (conversation) => {
    var pausex = document.getElementById('audio_' + conversation._id);
    pausex.pause();
    $('.voiPusIcn_' + conversation._id).hide();
    $('.voiPlyICn_' + conversation._id).show();
    $('.audio_' + conversation._id).hide();
  };
  Voice_Stop = () => {
    if (this.state.audio_play_id) {
      var pausex = document.getElementById('audio_' + this.state.audio_play_id);
      pausex.load();
      $('.voiPusIcn_tostop').hide();
      $('.voiPlyICn_toshow').show();
      $('.audio_' + this.state.audio_play_id).hide();
    }
  };

  otpTimer = () => {
    clearInterval(interval);
    var counter = 60;
    var interval = setInterval(function () {
      counter--;
      // Display 'counter' wherever you want to display it.
      if (counter <= 0) {
        clearInterval(interval);
        $('.timer').hide();
        $('.resend_otp').show();
        return;
      } else {
        $('.timer').show();
        $('.resend_otp').hide();
        $('.time').text(counter);
        console.log('Timer --> ' + counter);
      }
    }, 1000);
  };
  otpTimerBE = () => {
    clearInterval(interval);
    var counter = 60;
    var interval = setInterval(function () {
      counter--;
      // Display 'counter' wherever you want to display it.
      if (counter <= 0) {
        clearInterval(interval);
        $('#timer_be').hide();
        $('.resend_otp_be').show();
        return;
      } else {
        $('#timer_be').show();
        $('.resend_otp_be').hide();
        $('#time_be').text(counter);
        console.log('Timer2 --> ' + counter);
      }
    }, 1000);
  };

  sentOtp = (type) => {
    var OtpDetails = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      type: type,
    };
    if (type == 'phone') {
      var phone_number = this.state.dphone_number_code + this.state.dphone_number;
      if (phone_number.length == '0') {
        toast.dark('Phone number is required.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      } else {
        OtpDetails.phone_number = phone_number;
      }
    }

    axios
      .post(API_CALL_ENDPOINT + '/onboard/otp/send', OtpDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if (type == 'phone') {
            $('.get_ph_otp').hide();
            $('.submit_ph_otp').show();
          } else {
            $('.get_email_otp').hide();
            $('.submit_email_otp').show();
          }
          if (type == 'phone') {
            this.otpTimer();
          } else {
            this.otpTimer();
          }
        }
        if (res.data.error.reason === 'Invalid Id') {
          const errors = [];
          errors.push('Please make sure you have chosen a tag.');
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  submitPassword = (type) => {
    if (this.state.password != this.state.cpassword) {
      toast.dark('Password and confirm password does not match', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    const PasswordDetails = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      password: this.state.password,
    };

    axios
      .post(API_CALL_ENDPOINT + '/onboard/password/create', PasswordDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark('Your password has been set successfully.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const user_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
            },
          };

          axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
            if (res.data.result) {
              this.setState({
                user_info_details: res.data.result.user,
                user_email: res.data.result.user.email_address,
              });
            }
          });
          $('.close').trigger('click');
        }
        if (res.data.error.reason === 'Invalid Id') {
          const errors = [];
          errors.push('Please make sure you have chosen a tag.');
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  subMitOtp = (type) => {
    var otp = this.state.send_otp;
    if (otp.length == '0') {
      toast.dark('Otp is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    const OtpVerifyDetails = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      type: type,
      otp: otp,
    };
    if (type == 'email') {
      var successmessage = 'Thanks for verifying your email!';
    } else {
      var successmessage = 'Thanks for verifying your phone number!';
    }

    axios
      .post(API_CALL_ENDPOINT + '/onboard/otp/verify', OtpVerifyDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark(successmessage, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const user_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
            },
          };
          $('.submit_ph_otp').hide();
          axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
            if (res.data.result) {
              this.setState({
                user_info_details: res.data.result.user,
                user_profile_img: res.data.result.user.profile_image,
                user_email: res.data.result.user.email_address,
              });
            }
          });
        }
        if (res.data.error.reason === 'Invalid Id') {
          const errors = [];
          errors.push('Please make sure you have chosen a tag.');
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input',
    });
  };
  showHide1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      confirmpasswordtype: this.state.confirmpasswordtype === 'input' ? 'password' : 'input',
    });
  };

  editWorkspace = () => {
    if (this.state.workspace_edit?.length == 0) {
      toast.dark('Workspace name is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    var WorkspaceDetails = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_description: '',
      business_id: this.state.business_id,
      setup: 'info',
      business_name: this.state.workspace_edit,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', WorkspaceDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark('Your Workspace name has been updated successfully.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.Business_info();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  sentOtpBE = () => {
    const errors = [];
    var phone_number = this.state.user_phone_number_code + this.state.user_phone_number;
    if (this.state.usr_name?.length == 0) {
      // errors.push('Name field is required.');
      // this.setState({errors});
      toast.dark('🚀 Name field is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (phone_number?.length == 0) {
      // errors.push('Phone Number field is required.');
      // this.setState({errors});
      toast.dark('🚀 Phone Number is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    const business_otp_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: phone_number,
      name: this.state.usr_name,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/create/business_evaluation', business_otp_details)
      .then((res) => {
        console.log('res.data.status', res.data.status);
        if (res.data.status === 'success') {
          this.otpTimerBE();
          toast.dark('🚀' + res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('#warning_msg').hide();
          this.setState({
            evaluation_id: res.data.result._id,
            phone_verification: false,
            otp_verification: true,
            form_verification: false,
          });
          $('.cvv_get_ph_name').hide();
          $('#be_cvv_sent_otp').hide();
          $('.cvv_ph_verify').show();
          $('#cvv_submit_otp').show();

          this.setState({otp_time: false});
          setTimeout(() => {
            this.setState({otp_time: true});
          }, 60000);
        }
      })
      .catch((error) => {
        if (error.response) {
          // if (error.response.data.status === 'conflict') {
          toast.dark('🚀' + error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // errors.push(error.response.data.message);
          // this.setState({errors});
          // setTimeout(() => {
          //   this.setState({errors: []});
          // }, 4000);
          // }
        }
      });
  };

  verify_number() {
    if (this.state.user_otp.length != 4) {
      // const errors = [];
      // errors.push('Please enter the OTP.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      toast.dark('🚀 Please enter the OTP.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const OTP_Verify = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        otp: this.state.user_otp,
        evaluation_id: this.state.evaluation_id,
      };
      axios
        .post(API_CALL_ENDPOINT + '/business/business_evaluation_phone/verify', OTP_Verify)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark('🚀' + res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            $('.cvv_ph_verify').hide();
            $('#cvv_submit_otp').hide();
            $('.cvv_business_detail').show();
            $('#cvv_submit_details').show();
          }
        })
        .catch((error) => {
          console.log('error.response', error.response);
          console.log('error.response.data.message', error.response.data.error.message);
          // if (error.response) {
          //   const errors = [];
          //   errors.push(error.response.data.error.message);
          //   this.setState({errors});
          //   setTimeout(() => {
          //     this.setState({errors: []});
          //   }, 4000);
          // }
          toast.dark('🚀' + error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  error_toaster_show = (msg) => {
    toast.dark('🚀' + msg, {
      position: 'top-right',
      transition: Flip,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  business_evaluation_submit() {
    if (this.state.business_name.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Business name.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Business name.');
    } else if (this.state.business_type_value.length == 0) {
      // const errors = [];
      // errors.push('Please select the Business type.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please select the Business type.');
    } else if (this.state.business_type_value == 'others' && this.state.business_type_name_value.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Business type.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Business type.');
    } else if (this.state.tax_number.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Tax Number/ID/EIN.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Tax Number/ID/EIN.');
    } else if (this.state.address.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Address.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Address.');
    } else if (this.state.city.length == 0) {
      // const errors = [];
      // errors.push('Please enter the City.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the City.');
    } else if (this.state.state_value.length == 0) {
      // const errors = [];
      // errors.push('Please enter the State.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the State.');
    } else if (this.state.zip_code.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Zip code.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Zip code.');
    } else {
      if (this.state.business_type_name_value?.length != 0 && this.state.business_type_value == 'others') {
        const business_types = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          business_type: this.state.business_name,
          business_type: this.state.business_type_name_value,
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/create/business_types', business_types)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              this.setState({business_type_value: res.data.result._id});
              setTimeout(() => {
                this.submit_business_evaluation();
              }, 100);
            }
          })
          .catch((error) => {
            if (error.response) {
              const errors = [];
              errors.push(error.response.data.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 4000);
            }
          });
      } else {
        this.submit_business_evaluation();
      }
    }
  }

  submit_business_evaluation = () => {
    const business_evaluation = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      business_name: this.state.business_name,
      business_type: this.state.business_type_value,
      tax_number: this.state.tax_number,
      address: this.state.address,
      state: this.state.state_value,
      city: this.state.city,
      zip: this.state.zip_code,
      additional_information: this.state.additional_information,
      evaluation_id: this.state.evaluation_id,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/update/business_evaluation_details', business_evaluation)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({phone_verification: true, otp_verification: false, form_verification: false});
          $('#warning_msg').hide();
          $('#cvv_sent_otp').hide();
          $('#cvv_submit_details').hide();
          $('#cvv_submit_otp').hide();
          $('.cvv_get_ph_name').hide();
          $('.cvv_ph_verify').hide();
          $('.cvv_business_detail').hide();
          $('.success_msg').show().text(res.data.message);
          setTimeout(() => {
            $('.close').trigger('click');
            window.location.reload();
          }, 500);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          errors.push(error.response.data.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
          }, 4000);
        }
      });
  };
  find_relative_time(time) {
    var fuzzy;
    var from_now = moment(time).fromNow();
    if (
      from_now.includes('day') === true ||
      from_now.includes('days') === true ||
      from_now.includes('month') === true ||
      from_now.includes('months') === true ||
      from_now.includes('year') === true ||
      from_now.includes('years') === true
    ) {
      fuzzy = moment(time).format('MMM DD, YYYY hh:mma');
    } else {
      fuzzy = moment(time).fromNow();
    }

    return fuzzy;
  }

  render() {
    let initialYear = this.state.setYear;
    let initialMonths = this.state.setMonth;
    let noOfMonths = 2;
    let smallCalenderMinDate = new Date(new Date().setFullYear(new Date().getFullYear() - 4));
    let smallCalenderMaxDate = new Date();
    new Date().setDate(new Date().getDate() - 1);
    let minimumDate = new Date(new Date().setFullYear(new Date().getFullYear() - 4));
    let maximumDate = this.state.date;
    new Date().setDate(new Date().getDate() - 1);
    var moment = require('moment');
    const {errors, success, convcontact, run, steps} = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Dashboard</title>
        </Helmet>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <Header_component
                redirectToPage={this.redirectToPage.bind(this)}
                data={this.update.bind(this)}
                cart_data={this.cart_update.bind(this)}
                trigger={this.without_refresh.bind(this)}
                title="true"
              />
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="header_pagetitle d-block d-sm-block d-md-none">
                          <div className="text-center pt-2">
                            <div className="allheader_profile_drpdwn crsr_pntr">
                              <h4
                                className="d-inline-block"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title
                                data-original-title="Ble Media Solutions"></h4>
                              <div className="dropdown d-inline-block">
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right scrollbar_style">
                                  <div className="dropdown-item">
                                    <div className="allheader_profile_drplist text-center">
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">EphronSystems Pvt.Ltd</a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          EphronTech LLC.
                                          <br />
                                          <span className="f_sz13 fred">Cancelled</span>
                                        </a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          ABC Incorporation LLC.
                                          <br />
                                          <span className="f_sz13 forange">Assigned</span>
                                        </a>
                                      </div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem">
                                        <a href="#">
                                          <span className="fred">Payment Failed Profile</span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="container-fluid">
                    <div className="row position-relative mx-0 mt-5 welcome_dashboard">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                        <div className="all_dashboard_div">
                          <div className="w-90 shadow-none brdr_radius_div">
                            <div className="banner flex-fill card">
                              <div className="p-4 card-body row">
                                <div className="message d-flex flex-column pr-0 pr-lg-4 pl-lg-4 pb-4 pb-lg-0 col-lg-6 col-md-12">
                                  <h1 className="mb-4">Welcome to Redtie</h1>
                                  <p className="text-lg text-info mb-3 fmaroon">
                                    The Business Messaging Gateway for Everything, Just like email.
                                  </p>
                                  <div className="mt-4">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => this.redirectToPage('/board')}
                                      className="btn btn-black-dark btn-lg crsr_pntr f_sz16 sendmessagebtn_clk1">
                                      <span>
                                        <i className="fas fa-pencil-alt" />
                                      </span>
                                      <span className="d-none d-md-inline-block ml-2">Compose</span>
                                    </a>
                                  </div>
                                </div>
                                <div className="features pt-4 pt-lg-0 px-0 mx-3 mx-lg-0 px-lg-4 col-lg-6 col-md-12  d-flex flex-column">
                                  <h3 className="mb-3">Your Workspace Details</h3>
                                  {this.state.business_info_array?.business?.user_id !== this.state.user_id ? (
                                    <div className="widget_assigned">
                                      <span className="badge badge-assigned f_sz12">Assigned</span>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="user-block d-flex align-items-center mb-3">
                                    <div className="product-img">
                                      {this.state.business_info_array?.business?.profile_image ? (
                                        <span className=" bg_teammember4">
                                          <img src={this.state.business_info_array?.business.profile_image} />
                                        </span>
                                      ) : (
                                        <span
                                          class="usercircle_md bg_teammember4"
                                          style={{
                                            // backgroundColor: business.color_code ? business.color_code : '#097952',
                                            color: this.state.business_info_array?.business?.color_code
                                              ? this.state.business_info_array?.business?.color_code
                                              : '#097952',
                                          }}>
                                          {this.state.business_info_array?.business?.business_name
                                            ? this.state.business_info_array?.business?.business_name
                                                .substring(1, 0)
                                                .toUpperCase()
                                            : 'I'}
                                        </span>
                                      )}
                                    </div>
                                    <span className="username">
                                      {this.state.business_info_array?.business?.business_name == 'Guest Workspace'
                                        ? 'Trial Workspace'
                                        : this.state.business_info_array?.business?.business_name}{' '}
                                      {this.state.business_info_array?.business?.plan_id?.plan_name == 'Free' ? (
                                        <i class="fas fa-pencil-alt ws_pen"></i>
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                    <div className="form-horizontal formbiglbl ws_nme_edit" style={{display: 'none'}}>
                                      <div className="px-0">
                                        <div className="form-group row">
                                          <div className="col-12 col-sm-12 col-md-12 position-relative">
                                            <input
                                              type="text"
                                              className="form-control text-center"
                                              id="get_wsnme"
                                              onChange={(e) =>
                                                this.setState({
                                                  workspace_edit: e.target.value,
                                                })
                                              }
                                              placeholder="Enter Workspace Name"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-12 col-sm-4">
                                      <div className="info-box bg-light h-100">
                                        <div className="info-box-content text-center">
                                          <span className="info-box-text text-muted">
                                            {this.state.business_info_array?.business?.flag == 'appsumo'
                                              ? 'AppSumo Plan'
                                              : 'Plan'}
                                          </span>
                                          {today > this.state.business_expired_date ? (
                                            <div>
                                              <span className="text-center font-weight-bold mb-0 fmaroon">Expired</span>
                                              <span className="info-box-number text-center text-muted mb-0 mt-3 fmaroon">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    this.upgradeWorkspace(
                                                      '/create-profile?bid=' + this.state?.business_id,
                                                    )
                                                  }>
                                                  <span className="btn btn-black-dark btn-xs btnpdng_sm2 p-3">
                                                    Upgrade
                                                  </span>
                                                </a>
                                              </span>
                                            </div>
                                          ) : (
                                            <span className="info-box-number text-muted mb-0 fmaroon">
                                              {this.state.business_info_array?.business?.flag == 'appsumo'
                                              ? "" :this.state.business_info_array?.business?.plan_id?.plan_name}
                                            </span>
                                          )}

                                          {this.state.business_info_array?.business?.plan_id?.plan_name == 'Free' ? (
                                            <>
                                              <span className="info-box-number text-muted mb-0 mt-3 fmaroon ">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    this.upgradeWorkspace(
                                                      '/create-profile?bid=' + this.state?.business_id,
                                                    )
                                                  }>
                                                  <span className="btn btn-black-dark btn-xs btnpdng_sm2 p-3">
                                                    Upgrade
                                                  </span>
                                                </a>
                                              </span>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                      <div className="info-box bg-light h-100">
                                        <div className="info-box-content text-center">
                                          <span className="info-box-text text-muted">Total Messages</span>
                                          <span className="info-box-number text-muted mb-0 fmaroon">
                                            {this.state.dashboard_billing_details?.billing_usage
                                                      ?.available_message !== undefined
                                              ? this.state.dashboard_billing_details?.billing_usage
                                              ?.available_message
                                              : '0'}
                                          </span>
                                          <span className="info-box-number text-muted mb-0 mt-3 fmaroon">
                                            <a
                                              onClick={() =>
                                                this.state.business_info_array?.business?.plan_id?.plan_name == 'Free'
                                                  ? this.upgradeWorkspace(
                                                      '/create-profile?bid=' + this.state?.business_id,
                                                    )
                                                  : this.redirectToPage('profile_settings?pack_buynew=true')
                                              }
                                              className="text-center"
                                              href="javascript:void(0)">
                                              <span className="btn btn-black-dark btn-xs btnpdng_sm2 p-3">
                                                Get more
                                              </span>
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                      <div className="info-box bg-light h-100">
                                        <div className="info-box-content text-center">
                                          <span className="info-box-text text-muted">Total Contacts</span>
                                          <span className="info-box-number text-muted mb-0 fmaroon">
                                            {this.state.business_info_array?.business?.plan_id?.plan_name == 'Free'
                                              ? this.state.business_info_array?.business?.plan_id?.contacts
                                              : 'Unlimited'}
                                          </span>
                                          {this.state.business_info_array?.business?.plan_id?.plan_name == 'Free' ? (
                                            <>
                                              <span className="info-box-number text-muted mb-0 fmaroon mt-3">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    this.upgradeWorkspace(
                                                      '/create-profile?bid=' + this.state?.business_id,
                                                    )
                                                  }>
                                                  <span className="btn btn-black-dark btn-xs btnpdng_sm2 p-3">
                                                    Get more
                                                  </span>
                                                </a>
                                              </span>
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row position-relative mx-0 welcome_dashboard">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                        <div className="all_dashboard_div">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                            <div className="w-90 shadow-none brdr_radius_div">
                              <div className="banner flex-fill card">
                                <div className="card-body">
                                  <div className="pr-0 pr-lg-4 pb-4 pb-lg-0 position-relative col-xl-12 col-lg-12 col-md-12">
                                    <h3 className="mb-3">
                                      {!this.state.user_info_details?.is_first_time
                                        ? 'Recommendations'
                                        : "Let's get started "}{' '}
                                    </h3>
                                    <p className="text-lg text-info mb-5 fmaroon">
                                      Get these steps done to finish setting up your account and to explore more of
                                      Redtie's premium Features.
                                    </p>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                      <div
                                        className="nav flex-column nav-tabs h-100 step_things_do"
                                        id="vert-tabs-tab"
                                        role="tablist"
                                        aria-orientation="vertical">
                                        <a
                                          className={
                                            this.state.user_info_details?.email_verified
                                              ? 'nav-link rounded d-flex complete active'
                                              : 'nav-link rounded d-flex active'
                                          }
                                          id="vert-tabs-email-tab"
                                          data-toggle="pill"
                                          href="#email-verify"
                                          role="tab"
                                          aria-controls="vert-tabs-home"
                                          aria-selected="false">
                                          Verify your Email
                                        </a>
                                        <a
                                          className={
                                            this.state.user_info_details?.phone_verified ||
                                            this.state.business_info_array?.busines_evaluation_details?.status ==
                                              'approved'
                                              ? $('#vert-tabs-phoneno-tab').hasClass('active')
                                                ? 'nav-link rounded d-flex complete active'
                                                : 'nav-link rounded d-flex complete'
                                              : $('#vert-tabs-phoneno-tab').hasClass('active')
                                              ? 'nav-link rounded d-flex active'
                                              : 'nav-link rounded d-flex'
                                          }
                                          id="vert-tabs-phoneno-tab"
                                          data-toggle="pill"
                                          href="#phone-verify"
                                          role="tab"
                                          aria-controls="vert-tabs-profile"
                                          aria-selected="false">
                                          Verify your Phone Number
                                        </a>
                                        <a
                                          className={
                                            !this.state.user_info_details?.password
                                              ? $('#vert-tabs-messages-tab').hasClass('active')
                                                ? 'nav-link rounded d-flex active'
                                                : 'nav-link rounded d-flex'
                                              : $('#vert-tabs-messages-tab').hasClass('active')
                                              ? 'nav-link rounded d-flex complete active'
                                              : 'nav-link rounded d-flex complete'
                                          }
                                          id="vert-tabs-messages-tab"
                                          data-toggle="pill"
                                          href="#vert-tabs-messages"
                                          role="tab"
                                          aria-controls="vert-tabs-messages"
                                          aria-selected="false">
                                          Create your Password
                                        </a>
                                        {this.state.business_info_array?.business?.user_id == this.state.user_id && (
                                          <a
                                            data-ss={this.state.business_info_array?.busines_evaluation_details?.status}
                                            className={
                                              this.state.business_info_array?.busines_evaluation_details?.status ==
                                              'approved'
                                                ? $('#vert-tabs-business-eva-tab').hasClass('active')
                                                  ? 'nav-link rounded d-flex complete active'
                                                  : 'nav-link rounded d-flex complete'
                                                : $('#vert-tabs-business-eva-tab').hasClass('active')
                                                ? 'nav-link rounded d-flex active'
                                                : 'nav-link rounded d-flex'
                                            }
                                            id="vert-tabs-business-eva-tab"
                                            data-toggle="pill"
                                            href="#business_evaluation"
                                            role="tab"
                                            aria-controls="vert-tabs-business"
                                            aria-selected="false">
                                            Business Evaluation
                                          </a>
                                        )}

                                        {/* <a
                                          className="nav-link rounded d-flex"
                                          id="vert-tabs-settings-tab"
                                          data-toggle="pill"
                                          href="#vert-tabs-settings"
                                          role="tab"
                                          aria-controls="vert-tabs-settings"
                                          aria-selected="true">
                                          Create Workspace
                                        </a> */}
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                      <div className="border rounded p-4 h-100 tab-content" id="vert-tabs-tabContent">
                                        <div
                                          className="tab-pane text-left fade active show"
                                          id="email-verify"
                                          role="tabpanel"
                                          aria-labelledby="vert-tabs-email-tab">
                                          {/* <h3
                                            className="mb-3 verify_h"
                                            style={{
                                              display:
                                                this.state.user_info_details?.email_otp_status === 'yes'
                                                  ? 'none'
                                                  : 'block',
                                            }}>
                                            Verify Your Email
                                          </h3> */}
                                          <div
                                            className="verify_title"
                                            style={{
                                              display: this.state.user_info_details?.email_verified ? 'none' : 'block',
                                            }}>
                                            <p className="text-lg text-info fmaroon mt-3">
                                              Just to be sure that you operate a legitimate and genuine business, we
                                              need you to verify your e-mail address.
                                            </p>
                                            <div className="get_email_otp">
                                              <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    <i className="fa fa-envelope" />
                                                  </span>
                                                </div>
                                                <input
                                                  onChange={(e) =>
                                                    this.setState({
                                                      email_address_otp: e.target.value,
                                                    })
                                                  }
                                                  readonly="readonly"
                                                  value={this.state?.user_email}
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter your email id for sending OTP..."
                                                />
                                              </div>
                                              <button
                                                onClick={() => this.sentOtp('email')}
                                                type="button"
                                                className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3"
                                                id="things_get_email_otp">
                                                Send OTP
                                              </button>
                                            </div>
                                            <div className="submit_email_otp mt-3 mb-3" style={{display: 'none'}}>
                                              <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text">
                                                    <i className="fa fa-lock" />
                                                  </span>
                                                </div>
                                                <input
                                                  onChange={(e) =>
                                                    this.setState({
                                                      send_otp: e.target.value,
                                                    })
                                                  }
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter your OTP"
                                                />
                                              </div>
                                              <div className="bg-transparent mb-3" id="cvv_sent_otp">
                                                <button
                                                  onClick={() => this.subMitOtp('email')}
                                                  type="button"
                                                  className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3">
                                                  Submit
                                                </button>

                                                <p className="fmaroon mt-2">
                                                  <a
                                                    className="resend_otp fmaroon"
                                                    onClick={() => this.sentOtp('email')}
                                                    href="#"
                                                    style={{
                                                      display: 'none',
                                                    }}>
                                                    <span className="f_sz15">
                                                      <i className="fas fa-sync-alt mr-2"></i>Resend OTP&nbsp;
                                                    </span>
                                                  </a>
                                                  {/* <a
                                                    className="fmaroon resend_otp"
                                                    href="javascript:void(0)"
                                                    onClick={() => this.sentOtp('email')}>
                                                    {' '}
                                                    Resent OTP{' '}
                                                  </a>{' '} */}
                                                  <span className="timer">
                                                    <span className="time">60</span>&nbsp;Seconds
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="verify_success_title"
                                            style={{
                                              display: !this.state.user_info_details?.email_verified ? 'none' : 'block',
                                            }}>
                                            <p className="text-lg text-info fmaroon mt-3">
                                              Your email has been verified successfully.
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="tab-pane fade"
                                          id="phone-verify"
                                          role="tabpanel"
                                          aria-labelledby="vert-tabs-phoneno-tab">
                                          {/* <h3
                                            className="mb-3 verify_h"
                                            style={{
                                              display:
                                                this.state.user_info_details?.otp_status === 'yes' ? 'none' : 'block',
                                            }}>
                                            Verify Your Phone Number
                                          </h3> */}
                                          <p
                                            className="text-lg text-info fmaroon mt-3"
                                            style={{
                                              display:
                                                this.state.user_info_details?.phone_verified ||
                                                this.state.business_info_array?.busines_evaluation_details?.status ==
                                                  'approved'
                                                  ? 'none'
                                                  : 'block',
                                            }}>
                                            Just to be sure that you operate a legitimate and genuine business, we need
                                            you to verify phone number .
                                          </p>
                                          <div
                                            className="get_ph_otp"
                                            style={{
                                              display:
                                                this.state.user_info_details?.phone_verified ||
                                                this.state.business_info_array?.busines_evaluation_details?.status ==
                                                  'approved'
                                                  ? 'none'
                                                  : 'block',
                                            }}>
                                            <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                              {/* <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="fa fa-mobile" />
                                                </span>
                                              </div> */}
                                              {this.state.enabled_country_lists.length !== 0 ? (
                                                <IntlTelInput
                                                  defaultCountry={
                                                    this.state.dphone_number_country
                                                      ? this.state.dphone_number_country
                                                      : 'us'
                                                  }
                                                  containerClassName="intl-tel-input update_phone"
                                                  inputClassName="form-control form-control upt_cnts"
                                                  style={{width: '100 %'}}
                                                  onlyCountries={this.state.enabled_country_lists}
                                                  allowDropdown={true}
                                                  disabled={this.state.contact_deleted}
                                                  preferredCountries={[]}
                                                  placeholder="Phone Number"
                                                  // format={true}
                                                  value={this.state.dphone_number
                                                    .replace(/[^\dA-Z]/g, '')
                                                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                    .trim()}
                                                  onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                    console.log('countryData', countryData);
                                                    if (value.length <= 14) {
                                                      this.setState({dphone_number: value});
                                                    }
                                                    this.setState({
                                                      dphone_number_code: countryData.dialCode,
                                                    });
                                                  }}
                                                  onSelectFlag={(status, value, countryData, number, id) => {
                                                    console.log('value', value);
                                                    console.log('value.dialCode', value.dialCode);
                                                    this.setState({
                                                      dphone_number_code: value.dialCode,
                                                    });
                                                  }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                            <button
                                              onClick={() => this.sentOtp('phone')}
                                              type="button"
                                              className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3"
                                              id="things_clk_otp">
                                              Send OTP
                                            </button>
                                            {/* <a
                                              className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3"
                                              id="things_get_phone_otp">
                                              Verify
                                            </a> */}
                                          </div>
                                          <div
                                            className="submit_ph_otp mt-3 mb-3"
                                            style={{
                                              display: 'none',
                                            }}>
                                            <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  <i className="fa fa-lock" />
                                                </span>
                                              </div>
                                              <input
                                                onChange={(e) =>
                                                  this.setState({
                                                    send_otp: e.target.value,
                                                  })
                                                }
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your OTP"
                                              />
                                            </div>
                                            <div className="bg-transparent mb-3" id="cvv_sent_otp">
                                              <button
                                                onClick={() => this.subMitOtp('phone')}
                                                type="button"
                                                className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3">
                                                Submit
                                              </button>
                                              {/* <a
                                                className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3"
                                                id="things_get_email_otp">
                                                Submit
                                              </a> */}
                                              <p className="fmaroon mt-2">
                                                {' '}
                                                <a
                                                  className="resend_otp fmaroon"
                                                  onClick={() => this.sentOtp('phone')}
                                                  href="#"
                                                  style={{
                                                    display: 'none',
                                                  }}>
                                                  <span className="f_sz15">
                                                    <i className="fas fa-sync-alt mr-2"></i>Resend OTP&nbsp;
                                                  </span>
                                                </a>
                                                {/* <a
                                                  className="fmaroon resend_otp"
                                                  href="javascript:void(0)"
                                                  onClick={() => this.sentOtp('phone')}>
                                                  {' '}
                                                  Resent OTP{' '}
                                                </a>{' '} */}
                                                <span className="timer">
                                                  <span className="time">60</span>&nbsp;Seconds
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div
                                            className="verify_success_title"
                                            style={{
                                              display:
                                                this.state.user_info_details?.phone_verified ||
                                                this.state.business_info_array?.busines_evaluation_details?.status ==
                                                  'approved'
                                                  ? 'block'
                                                  : 'none',
                                            }}>
                                            <p className="text-lg text-info fmaroon mt-3">
                                              Your phone number has been verified successfully.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="tab-pane fade" id="vert-tabs-messages" role="tabpanel">
                                          {!this.state.user_info_details?.password ? (
                                            <>
                                              {/* <h3 className="mb-3 verify_h">Create Your password</h3> */}
                                              <p className="text-lg text-info fmaroon">
                                                Set your account password here. You can log in with your e-mail and
                                                password in the future, as well as SSO.
                                              </p>
                                              <div className="things_create_passord">
                                                <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      <i className="fa fa-lock" />
                                                    </span>
                                                  </div>
                                                  <input
                                                    type={this.state.passwordtype}
                                                    className="form-control"
                                                    placeholder="Enter your Password"
                                                    onChange={(e) => {
                                                      this.setState({password: e.target.value});
                                                    }}
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text eye_br_none">
                                                      <i
                                                        onClick={this.showHide}
                                                        className={
                                                          this.state.passwordtype === 'input'
                                                            ? 'fa fa-eye'
                                                            : 'fa fa-eye-slash'
                                                        }
                                                      />
                                                    </span>
                                                    <span
                                                      className="input-group-text eye_br_none"
                                                      style={{display: 'none'}}>
                                                      <i className="fa fa-eye" />
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="input-group mrgnbtm_20 frm_wid_md mt-3">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      <i className="fa fa-lock" />
                                                    </span>
                                                  </div>
                                                  <input
                                                    type={this.state.confirmpasswordtype}
                                                    className="form-control"
                                                    placeholder="Enter your confirm Password"
                                                    onChange={(e) => {
                                                      this.setState({cpassword: e.target.value});
                                                    }}
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text eye_br_none">
                                                      <i
                                                        onClick={this.showHide1}
                                                        className={
                                                          this.state.confirmpasswordtype === 'input'
                                                            ? 'fa fa-eye'
                                                            : 'fa fa-eye-slash'
                                                        }
                                                      />
                                                    </span>
                                                    <span
                                                      className="input-group-text eye_br_none"
                                                      style={{display: 'none'}}>
                                                      <i className="fa fa-eye" />
                                                    </span>
                                                  </div>
                                                </div>
                                                <button
                                                  onClick={() => this.submitPassword()}
                                                  type="button"
                                                  className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3">
                                                  Submit
                                                </button>
                                                {/* <a
                                              className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3"
                                              id="things_get_phone_otp">
                                              Submit
                                            </a> */}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                className="verify_success_title"
                                                style={{
                                                  display: !this.state.user_info_details?.password ? 'none' : 'block',
                                                }}>
                                                <p className="text-lg text-info fmaroon mt-3">
                                                  Your password has been set successfully.
                                                </p>
                                              </div>
                                            </>
                                          )}

                                          {/* <a class="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3" data-toggle="modal" data-placement="bottom" data-target="#things_do_create_pass" title="create Your password" data-original-title="create Your password">Create</a> */}
                                        </div>
                                        {this.state.business_info_array?.business?.user_id == this.state.user_id && (
                                          <div className="tab-pane fade" id="business_evaluation" role="tabpanel">
                                            <h3
                                              style={{
                                                display:
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'pending' ||
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'approved'
                                                    ? 'none'
                                                    : 'block',
                                              }}
                                              className="mb-3 verify_h">
                                              Verify Your Business
                                            </h3>
                                            <p
                                              style={{
                                                display:
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'pending' ||
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'approved'
                                                    ? 'none'
                                                    : 'block',
                                              }}
                                              className="text-lg text-info fmaroon mt-3">
                                              Help us check your company for authenticity. Provide us with valid
                                              information through this form.
                                            </p>
                                            {/* <div class="all_contentlft_450"> */}
                                            <div
                                              style={{
                                                display:
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'pending' ||
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                    'approved'
                                                    ? 'none'
                                                    : 'block',
                                              }}
                                              className="row mt-5">
                                              <div className="col-sm-12">
                                                <div className="cvv_business_vfy_div">
                                                  <div className="row mt-2">
                                                    <div className="col-sm-12">
                                                      <div
                                                        className="cvv_get_ph_name"
                                                        style={{
                                                          display: this.state.phone_verification ? 'block' : 'none',
                                                        }}>
                                                        <div className="form-group">
                                                          <label>Name</label>
                                                          <input
                                                            type="text"
                                                            onChange={(e) => this.setState({usr_name: e.target.value})}
                                                            className="form-control"
                                                            placeholder="Enter your full name..."
                                                            value={this.state.usr_name}
                                                          />
                                                        </div>
                                                        <div className="form-group">
                                                          <label>Phone Number</label>
                                                          {this.state.enabled_country_lists.length !== 0 ? (
                                                            <IntlTelInput
                                                              defaultCountry={'us'}
                                                              containerClassName="intl-tel-input"
                                                              inputClassName="form-control"
                                                              style={{width: '100 %'}}
                                                              allowDropdown={true}
                                                              onlyCountries={this.state.enabled_country_lists}
                                                              preferredCountries={[]}
                                                              placeholder="Enter the Phone Number"
                                                              value={this.state.user_phone_number
                                                                .replace(/[^\dA-Z]/g, '')
                                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                .trim()}
                                                              onPhoneNumberChange={(
                                                                status,
                                                                value,
                                                                countryData,
                                                                number,
                                                                id,
                                                              ) => {
                                                                console.log(value.length);
                                                                if (value.length < 15) {
                                                                  this.setState({user_phone_number: value});
                                                                }
                                                                this.setState({
                                                                  user_phone_number_code: countryData.dialCode,
                                                                });
                                                              }}
                                                              onSelectFlag={(
                                                                status,
                                                                value,
                                                                countryData,
                                                                number,
                                                                id,
                                                              ) => {
                                                                console.log('value', value);
                                                                this.setState({
                                                                  user_phone_number_code: value.dialCode,
                                                                });
                                                              }}
                                                            />
                                                          ) : (
                                                            ''
                                                          )}
                                                          {/* <input type="text" className="form-control" placeholder="Enter phone for sending OTP..." /> */}
                                                        </div>
                                                      </div>
                                                      <div className="cvv_ph_verify" style={{display: 'none'}}>
                                                        <div className="form-group row clearfix mb-3">
                                                          <div className="col-12 col-sm-12 col-md-12">
                                                            <div className="digit-group">
                                                              <label className="col-12 col-form-label text-center px-0 pt-0 font-weight-semibold">
                                                                Enter your OTP
                                                              </label>
                                                              <PinInput
                                                                length={4}
                                                                initialValue=""
                                                                // secret
                                                                onChange={(value, index) => {
                                                                  this.setState({user_otp: value});
                                                                }}
                                                                type="numeric"
                                                                inputMode="number"
                                                                style={{
                                                                  width: '250px',
                                                                  // height: "40px"
                                                                }}
                                                                inputStyle={{borderColor: 'red'}}
                                                                inputFocusStyle={{borderColor: 'blue'}}
                                                                onComplete={(value, index) => {}}
                                                                autoSelect={true}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="bg-transparent mb-3"
                                                        id="cvv_submit_otp"
                                                        style={{display: 'none'}}>
                                                        <div className="text-center mt-3 mb-3">
                                                          <button
                                                            type="button"
                                                            onClick={() => this.verify_number()}
                                                            className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">
                                                            Submit
                                                          </button>
                                                          <p className="fmaroon mt-2">
                                                            <div class="all_link mt-2 text-center ResendOtp">
                                                              <a
                                                                style={{display: 'none'}}
                                                                className="resend_otp_be fmaroon"
                                                                onClick={() => this.sentOtpBE()}
                                                                href="#">
                                                                <span class="f_sz15">
                                                                  <i class="fas fa-sync-alt mr-2"></i>Resend OTP&nbsp;
                                                                </span>
                                                              </a>
                                                              <span id="timer_be" className="fmaroon ">
                                                                <span id="time_be">60</span>&nbsp;Seconds
                                                              </span>
                                                            </div>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="cvv_business_detail"
                                                        style={{
                                                          display: this.state.form_verification ? 'block' : 'none',
                                                        }}>
                                                        <div className="form-group">
                                                          <label>Business Name</label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this.setState({business_name: e.target.value})
                                                            }
                                                            value={this.state.business_name}
                                                            placeholder="Enter your full name..."
                                                          />
                                                        </div>
                                                        <div className="form-group">
                                                          <label>Business Type</label>
                                                          <select
                                                            className="form-control"
                                                            id="business_type_drpdn"
                                                            onChange={(e) =>
                                                              this.setState({business_type_value: e.target.value})
                                                            }>
                                                            <option value="">Select</option>
                                                            {this.state?.business_type_details?.map(
                                                              (business_types_values) => {
                                                                return (
                                                                  <option value={business_types_values._id}>
                                                                    {business_types_values.business_type}
                                                                  </option>
                                                                );
                                                              },
                                                            )}
                                                            <option value="others">Others</option>
                                                          </select>
                                                        </div>
                                                        <div
                                                          className="form-group other_type"
                                                          style={{display: 'none'}}>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.business_type_name_value}
                                                            onChange={(e) =>
                                                              this.setState({business_type_name_value: e.target.value})
                                                            }
                                                            placeholder="Enter business type..."
                                                          />
                                                        </div>
                                                        <div className="form-group">
                                                          <label>Tax Number/ID/EIN</label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                              this.setState({tax_number: e.target.value})
                                                            }
                                                            placeholder="Enter ..."
                                                            value={this.state.tax_number}
                                                          />
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <div className="form-group bg_r_light p-3">
                                                              <label>Company Address</label>
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <div className="form-group">
                                                              <label>Street address</label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                  this.setState({address: e.target.value})
                                                                }
                                                                placeholder="Enter ..."
                                                                value={this.state.address}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <div className="form-group">
                                                              <label>City</label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) => this.setState({city: e.target.value})}
                                                                placeholder="Enter ..."
                                                                value={this.state.city}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <div className="form-group">
                                                              <label>State</label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                  this.setState({state_value: e.target.value})
                                                                }
                                                                value={this.state.state_value}
                                                                placeholder="Enter ..."
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-6">
                                                            <div className="form-group">
                                                              <label>ZIP/ Postal Code</label>
                                                              <input
                                                                type="number"
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                  this.setState({zip_code: e.target.value})
                                                                }
                                                                value={this.state.zip_code}
                                                                placeholder="Enter ..."
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="form-group">
                                                          <div className="col-sm-12">
                                                            <div className="form-group">
                                                              <label>Additional info...</label>
                                                              <textarea
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                  this.setState({
                                                                    additional_information: e.target.value,
                                                                  })
                                                                }
                                                                value={this.state.additional_information}
                                                                rows={3}
                                                                placeholder="Enter ..."
                                                                spellCheck="false"
                                                                defaultValue={''}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="bg-transparent mb-3"
                                                id="be_cvv_sent_otp"
                                                style={{display: this.state.phone_verification ? 'block' : 'none'}}>
                                                <div className="text-center mt-3 mb-3">
                                                  <button
                                                    type="button"
                                                    onClick={() => (this.state.otp_time ? this.sentOtpBE() : null)}
                                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">
                                                    Send OTP
                                                  </button>
                                                </div>
                                              </div>

                                              <div
                                                className="bg-transparent mb-3"
                                                style={{display: this.state.form_verification ? 'block' : 'none'}}
                                                id="cvv_submit_details">
                                                <div className="text-center mt-3 mb-3">
                                                  <button
                                                    type="button"
                                                    onClick={() => this.business_evaluation_submit()}
                                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2 ss">
                                                    Submit
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="verify_success_title"
                                              style={{
                                                display:
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                  'pending'
                                                    ? 'block'
                                                    : 'none',
                                              }}>
                                              <p className="text-lg text-info fmaroon mt-3">
                                                Thank you for getting us the information. We will evaluate and get back
                                                to you at the earliest. Keep an eye on your email.
                                              </p>
                                            </div>
                                            <div
                                              className="verify_success_title"
                                              style={{
                                                display:
                                                  this.state.business_info_array?.busines_evaluation_details?.status ==
                                                  'approved'
                                                    ? 'block'
                                                    : 'none',
                                              }}>
                                              <p className="text-lg text-info fmaroon mt-3">
                                                Your business was reviewed and approved to use the premium features of
                                                Redtie.
                                              </p>
                                            </div>
                                          </div>
                                        )}

                                        <div
                                          className="tab-pane fade"
                                          id="vert-tabs-settings"
                                          role="tabpanel"
                                          aria-labelledby="vert-tabs-settings-tab">
                                          <h3 className="mb-3 verify_h">Create your workspace</h3>
                                          <p className="text-lg text-info fmaroon">
                                            Let us know more about your business. Update your workspace info.
                                          </p>
                                          <a
                                            href="/create-profile?create=true"
                                            className="btn btn-black-dark btn-lg crsr_pntr f_sz15 mt-3">
                                            Create
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row position-relative mx-0 mt-2 welcome_dashboard">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                        <div className="all_dashboard_div">
                          <div className="w-90 shadow-none brdr_radius_div">
                            <div className="banner flex-fill card">
                              <div className="p-4 card-body row">
                                <div className="message d-flex flex-column pr-0 pr-lg-4 pl-lg-4 pb-4 pb-lg-0 col-lg-6 col-md-12">
                                  <h1 className="mb-4">See How Businesses Can Use Two-Way Messaging</h1>
                                  <p className="text-lg text-info mb-3 fmaroon">
                                    Surveys | Voting | Feedback | Personalised offers | Payments | Contests | Opt in/
                                    Opt Out Campaigns
                                  </p>
                                </div>
                                <div className="features pt-4 pt-lg-0 px-0 mx-3 mx-lg-0 px-lg-4 col-lg-6 col-md-12">
                                  <h3 className="mb-3">Our New Features</h3>
                                  <div className="bd-example mb-3 h-100">
                                    <div
                                      id="carouselExampleCaptions"
                                      className="carousel slide pointer-event"
                                      data-ride="carousel">
                                      <div className="carousel-inner mt-4">
                                        <div className="carousel-item active">
                                          <div className="d-flex align-items-center mb-3">
                                            <h5 className="mb-0 fmaroon">Buy a New Phone Number</h5>
                                          </div>
                                          {/* <p class="text-info mb-0 pb-2 fmaroon">Buy a New Phone Number</p> */}
                                        </div>
                                        <div className="carousel-item">
                                          <div className="d-flex align-items-center mb-3">
                                            <h5 className="mb-0 fmaroon">Bring your Own Phone Number for messaging</h5>
                                          </div>
                                          {/* <p class="text-info mb-0 pb-2 fmaroon">A workspace may be created with Redtie providing the number or you can bring your own provider credentials.</p> */}
                                        </div>
                                        <div className="carousel-item">
                                          <div className="d-flex align-items-center mb-3">
                                            <h5 className="mb-0 fmaroon">Business Utility Apps</h5>
                                          </div>
                                          {/* <p class="text-info mb-0 pb-2 fmaroon">If your business requires messages to be archived, Redtie provides this feature out of the box. Just turn it on and stay compliant.</p> */}
                                        </div>
                                        <div className="carousel-item">
                                          <div className="d-flex align-items-center mb-3">
                                            <h5 className="mb-0 fmaroon">Message Archival</h5>
                                          </div>
                                          {/* <p class="text-info mb-0 pb-2 fmaroon">If your business requires messages to be archived, Redtie provides this feature out of the box. Just turn it on and stay compliant.</p> */}
                                        </div>
                                        <div className="carousel-item">
                                          <div className="d-flex align-items-center mb-3">
                                            <h5 className="mb-0 fmaroon">Message Automation with Workflows</h5>
                                          </div>
                                          {/* <p class="text-info mb-0 pb-2 fmaroon">Make use of Redtie's code-free workflow to automate messaging workloads. If you are a bit more tech savvy, make use of the webhooks for deeper integrations.</p> */}
                                        </div>
                                      </div>
                                      <ol className="carousel-indicators">
                                        <li data-target="#carouselExampleCaptions" data-slide-to={0} className />
                                        <li data-target="#carouselExampleCaptions" data-slide-to={1} className />
                                        <li
                                          data-target="#carouselExampleCaptions"
                                          data-slide-to={2}
                                          className="active"
                                        />
                                        <li data-target="#carouselExampleCaptions" data-slide-to={3} className />
                                        <li data-target="#carouselExampleCaptions" data-slide-to={4} className />
                                        <li data-target="#carouselExampleCaptions" data-slide-to={5} className />
                                      </ol>
                                      <a
                                        className="carousel-control-prev"
                                        href="#carouselExampleCaptions"
                                        role="button"
                                        data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                                        <span className="sr-only">Previous</span>
                                      </a>
                                      <a
                                        className="carousel-control-next"
                                        href="#carouselExampleCaptions"
                                        role="button"
                                        data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true" />
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row position-relative mx-0 mt-2 welcome_dashboard">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                        <div className="all_dashboard_div">
                          <div className="w-90 shadow-none brdr_radius_div">
                            <div className="banner flex-fill card">
                              <div className="apps_marketplace all_contentflex_div mt-2 mb-3 pl-5 pr-5 pt-3 justify-content-center">
                                <div className="pr-0 pr-lg-4 pb-lg-0 position-relative col-xl-12 col-lg-12 col-md-12">
                                  <h3 className="mb-3">
                                    Apps.{' '}
                                    <span className="fmaroon">
                                      Check out our amazing pool of business utility apps.{' '}
                                    </span>
                                  </h3>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <div className="card bx_shadow_sm">
                                    {/* /.card-header */}
                                    <div className="card-body box-profile">
                                      <a href="/qrcode">
                                        {' '}
                                        <img className="img-fluid" src={qr_code} alt="User profile picture" />{' '}
                                      </a>
                                    </div>
                                    {/* /.card-body */}
                                    {/* /.card-footer */}
                                  </div>
                                  {/* /.card */}
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <div className="card bx_shadow_sm">
                                    {/* /.card-header */}
                                    <div className="card-body box-profile">
                                      <a href="/nftapp">
                                        {' '}
                                        <img className="img-fluid" src={nft} alt="User profile picture" />{' '}
                                      </a>
                                    </div>
                                    {/* /.card-body */}
                                    {/* /.card-footer */}
                                  </div>
                                  {/* /.card */}
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <div className="card bx_shadow_sm">
                                    {/* /.card-header */}
                                    <div className="card-body box-profile">
                                      <a href="/ftapp">
                                        {' '}
                                        <img className="img-fluid" src={ft} alt="User profile picture" />{' '}
                                      </a>
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                </div>
                                {/* /.col */}
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                  <div className="card bx_shadow_sm">
                                    {/* /.card-header */}
                                    <div className="card-body box-profile">
                                      <a href="/paraphraseapp">
                                        {' '}
                                        <img className="img-fluid" src={paraphrase} alt="User profile picture" />{' '}
                                      </a>
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                </div>
                                {/* /.col */}
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                  <div className="mt-4 text-center">
                                    <a
                                      href="/apps"
                                      className="btn btn-black-dark btn-lg crsr_pntr f_sz16 sendmessagebtn_clk1">
                                      <span className="d-md-inline-block ml-2">See more</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row position-relative mx-0 mt-3">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                        <div className="all_dashboard_div">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                            <div className="w-90 d-flex flex-row flex-wrap">
                              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="card card-widget widget-user brdrbtm_yellow3 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user">
                            <div className="w-100 d-flex flex-row flex-wrap">
                                    <div
                                      className="widget_wkspce_created"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title
                                      data-original-title="Created: 5 June 2021 9:45 pm">
                                      Active for:{' '}
                                      {this.state.dashboard_extra_details?.active_date?.year !== undefined &&
                                      this.state.dashboard_extra_details?.active_date?.year !== 0
                                        ? this.state.dashboard_extra_details?.active_date?.year > 1
                                          ? this.state.dashboard_extra_details?.active_date?.year + ' Years'
                                          : this.state.dashboard_extra_details?.active_date?.year + ' Year'
                                        : this.state.dashboard_extra_details?.active_date?.month !== undefined &&
                                          this.state.dashboard_extra_details?.active_date?.month !== 0
                                        ? this.state.dashboard_extra_details?.active_date?.month > 1
                                          ? this.state.dashboard_extra_details?.active_date?.month + ' Months'
                                          : this.state.dashboard_extra_details?.active_date?.month + ' Month'
                                        : this.state.dashboard_extra_details?.active_date?.week !== undefined &&
                                          this.state.dashboard_extra_details?.active_date?.week !== 0
                                        ? this.state.dashboard_extra_details?.active_date?.week > 1
                                          ? this.state.dashboard_extra_details?.active_date?.week + ' Weeks'
                                          : this.state.dashboard_extra_details?.active_date?.week + ' Week'
                                        : this.state.dashboard_extra_details?.active_date?.day !== undefined &&
                                          this.state.dashboard_extra_details?.active_date?.day !== 0
                                        ? this.state.dashboard_extra_details?.active_date?.day > 1
                                          ? this.state.dashboard_extra_details?.active_date?.day + ' Days'
                                          : this.state.dashboard_extra_details?.active_date?.day + ' Day'
                                        : this.state.dashboard_extra_details?.active_date?.hour !== undefined &&
                                          this.state.dashboard_extra_details?.active_date?.hour !== 0
                                        ? this.state.dashboard_extra_details?.active_date?.hour > 1
                                          ? this.state.dashboard_extra_details?.active_date?.hour + ' Hours'
                                          : this.state.dashboard_extra_details?.active_date?.hour + ' Hour'
                                        : ''}
                                    </div>
                                    {this.state.business_info_array?.business?.user_id !== this.state.user_id ? (
                                      <div class="widget_assigned">
                                        <span class="badge badge-assigned f_sz12">Assigned</span>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <div className="col-9 col-sm-9 col-md-9 col-lg-12 col-xl-12">
                                      <div className="card-body mt-4">
                                        <div className="row text-center border-bottom pb-4">
                                          <div className="col-sm-4 border-right">
                                            <div className="description-block">
                                              <div className="description-header">
                                                <h3 className="description-header f_sz18">
                                                  <span className="widget-user-image">
                                                    <div className="dash_workspacelogo">
                                          {this.state.business_info_array?.business?.profile_image ? (
                                                        <img
                                                          src={this.state.business_info_array?.business.profile_image}
                                                        />
                                          ) : (
                                            <span
                                              class="dashworkspacecircle"
                                              style={{
                                                color: this.state.business_info_array?.business?.color_code
                                                  ? this.state.business_info_array?.business?.color_code
                                                  : '#097952',
                                              }}>
                                              {this.state.business_info_array?.business?.business_name
                                                ? this.state.business_info_array?.business?.business_name
                                                    .substring(1, 0)
                                                    .toUpperCase()
                                                : 'I'}
                                            </span>
                                          )}
                                        </div>
                                                  </span>
                                                </h3>
                                                <span className="description-text">
                                                  {this.state.business_info_array?.business?.business_name
                                                    ? this.state.business_info_array?.business?.business_name
                                                    : ''}{' '}
                                                  <i className="fas fa-pencil-alt ws_pen" />{' '}
                                                </span>
                                        <div
                                                  className="form-horizontal formbiglbl ws_nme_edit"
                                                  style={{display: 'none'}}>
                                                  <div className="card-body px-0">
                                                    <div className="form-group row mb-2">
                                                      <div className="col-12 col-sm-12 col-md-12 position-relative">
                                                        <input
                                                          type="text"
                                                          className="form-control text-center"
                                                          id="get_wsnme"
                                                          defaultValue
                                                          placeholder="Enter Workspace Name"
                                                        />
                                        </div>
                                      </div>
                                        </div>
                                      </div>
                                        </div>
                                      </div>
                                        </div>
                                          <div className="col-sm-4 border-right">
                                            <div className="description-block">
                                              <h5 className="description-header f_sz18">Plan</h5>
                                              <div className="font-weight-bold fmaroon f_sz18">
                                                {this.state.business_info_array?.business?.plan_id?.plan_name}
                                      </div>
                                              {this.state.business_info_array?.business?.plan_id?.plan_name ==
                                              'Free' ? (
                                        <>
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.upgradeWorkspace(
                                                        '/create-profile?bid=' + this.state?.business_id,
                                                      )
                                                }
                                                    class="btn btn-black-dark btn-xs btnpdng_sm2 p-3 mt-2">
                                                    Upgrade
                                                  </a>
                                        </>
                                      ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-sm-4">
                                            {this.state.business_info_array?.business?.plan_id?.plan_name == 'Free' ? (
                                              <>
                                                <div className="description-block">
                                                  <h3 className="description-header f_sz18">
                                                    Get Dedicated Phone Number
                                                  </h3>
                                                  <div className="mt-3">
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    this.upgradeWorkspace(
                                                      '/create-profile?bid=' + this.state?.business_id,
                                                    )
                                                  }
                                                      class="btn btn-black-dark btn-xs btnpdng_sm2 p-3 mt-2">
                                                  Upgrade
                                                </a>
                                            </div>
                                          </div>
                                              </>
                                            ) : (
                                              ''
                                      )}
                                            </div>
                                            </div>
                                        <div className="row text-center mt-5">
                                          <div className="col-sm-4 border-right">
                                            <div className="description-block">
                                              <div className="description-header">
                                            <div
                                                  className="detailscount_wrapper bg_dashgreen mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                                  title={this.state.business_info_array?.contacts?.total + '- Contacts'}
                                                  data-original-title="Contacts">
                                                  <span>{this.state.business_info_array?.contacts?.total}</span>
                                            </div>
                                                <div className="f_sz12 font-weight-bold text-center mt-1">
                                                  Total Contacts
                                            </div>
                                          </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-4 border-right">
                                            <div className="description-block">
                                              <div className="description-header">
                                          <div
                                                  className="detailscount_wrapper bg_dashred mb-2"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title
                                                  data-original-title="Contacts">
                                                  <span>
                                                    {this.state.dashboard_extra_details?.messages !== undefined
                                                      ? this.display_contcat_count(
                                                          this.state.dashboard_extra_details?.messages,
                                                        )
                                                      : '0'}
                                                  </span>
                                            </div>
                                                <div className="f_sz12 font-weight-bold text-center mt-1">
                                                  Total Messages
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-4">
                                            <div className="description-block">
                                              <div className="description-header" style={{visibility: 'hidden'}}>
                                            <div
                                                  className="detailscount_wrapper bg_dashgreen mb-2"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              title
                                                  data-original-title="Contacts">
                                                  <span>0</span>
                                            </div>
                                                <div className="f_sz12 font-weight-bold text-center mt-1">
                                                  Total Contacts
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="all_dashboard_div">
                                  <div className="w-100 card brdr_dashboxes brdrbtm_green1 shadow-none brdr_radius_div acctdashbrd_div">
                                    <div className="card-header px-1">
                                      <div className="w-100 d-flex flex-row flex-wrap">
                                        <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                                          <h5 className="font-weight-bold mb-0">Billing and Usage</h5>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                          <a className="viewpackshistory_clk crsr_pntr packs_txt" style={{}}>
                                            <div className="fmaroon text-right pt-1">Message Packs</div>
                                          </a>
                                          <a
                                            className="viewcurrentbilling_clk crsr_pntr backbilling_txt"
                                            style={{display: 'none'}}>
                                            <div className="fmaroon text-right pt-1">Back to Billing</div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body p-0">
                                      <div className="viewusage_div">
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                            <div className="mt-2 pt-1 text-left">
                                              <span className="fmaroon font-weight-bold">Current Billing:</span>{' '}
                                              {moment(
                                                this.state.dashboard_billing_details?.billing_usage?.from_date,
                                              ).format('MM/DD/YYYY')}{' '}
                                              to{' '}
                                              {moment(
                                                this.state.dashboard_billing_details?.billing_usage?.to_date,
                                              ).format('MM/DD/YYYY')}
                                            </div>
                                          </div>
                                          <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                                            <div className="w-100 d-inline-block text-right mt-2 pt-1">
                                              <a className="viewbillinghistory_clk crsr_pntr  mr-2">
                                                <span className="fmaroon">
                                                  <i
                                                    className="fas fa-history f_sz18"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="View History"
                                                    data-original-title="View History"
                                                  />
                                                </span>
                                              </a>
                                              <a className="viewbillingstatus_clk crsr_pntr">
                                                <span className="fmaroon">
                                                  <i
                                                    className="fas fa-file-invoice f_sz18"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="View Billing Status"
                                                    data-original-title="View Billing Status"
                                                  />
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer bg-transparent pt-3">
                                          <div className="row mt-4 pt-3 f_12_span">
                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                              <div className="text-center w-100 d-block">
                                                <h3 className="font-weight-bold fmaroon">
                                                  {this.state.dashboard_billing_details?.billing_usage?.usage}
                                                </h3>
                                                <span className="font-weight-bold">Used</span>
                                              </div>
                                              {/* <div
                                                className="detailscount_wrapper bg_dashgreen mb-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Used"
                                                data-original-title="Used">
                                                <span>
                                                  {this.state.dashboard_billing_details?.billing_usage?.usage}
                                                </span>
                                              </div>
                                              <div className="text-center w-100 d-block">
                                                <span className="font-weight-bold">Used</span>
                                              </div> */}
                                            </div>
                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                              <div className="text-center w-100 d-block">
                                                <h3 className="font-weight-bold fmaroon">
                                                  {this.state.dashboard_billing_details?.billing_usage?.sent}
                                                </h3>
                                                <span className="font-weight-bold">Sent</span>
                                              </div>
                                              {/* <div
                                                className="detailscount_wrapper bg_dashorange mb-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Sent"
                                                data-original-title="Sent">
                                                <span>{this.state.dashboard_billing_details?.billing_usage?.sent}</span>
                                              </div>
                                              <div className="text-center w-100 d-block">
                                                <span className="font-weight-bold">Sent</span>
                                              </div> */}
                                            </div>
                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                              <div className="text-center w-100 d-block">
                                                <h3 className="font-weight-bold fmaroon">
                                                  {this.state.dashboard_billing_details?.billing_usage?.received}
                                                </h3>
                                                <span className="font-weight-bold">Received</span>
                                              </div>
                                              {/* <div
                                                className="detailscount_wrapper bg_dashred mb-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Received"
                                                data-original-title="Received"
                                                data-bal={
                                                  this.state.dashboard_billing_details?.billing_usage
                                                    ?.available_message <= 0
                                                }>
                                                <span>
                                                  {this.state.dashboard_billing_details?.billing_usage?.received}
                                                </span>
                                              </div>
                                              <div className="text-center w-100 d-block">
                                                <span className="font-weight-bold">Received</span>
                                              </div> */}
                                            </div>
                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                              <div className="text-center w-100 d-block">
                                                <h3 className="font-weight-bold fmaroon">
                                                  {' '}
                                                  {
                                                    this.state.dashboard_billing_details?.billing_usage
                                                      ?.available_message
                                                  }
                                                </h3>
                                                <span className="font-weight-bold">Remaining</span>
                                              </div>
                                              {/* <div
                                                className="detailscount_wrapper bg_dashblue mb-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Remaining">
                                                <span>
                                                  {
                                                    this.state.dashboard_billing_details?.billing_usage
                                                      ?.available_message
                                                  }
                                                </span>
                                              </div>
                                              <div className="text-center w-100 d-block">
                                                <span className="font-weight-bold">Remaining</span>
                                              </div> */}
                                              {/* <div class="f_sz12 text-center mt-1">Freq. used Contacts</div> */}
                                            </div>
                                            {this.state.dashboard_billing_details?.billing_usage?.usage == 0 ? (
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <a
                                                  onClick={() => this.redirectToPage('/board?compose_message=true')}
                                                  // href="/board?compose_message=true"
                                                >
                                                  <div className="text-center pt-3 pb-2">
                                                    <span className="btn btn-sm1 btnpdng_sm2 btn-black">
                                                      Send a Message
                                                    </span>
                                                  </div>
                                                </a>
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                          {this.state.dashboard_billing_details?.billing_usage?.available_message <=
                                          0 ? (
                                            <>
                                              <div className="row mt-3 border-top mt-5">
                                                <div
                                                  className="col-12 col-sm-12 col-md-12 col-lg-12 pt-4 mt-2 text-center"
                                                  style={{minHeight: 100}}>
                                                  {/* <i className="fas fa-exclamation-triangle mr-1 fred" />  */}
                                                  Buy message packs to continue messaging.{' '}
                                                  <a href="/profile_settings?pack_buynew=true">
                                                    <span className="btn btn-sm1 btnpdng_sm2 btn-black ml-2 mt-1">
                                                      Buy Message Packs
                                                    </span>
                                                  </a>
                                                </div>
                                                {/* /.col */}
                                              </div>
                                            </>
                                          ) : this.state.dashboard_billing_details?.billing_usage?.available_message <=
                                              9 &&
                                            this.state.business_info_array?.business?.plan_id?.plan_name !== 'Free' ? (
                                            <>
                                              <div className="row border-top mt-2">
                                                <div
                                                  className="col-12 col-sm-12 col-md-12 col-lg-12 pt-4 mt-2"
                                                  style={{minHeight: 100}}>
                                                  <i className="fas fa-exclamation-triangle mr-1 fred" /> You almost
                                                  reached your message limit. Buy message packs to continue messaging.{' '}
                                                  <a href="/profile_settings?pack_buynew=true">
                                                    <span className="btn btn-sm1 btnpdng_sm2 btn-black ml-2 mt-1">
                                                      Buy Message Packs
                                                    </span>
                                                  </a>
                                                </div>
                                                {/* /.col */}
                                              </div>
                                            </>
                                          ) : (
                                            ''
                                          )}

                                          {this.state.business_info_array?.business?.plan_id?.plan_name === 'Free' ? (
                                            <div
                                              className={
                                                this.state.dashboard_billing_details?.billing_usage?.usage == 0
                                                  ? 'row border-top mt-2 pt-6 mb-2'
                                                  : 'row border-top mt-5 pt-3 mb-2'
                                              }
                                              style={{minHeight: 100}}>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div
                                                  className="detailscount_wrapper"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Your Plan">
                                                  FREE
                                                </div>
                                                <div className="text-center w-100 d-block">
                                                  <span>
                                                    <span
                                                      className="btn btn-black-dark btn-xs btnpdng_sm1"
                                                      onClick={() =>
                                                        this.upgradeWorkspace(
                                                          '/create-profile?bid=' + this.state?.business_id,
                                                        )
                                                      }>
                                                      Upgrade
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : this.state.dashboard_billing_details?.billing_usage?.available_message <=
                                              0 ||
                                            this.state.dashboard_billing_details?.billing_usage?.available_message <=
                                              9 ? (
                                            ''
                                          ) : (
                                            <div
                                              className="row border-top mt-2 plan_details_div"
                                              style={{display: 'none'}}>
                                              <div
                                                className="col-12 col-sm-12 col-md-12 col-lg-12 pt-4 mt-2"
                                                style={{minHeight: 100}}>
                                                <div>
                                                  <div className="detailscount_wrapper">
                                                    <div
                                                      className="phnumberimg_lrge_md"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      title={
                                                        this.state.businessprofiledetails?.authentication?.type ===
                                                        'plivo'
                                                          ? 'Own Service Provider - Plivo'
                                                          : this.state.businessprofiledetails?.authentication?.type ===
                                                            'twilio'
                                                          ? 'Own Service Provider - Twilio'
                                                          : this.state.businessprofiledetails?.authentication?.type ===
                                                            'bandwidth'
                                                          ? 'Own Service Provider - Bandwith'
                                                          : 'Service Provider'
                                                      }
                                                      data-original-title="Service Provider">
                                                      <img
                                                        src={
                                                          this.state.businessprofiledetails?.authentication?.type ===
                                                          'plivo'
                                                            ? plivo_logo
                                                            : this.state.businessprofiledetails?.authentication
                                                                ?.type === 'twilio'
                                                            ? twilio_logo
                                                            : this.state.businessprofiledetails?.authentication
                                                                ?.type === 'bandwidth'
                                                            ? bandwidth_logo
                                                            : redtie_logo
                                                        }
                                                      />
                                                      {/* <img src="../dist/img/redtie_logo.png" /> */}
                                                    </div>
                                                  </div>
                                                  <div className="text-center w-100 d-block">
                                                    <span className="font-weight-bold">
                                                      {this.state.businessprofiledetails?.flag === 'appsumo'
                                                        ? 'Appsumo'
                                                        : 'PAID'}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="viewbillingpackhistory_div" style={{display: 'none'}}>
                                        <div
                                          className="table-responsive scrollbar_style dashboard_listhgt3"
                                          style={{
                                            display:
                                              this.state?.pack_history_details.length != 0 ? 'inline-block' : 'none',
                                          }}>
                                          <DataTable
                                            columns={pack_history_columns}
                                            data={this.state?.pack_history_details}
                                            defaultSortFieldId="historypacksDate"
                                            pagination
                                            responsive
                                            subHeaderAlign="right"
                                            subHeaderWrap
                                            paginationPerPage="5"
                                            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            // defaultSortFieldId={1
                                          />
                                        </div>
                                        <div
                                          className="card-body py-0"
                                          style={{
                                            display:
                                              this.state?.pack_history_details.length == 0 ? 'inline-block' : 'none',
                                          }}>
                                          <div className="table-responsive scrollbar_style dashboard_listhgt2">
                                            <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center mt-5 pt-5">
                                              <div className="text-center f_sz16 mb-3 mt-3 w-100">
                                                You haven't bought any message packs.
                                              </div>
                                              <a href="/profile_settings?pack_buynew=true" class="btn btn-black ml-2">
                                                Buy Now
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="viewbillinghistory_div" style={{display: 'none'}}>
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            {/* <div className="d-inline-block">
                                              <a>
                                                <span>
                                                  <i className="fas fa-filter fmaroon" />
                                                </span>
                                              </a>
                                            </div> */}
                                            {/* <div className="d-inline-block chrtfltr_100">
                                              <input type="text" className="form-control" defaultValue="July" />
                                            </div> */}
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-2 pt-1 text-right">
                                              <a className="viewusage_clk crsr_pntr">
                                                <span className="fmaroon">Back</span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="table-responsive scrollbar_style dashboard_listhgt3">
                                          <DataTable
                                            className="responsive-table site-headers pagination-align-left"
                                            columns={history_columns}
                                            data={this.state?.history_details}
                                            // direction="auto"
                                            // fixedHeader
                                            // fixedHeaderScrollHeight="300px"
                                            defaultSortFieldId="historyDate"
                                            pagination
                                            responsive
                                            subHeaderAlign="right"
                                            subHeaderWrap
                                            paginationPerPage="5"
                                            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            // defaultSortFieldId={1}
                                            defaultSortAsc={false}
                                          />
                                          {/* <DataTable
                                            id="htable_with_data"
                                          //paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            columns={history_columns}
                                            data={this.state?.history_details}
                                          pagination="true"
                                          paginationTotalRows="4"
                                          paginationPerPage='4'
                                          // theme="solarized"
                                          paginationComponent="Pagination"
                                          paginationComponentOptions={{noRowsPerPage: true}}
                                          customStyles={customStyles}
                                        /> */}
                                        </div>
                                      </div>
                                      <div className="viewbillingstatus_div" style={{display: 'none'}}>
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            {/* <div className="d-inline-block">
                                              <a>
                                                <span>
                                                  <i className="fas fa-filter fmaroon" />
                                                </span>
                                              </a>
                                            </div> */}
                                            {/* <div className="d-inline-block chrtfltr_100">
                                              <input type="text" className="form-control" defaultValue="July" />
                                            </div> */}
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-2 pt-1 text-right">
                                              <a className="viewusage_clk crsr_pntr">
                                                <span className="fmaroon">Back</span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {this.state?.billing_status?.length != 0 ? (
                                          <>
                                            <div className="table-responsive scrollbar_style dashboard_listhgt3">
                                              <DataTable
                                                className="responsive-table site-headers pagination-align-left"
                                                columns={billingstatus_columns}
                                                data={this.state?.billing_status}
                                                // direction="auto"
                                                // fixedHeader
                                                // fixedHeaderScrollHeight="300px"
                                                defaultSortFieldId="paidDate"
                                                pagination
                                                responsive
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                paginationPerPage="5"
                                                paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                // defaultSortFieldId={1}
                                                defaultSortAsc={false}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center mt-5 pt-5">
                                              <div className="text-center f_sz16 mb-3 mt-3 w-100">
                                                {this.state.business_info_array?.business?.plan_id?.plan_name === 'Free'
                                                  ? 'This workspace is on FREE plan and nothing to show here.'
                                                  : this.state.businessprofiledetails?.flag === 'appsumo'
                                                  ? 'This workspace is on AppSumo one year deal.'
                                                  : ''}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <div className="all_dashboard_div">
                                  <div className="w-100 card brdr_dashboxes brdrbtm_yellow3 shadow-none brdr_radius_div">
                                    <div className="card-header px-1">
                                      <div className="w-100 d-flex flex-row flex-wrap">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                          <h5 className="font-weight-bold mb-0">Redtie Bot</h5>
                                        </div>
                                        {/* <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right"><a href="#" class="fmaroon"><i class="fas fa-filter"></i></a></div> */}
                                      </div>
                                    </div>
                                    <div className="card-body p-0">
                                      <div className="table-responsive scrollbar_style dashboard_listhgt2">
                                        {this.state?.rbot_conversation?.length !== 0 ? (
                                          <table
                                            width="100%"
                                            className="table table-hover dash_redtiebot_tbl"
                                            cellPadding={0}
                                            cellSpacing={0}>
                                            <thead>
                                              <tr>
                                                <th>&nbsp;</th>
                                                <th>Messages</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state?.rbot_conversation?.map((rbot_conversations, index) => {
                                                var displayval = 1;
                                                return rbot_conversations?.attachment_url === 'true' ? (
                                                  rbot_conversations.parent_text_message ? (
                                                    <tr>
                                                      <td>
                                                        <span className="usercircleimg_sm1 mr-3">
                                                          <img
                                                            src={
                                                              rbot_conversations?.user_info[0]?.profile_image
                                                                ? rbot_conversations?.user_info[0]?.profile_image
                                                                : ''
                                                            }
                                                            border={0}
                                                          />
                                                        </span>
                                                      </td>
                                                      <td>
                                                        {rbot_conversations.parent_text_message}{' '}
                                                        <a className="fmaroon crsr_pntr mr-1">
                                                          {rbot_conversations.parent_shorten_url}
                                                        </a>
                                                        <span className="font-weight-bold">
                                                          {rbot_conversations?.user_info[0]?.first_name
                                                            ? rbot_conversations?.user_info[0]?.first_name
                                                            : rbot_conversations?.from_source}
                                                        </span>
                                                        <div class="d-block w-100 text-xs text-muted">
                                                          <i class="far fa-clock mr-2"></i>{' '}
                                                          {this.find_relative_time(rbot_conversations.updated_date)}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <tr>
                                                      <td>Currently Unavailable</td>
                                                    </tr>
                                                  )
                                                ) : rbot_conversations?.spam_flagged &&
                                                  this.state.business_info_array?.business?.number_change
                                                    ?.is_spam_flagged ? (
                                                  <>
                                                    <tr>
                                                      <td>
                                                        <span className="usercircleimg_sm1 usercircleimg_nobrdr mr-3">
                                                          <img src={redtie_bot} className="" border="0" />
                                                        </span>
                                                      </td>
                                                      <td>
                                                        Your number has been flagged as spam by a carrier network.
                                                        Please pick a new number to send messages now.{' '}
                                                        <div>
                                                          <a
                                                            href="/profile_settings?change_number=true"
                                                            className="fmaroon font-weight-bold">
                                                            Pick a new number
                                                          </a>
                                                        </div>
                                                        <div class="d-block w-100 text-xs text-muted">
                                                          <i class="far fa-clock mr-2"></i>{' '}
                                                          {this.find_relative_time(rbot_conversations.updated_date)}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="tbl_actionbtns text-center">
                                                          <span className="dropdown redtiebot_drpdwn d-inline-block">
                                                            <a data-toggle="dropdown" href="#">
                                                              <i
                                                                className="far fa-calendar-alt fmaroon f_sz16 crsr_pntr"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                data-original-title="Set Reminder"
                                                                title="Set Reminder"
                                                              />
                                                            </a>
                                                            <span className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                              <span className="scrollbar_style">
                                                                <span className="dropdown-item dropdown-header f_sz14 text-left fmaroon font-weight-semibold">
                                                                  Remind me about this
                                                                </span>
                                                                <span className="dropdown-divider" />
                                                                <span className="dropdown-item">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                      this.set_reminder(
                                                                        rbot_conversations._id,
                                                                        rbot_conversations.contact,
                                                                        '20mins',
                                                                      );
                                                                    }}
                                                                    className="fgrey">
                                                                    In 20 minutes
                                                                  </a>
                                                                </span>
                                                                <span className="dropdown-divider" />
                                                                <span className="dropdown-item">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                      this.set_reminder(
                                                                        rbot_conversations._id,
                                                                        rbot_conversations.contact,
                                                                        '1hr',
                                                                      );
                                                                    }}
                                                                    className="fgrey">
                                                                    In 1 hour
                                                                  </a>
                                                                </span>
                                                                <span className="dropdown-divider" />
                                                                <span className="dropdown-item">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                      this.set_reminder(
                                                                        rbot_conversations._id,
                                                                        rbot_conversations.contact,
                                                                        '3hr',
                                                                      );
                                                                    }}
                                                                    className="fgrey">
                                                                    In 3 hours
                                                                  </a>
                                                                </span>
                                                                <span className="dropdown-divider" />
                                                                <span className="dropdown-item">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                      this.set_reminder(
                                                                        rbot_conversations._id,
                                                                        rbot_conversations.contact,
                                                                        '1day',
                                                                      );
                                                                    }}
                                                                    className="fgrey">
                                                                    Tomorrow
                                                                  </a>
                                                                </span>
                                                                <span className="dropdown-divider" />
                                                                <span className="dropdown-item">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                      this.set_reminder(
                                                                        rbot_conversations._id,
                                                                        rbot_conversations.contact,
                                                                        '7days',
                                                                      );
                                                                    }}
                                                                    className="fgrey">
                                                                    Next week
                                                                  </a>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          {/* <span class="d-inline-block"><a href="#" data-toggle="modal" data-target="#modal_autoresponse" data-backdrop="static"><i class="fas fa-reply fmaroon f_sz16 crsr_pntr" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Send Auto Response"></i></a></span> */}
                                                          <span className="d-inline-block">
                                                            <a
                                                              href="javascript:void(0)"
                                                              onClick={() => {
                                                                this.goto_message(
                                                                  rbot_conversations.contact,
                                                                  rbot_conversations._id,
                                                                );
                                                              }}>
                                                              <i
                                                                className="fas fa-share fa-flip-horizontal fmaroon f_sz16 crsr_pntr"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Go to Message"
                                                                data-original-title="Go to Message"
                                                              />
                                                            </a>
                                                          </span>
                                                          {rbot_conversations.reminder == 'yes' ? (
                                                            <span className="d-inline-block ml-1">
                                                              <i
                                                                className="far fa-calendar-check fgreen4 f_sz16 crsr_pntr"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title={
                                                                  'Will remind in ' + rbot_conversations.reminder_type
                                                                }
                                                                data-original-title={`Will remind in ${rbot_conversations.reminder_type}`}
                                                              />
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </>
                                                ) : (
                                                  <tr>
                                                    <td>
                                                      {(parseInt(rbot_conversations.record_duration) <= 3 &&
                                                        rbot_conversations.record_url) ||
                                                      rbot_conversations.record_url ||
                                                      !rbot_conversations.text_message ? (
                                                        <span className="usercircleimg_sm1 mr-3">
                                                          <img src={redtie_bot1} border={0} />
                                                        </span>
                                                      ) : rbot_conversations?.user_info[0]?.profile_image ? (
                                                        <span className="usercircleimg_sm1 mr-3">
                                                          <img
                                                            src={rbot_conversations?.user_info[0]?.profile_image}
                                                            border={0}
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span className="usercircle_md bg-info">
                                                          {rbot_conversations?.user_info[0]?.first_name
                                                            ?.substring(1, 0)
                                                            ?.toUpperCase()}
                                                        </span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {parseInt(rbot_conversations.record_duration) <= 3 &&
                                                      rbot_conversations.record_url ? (
                                                        'You missed a call from' +
                                                        (rbot_conversations?.contact_info[0]?.first_name != 'Guest'
                                                          ? ' ' +
                                                            rbot_conversations?.contact_info[0]?.first_name +
                                                            (rbot_conversations?.contact_info[0]?.last_name != ''
                                                              ? ' ' + rbot_conversations?.contact_info[0]?.last_name
                                                              : '')
                                                          : ' ' +
                                                            rbot_conversations.from_source
                                                              .toString()
                                                              .substring(1)
                                                              .replace(/[^\dA-Z]/g, '')
                                                              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                              .trim())
                                                      ) : rbot_conversations.record_url ? (
                                                        'You received a voice mail from' +
                                                        (rbot_conversations?.contact_info[0]?.first_name != 'Guest'
                                                          ? ' ' +
                                                            rbot_conversations?.contact_info[0]?.first_name +
                                                            (rbot_conversations?.contact_info[0]?.last_name != ''
                                                              ? ' ' + rbot_conversations?.contact_info[0]?.last_name
                                                              : '')
                                                          : ' ' +
                                                            rbot_conversations.from_source
                                                              .toString()
                                                              .substring(1)
                                                              .replace(/[^\dA-Z]/g, '')
                                                              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                              .trim())
                                                      ) : !rbot_conversations.text_message ? (
                                                        'You missed a call from' +
                                                        (rbot_conversations?.contact_info[0]?.first_name != 'Guest'
                                                          ? ' ' +
                                                            rbot_conversations?.contact_info[0]?.first_name +
                                                            (rbot_conversations?.contact_info[0]?.last_name != ''
                                                              ? ' ' + rbot_conversations?.contact_info[0]?.last_name
                                                              : '')
                                                          : ' ' +
                                                            rbot_conversations.from_source
                                                              .toString()
                                                              .substring(1)
                                                              .replace(/[^\dA-Z]/g, '')
                                                              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                              .trim())
                                                      ) : <Linkify
                                                          componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                              {decoratedText}
                                                            </a>
                                                          )}>
                                                          {rbot_conversations.text_message}
                                                        </Linkify> ? (
                                                        this.state?.businessprofileplandetails?.plan_name === 'Trial' ||
                                                        this.state?.businessprofileplandetails?.plan_name === 'Free' ? (
                                                          <span title="Please upgrade to view this message">
                                                            <span
                                                              className="mr-1"
                                                              style={{
                                                                color: 'transparent',
                                                                textShadow: '#111 0 0 5px',
                                                              }}>
                                                              {rbot_conversations.text_message.replace(
                                                                /[a-zA-Z0-9]/g,
                                                                '*',
                                                              )}
                                                              {'\n'}
                                                            </span>
                                                            <span
                                                              className="fmaroon crsr_pntr mr-1"
                                                              onClick={() =>
                                                                this.upgradeWorkspace(
                                                                  '/create-profile?bid=' + this.state?.business_id,
                                                                )
                                                              }>
                                                              Upgrade
                                                            </span>
                                                            {/* <span>
                                                              <TrialPaymentButton
                                                                class="fmaroon crsr_pntr mr-1"
                                                                name="Upgrade"
                                                              />
                                                            </span> */}
                                                          </span>
                                                        ) : (
                                                          <Linkify
                                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                              <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                              </a>
                                                            )}>
                                                            {rbot_conversations.text_message}
                                                          </Linkify>
                                                        )
                                                      ) : rbot_conversations.status === 'completed' ||
                                                        rbot_conversations.status === 'cancel' ? (
                                                        'You have missed a call from' +
                                                        ' ' +
                                                        rbot_conversations.from_source
                                                          .toString()
                                                          .substring(1)
                                                          .replace(/[^\dA-Z]/g, '')
                                                          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                          .trim()
                                                      ) : undefined}
                                                      <div class="d-block w-100 text-xs text-muted">
                                                        <i class="far fa-clock mr-2"></i>{' '}
                                                        {this.find_relative_time(rbot_conversations.updated_date)}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="tbl_actionbtns text-center">
                                                        {rbot_conversations.record_url &&
                                                        parseInt(rbot_conversations.record_duration) > 3 ? (
                                                          <span className="crsr_pntr">
                                                            <i
                                                              className={
                                                                'far fa-play-circle fa-2x fgreen1 voiPlyICn_toshow voiPlyICn_' +
                                                                rbot_conversations._id
                                                              }
                                                              onClick={() => {
                                                                this.Voice_Ply(rbot_conversations);
                                                              }}
                                                            />
                                                            {/* <i
                                                              className={
                                                                'fas fa-pause f_sz24 voiPusIcn_tostop voiPusIcn_' +
                                                                rbot_conversations._id
                                                              }
                                                              onClick={(e) => this.Voice_Pause(rbot_conversations)}
                                                              style={{
                                                                display: 'none',
                                                              }}></i>
                                                            <span
                                                              className="ml-2 d-inline-block postop_5up"
                                                              // style={{
                                                              //   display: 'none',
                                                              // }}
                                                            ></span>
                                                            <audio
                                                              className={
                                                                'audioplayer123 mr-4 audio_' + rbot_conversations._id
                                                              }
                                                              style={{width: '258px', display: 'none'}}
                                                              src={rbot_conversations.record_url}
                                                              controls="controls"
                                                              controlsList="nodownload"
                                                              // hidden
                                                              id={'audio_' + rbot_conversations._id}
                                                            /> */}
                                                          </span>
                                                        ) : (
                                                          ''
                                                        )}

                                                        <span className="dropdown redtiebot_drpdwn d-inline-block">
                                                          <a data-toggle="dropdown" href="#">
                                                            <i
                                                              className="far fa-calendar-alt fmaroon f_sz16 crsr_pntr"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              data-original-title="Set Reminder"
                                                              title="Set Reminder"
                                                            />
                                                          </a>
                                                          <span className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                            <span className="scrollbar_style">
                                                              <span className="dropdown-item dropdown-header f_sz14 text-left fmaroon font-weight-semibold">
                                                                Remind me about this
                                                              </span>
                                                              <span className="dropdown-divider" />
                                                              <span className="dropdown-item">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.set_reminder(
                                                                      rbot_conversations._id,
                                                                      rbot_conversations.contact,
                                                                      '20mins',
                                                                    );
                                                                  }}
                                                                  className="fgrey">
                                                                  In 20 minutes
                                                                </a>
                                                              </span>
                                                              <span className="dropdown-divider" />
                                                              <span className="dropdown-item">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.set_reminder(
                                                                      rbot_conversations._id,
                                                                      rbot_conversations.contact,
                                                                      '1hr',
                                                                    );
                                                                  }}
                                                                  className="fgrey">
                                                                  In 1 hour
                                                                </a>
                                                              </span>
                                                              <span className="dropdown-divider" />
                                                              <span className="dropdown-item">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.set_reminder(
                                                                      rbot_conversations._id,
                                                                      rbot_conversations.contact,
                                                                      '3hr',
                                                                    );
                                                                  }}
                                                                  className="fgrey">
                                                                  In 3 hours
                                                                </a>
                                                              </span>
                                                              <span className="dropdown-divider" />
                                                              <span className="dropdown-item">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.set_reminder(
                                                                      rbot_conversations._id,
                                                                      rbot_conversations.contact,
                                                                      '1day',
                                                                    );
                                                                  }}
                                                                  className="fgrey">
                                                                  Tomorrow
                                                                </a>
                                                              </span>
                                                              <span className="dropdown-divider" />
                                                              <span className="dropdown-item">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.set_reminder(
                                                                      rbot_conversations._id,
                                                                      rbot_conversations.contact,
                                                                      '7days',
                                                                    );
                                                                  }}
                                                                  className="fgrey">
                                                                  Next week
                                                                </a>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span className="d-inline-block">
                                                          <a
                                                            href="javascript:void(0)"
                                                            onClick={() => {
                                                              this.goto_message(
                                                                rbot_conversations.contact,
                                                                rbot_conversations._id,
                                                              );
                                                            }}>
                                                            <i
                                                              className="fas fa-share fa-flip-horizontal fmaroon f_sz16 crsr_pntr"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title="Go to Message"
                                                              data-original-title="Go to Message"
                                                            />
                                                          </a>
                                                        </span>
                                                        {rbot_conversations.reminder == 'yes' ? (
                                                          <span className="d-inline-block ml-1">
                                                            <i
                                                              className="far fa-calendar-check fgreen4 f_sz16 crsr_pntr"
                                                              data-toggle="tooltip"
                                                              data-placement="bottom"
                                                              title={
                                                                'Will remind in ' + rbot_conversations.reminder_type
                                                              }
                                                              data-original-title={`Will remind in ${rbot_conversations.reminder_type}`}
                                                            />
                                                          </span>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  //                                                   <tr>
                                                  //                                                      <td colSpan="3">
                                                  //                                                      <div className="tbl_actionbtns text-center">
                                                  //                                                         {rbot_conversations.record_url &&
                                                  //                                                         parseInt(rbot_conversations.record_duration) > 3 ? (
                                                  //                                                           <span className="crsr_pntr">

                                                  //                                                             <i
                                                  //                                                               className={
                                                  //                                                                 'fas fa-pause f_sz24 voiPusIcn_tostop voiPusIcn_' +
                                                  //                                                                 rbot_conversations._id
                                                  //                                                               }
                                                  //                                                               onClick={(e) => this.Voice_Pause(rbot_conversations)}
                                                  //                                                               style={{
                                                  //                                                                 display: 'none',
                                                  //                                                               }}></i>
                                                  //                                                             <span
                                                  //                                                               className="ml-2 d-inline-block postop_5up"
                                                  //                                                               // style={{
                                                  //                                                               //   display: 'none',
                                                  //                                                               // }}
                                                  //                                                             ></span>
                                                  //                                                             <audio
                                                  //                                                               className={
                                                  //                                                                 'audioplayer123 mr-4 audio_' + rbot_conversations._id
                                                  //                                                               }
                                                  //                                                               style={{width: '258px', display: 'none'}}
                                                  //                                                               src={rbot_conversations.record_url}
                                                  //                                                               controls="controls"
                                                  //                                                               controlsList="nodownload"
                                                  //                                                               // hidden
                                                  //                                                               id={'audio_' + rbot_conversations._id}
                                                  //                                                             />
                                                  //                                                           </span>
                                                  //                                                         ) : (
                                                  //                                                           null
                                                  //                                                         )}
                                                  // </div>
                                                  //                                                      </td>
                                                  //                                                   </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center mt-5 pt-5">
                                            <div className="text-center f_sz16 mb-3 mt-3 w-100">
                                              R-bot is your personal assistant. It gathers important to-do's for you and
                                              shows up here.
                                            </div>
                                            <a
                                              onClick={() => this.redirectToPage('/board?compose_message=true')}
                                              // href="/board?compose_message=true"
                                              className="btn btn-black ml-2">
                                              Get Started
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row position-relative mx-0 mt-3">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <div className="all_dashboard_div">
                          <div className="w-90 card brdr_dashboxes4 shadow-none">
                            <div className="card-header px-1">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                  <h5 className="font-weight-bold mb-0">Messages</h5>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-left text-md-right position-relative mt-2 mt-sm-0">
                                  <div className="d-inline-block dashbrd_chartbtns">
                                    <div className="btn-group btn-group-toggle" id="buttons_div" data-toggle="buttons">
                                      <label
                                        className={
                                          this.state.selectedBtnType == 1
                                            ? 'btn btn-secondary radio_for_filter active'
                                            : 'btn btn-secondary'
                                        }
                                        style={{pointerEvents: !this.state.hoursDisabled ? 'auto' : 'none'}}
                                        onClick={() => (!this.state.hoursDisabled ? this.filterBtnClick(1) : null)}
                                        id="hours_btn">
                                        <input
                                          type="radio"
                                          name="options"
                                          id="option1"
                                          className=""
                                          value="1"
                                          autoComplete="off"
                                        />{' '}
                                        Hours
                                      </label>
                                      <label
                                        className={
                                          this.state.selectedBtnType == 2
                                            ? 'btn btn-secondary radio_for_filter active'
                                            : 'btn btn-secondary'
                                        }
                                        onClick={() => this.filterBtnClick(2)}>
                                        <input
                                          type="radio"
                                          name="options"
                                          id="option2"
                                          className=""
                                          value="2"
                                          autoComplete="off"
                                        />{' '}
                                        Days
                                      </label>
                                      <label
                                        className={
                                          this.state.selectedBtnType == 3
                                            ? 'btn btn-secondary radio_for_filter active'
                                            : 'btn btn-secondary'
                                        }
                                        onClick={() => this.filterBtnClick(3)}>
                                        <input
                                          type="radio"
                                          name="options"
                                          id="option3"
                                          className=""
                                          value="3"
                                          autoComplete="off"
                                        />{' '}
                                        Weeks
                                      </label>
                                      <label
                                        className={
                                          this.state.selectedBtnType == 4
                                            ? 'btn btn-secondary radio_for_filter active'
                                            : 'btn btn-secondary'
                                        }
                                        onClick={() => this.filterBtnClick(4)}>
                                        <input
                                          type="radio"
                                          name="options"
                                          id="option4"
                                          className=""
                                          value="4"
                                          autoComplete="off"
                                        />{' '}
                                        Months
                                      </label>
                                    </div>
                                  </div>
                                  <div className="d-inline-block chrtfltr_200 mt-2 mt-sm-0">
                                    <input
                                      type="text"
                                      className="form-control text-center"
                                      id="default_dateval"
                                      defaultValue="Sep 15, 2021 - Sep 23, 2021"
                                    />
                                  </div>
                                  <div className="dashchartfiltercalendardrpdwn_pos" style={{display: 'none'}}>
                                    <div className="chartoverallcounts_div text-center">
                                      <div className="all_contentflex_div py-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          {/* {this.state.showNewMonth && ( */}
                                          <DateRangePicker
                                            selectionType="range"
                                            stateDefinitions={stateDefinitions}
                                            // dateStates={dateRanges}
                                            defaultState="available"
                                            value={this.state.value}
                                            onSelect={this.dateRangePickerSelect}
                                            numberOfCalendars={noOfMonths}
                                            initialMonth={initialMonths}
                                            initialYear={initialYear}
                                            minimumDate={minimumDate}
                                            maximumDate={maximumDate}
                                          />
                                          {/* )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{
                                display:
                                  this.state?.chart_data !== null ||
                                  this.state?.chart_data !== '' ||
                                  this.state?.chart_data.length !== 0
                                    ? 'auto'
                                    : 'none',
                              }}>
                              <div
                                style={{
                                  display:
                                    this.state.dashboard_extra_details?.messages === undefined ||
                                    this.state.dashboard_extra_details?.messages === 0
                                      ? 'none'
                                      : 'block',
                                }}
                                className="dashbrd_allcharts text-center">
                                <Bar data={this.state?.chart_data} options={options} />
                              </div>
                              <div
                                style={{
                                  display:
                                    this.state.dashboard_extra_details?.messages === undefined ||
                                    this.state.dashboard_extra_details?.messages === 0
                                      ? 'block'
                                      : 'none',
                                }}>
                                <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center mt-5 mb-5">
                                  <div className="w-100 text-center f_sz16 mb-3 mt-3">
                                    Your bird's eye view of what you send and receive is displayed here.
                                  </div>
                                  <a
                                    onClick={() =>
                                      this.redirectToPage(
                                        this.state.business_info_array?.contacts?.total != undefined &&
                                          this.state.business_info_array?.contacts?.total > 0
                                          ? '/board?compose_message=true'
                                          : '/board?create_contact=true',
                                      )
                                    }
                                    // href={
                                    //   this.state.business_info_array?.contacts?.total != undefined &&
                                    //   this.state.business_info_array?.contacts?.total > 0
                                    //     ? '/board?compose_message=true'
                                    //     : '/board?create_contact=true'
                                    // }
                                    className="btn btn-black ml-2">
                                    Get Started
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row position-relative mx-0 mt-1">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                        <div className="all_dashboard_div">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                            <div className="w-90 d-flex flex-row flex-wrap">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                                <div className="all_dashboard_div">
                                  <div className="w-100 card brdr_dashboxes brdrbtm_green1 shadow-none brdr_radius_div">
                                    <div className="card-header px-1">
                                      <div className="w-100 d-flex flex-row flex-wrap">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                          <h5 className="font-weight-bold mb-0">
                                            Workflows{' '}
                                            <a
                                              onClick={() =>
                                                this.redirectToPage('/profile_settings?workflow_trigger=true')
                                              }
                                              // href="/profile_settings?workflow_trigger=true"
                                            >
                                              <span className="ml-3 crsr_pntr">
                                                <i
                                                  className="fas fa-plus fmaroon f_sz16"
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  title
                                                  data-original-title="Add Workspace"
                                                />
                                              </span>
                                            </a>
                                          </h5>
                                        </div>

                                        {/* <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                          <h5 className="font-weight-bold mb-0">Your Workflow</h5>
                                        </div> */}
                                        {/* <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right"><a href="#" class="fmaroon"><i class="fas fa-filter"></i></a></div> */}
                                      </div>
                                    </div>
                                    <div className="card-body py-0" id="table_with_data">
                                      <div className="table-responsive scrollbar_style dashboard_listhgt2">
                                        <DataTable
                                          id="table_with_data"
                                          //paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                          columns={tcolumns}
                                          data={this.state?.worflow_details}
                                          pagination="true"
                                          // paginationTotalRows="5"
                                          //paginationPerPage='5'
                                          // theme="solarized"
                                          //paginationComponent="Pagination"
                                          paginationComponentOptions={{noRowsPerPage: true}}
                                          customStyles={customStyles}
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body py-0" id="table_without_data">
                                      <div className="table-responsive scrollbar_style dashboard_listhgt2">
                                        <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center mt-5 pt-5">
                                          <div className="text-center f_sz16 mb-3 mt-3 w-100">
                                            Automate your messages with amazing workflows.
                                          </div>
                                          <a href="/profile_settings?workflow_trigger=true" class="btn btn-black ml-2">
                                            Create Your First Workflow
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="card card-widget widget-user brdrbtm_green1 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div">
                                  <div className="w-100 d-flex flex-row flex-wrap border-bottom">
                                    <div className="w-100 d-inline-block text-center mt-4">
                                      <img src={nft_green} border={0} />
                                    </div>
                                    <div className="w-100 d-inline-block text-center f_sz17 fmaroon font-weight-semibold mt-1 mb-4">
                                      Non Fungible Tokens
                                    </div>
                                  </div>

                                  {this.state.dashboard_extra_details?.nft?.minted !== 0 ||
                                  this.state.dashboard_extra_details?.nft?.sent !== 0 ||
                                  this.state.dashboard_extra_details?.nft?.accepted !== 0 ? (
                                    <>
                                      <NftTables business_id={this.state.business_id} user_id={this.state.user_id} />
                                    </>
                                  ) : (
                                    <>
                                      <div className="row mt-5 pt-3">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <div className="text-center f_sz16 mb-3 w-100">
                                              Want to mint NFTs? Apply for a smartcontract to be created.
                                            </div>
                                            <a
                                              onClick={() => this.redirectToPage('/profile_settings?nft_cont=true')}
                                              className="btn btn-black ml-2">
                                              Request Now
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                </div>
                              </div> */}
                              {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="card card-widget widget-user brdrbtm_yellow3 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div">
                                  <div className="w-100 d-flex flex-row flex-wrap border-bottom">
                                    <div className="w-100 d-inline-block text-center mt-4">
                                      <img src={ft_green} border={0} />
                                    </div>
                                    <div className="w-100 d-inline-block text-center f_sz17 fmaroon font-weight-semibold mt-1 mb-4">
                                      Fungible Tokens
                                    </div>
                                  </div>

                                  {this.state.business_info_array?.business?.fungible_token?.token_name?.length !==
                                  0 ? (
                                    <>
                                      <div className="row mt-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="my-4 text-center f_sz16">
                                            <span className="mr-2">Token Name:</span>
                                            <span className="fmaroon font-weight-bold">
                                              {this.state.business_info_array?.business?.fungible_token?.token_name}
                                            </span>
                                          </div>
                                          <table
                                            className="text-center f_sz12 table-bordered wfcount_tbl"
                                            width="100%"
                                            cellSpacing={0}
                                            cellPadding={5}>
                                            <tbody>
                                              <tr>
                                                <td className="text-center">
                                                  <div className="font-weight-bold">Limit</div>
                                                  <div className="f_sz14">
                                                    {
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_limit
                                                    }
                                                  </div>
                                                </td>
                                                <td className="text-center">
                                                  <div className="font-weight-bold">Transferred</div>
                                                  <div className="f_sz14">
                                                    {this.state.business_info_array?.business?.fungible_token
                                                      ?.token_limit -
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_available}
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="text-center">
                                                  <div className="font-weight-bold">Accepted</div>
                                                  <div className="f_sz14">
                                                    {this.state.business_info_array?.business?.fungible_token
                                                      ?.token_limit -
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_available -
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_onhold}
                                                  </div>
                                                </td>
                                                <td className="text-center">
                                                  <div className="font-weight-bold">Available</div>
                                                  <div className="f_sz14">
                                                    {
                                                      this.state.business_info_array?.business?.fungible_token
                                                        ?.token_available
                                                    }
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>

                                    </>
                                  ) : (
                                    <>
                                      <div className="row mt-5 pt-1">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center">
                                            <div className="text-center f_sz16 mb-3 w-100">
                                              Want to mint fungible tokens to send as attachments? Apply for the
                                              smartcontract.
                                            </div>
                                            <a
                                              onClick={() => this.redirectToPage('/profile_settings?ft_lists=true')}
                                              className="btn btn-black ml-2">
                                              Request Now
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div> */}
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="card card-widget widget-user brdrbtm_green1 shadow-none brdr_dashboxes brdr_radius_div acctdashbrd_user acctdashbrd_div d-flex flex-row flex-wrap align-items-center justify-content-center dash_addwrkspace">
                                  <a
                                    href="javascript:void(0);"
                                    onClick={() => this.goto_account_dashboard()}
                                    className="btn btn-green_outline crsr_pntr btn-lg m-2 btnpdng_sm">
                                    Go to Account Dashboard
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal fade modal_custom_800" id="modal_nfttoken">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title fmaroon">{this.state.nft_token?.nft_name}</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body pt-0">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none mb-0">
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body px-0 pb-3">
                                  <div>
                                    <div className="form-group mb-4 mx-1">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">Owner:</label>
                                        <div className="ml-3 d-inline-block" style={{textTransform: 'capitalize'}}>
                                          {typeof this.state.nft_token?.user_id?.first_name !== 'undefined' &&
                                          this.state.nft_token?.user_id?.first_name != null &&
                                          this.state.nft_token?.user_id?.first_name?.length !== 0
                                            ? this.state.nft_token?.user_id?.first_name +
                                              ' ' +
                                              this.state.nft_token?.user_id?.last_name
                                            : this.state.nft_token?.user_id?.email_address}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group mb-4 mx-1">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">State:</label>
                                        <div
                                          className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                          style={{textTransform: 'capitalize'}}>
                                          {this.state.nft_token?.status}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group mb-4">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">QR Code</label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="nftqrcode_img">
                                          <QRCode
                                            //id="qrcode"
                                            value={
                                              APP_ENDPOINT +
                                              '/Nft_Receipt?qrcode=true&trans_id=' +
                                              this.state.nft_token?.token_hash +
                                              '&user_id=' +
                                              this.state.user_id +
                                              '&business_id=' +
                                              this.state.business_id
                                            }
                                            size={200}
                                            level={'L'}
                                            includeMargin={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group mt-3 mb-4">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">IPFS</label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <a
                                          target="_blank"
                                          href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                          className="fmaroon">
                                          {this.state.nft_token?.ipfs}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="form-group mt-3 mb-4">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">Description</label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        {this.state.nft_token?.nft_description}
                                      </div>
                                    </div>
                                    <div
                                      className="form-group mt-3 mb-4"
                                      style={{
                                        display: this.state.nft_token?.upload_type !== 'image/gif' ? '' : 'none',
                                      }}>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">Image</label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="nftimage_div">
                                          <img src={this.state.nft_token?.file_url} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="form-group row mt-3 mb-4 m-2">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <label className="col-form-label">Audio</label>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uploaded_audiodiv text-center">
                                                  <div className="d-flex align-items-center">
                                                    <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                    <span className="font-weight-semibold">sample_audio.mp3</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                    <div
                                      className="form-group row mt-3 mb-4 m-2"
                                      style={{
                                        display: this.state.nft_token?.upload_type === 'image/gif' ? '' : 'none',
                                      }}>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label className="col-form-label">GIF</label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="nftimage_div mt-3">
                                          <img src={this.state.nft_token?.file_url} />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 receipt_div mt-4 p-0">
                                              <div className="w-100 d-table">
                                                <div className="d-tablerow">
                                                  <div className="d-tablecell">
                                                    <div className="receipt_photo" align="center">
                                                      <img src="../dist/img/sample_painting1.jpg" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_nftlogo" align="center">
                                                      <img src="../dist/img/nft_icon.png" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_ethereumlogo" align="center">
                                                      <img src="../dist/img/ethereum_logo.png" border={0} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                    <div className="mt-4">
                                      {this.state.nft_token?.token_key?.length != 0
                                        ? this.state.nft_token?.token_key?.map((data, index) => (
                                            <div className="form-group row mt-3 mb-2 m-2">
                                              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                                <label className="col-form-label">{data.key}</label>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <div className="mt-2">{data.value}</div>
                                              </div>
                                            </div>
                                          ))
                                        : null}
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="mt-4 mb-1">
                                        Your art is available as NFT as of{' '}
                                        {moment(this.state.nft_token?.created_date).format('MMM DD,YYYY, h:mm a')}
                                      </div>
                                      <div className="mt-3 mb-1">
                                        <span className="font-weight-bold">Wallet Address:</span>{' '}
                                        <a
                                          target="_blank"
                                          href={
                                            'http://blezar.ephrontech.com/address/' +
                                            this.state.nft_token?.original_wallet_address
                                          }
                                          className="fmaroon">
                                          {this.state.nft_token?.original_wallet_address}
                                        </a>
                                      </div>
                                      <div className="mt-3 mb-1">
                                        <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                        <a
                                          target="_blank"
                                          href={
                                            'http://blezar.ephrontech.com/transaction/' +
                                            this.state.nft_token?.token_hash +
                                            '?project=redtie_live'
                                          }
                                          className="fmaroon">
                                          {this.state.nft_token?.token_hash}
                                        </a>
                                      </div>
                                      <div className="mt-3 mb-1">
                                        <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                        <a
                                          target="_blank"
                                          href={
                                            'http://blezar.ephrontech.com/transaction/' +
                                            this.state.nft_token?.token_transfer_hash +
                                            '?project=redtie_live'
                                          }
                                          className="fmaroon">
                                          {this.state.nft_token?.token_transfer_hash}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>

                  {/* <TrialPayment /> */}
                </div>

                <div className="modal fade modal_custom_350" id="modal_packs">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Message Packs</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="card-body p-0">
                              <div className="w-100 dashbrd_packs">
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 ? (
                                  this.state?.businessprofiledetails?.pack_details?.map((pack_dets, index) => {
                                    if (pack_dets.purchase_type != 'appsumo') {
                                      packs_details = 1;
                                      return (
                                        <div>
                                          <span className="font-weight-bold">
                                            {pack_dets.master_pack_id.pack_name}{' '}
                                          </span>{' '}
                                          - <span className="fbred">{pack_dets.pack_usage_total}</span>/
                                          {pack_dets.order_data_count}
                                        </div>
                                      );
                                    }
                                  })
                                ) : (
                                  <div>
                                    <span className="font-weight-bold">You haven't bought any message packs yet!</span>
                                  </div>
                                )}
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 && packs_details == 0 ? (
                                  <div>
                                    <span className="font-weight-bold">You haven't bought any message packs yet!</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                {/* /.modal */}
                <div className="modal fade modal_custom_350" id="modal_appsumopacks">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">AppSumo Packs</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="card-body p-0">
                              <div className="w-100 dashbrd_packs">
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 ? (
                                  this.state?.businessprofiledetails?.pack_details?.map((pack_dets, index) => {
                                    if (pack_dets.purchase_type == 'appsumo') {
                                      appsumo_packs_details = 1;
                                      return (
                                        <div>
                                          <span className="font-weight-bold">
                                            {pack_dets.master_pack_id.pack_name}{' '}
                                          </span>{' '}
                                          - <span className="fbred">{pack_dets.pack_usage_total}</span>/
                                          {pack_dets.order_data_count}
                                        </div>
                                      );
                                    }
                                  })
                                ) : (
                                  <div>
                                    <span className="font-weight-bold">You haven't bought any AppSumo packs yet!</span>
                                  </div>
                                )}
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 &&
                                appsumo_packs_details == 0 ? (
                                  <div>
                                    <span className="font-weight-bold">You haven't bought any AppSumo packs yet!</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
              </div>
              {/* <TrialPayment /> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Dashboard;
