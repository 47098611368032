import React, {useEffect, useState} from 'react';
import DataTable, {createTheme} from 'react-data-table-component';
import axios from 'axios';
import $ from 'jquery';
import AsyncStorage from '@callstack/async-storage';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const moment = extendMoment(Moment);
export const NftTables = (props) => {
  const [state, setstate] = useState({user_id: null, business_id: null, nft_history: null});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState('5');
  const tcolumns = [
    {
      name: 'Token Name',
      selector: 'nft_name',
      sortable: true,
      left: true,
    },
    {
      name: 'Sent',
      selector: (row) => (row?.send_date ? moment(row.send_date).unix() : '-'),
      cell: (row) => (row?.send_date ? moment(row.send_date).format('MM/DD/YYYY') : '-'),
      //   selector: 'send_date',
      sortable: true,
      left: true,
    },
    {
      name: 'Accepted',
      selector: (row) => (row?.accepted_date ? moment(row.accepted_date).unix() : '-'),
      cell: (row) => (row?.accepted_date ? moment(row.accepted_date).format('MM/DD/YYYY') : '-'),
      //   selector: 'accepted_date',
      sortable: true,
      left: true,
    },
    {
      name: 'Action',
      selector: '_id',
      cell: (row) => (
        <a
          data-toggle="modal"
          data-target="#modal_nfttoken"
          data-backdrop="static"
          class="crsr_pntr open_nft_receipt"
          data-id={row.token_hash}>
          <i
            class="far fa-eye fmaroon f_sz15 crsr_pntr"
            data-toggle="tooltip"
            data-placement="bottom"
            title=""
            data-original-title="View"></i>
        </a>
      ),
      sortable: true,
      left: true,
    },
  ];
  console.log('propspropsprops', props);
  useEffect(() => {
    if (props.business_id) {
      setstate((prev) => ({...prev, business_id: props.business_id}));
    }
    if (props.user_id) {
      setstate((prev) => ({...prev, user_id: props.user_id}));
    }
  }, [props.user_id, props.business_id]);

  useEffect(() => {
    if (state.user_id !== null && state.business_id !== null) {
      fetchUsers(0);
    }
  }, [state.user_id, state.business_id]);
  const fetchUsers = async (page) => {
    if (page != 0) {
      page = parseInt(page) - parseInt(1);
    }
    setLoading(true);
    const dashboard_billing = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: state.user_id,
        business_id: state.business_id,
        start: page * perPage,
        limit: perPage,
      },
    };
    const response = await axios.get(API_CALL_ENDPOINT + '/dashboard/nft/list', dashboard_billing);
    setstate((prev) => ({...prev, nft_history: response.data.result.data}));
    setTotalRows(response.data.result.records);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const dashboard_billing = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: state.user_id,
        business_id: state.business_id,
        start: page,
        limit: newPerPage,
      },
    };
    const response = await axios.get(API_CALL_ENDPOINT + '/dashboard/nft/list', dashboard_billing);

    setstate((prev) => ({...prev, nft_history: response.data.result.data}));
    setPerPage(newPerPage);
    setLoading(false);
  };
  console.log('nftdata', state);
  return (
    <>
      {state.nft_history ? (
        <>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="table-responsive scrollbar_style dashboard_listhgt4">
                <div id="nonfungibledashbrd_tbl_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-sm-12 col-md-6" />
                    <div className="col-sm-12 col-md-6" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        columns={tcolumns}
                        data={state.nft_history}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationPerPage="5"
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.col */}
          </div>
        </>
      ) : (
        <>
          <div className="row mt-5 pt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="text-center f_sz16 mb-3 w-100">
                  Want to mint NFTs? Apply for a smartcontract to be created.
                </div>
                <a href="/profile_settings?nft_cont=true" className="btn btn-black ml-2">
                  Request Now
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NftTables;
