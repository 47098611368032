import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
import {Worker} from '@phuocng/react-pdf-viewer';
import Viewer, {defaultLayout} from '@phuocng/react-pdf-viewer';
import redtie_logo from '../assets/img/pdf_logo.png';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

export default class PDF_Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s3_file_url: '',
    };
  }
  componentDidMount() {
    document.getElementById('spinner').style.display = 'none';
    AsyncStorage.getItem('PDF_View_url').then((PDF_View_url) => {
      if (PDF_View_url) {
        this.setState({s3_file_url: PDF_View_url});
        console.log('PDF_View_url');

        console.log(PDF_View_url);
      }
    });
  }
  renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}>
          <div style={{padding: '0 2px'}}>
            <img style={{width: '30px'}} src={redtie_logo} border="0" />
          </div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.toggleSidebarButton}</div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.searchPopover}</div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.previousPageButton}</div>
          <div style={{padding: '0 2px'}}>
            {toolbarSlot.currentPage} / {toolbarSlot.numPages}
          </div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.nextPageButton}</div>
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomInButton}
                    </div> */}
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
          }}>
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomOutButton}
                    </div> */}
          <div style={{padding: '0 2px'}}>{toolbarSlot.zoomPopover}</div>
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomInButton}
                    </div> */}
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomOutButton}
                    </div>
                    <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomPopover}
                    </div>
                    <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomInButton}
                    </div> */}
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            marginLeft: 'auto',
          }}>
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.fullScreenButton}
                    </div> */}
          <div style={{padding: '0 2px'}}>{toolbarSlot.downloadButton}</div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.printButton}</div>
          <div style={{padding: '0 2px'}}>{toolbarSlot.moreActionsPopover}</div>
          {/* <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomPopover}
                    </div>
                    <div style={{ padding: '0 2px' }}>
                        {toolbarSlot.zoomInButton}
                    </div> */}
        </div>
      </div>
    );
  };
  layout = (isSidebarOpened, container, main, toolbar, sidebar) => {
    return defaultLayout(isSidebarOpened, container, main, toolbar(this.renderToolbar), sidebar);
  };
  render() {
    if (this.state.s3_file_url) {
      return (
        <div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
            <div
              style={{
                height: '750px',
              }}>
              <Viewer fileUrl={this.state.s3_file_url} layout={this.layout} />
            </div>
          </Worker>
        </div>
      );
    } else {
      return (
        <div>
          <p style={{fontSize: '50px', color: 'red'}}>This Page is Restricted</p>
        </div>
      );
    }
  }
}
