import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
// import '../assets/css/innerpages_customstyle.css';
// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import $ from 'jquery';
import PinInput from 'react-pin-input';
import moment from 'moment';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const PAYMENTENDPOINT = process.env.REACT_APP_API_PAYMENT_ENDPOINT;
function validate(plivo_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (plivo_number.length === 0) {
    errors.push('Please select a number to proceed');
  }
  return errors;
}

export default class BusinessVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cclists: '',
      business_id: '',
      user_id: '',
      errors: [],
      user_email: '',
      business_type_details: [],
      user_phone_number_code: '',
      user_phone_number: '',
      usr_name: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      enabled_country_lists: '',
      action_required: false,
      evaluation_id: '',
      user_otp: '',
      business_name: '',
      business_type_value: '',
      business_type_name_value: '',
      tax_number: '',
      address: '',
      city: '',
      state_value: '',
      zip_code: '',
      additional_information: '',
      otp_time: true,
    };
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');

    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });

    setTimeout(() => {
      const business_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios
        .get(API_CALL_ENDPOINT + '/business/business_type/list', business_details)
        .then((res) => {
          console.log('/business/business_type/list', res.data);
          if (res.data.result) {
            this.setState({business_type_details: res.data?.result});
          }
        })
        .catch((error) => {
          console.log(error);
        });
        var that = this;
      const enabled_country_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          type: 'text',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/admin/enable/plivo/country', enabled_country_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            let country = [];
            for (let i = 0; i < res.data.result?.country?.length; i++) {
              country.push(res.data.result?.country[i].country_iso.toLowerCase());
            }
            this.setState({enabled_country_lists: country});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              that.setState({enabled_country_lists: []});
            }
          }
        });
    }, 100);

    $('.planselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        console.log('month');
        $('.monthly_tgldiv').show();
        $('.annually_tgldiv').hide();
      } else {
        console.log('year');
        $('.monthly_tgldiv').hide();
        $('.annually_tgldiv').show();
      }
    });
    $('.typeselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        $('.card_tgldiv').hide();
        $('.appsumocode_tgldiv').show();
      } else {
        $('.card_tgldiv').show();
        $('.appsumocode_tgldiv').hide();
      }
    });
    $('#business_type_drpdn').on('change', function () {
      var business_typeval = $(this).val();
      if (business_typeval == 'others') {
        $('.other_type').show();
      } else {
        $('.other_type').hide();
      }
    });
  }
  payment_process_faild() {
    if (!this.state.action_required) {
      $('.processing_div').hide();
    }
  }

  otpTimer = () => {
    var counter = 60;
    var interval = setInterval(function () {
      counter--;
      // Display 'counter' wherever you want to display it.
      if (counter <= 0) {
        clearInterval(interval);
        $('#timer').hide();
        return;
      } else {
        $('#timer').show();
        $('#time').text(counter);
        console.log('Timer --> ' + counter);
      }
    }, 1000);
  };

  sentOtp = () => {
    const errors = [];
    var phone_number = this.state.user_phone_number_code + this.state.user_phone_number;
    if (this.state.usr_name?.length == 0) {
      // errors.push('Name field is required.');
      // this.setState({errors});
      toast.dark('🚀 Name field is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (phone_number?.length == 0) {
      // errors.push('Phone Number field is required.');
      // this.setState({errors});
      toast.dark('🚀 Phone Number is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    const business_otp_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: phone_number,
      name: this.state.usr_name,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/create/business_evaluation', business_otp_details)
      .then((res) => {
        console.log('res.data.status', res.data.status);
        if (res.data.status === 'success') {
          this.otpTimer();
          toast.dark('🚀' + res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('#warning_msg').hide();
          this.setState({evaluation_id: res.data.result._id});
          $('.cvv_get_ph_name').hide();
          $('.cvv_sent_otp').hide();
          $('.cvv_ph_verify').show();
          $('#cvv_submit_otp').show();

          this.setState({otp_time: false});
          setTimeout(() => {
            this.setState({otp_time: true});
          }, 60000);
        }
      })
      .catch((error) => {
        if (error.response) {
          // if (error.response.data.status === 'conflict') {
          toast.dark('🚀' + error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // errors.push(error.response.data.message);
          // this.setState({errors});
          // setTimeout(() => {
          //   this.setState({errors: []});
          // }, 4000);
          // }
        }
      });
  };

  verify_number() {
    if (this.state.user_otp.length != 4) {
      // const errors = [];
      // errors.push('Please enter the OTP.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      toast.dark('🚀 Please enter the OTP.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const OTP_Verify = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        otp: this.state.user_otp,
        evaluation_id: this.state.evaluation_id,
      };
      axios
        .post(API_CALL_ENDPOINT + '/business/business_evaluation_phone/verify', OTP_Verify)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark('🚀' + res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            $('.cvv_ph_verify').hide();
            $('#cvv_submit_otp').hide();
            $('.cvv_business_detail').show();
            $('#cvv_submit_details').show();
          }
        })
        .catch((error) => {
          console.log('error.response', error.response);
          console.log('error.response.data.message', error.response.data.error.message);
          // if (error.response) {
          //   const errors = [];
          //   errors.push(error.response.data.error.message);
          //   this.setState({errors});
          //   setTimeout(() => {
          //     this.setState({errors: []});
          //   }, 4000);
          // }
          toast.dark('🚀' + error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  error_toaster_show = (msg) => {
    toast.dark('🚀' + msg, {
      position: 'top-right',
      transition: Flip,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  business_evaluation_submit() {
    if (this.state.business_name.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Business name.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Business name.');
    } else if (this.state.business_type_value.length == 0) {
      // const errors = [];
      // errors.push('Please select the Business type.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please select the Business type.');
    } else if (this.state.business_type_value == 'others' && this.state.business_type_name_value.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Business type.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Business type.');
    } else if (this.state.tax_number.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Tax Number/ID/EIN.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Tax Number/ID/EIN.');
    } else if (this.state.address.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Address.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Address.');
    } else if (this.state.city.length == 0) {
      // const errors = [];
      // errors.push('Please enter the City.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the City.');
    } else if (this.state.state_value.length == 0) {
      // const errors = [];
      // errors.push('Please enter the State.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the State.');
    } else if (this.state.zip_code.length == 0) {
      // const errors = [];
      // errors.push('Please enter the Zip code.');
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      // }, 3000);
      this.error_toaster_show('Please enter the Zip code.');
    } else {
      if (this.state.business_type_name_value?.length != 0 && this.state.business_type_value == 'others') {
        const business_types = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          business_type: this.state.business_name,
          business_type: this.state.business_type_name_value,
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/create/business_types', business_types)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              this.setState({business_type_value: res.data.result._id});
              setTimeout(() => {
                this.submit_business_evaluation();
              }, 100);
            }
          })
          .catch((error) => {
            if (error.response) {
              const errors = [];
              errors.push(error.response.data.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 4000);
            }
          });
      } else {
        this.submit_business_evaluation();
      }
    }
  }

  submit_business_evaluation = () => {
    const business_evaluation = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      business_name: this.state.business_name,
      business_type: this.state.business_type_value,
      tax_number: this.state.tax_number,
      address: this.state.address,
      state: this.state.state_value,
      city: this.state.city,
      zip: this.state.zip_code,
      additional_information: this.state.additional_information,
      evaluation_id: this.state.evaluation_id,
    };
    axios
      .post(API_CALL_ENDPOINT + '/business/update/business_evaluation_details', business_evaluation)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          $('#warning_msg').hide();
          $('.cvv_sent_otp').hide();
          $('#cvv_submit_details').hide();
          $('#cvv_submit_otp').hide();
          $('.cvv_get_ph_name').hide();
          $('.cvv_ph_verify').hide();
          $('.cvv_business_detail').hide();
          $('.success_msg').show().text(res.data.message);
          setTimeout(() => {
            $('.close').trigger('click');
            window.location.reload();
          }, 500);
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          errors.push(error.response.data.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
          }, 4000);
        }
      });
  };

  componentDidUpdate() {}
  show_business_verification() {
    $('.modal_business_verification').show();
  }
  hide_trial_payment() {
    $('.modal_business_verification').hide();

    //this.props.history.push('/dashboard');
  }

  render() {
    console.log('this.state?.business_types', this.state?.business_type_details);
    return (
      <>
        {/* <a onClick={() => this.show_business_verification()} className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">{this.props.name}</a> */}
        <div
          className="modal fade modal_business_verification"
          id="modal_business_verification"
          aria-modal="true"
          style={{paddingRight: 17}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">&nbsp;</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body ml-4 mr-4">
                <div className="cvv_business_vfy_div">
                  <div
                    id="error"
                    className="alert alert-success text-center success_msg"
                    style={{display: 'none'}}></div>
                  {this.state.errors.map((error) => (
                    <div id="error" className="alert alert-danger text-center" key={error}>
                      {error}
                    </div>
                  ))}
                  <div id="warning_msg" className="alert alert-warning text-center">
                    Please verify your business identity by filling out the below form, we shall verify your business
                    and approve your workspace within 24 hours.
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <div className="cvv_get_ph_name">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            onChange={(e) => this.setState({usr_name: e.target.value})}
                            className="form-control"
                            placeholder="Enter your full name..."
                          />
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          {this.state.enabled_country_lists.length !== 0 ? (
                            <IntlTelInput
                              defaultCountry={'us'}
                              containerClassName="intl-tel-input"
                              inputClassName="form-control text-center"
                              style={{width: '100 %'}}
                              allowDropdown={true}
                              onlyCountries={this.state.enabled_country_lists}
                              preferredCountries={[]}
                              placeholder="Enter the Phone Number"
                              value={this.state.user_phone_number
                                .replace(/[^\dA-Z]/g, '')
                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                .trim()}
                              onPhoneNumberChange={(status, value, countryData, number, id) => {
                                console.log(value.length);
                                if (value.length < 15) {
                                  this.setState({user_phone_number: value});
                                }
                                this.setState({
                                  user_phone_number_code: countryData.dialCode,
                                });
                              }}
                              onSelectFlag={(status, value, countryData, number, id) => {
                                console.log('value', value);
                                this.setState({
                                  user_phone_number_code: value.dialCode,
                                });
                              }}
                            />
                          ) : (
                            ''
                          )}
                          {/* <input type="text" className="form-control" placeholder="Enter phone for sending OTP..." /> */}
                        </div>
                      </div>
                      <div className="cvv_ph_verify" style={{display: 'none'}}>
                        <div className="form-group row clearfix mb-3">
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="digit-group">
                              <label className="col-12 col-form-label text-center px-0 pt-0 font-weight-semibold">
                                Enter your OTP
                              </label>
                              <PinInput
                                length={4}
                                initialValue=""
                                // secret
                                onChange={(value, index) => {
                                  this.setState({user_otp: value});
                                }}
                                type="numeric"
                                inputMode="number"
                                style={{
                                  width: '250px',
                                  // height: "40px"
                                }}
                                inputStyle={{borderColor: 'red'}}
                                inputFocusStyle={{borderColor: 'blue'}}
                                onComplete={(value, index) => {}}
                                autoSelect={true}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <label className='col-12 col-form-label text-center px-0 pt-0 font-weight-semibold'>Enter your OTP</label>
                          <PinInput
                            length={4}
                            initialValue=""
                            // secret
                            onChange={(value, index) => {
                              this.setState({user_otp: value});
                            }}
                            type="numeric"
                            inputMode="number"
                            style={{
                              width: '250px',
                              // height: "40px"
                            }}
                            inputStyle={{borderColor: 'red'}}
                            inputFocusStyle={{borderColor: 'blue'}}
                            onComplete={(value, index) => {}}
                            autoSelect={true}
                          />
                        </div> */}
                      </div>
                      <div className="cvv_business_detail" style={{display: 'none'}}>
                        <div className="form-group">
                          <label>Business Name</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => this.setState({business_name: e.target.value})}
                            placeholder="Enter your full name..."
                          />
                        </div>
                        <div className="form-group">
                          <label>Business Type</label>
                          <select
                            className="form-control"
                            id="business_type_drpdn"
                            onChange={(e) => this.setState({business_type_value: e.target.value})}>
                            <option value="">Select</option>
                            {this.state?.business_type_details?.map((business_types_values) => {
                              return (
                                <option value={business_types_values._id}>{business_types_values.business_type}</option>
                              );
                            })}
                            <option value="others">Others</option>
                          </select>
                        </div>
                        <div className="form-group other_type" style={{display: 'none'}}>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => this.setState({business_type_name_value: e.target.value})}
                            placeholder="Enter business type..."
                          />
                        </div>
                        <div className="form-group">
                          <label>Tax Number/ID/EIN</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => this.setState({tax_number: e.target.value})}
                            placeholder="Enter ..."
                          />
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group bg_r_light p-3">
                              <label>Company Address</label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Street address</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => this.setState({address: e.target.value})}
                                placeholder="Enter ..."
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => this.setState({city: e.target.value})}
                                placeholder="Enter ..."
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => this.setState({state_value: e.target.value})}
                                placeholder="Enter ..."
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>ZIP/ Postal Code</label>
                              <input
                                type="number"
                                className="form-control"
                                onChange={(e) => this.setState({zip_code: e.target.value})}
                                placeholder="Enter ..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Additional info...</label>
                              <textarea
                                className="form-control"
                                onChange={(e) => this.setState({additional_information: e.target.value})}
                                rows={3}
                                placeholder="Enter ..."
                                spellCheck="false"
                                defaultValue={''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-transparent mb-3 cvv_sent_otp" id="cvv_sent_otp">
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    onClick={() => (this.state.otp_time ? this.sentOtp() : null)}
                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">
                    Send OTP
                  </button>
                </div>
              </div>
              <div className="bg-transparent mb-3" id="cvv_submit_otp" style={{display: 'none'}}>
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    onClick={() => this.verify_number()}
                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">
                    Submit
                  </button>
                  <p className="fmaroon mt-2">
                    <div class="all_link mt-2 text-center ResendOtp">
                      <a
                        className={this.state.otp_time ? '' : 'isdisabled'}
                        onClick={() => (this.state.otp_time ? this.sentOtp() : null)}
                        href="#">
                        <span class="f_sz15">
                          <i class="fas fa-sync-alt mr-2"></i>Resend OTP&nbsp;
                        </span>
                      </a>
                      <span id="timer">
                        <span id="time">60</span>&nbsp;Seconds
                      </span>
                    </div>
                  </p>
                </div>
              </div>
              <div className="bg-transparent mb-3" style={{display: 'none'}} id="cvv_submit_details">
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    onClick={() => this.business_evaluation_submit()}
                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2 ss">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
