import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import {stopReportingRuntimeErrors} from 'react-error-overlay';
//console.log = function () {};
// stopReportingRuntimeErrors();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
