import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import PropTypes from "prop-types";
import AsyncStorage from "@callstack/async-storage";
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link
//   } from "react-router-dom";
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, { loadCSS, removeCSS } from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from "../assets/img/redtie_logo.png"
import signup_getstarted from "../assets/img/signup_getstarted.png"
import { render } from 'react-dom';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

function validate(code) {
    // we are going to store errors for all fields
    // in a signle array
    const errors = [];

    if (code.length === 0) {
        errors.push("Code can't be empty");
    }

    return errors;
}
// export default class SignUpEmailcode extends Component
export default class SignUpEmailcode extends Component {
    constructor(props) {
        super(props)
        this.onChangecode = this.onChangecode.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.reSendotp = this.reSendotp.bind(this);
        this.state = {
            user_email: '',
            user_id: '',
            user_ip_location: '',
            code: '',
            success: [],
            errors: [],
            isLoading: false,
        }
    }
    onChangecode(e) {
        this.setState({ code: e.target.value })
    }
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    componentWillUnmount() {
        removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
        removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")

    }
    componentDidMount() {
        loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
        loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")



        // setTimeout(() => {
        //     document.getElementById('code-success').style.display='none'
        //   }, 3000)

        AsyncStorage.getItem("user_email").then(user_email => {
            if (user_email) {
                this.setState({ user_email });
                console.log("email code")
                console.log(user_email)
            }
        });
        AsyncStorage.getItem("user_id").then(user_id => {
            if (user_id) {
                this.setState({ user_id });
                console.log("User_id")
                console.log(user_id)
            }
        });
        AsyncStorage.getItem("user_ip_location").then(user_ip_location => {
            if (user_ip_location) {
                this.setState({ user_ip_location });
                console.log("user_ip_location")
                console.log(user_ip_location)
            }
        });

    }
    reSendotp = (e) => {
        e.preventDefault();
        console.log('The link was clicked.');
        const userObject1 = {
      // email_address: this.state.user_email,
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state?.user_id,
      type:'email'
        };

    axios
      .post(API_CALL_ENDPOINT + '/onboard/otp/send', userObject1)
            .then((res) => {
        if (res.data.status == 'success') {
                    const success = [];
          success.push('We have resent the code.');
                    console.log(this.state.success);
          this.setState({success});
                    setTimeout(() => {
            this.setState({success: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('success').style.display='none'
          }, 3000);
                    // this.props.history.push('/SignUp-setpassword')
                }
                console.log(res.data)
                // alert("opt has been resent successfully")
      })
      .catch((error) => {
        console.log(error);
            });

    this.setState({email: ''});
  };
    onSubmit(e) {
        e.preventDefault()
        this.setState({ isLoading: true });
        const { code } = this.state;

        const errors = validate(code);
        if (errors.length > 0) {
            this.setState({ errors });
            this.setState({ isLoading: false });
            setTimeout(() => {
                this.setState({ errors: [] });
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display='none'
            }, 3000)
            return;
        } else {
            const userObject = {
                user_id: this.state.user_id,
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        otp: this.state.code.trim(),
        type:'email',
        is_login : 'yes'
            };

      axios
        .post(API_CALL_ENDPOINT + '/onboard/otp/verify', userObject)
                .then((res) => {
          console.log(res.data);
          if (res.data.status == 'success') {
            AsyncStorage.setItem('user_email', res.data.result.user_email);
            AsyncStorage.setItem('user_id', res.data.result.user_id);
            AsyncStorage.setItem('business_id', res.data.result.business_id);
            localStorage.setItem('token', 'loggedIn');
                        setTimeout(() => {
              this.setState({errors: []});
              if(res.data.result?.business_id != ""){
                this.props.history.push('/board');
              }else{
                this.props.history.push('/create-profile');
              }

            }, 3000);
           
                    }
          // if (res.data.error.error_type == 'email_otp_wrong') {
          //   this.setState({isLoading: false});
          //   const errors = [];
          //   errors.push('The code you entered is incorrect, please retry with the correct code.');
          //   console.log(this.state.errors);
          //   this.setState({errors});
          //   setTimeout(() => {
          //     this.setState({errors: []});
          //     // document.getElementById("error").remove();
          //     // document.getElementById('error').style.display='none'
          //   }, 3000);
          // }
        })
        .catch((error) => {
                    if (error.response) {
            if (error.response.data.error.error_type == 'email_otp_wrong') {
              this.setState({isLoading: false});
                            const errors = [];
              errors.push('The code you entered is incorrect, please retry with the correct code.');
                            console.log(this.state.errors);
              this.setState({errors});
                            setTimeout(() => {
                this.setState({errors: []});
                                // document.getElementById("error").remove();
                                // document.getElementById('error').style.display='none'
              }, 3000);
                        }
                    }
                });

      this.setState({email: ''});
        }
    }

    // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
    render() {
        const user_email = this.state.user_email;
        const reSendotp = this.state.reSendotp;
        const { errors, success } = this.state;
        return (
            <>
                <Helmet>
                    <title>Redtie - Sign Up</title>
                </Helmet>
                <div className="hold-transition new_signup_bodywrapper">
                    <div className="wrapper">
                        <div className="content-wrapper">
                            <div className="container-fluid">
                                <div className="new_signup_bodydiv">
                                    <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                                        <img
                                            src={redtie_logo}
                                            alt="Redtie"
                                            title="Redtie"
                                            border={0}
                                        />
                                    </div>
                                    <div className="new_overall_signup_container">
                                        <div className="new_overall_signup_bodydiv bg_white">
                                            <div className="col-xs-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                                                <div className="new_signup_leftcontainer">
                                                    <div className="new_signup_titleicon text-center"> <img
                                                        src={signup_getstarted}
                                                        alt="Redtie Get Started"
                                                        border={0}
                                                    /></div>
                                                    <div className="new_signup_title text-center">
                                                        <h1>Get started with your account</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-7">
                                                <div className="new_signup_rightcontainer">
                                                    <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">                           <img
                                                        src={redtie_logo}
                                                        alt="Redtie"
                                                        title="Redtie"
                                                        border={0}
                                                    /> </div>
                                                    <div className="new_signup_innerdiv">
                                                        {/* <div class="new_signup_innermsgicon text-center f_sgreen"><i class="fa fa-envelope-o"></i></div> */}
                                                        {/* <div id="code-success" > */}
                                                        <h3 className="text-center mrgnbtm_25 fmaroon">{/*<i class="fa fa-envelope-o"></i>*/} Thank You!</h3>
                                                        <p className="text-center mrgnbtm_25">We have sent the verification code to <span className="fmaroon fw_osemibold">{user_email}</span>.</p>
                                                        {/* </div> */}
                                                        <form onSubmit={this.onSubmit}>
                                                            {errors.map(error => (
                                                                <div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
                                                            ))}
                                                            {success.map(success => (
                                                                <div id="success" className="alert alert-success text-center" key={success}>{success}</div>
                                                            ))}
                                                            <div className="form-group row clearfix mrgnbtm_15">
                                                                <div className="col-12 col-sm-12 col-md-5">
                                                                    <h3 className="text-center mrgntop_10 mrgnbtm_10">Code</h3>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-7">
                                  <input
                                    type="text"
                                    value={this.state.code}
                                    onChange={this.onChangecode}
                                    className="form-control new_signup_code_wdth mrgn_0auto"
                                    placeholder="Enter Code"
                                  />
                                  <div className="new_signup_link mrgntop_5 text-right">
                                    <a href="#" onClick={this.reSendotp.bind(this)}>
                                      <i className="fa fa-refresh mrgnrgt_10" />
                                      Resend Code
                                    </a>
                                                                </div>
                                                            </div>
                              </div>
                              <div
                                align="center"
                                className="col-12 col-sm-12 mrgntop_25 mrgnbtm_15 clearfix pdnglftrgt_0">
                                                                {/*<a href="signup_phone_NEW.html" class="btn btn-black btnpdng f_sz18 fw_osemibold">Continue</a>*/}
                                                                {/* <a href="SignUp-setpassword" className="btn btn-black btnpdng f_sz18 fw_osemibold">Continue</a>  */}
                                <button
                                  disabled={this.state.isLoading}
                                  className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                  {this.state.isLoading ? 'Processing...' : 'Continue'}
                                </button>
                                                            </div>
                                                        </form>
                            <div
                              align="center"
                              className="col-12 col-sm-12 mrgntop_25 mrgnbtm_15 clearfix pdnglftrgt_0">
                              <div className="new_signup_link">
                                <a href="/">
                                  <i className="fas fa-long-arrow-alt-left" /> Start Over
                                </a>
                                                        </div>
                                                    </div>
                          </div>
                                                    {/*<div align="center" class="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
        <div class="new_signup_link"><a href="#">Sign In to my account <i class="fa fa-long-arrow-right"></i></a></div>
       </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.register-box */}
                                </div>
                            </div>
                        </div>
                        {/* <footer className="main-footer">
                        <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved.
  </footer> */}
                    </div>
                    {/* jQuery 3 */}
                    {/* Bootstrap 3.3.7 */}
                    {/* AdminLTE App */}
                </div>
            </>
        );
    }
}
