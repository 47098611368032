import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Redirect} from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import signup_getstarted from '../assets/img/signup_getstarted.png';
import redtie_google_btn_sm from '../assets/img/redtie_google_btn_sm.png';
import redtie_microsoft_btn_sm from '../assets/img/redtie_microsoft_btn_sm.png';
// import redtie_yahoo_btn_sm from "../assets/img/redtie_yahoo_btn_sm.png"
import MicrosoftLogin from 'react-microsoft-login';
import {detect} from 'detect-browser';
// axios.defaults.baseURL = "https://stagingrv4.redtie.co:1221";
const browser_name = detect();
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
function validate(email) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (email.length === 0) {
    errors.push("Email can't be empty");
  }
  return errors;
}

export default class SignUp extends Component {
  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    let loggedIn = true;
    if (!token) {
      loggedIn = false;
    } else {
      window.location = '/dashboard';
    }
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    this.onChangeerror = this.onChangeerror.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmityahoo = this.onSubmityahoo.bind(this);
    this.authHandler = this.authHandler.bind(this);

    this.state = {
      email: '',
      user_id: '',
      user_email: '',
      loggedIn,
      success: [],
      errors: [],
      isLoading: false,
      ip_location: {},
      user_agent: '',
      browser_name: '',
      plan_name: '',
    };
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '357140609134-0mcqna5qmqqk4dv1l6uectm1dhtuns12.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
        let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log('params', params);
    if (params?.signup === 'true') {
      localStorage.setItem('token', 'loggedIn');
      AsyncStorage.setItem('user_id', params?.user);
      AsyncStorage.setItem('user_email', params?.email);
      this.setState({
        loggedIn: true,
        user_id: params?.user,
      });
      this.props.history.push('/create-profile');
      window.location.reload(true);
    }
    axios
    .get('https://www.cloudflare.com/cdn-cgi/trace%27')
    .then((res) => {
      let data = res.data.trim().split('\n').reduce(function(obj, pair) {
        pair = pair.split('=');
        return obj[pair[0]] = pair[1], obj;
      }, {});
      console.log('data', data);
      if (data) {
       this.setState({
          ip_location: data,
          user_agent: data.uag,
          browser_name: browser_name.name,
        });
        AsyncStorage.setItem('user_ip_location', data);
      }
      if (res.data.error) {
      }
    })
    .catch((error) => {
      console.log(error);
    });
    // axios
    //   .get('https://api.ipify.org/?format=json')
    //   .then((res) => {
    //     if (res.data) {
    //       console.log('Ip address', res.data.ip);
    //       this.setState({
    //         ip_location: res.data,
    //         user_agent: navigator.userAgent,
    //         browser_name: browser_name.name,
    //       });
    //       AsyncStorage.setItem('user_ip_location', res?.data?.ip);
    //     }
    //     if (res.data.error) {
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error)
    //   });
    // axios
    //   .get('https://freegeoip.app/json/')
    //   .then((res) => {
    //     if (res.data) {
    //       // console.log('https://freegeoip.app/json/', res.data);
    //       // console.log('user-agent', navigator.userAgent);
    //       // console.log('browser_name.name', browser_name.name);
    //       this.setState({
    //         ip_location: res.data,
    //         user_agent: navigator.userAgent,
    //         browser_name: browser_name.name,
    //       });
    //       AsyncStorage.setItem('user_ip_location', res?.data?.ip);
    //     }
    //     if (res.data.error) {
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error)
    //   });

    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');

    this.googleSDK();
  }
  businessInfo = (bid) => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: bid,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({plan_name: res.data?.result?.business?.plan_id?.plan_name});
        } else {
          this.setState({plan_name: ''});
        }
      })
      .catch((error) => {});
  };
  prepareLoginButton = () => {
    // console.log(this.refs.googleLoginBtn);
    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
        const userAuth = {
          email_address: profile.getEmail(),
          apikey: API_KEY,
          secret: API_SECRET,
          signup_type: 'gmail',
          ip_address: this.state?.ip_location?.ip,
          country: this.state?.ip_location?.country_name,
          agent: this.state?.user_agent,
          browser: this.state?.browser_name,
        };
        // console.log("userObject",userAuth)
        // return;
        axios
          .post(API_CALL_ENDPOINT + '/onboard/user/signup', userAuth)
          .then((res) => {
            localStorage.setItem('token', 'loggedIn');
            this.setState({loggedIn: true});
            if (res.data.status == 'success') {
              const success = [];
              success.push('Gmail Login successfully');
              console.log(this.state.success);
              this.setState({success});
              var success_message = res.data.message;
              // window.location.reload(true);
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              if (res.data.result?.skip_simplified == '0') {
                AsyncStorage.setItem('user_first_time', true);
                const user_update_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: res.data.result.user_id,
                  setup: 'simplified',
                  skip_simplified: '1',
                };
                axios.post(API_CALL_ENDPOINT + '/user/update', user_update_details).then((res) => {
                  console.log(res);
                  console.log(res.data.message);

                  if (res.data.status == 'success') {
                  }
                });
              }else{
                AsyncStorage.setItem('user_first_time', '');
              }

              const businessList = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.user_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/business/list', businessList)
                .then((res) => {
                  console.log(res.data);
                  // console.log(res.data.result[0].phone_number.length)
                  if (res.data.status === 'success') {
                    var array = res.data.result;
                    var arrayLength = res.data.result.length;
                    for (var i = 0; i < arrayLength; i++) {
                      if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                        this.businessInfo(res.data.result[i]._id);
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length === 0 &&
                          res.data.result[i].business_name?.length === 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                            this.props.history.push('/create-profile1');
                          }
                        }
                        if (
                          res.data.result[i].phone_number?.length === 0 &&
                          res.data.result[i].subscription_id?.length !== 0
                        ) {
                          document.body.classList.remove('sidebar-mini');
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                            this.props.history.push('/create-profile1');
                          }
                        }
                        if (
                          res.data.result[i].phone_number.length > 0 &&
                          res.data.result[i].business_name.length === 0
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          if (this.state.plan_name === 'Bring your own messaging service provider') {
                            this.props.history.push('/createProfileServiceProvider');
                          } else {
                            this.props.history.push('/create-profile2');
                          }
                        }
                        if (
                          (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                          !res.data.result[i].subscription_id
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          // alert("1");
                          //window.location.reload(true);
                          if(success_message == "User has been created successfully."){
                            AsyncStorage.setItem('user_first_time', true);
                            setTimeout(() => this.props.history.push('/board'), 500);
                          }else{
                            setTimeout(() => this.props.history.push('/dashboard'), 500);
                        }
                        }
                      } else if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        if(success_message == "User has been created successfully."){
                          AsyncStorage.setItem('user_first_time', true);
                          setTimeout(() => this.props.history.push('/board'), 1500);
                       }else{
                          setTimeout(() => this.props.history.push('/dashboard'), 1500);
                      }
                        
                    }
                  }
                  }
                  if (res.data.status === 'no_record') {
                    this.props.history.push('/create-profile');
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error?.response?.data?.status === 'no_record') {
                      this.props.history.push('/create-profile');
                    }
                  }
                });
              localStorage.setItem('token', 'loggedIn');
              this.setState({loggedIn: true});

              // setTimeout(() => {
              //   this.setState({ isLoading: false });
              //   this.props.history.push('/create-profile')
              //   // document.getElementById('error').style.display='none'
              // }, 3000)
              setTimeout(() => {
                // this.props.history.push('/create-profile')
              }, 3000);
              // this.props.history.push('/SignUp-setpassword')
            }
            console.log(res.data);
            // alert("opt has been resent successfully")
          })
          .catch((error) => {
            if (error.response) {
              const errors = [];
              errors.push(error.response.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 4000);
            }
          });
      },
      (error) => {
        // console.log(JSON.stringify(error, undefined, 2));
      },
    );
  };
  authHandler = (err, data) => {
    // const email_address = "";
    // console.log(err, data);
    try {
      if (err) {
        const errors = [];
        console.log(err);
        errors.push('Please try again');
        this.setState({errors});
        setTimeout(() => {
          this.setState({errors: []});
          // document.getElementById("error").remove();
          // document.getElementById('error').style.display = 'none'
        }, 3000);
        return errors;
      } else {
        const userAuth = {
          email_address: data.authResponseWithAccessToken.account.userName,
          apikey: API_KEY,
          secret: API_SECRET,
          signup_type: 'microsoft',
          ip_address: this.state?.ip_location?.ip,
          country: this.state?.ip_location?.country_name,
          agent: this.state?.user_agent,
          browser: this.state?.browser_name,
        };
        //   console.log("userObject",userAuth)
        //  return;
        axios
          .post(API_CALL_ENDPOINT + '/onboard/user/signup', userAuth)
          .then((res) => {
            localStorage.setItem('token', 'loggedIn');
            this.setState({loggedIn: true});
            if (res.data.status == 'success') {
              const success = [];
              success.push('Microsoft Login successfully');
              console.log(this.state.success);
              this.setState({success});
              var success_message = res.data.message;
              window.location.reload(true);
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);

              AsyncStorage.setItem('user_id', res.data.result.user_id);
              if (res.data.result?.skip_simplified == '0') {
                AsyncStorage.setItem('user_first_time', true);
                const user_update_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: res.data.result.user_id,
                  setup: 'simplified',
                  skip_simplified: '1',
                };
                axios.post(API_CALL_ENDPOINT + '/user/update', user_update_details).then((res) => {
                  console.log(res);
                  console.log(res.data.message);

                  if (res.data.status == 'success') {
                  }
                });
              }else{
                AsyncStorage.setItem('user_first_time', '');
              }
              const businessList = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.user_id,
                },
              };

              axios
              .get(API_CALL_ENDPOINT + '/business/list', businessList)
              .then((res) => {
                console.log(res.data);
                // console.log(res.data.result[0].phone_number.length)
                if (res.data.status === 'success') {
                  var array = res.data.result;
                  var arrayLength = res.data.result.length;
                  for (var i = 0; i < arrayLength; i++) {
                    if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                      if (
                        res.data.result[i].phone_number?.length === 0 &&
                        res.data.result[i].subscription_id?.length === 0 &&
                        res.data.result[i].business_name?.length === 0
                      ) {
                        document.body.classList.remove('sidebar-mini');
                        console.log('phone empty');
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                        this.props.history.push('/create-profile1');
                      }
                      if (
                        res.data.result[i].phone_number?.length === 0 &&
                        res.data.result[i].subscription_id?.length !== 0
                      ) {
                        document.body.classList.remove('sidebar-mini');
                        console.log('phone empty');
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);

                        this.props.history.push('/create-profile1');
                      }
                      if (
                        res.data.result[i].phone_number.length > 0 &&
                        res.data.result[i].business_name.length === 0
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        this.props.history.push('/create-profile2');
                      }
                      if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        window.location.reload(true);
                        if(success_message == "User has been created successfully."){
                          AsyncStorage.setItem('user_first_time', true);
                          setTimeout(() => this.props.history.push('/board'), 1500);
                        }else{
                          setTimeout(() => this.props.history.push('/dashboard'), 1500);
                        }
                       
                      }
                    } else if (
                      (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                      !res.data.result[i].subscription_id
                    ) {
                      console.log('phone not empty');
                      console.log(res.data.result[i].phone_number);
                      AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                      AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                      AsyncStorage.setItem('business_id', res.data.result[i]._id);
                      AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                      window.location.reload(true);
                      if(success_message == "User has been created successfully."){
                        AsyncStorage.setItem('user_first_time', true);
                        setTimeout(() => this.props.history.push('/board'), 1500);
                      }else{
                        setTimeout(() => this.props.history.push('/dashboard'), 1500);
                      }
                    }
                  }
                }
                if (res.data.status === 'no_record') {
                  this.props.history.push('/create-profile');
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error?.response?.data?.status === 'no_record') {
                    this.props.history.push('/create-profile');
                  }
                }
              });
              localStorage.setItem('token', 'loggedIn');
              this.setState({loggedIn: true});

              // setTimeout(() => {
              //   this.setState({ isLoading: false });
              //   this.props.history.push('/create-profile')
              //   // document.getElementById('error').style.display='none'
              // }, 3000)
              setTimeout(() => {
                // this.props.history.push('/create-profile')
              }, 3000);
              // this.props.history.push('/SignUp-setpassword')
            }
            console.log(res.data);
            // alert("opt has been resent successfully")
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  onChangeUserEmail(e) {
    this.setState({email: e.target.value});
  }
  onChangeerror(e) {
    this.setState({errors: e.target.value});
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  onSubmityahoo(e) {
    e.preventDefault();
    const userObject = {
      client_id: 'dj0yJmk9cjZWbnczTVdXa3RNJmQ9WVdrOVRWTlBRWHBKTlRRbWNHbzlNQS0tJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWIz',
      response_type: 'code',
      redirect_uri: 'https://stagingrv4.redtie.co/',
    };

    axios
      .get('https://api.login.yahoo.com/oauth2/request_auth', userObject)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onSubmit(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {email} = this.state;
    const errors = validate(email);
    if (errors.length > 0) {
      console.log(errors);
      console.log('clicked');
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // document.getElementById("error").remove();
        // document.getElementById('error').style.display = 'none'
      }, 3000);
      return;
    } else {
      const userObject = {
        email_address: this.state.email,
        apikey: API_KEY,
        secret: API_SECRET,
        ip_address: this.state?.ip_location?.ip,
        country: this.state?.ip_location?.loc,
        agent: this.state?.user_agent,
        browser: this.state?.browser_name,
      };
      //  console.log("userObject",userObject)
      //  return;
      axios
        .post(API_CALL_ENDPOINT + '/onboard/user/signup', userObject)
        .then((res) => {
          // console.log(res.data.error.error_type)
          console.log(res.data.result);
          // return false;
          if (res.data.status === 'success') {
            // const success = [];
            // success.push("Otp has been sent successfully ");
            // console.log(this.state.success);
            // this.setState({ success });
            this.setState({isLoading: false});
            AsyncStorage.setItem('user_email', res.data.result.email_address);
            AsyncStorage.setItem('user_id', res.data.result.user_id);
            AsyncStorage.setItem('business_id', res.data.result.business);
            AsyncStorage.setItem('business_name', 'Guest Workspace');
            AsyncStorage.setItem('user_first_time', true);
            const user_update_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: res.data.result.user_id,
              setup: 'skip_simplified',
              skip_simplified: '1',
            };
            axios.post(API_CALL_ENDPOINT + '/user/update', user_update_details).then((res) => {
              console.log(res);
              console.log(res.data.message);

              if (res.data.status == 'success') {
              }
            });
            localStorage.setItem('token', 'loggedIn');

            setTimeout(() => {
              // document.getElementById("error").remove();
              this.props.history.push('/board');
            }, 3000);
            console.log(this.state.user_email);
          }
          if (res.data.result) {
            if (res.data.result.status === 'incomplete') {
              const errors = [];
              errors.push('Your email Address has already been Verified please Set-password');
              console.log(this.state.errors);
              this.setState({errors});
              this.setState({isLoading: false});
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              // this.props.history.push('/SignUp-setpassword')

              setTimeout(() => {
                // document.getElementById("error").remove();
                this.props.history.push('/SignUp-setpassword');
              }, 3000);
            }
            if (res.data.result.status === 'not_verfied') {
              // const success = [];
              // success.push("Otp has been sent successfully ");
              // console.log(this.state.success);
              // this.setState({ success });
              this.setState({isLoading: false});
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              this.props.history.push('/SignUp-Emailcode');

              // setTimeout(() => {
              //   // document.getElementById("error").remove();
              //   this.props.history.push('/SignUp-Emailcode')
              // }, 3000)
            }
          }
          if (res.data.error) {
            if (res.data.error.error_type === 'invalid_email') {
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              console.log(this.state.errors);
              this.setState({isLoading: false});

              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
            if (res.data.error.error_type === 'email_conflict') {
              const errors = [];
              errors.push('This email already exists, please sign in.');
              this.setState({isLoading: false});
              console.log(this.state.errors);
              this.setState({errors});
              console.log(this.state.errors);
              // alert("this email already exists please signin")
              // this.props.history.push('/SignIn')
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/SignIn')
              }, 3000);
              return errors;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            if (error?.response?.data?.result?.status === 'incomplete') {
              const errors = [];
              errors.push('Your email Address has already been Verified please Set-password');
              console.log(this.state.errors);
              this.setState({errors});
              this.setState({isLoading: false});
              AsyncStorage.setItem('user_email', error?.response?.data?.result?.email_address);
              AsyncStorage.setItem('user_id', error?.response?.data?.result?.user_id);
              // this.props.history.push('/SignUp-setpassword')

              setTimeout(() => {
                // document.getElementById("error").remove();
                this.props.history.push('/SignUp-setpassword');
              }, 3000);
            }
            if (error?.response?.data?.result?.status === 'not_verfied') {
              // const success = [];
              // success.push("Otp has been sent successfully ");
              // console.log(this.state.success);
              // this.setState({ success });
              this.setState({isLoading: false});
              AsyncStorage.setItem('user_email', error?.response?.data?.result?.email_address);
              AsyncStorage.setItem('user_id', error?.response?.data?.result?.user_id);
              this.props.history.push('/SignUp-Emailcode');

              // setTimeout(() => {
              //   // document.getElementById("error").remove();
              //   this.props.history.push('/SignUp-Emailcode')
              // }, 3000)
            }
            if (error?.response?.data?.error) {
              if (error?.response?.data?.error.error_type === 'ip_blocked') {
                const errors = [];
                errors.push(error?.response?.data?.error.message);
                console.log(this.state.errors);
                this.setState({errors});
                console.log(this.state.errors);
                this.setState({isLoading: false});

                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
              if (error?.response?.data?.error.error_type === 'invalid_email') {
                const errors = [];
                errors.push(error?.response?.data?.error.message);
                console.log(this.state.errors);
                this.setState({errors});
                console.log(this.state.errors);
                this.setState({isLoading: false});

                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
              if (error?.response?.data?.error.error_type === 'email_conflict') {
                const errors = [];
                errors.push('This email already exists, please sign in.');
                this.setState({isLoading: false});
                console.log(this.state.errors);
                this.setState({errors});
                console.log(this.state.errors);
                // alert("this email already exists please signin")
                // this.props.history.push('/SignIn')
                setTimeout(() => {
                  this.setState({errors: []});
                  // this.props.history.push('/SignIn')
                }, 3000);
                return errors;
              }
            }
          }
        });
    }
  }

  render() {
    // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
    const user_email = this.state.user_email;
    if (this.state.loggedIn) {
      return <Redirect to="/dashboard" />;
    }
    const {errors, success} = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Sign Up</title>
        </Helmet>
        <div className="hold-transition new_signup_bodywrapper">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="new_signup_bodydiv">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                  <a href='https://www.getredtie.com/' target='_blank'>
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                    </a>
                  </div>
                  <div className="new_overall_signup_container">
                    <div className="new_overall_signup_bodydiv bg_white">
                      <div className="col-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                        <div className="new_signup_leftcontainer">
                          <div className="new_signup_titleicon text-center">
                            <img src={signup_getstarted} border={0} alt="Redtie Get Started" />
                          </div>
                          <div className="new_signup_title text-center">
                            <h1>Get started with your account</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="new_signup_rightcontainer">
                          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                          <a href='https://www.getredtie.com/' target='_blank'>
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                    </a>
                          </div>
                          <div
                            align="center"
                            className="col-12 col-sm-12 mrgntop_20 mb-3 mb-md-0 clearfix pdnglftrgt_0">
                            <div className="new_signup_link">
                              Are you an existing user?
                              <span className="ml-2">
                                <a href="SignIn" className="fmaroon">
                                  Sign in here
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="new_signup_innerdiv newsignup_emaildiv">
                            <form onSubmit={this.onSubmit}>
                              {errors.map((error) => (
                                <div
                                  id="error"
                                  onChange={this.onChangeerror}
                                  value={error}
                                  className="alert alert-danger text-center"
                                  key={error}>
                                  {error}
                                </div>
                              ))}
                              {success.map((success) => (
                                <div id="success" className="alert alert-success text-center" key={success}>
                                  {success}
                                </div>
                              ))}
                              {/* <h3 className="text-center mrgnbtm_25">Email Address</h3> */}
                              {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                              {/* <div className="form-group clearfix mrgnbtm_25">
                                <input type="email" value={this.state.email} onChange={this.onChangeUserEmail} className="form-control" placeholder="Enter your Email Address" />
                              </div> */}

                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-envelope" />
                                  </span>
                                </div>
                                <input
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.onChangeUserEmail}
                                  className="form-control"
                                  placeholder="Enter your Email Address"
                                />
                              </div>

                              <div
                                align="center"
                                className="col-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
                                {/* <a href="SignUp-Emailcode" className="btn btn-black btnpdng f_sz18 fw_osemibold">Continue</a> */}
                                <button
                                  disabled={this.state.isLoading}
                                  className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                  {this.state.isLoading ? 'Processing...' : 'Continue'}
                                </button>
                              </div>
                            </form>
                            <div
                              align="center"
                              className="col-12 col-sm-12 mrgntop_20 mrgnbtm_10 clearfix pdnglftrgt_0">
                              <h4 className="signup_ssotxt">
                                <span>Or Sign up with</span>
                              </h4>
                            </div>
                            {/*<div align="center" class="col-12 col-sm-12 clearfix pdnglftrgt_0">
            <div class="newreg_google"><img src="dist/img/redtie_google_btn_sm.png" border="0" alt="Redtie" title="Redtie"/></div>
           </div>*/}
                            {/* <div align="center" class="col-12 col-sm-12 mrgntop_15 clearfix pdnglftrgt_0">
            <div class="newreg_google"><img src="dist/img/redtie_office365_btn.png" border="0" alt="Redtie" title="Redtie"/></div>
           </div>
           
            <div align="center" class="col-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
            <div class="newreg_google"><img src="dist/img/redtie_yahoo_btn.png" border="0" alt="Redtie" title="Redtie"/></div>
           </div>*/}
                            <div align="center" className="col-12 col-sm-12 clearfix mrgntop_15 pdnglftrgt_0">
                              <div className="newreg_otherbtns">
                                <img
                                  className="social_signin"
                                  style={{cursor: 'pointer'}}
                                  src={redtie_google_btn_sm}
                                  ref="googleLoginBtn"
                                  border={0}
                                  alt="Google"
                                  title="Google"
                                />
                                <MicrosoftLogin
                                  buttonTheme="light_short"
                                  clientId="2841e3dc-ba01-4bd2-9da8-bd5c74c0f8fc"
                                  authCallback={this.authHandler}>
                                  <img
                                    className="social_signin"
                                    style={{cursor: 'pointer'}}
                                    src={redtie_microsoft_btn_sm}
                                    border="0"
                                    alt="Microsoft"
                                    title="Microsoft"
                                  />
                                </MicrosoftLogin>
                                {/* <img src={redtie_office365_btn_sm} border={0} alt="Redtie" title="Redtie" /> */}
                                {/* <img onClick={this.onSubmityahoo} className="social_signin" src={redtie_yahoo_btn_sm} border={0} alt="Redtie" title="Redtie" /> */}
                                {/* <MicrosoftLogin clientId="de4fef74-7817-4076-a4d2-f27ba2e000e8" authCallback={this.authHandler} /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div align="center" className="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                            <div className="new_signup_link"><a href="SignIn">Sign In to my account <i className="fa fa-long-arrow-right" /></a></div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.register-box */}
                </div>
              </div>
            </div>
            {/* <footer className="main-footer">
              <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved.
    </footer> */}
          </div>
          {/* jQuery 3 */}
          {/* Bootstrap 3.3.7 */}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
